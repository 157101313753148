import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { useHistory } from 'react-router-dom';

import { LoadingOverlay } from '../LoadingOverlay';
import { getInnerContentHeight } from '../../consts';
import { ActorNotificationMessageTypeValue, NotificationMessage, fetchNotificationView } from '../../lib/api';
import { clearNotificationDetail, selectNotification } from '../../app/notificationSlice';
import { Path } from '../../routes';

import { NotificationDetailContents表示型 } from './NotificationDetailContents表示型';
import { NotificationDetailContentsYesNo型 } from './NotificationDetailContentsYesNo型';
import { NotificationDetailContents確認型 } from './NotificationDetailContents確認型';

import { showErrorAlert } from 'app/applicationSlice';

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20 - 100; // footer分
const r1Height = 38;
const r3Height = 20;
const r2Height = containerHeight - r1Height - r3Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {},
    r1C2: {
      textAlign: 'center',
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 18,
      paddingTop: 20,
      color: theme.palette.text.hint,
    },
    r2: {
      height: r2Height,
    },
    r3: {
      height: r3Height,
    },
  }),
);

function getDetailContents(notification: NotificationMessage | null | undefined, handleOnClose: () => void) {
  if (!notification) return <></>;
  switch (notification.object_type) {
    case ActorNotificationMessageTypeValue.POS側からの出勤依頼:
    case ActorNotificationMessageTypeValue.POS側からの出勤申請変更依頼:
    case ActorNotificationMessageTypeValue.POS側からの出勤移動依頼:
    case ActorNotificationMessageTypeValue.POS側からの出勤取り下げ依頼:
      return <NotificationDetailContentsYesNo型 notification={notification} handleOnClose={handleOnClose} />;

    case ActorNotificationMessageTypeValue.本日シフト:
    case ActorNotificationMessageTypeValue.来週シフトとお客様誕生日予定:
    case ActorNotificationMessageTypeValue.本日イベント:
      return <NotificationDetailContents確認型 notification={notification} handleOnClose={handleOnClose} />;

    default:
      return <NotificationDetailContents表示型 notification={notification} handleOnClose={handleOnClose} />;
  }
}
export const NotificationDetailDialog: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { detail } = useSelector(selectNotification);
  const { notificationId } = useParams();
  const history = useHistory();

  const onClose = () => {
    if (history.length <= 2) {
      history.replace(Path.notifications);
    } else {
      history.goBack();
    }
  };

  useEffect(() => {
    if (!notificationId) {
      dispatch(clearNotificationDetail());
      return;
    }
    if (!!detail && detail.id === notificationId) return;
    (async () => {
      try {
        await dispatch(fetchNotificationView({ id: notificationId }));
      } catch {
        dispatch(showErrorAlert(`通知詳細が取得できませんでした。`));
        onClose();
      }
    })();
  }, [notificationId, detail]);

  const contents = getDetailContents(detail, onClose);
  return (
    <Dialog open={!!notificationId} onClose={onClose} maxWidth={false}>
      {!!notificationId && (
        <Grid container className={classes.container}>
          <Grid item xs={10} className={classNames(classes.r1, classes.r1C1)}></Grid>
          <Grid item xs={2} className={classNames(classes.r1, classes.r1C3)}>
            <CloseIcon onClick={onClose} />
          </Grid>

          <Grid item xs={12} className={classes.r2}>
            {!detail || detail.id !== notificationId ? (
              <LoadingOverlay />
            ) : (
              <div className={classes.r2}>{contents}</div>
            )}
          </Grid>

          <Grid item xs={12} className={classes.r3}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
