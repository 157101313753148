import React, { CSSProperties } from 'react';
import { AppBar, IconButton, Toolbar } from '@material-ui/core';
import NotificationsIcon from '@material-ui/icons/Notifications';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import { Link, useHistory, useLocation } from 'react-router-dom';
// import ReactGA from "react-ga4";

import styles from './Header.module.css';

import { Path } from 'routes';
import { imagePath } from 'lib/pathes';
import { CountMark } from 'components/CountMark';
import {selectHome} from "../app/homeSlice";
import { useSelector } from 'react-redux';
import {ActorSwitcher} from "../components/ActorSwitcher";

type Props = {
  className?: string;
  style?: CSSProperties;
};

export const Header = (props: Props) => {
  const location = useLocation();
  const history = useHistory();
  const home = useSelector(selectHome);

  return (
    <>
      <AppBar position='fixed' elevation={0} className={props.className} style={props.style}>
        <Toolbar variant='dense'>
          {location.pathname.startsWith(Path.help) ||
          location.pathname.startsWith(Path.my) ||
          location.pathname.startsWith(Path.notifications) ||
          location.pathname.startsWith(Path.config) ||
          location.pathname === Path.gacha ||
          location.pathname.startsWith(Path.gachaMovieCheck) ||
          location.pathname.startsWith(Path.gachaLotteryHistory) ||
          location.pathname.startsWith(Path.gachaExchangeHistory) ||
          location.pathname.startsWith(Path.gachaExchange) || 
          location.pathname.startsWith(Path.gachaDetailPage) ? (
            <IconButton
              aria-label='Back'
              onClick={() => {
                if (history.length <= 1) {
                  history.replace(Path.home);
                } else {
                  history.goBack();
                }
              }}
              className={styles.backButton}
            >
              <ArrowBackIosIcon />
            </IconButton>
          ) : (
            <IconButton aria-label='MyPage' color='inherit' component={Link} to={Path.my} className={styles.homeButton}>
              <img src={imagePath('home_my page.png')} className={styles.homeIcon} alt='home' />
              <div className={styles.homeIconLabel}>MY PAGE</div>
            </IconButton>
          )}

          <div className={styles.logoHorizontallyCenter}>
            {
              (home.loaded && !!home.switchableActorInfos)
              ? <ActorSwitcher />
              : <img src={imagePath('logo_474.png')} className={styles.logo} alt='logo' />
            }
          </div>
          <div className={styles.grow} />
          <IconButton
            aria-label='Notification'
            onClick={() => {
              if (!location.pathname.startsWith(Path.notifications)) {
                history.push(Path.notifications);
              }
            }}
            className={styles.notificationButton}
          >
            <NotificationsIcon />
            <CountMark />
          </IconButton>
        </Toolbar>
      </AppBar>
      <Toolbar variant='dense' />
    </>
  );
};
