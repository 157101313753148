import React from 'react';

import { LoadingOverlay } from '../components/LoadingOverlay';

// TODO 初期表示ページ ロゴなど表示する
export const InitialPage: React.FC = () => {
  return (
    <>
      <LoadingOverlay />
    </>
  );
};
