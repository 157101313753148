import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  EffectDetail,
  effectImageHeight,
  effectImageWidth,
} from "../../lib/effectLib";
import classNames from 'classnames';
import {assetPath} from "../../lib/pathes";

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  monthImage:{
    width: effectImageWidth(184),
    height: effectImageHeight(87),
    top: effectImageHeight(407),
  },
}));

export function getEffectSheetType1PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}
interface Props {
  sheet: EffectDetail;
  className: string;
}

// SheetLayoutType.月
export function EffectSheetType1(props: Props) {
  const classes = useStyles();

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <img
      className={classNames(classes.image, classes.horizontalCenter, classes.monthImage)}
      src={assetPath(`img/effect/zetaisu/zetaisu/zetaisu_${props.sheet.dto.param1}.png`)}
      alt="month"
    />
  </div>;
}
