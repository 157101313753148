// native <-> js インターフェース
import { setNextUrl } from '../app/applicationSlice';
import { store } from '../app/store';

import { fetchNotificationList } from './api';
import { checkVersions } from './checkFrontVersion';

// native -> jsのハンドラ登録
export function registerHandlers() {
  // アプリがフォアグラウンドに戻った際に実行される TODO 名前変更
  window.loadNotificationCount = enterForegroundHandler;

  window.pushHistory = pushHistory;
}

function enterForegroundHandler() {
  checkVersions();
  store.dispatch(fetchNotificationList({ limit: 0, offset: 0, cause: 'foreground' }));
}

function pushHistory(url) {
  store.dispatch(setNextUrl(url));
}

// js -> native
export function callNativeSyncRestCount(restCount) {
  callNative('syncRestCount', restCount);
}

export function callNativeApiCallDone() {
  callNative('apiCallDone', {});
}

export function callNativePrepareGachaResources() {
  let urls = [];
  urls.push(process.env.REACT_APP_GACHA_RESOURCE_PATH + process.env.REACT_APP_GACHA_BGM_FILENAME);
  const movieFileNames = process.env.REACT_APP_GACHA_MOVIE_FILENAMES.split(',');
  urls = urls.concat(
    movieFileNames.map(
      (filename) => process.env.REACT_APP_GACHA_RESOURCE_PATH + filename + process.env.REACT_APP_GACHA_MOVIE_EXT,
    ),
  );
  callNative('apiPrepareGachaResources', JSON.stringify(urls));
}

export function callNativePlayGachaMovies(arg) {
  callNative('apiPlayGachaMovies', JSON.stringify(arg));
}

export function isBridgeDefined(name) {
  if(window.webkit && !window.webkit.messageHandlers[name]){
    return false
  }
  if(window.Android && !window.Android[name]){
    return false
  }
  return true
}

function callNative(name, arg = null) {
  if (window.webkit && window.webkit.messageHandlers[name]) {
    window.webkit.messageHandlers[name].postMessage(arg);
  }
  if (window.Android && window.Android[name]) {
    window.Android[name](arg);
  }
}
