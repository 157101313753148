import {
  GachaLotteryNormalResponse,
  お客様Type,
  お客様TypeValue,
  シャンパンType,
  シャンパンTypeValue,
  ドレスType,
  ドレスTypeValue, メッセージカードType, メッセージカードTypeValue,
  予告TypeValue,
  排出Type,
  排出TypeValue,
  魚群Type,
  魚群TypeValue,
} from './api';
import { getPhotoPath } from './pathes';

export type GachaItem = {
  url: string;
  loop: boolean;
  bgmVol: number;
};
export type GachaArg = {
  bgm: string;
  title: string;
  message: string;
  imageUrl: string;
  items: GachaItem[];
  debug?: boolean;
};
export const BGM_VOL_BASE = 0.45;
export const BGM_VOL_MIN = 0.04;
const BGM_VOL_MIN_PREFIX = 'c007_1';
const LOOP_PREFIX = 'c006_02';
const KANJI_NUMS = ['零', '一', '二', '三', '四', '五', '六', '七', '八', '九'];

export function buildGachaArg(r: GachaLotteryNormalResponse): GachaArg | null {
  const items: GachaItem[] = [];
  if (!r.初回抽選結果) {
    return null;
  }

  // 初回抽選リザルト
  const ドレスID = getドレスID(r.初回抽選結果.ドレス);
  const お客様ID = getお客様ID(r.初回抽選結果.お客様);
  const シャンパンID = getシャンパンID(r.初回抽選結果.シャンパン);
  const 魚群ID = get魚群ID(r.初回抽選結果.魚群);
  switch (r.初回抽選結果.予告) {
    case 予告TypeValue.N:
      break;
    case 予告TypeValue.HN:
      items.push(createItem('c001_01')); // 過激
      break;
    case 予告TypeValue.R:
      items.push(createItem('c001_02')); // 熱い
      break;
    case 予告TypeValue.HR:
      items.push(createItem('c001_04')); // レインボー確定？
      break;
    case 予告TypeValue.SR:
      items.push(createItem('c001_05')); // レインボー確定！
      break;
  }
  // items.push(createItem(`c002_${ドレスID}`)); // ご指名です
  // items.push(createItem(`c003_${ドレスID}`)); // 振り返り
  items.push(createItem(`c002_c003_${ドレスID}_fc24`)); // ご指名です + 振り返り

  items.push(createItem(`c004_${お客様ID}_${ドレスID}`)); // よし、シャンパン入れるぞ
  items.push(createItem(`c005_${ドレスID}_s${シャンパンID}${魚群ID}`)); // シャンパン頂きました
  items.push(createItem(`c006_02_s${シャンパンID}`)); // シャンパン待機
  if (r.初回抽選結果.排出 !== 排出TypeValue.F) {
    // 成功
    const 宝箱ID = get宝箱ID(r.初回抽選結果.排出);
    const メッセージカードID = getメッセージカードID(r.当選景品!.メッセージカード);
    items.push(createItem(`c006_03_s${シャンパンID}`)); // シャンパンオープン - 成功
    items.push(createItem(`c007_2_t${宝箱ID}_s${シャンパンID}`)); // シャンパンタワー
    items.push(createItem(`c009_r${宝箱ID}_${メッセージカードID}`)); // 宝箱とメッセージカード
  } else {
    // 失敗
    items.push(createItem(`c006_04_s${シャンパンID}`)); // シャンパンオープン - 失敗
    if (!r.復活抽選結果) {
      items.push(createItem(`c007_1_${ドレスID}_s${シャンパンID}`)); // 失敗しちゃった
    } else {
      items.push(createItem(`c007_1${お客様ID}_${ドレスID}_s${シャンパンID}`)); // 失敗しちゃった -> もう一本
    }
  }

  // 復活抽選リザルト
  if (r.復活抽選結果) {
    const 復活シャンパンID = getシャンパンID(r.復活抽選結果.シャンパン);
    const 復活ドレスID = getドレスID(r.復活抽選結果.ドレス);
    const 復活宝箱ID = get宝箱ID(r.復活抽選結果.排出);
    const メッセージカードID = getメッセージカードID(r.当選景品!.メッセージカード);
    items.push(createItem(`c005b_${復活ドレスID}_s${復活シャンパンID}`)); // シャンパンもう一本頂きました
    items.push(createItem(`c006_02_s${復活シャンパンID}`)); // シャンパン待機
    // 必ず成功
    items.push(createItem(`c006_03_s${復活シャンパンID}`)); // シャンパンオープン
    items.push(createItem(`c007_2_t${復活宝箱ID}_s${復活シャンパンID}`)); // シャンパンタワー
    items.push(createItem(`c009_r${復活宝箱ID}_${メッセージカードID}`)); // 宝箱とメッセージカード
  }

  if (r.当選景品) {
    return {
      bgm: getBGMUrl(),
      title: `${
        0 <= r.当選景品.等級 && r.当選景品.等級 < KANJI_NUMS.length ? KANJI_NUMS[r.当選景品.等級] : r.当選景品.等級
      }等`,
      message: `${r.当選景品.景品名}\n${r.当選景品.景品名2行目}`,
      imageUrl: r.当選景品.画像id ? getPhotoPath(r.当選景品.画像id) : '',
      items: items,
    };
  } else {
    return {
      bgm: getBGMUrl(),
      title: '',
      message: '',
      imageUrl: '',
      items: items,
    };
  }
}
export function getResourceUrl(filename: string): string {
  return process.env.REACT_APP_GACHA_RESOURCE_PATH + filename;
}
export function getMovieUrl(movie: string): string {
  return getResourceUrl(movie + process.env.REACT_APP_GACHA_MOVIE_EXT);
}
function getBGMUrl(): string {
  return getResourceUrl(process.env.REACT_APP_GACHA_BGM_FILENAME + '');
}
function createItem(filename: string): GachaItem {
  return {
    url: getMovieUrl(filename),
    loop: filename.startsWith(LOOP_PREFIX),
    bgmVol: filename.startsWith(BGM_VOL_MIN_PREFIX) ? BGM_VOL_MIN : BGM_VOL_BASE,
  };
}
function getドレスID(ドレス: ドレスType): string {
  switch (ドレス) {
    case ドレスTypeValue.N:
      return '01';
    case ドレスTypeValue.R:
      return '02';
    case ドレスTypeValue.HR:
      return '03';
  }
}
function getお客様ID(お客様: お客様Type): string {
  switch (お客様) {
    case お客様TypeValue.N:
      return 'a';
    case お客様TypeValue.R:
      return 'b';
  }
}
function getシャンパンID(シャンパン: シャンパンType): string {
  switch (シャンパン) {
    case シャンパンTypeValue.N:
      return '01';
    case シャンパンTypeValue.R:
      return '02';
    case シャンパンTypeValue.HR:
      return '03';
  }
}
function get魚群ID(魚群: 魚群Type): string {
  switch (魚群) {
    case 魚群TypeValue.N:
      return '_g0';
    case 魚群TypeValue.R:
      return '';
  }
}
function get宝箱ID(排出: 排出Type): string {
  switch (排出) {
    case 排出TypeValue.N:
      return '01';
    case 排出TypeValue.R:
      return '02';
    case 排出TypeValue.HR:
      return '03';
  }
  return '';
}
function getメッセージカードID(メッセージカード: メッセージカードType): string {
  switch (メッセージカード) {
    case メッセージカードTypeValue.N:
      return '01';
    case メッセージカードTypeValue.R:
      return '02';
    case メッセージカードTypeValue.HR:
      return '03';
  }
  return '';
}
