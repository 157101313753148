import { createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';

import { ActorInfoResponse, EmptyActorInfoResponse, fetchActorInfo, fetchActorInfoAction } from 'lib/api';

interface ActorState {
  loaded: boolean;
  actorInfo: ActorInfoResponse;
}

const initialState: ActorState = {
  loaded: false,
  actorInfo: EmptyActorInfoResponse,
};

export const actorSlice = createSlice({
  name: 'actor',
  initialState,
  reducers: {},
  extraReducers: {
    [fetchActorInfo.fulfilled.toString()]: (state, action: fetchActorInfoAction) => {
      state.actorInfo = action.payload.response;
      state.loaded = true;
    },
  },
});

export const selectActor = (state: RootState) => state.actor;

export default actorSlice.reducer;
