import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { orEmptyGraphGoalsDto } from '../lib/dataFunctions';

import { RootState } from './store';

import {
  EmptyMyPageResponse,
  MyPageResponse,
  fetchActorInfo,
  fetchActorInfoAction,
  fetchGoalsConfig,
  fetchGoalsConfigAction,
  fetchGoalsConfigUpdate,
  fetchGoalsConfigUpdateAction,
  fetchMyPage,
  fetchMyPageAction,
} from 'lib/api';

export type GoalsConfig = {
  year: number; // 年
  month: number; // 月 1~12
  result: string;
  成績最低値: number;

  成績: string;
  支給総額: string;
  店舗内ランキング: string;
  グループ内ランキング: string;
  指名本数: string;
  同伴本数: string;
  出勤時間: string;
};

interface MyPageState {
  myPageResponse: MyPageResponse;
  goalsConfigList: GoalsConfig[];
  goalsConfigListOrg: GoalsConfig[];
  大目標: string;
}

const initialState: MyPageState = {
  myPageResponse: EmptyMyPageResponse,
  goalsConfigList: [],
  goalsConfigListOrg: [],
  大目標: '',
};
export const myPageSlice = createSlice({
  name: 'myPage',
  initialState,
  reducers: {
    clearGoalsConfigList: (state) => {
      state.goalsConfigList = [];
    },
    setGoalsConfig: (state, action: PayloadAction<GoalsConfig>) => {
      const index = state.goalsConfigList.findIndex(
        (c) => c.year === action.payload.year && c.month === action.payload.month,
      );
      state.goalsConfigList.splice(index, 1, action.payload);
    },
    set大目標: (state, action: PayloadAction<string>) => {
      state.大目標 = action.payload;
    },
  },

  extraReducers: {
    [fetchMyPage.fulfilled.toString()]: (state, action: fetchMyPageAction) => {
      state.myPageResponse = action.payload.response;
    },
    [fetchGoalsConfig.fulfilled.toString()]: (state, action: fetchGoalsConfigAction) => {
      const [year, month, response] = [
        action.payload.request.year,
        action.payload.request.month,
        action.payload.response,
      ];
      const グラフ目標 = orEmptyGraphGoalsDto(response.グラフ目標);
      const config = {
        year: year,
        month: month,
        result: response.result,
        成績最低値: response.成績最低値,

        成績: String(グラフ目標.成績),
        支給総額: String(グラフ目標.支給総額),
        店舗内ランキング: String(グラフ目標.店舗内ランキング),
        グループ内ランキング: String(グラフ目標.グループ内ランキング),
        指名本数: String(グラフ目標.指名本数),
        同伴本数: String(グラフ目標.同伴本数),
        出勤時間: String(グラフ目標.出勤時間 / 60),
      };
      const index = state.goalsConfigList.findIndex((c) => c.year === year && c.month === month);
      if (index !== -1) {
        state.goalsConfigList.splice(index, 1, config);
        state.goalsConfigListOrg.splice(index, 1, config);
      } else {
        state.goalsConfigList.push(config);
        state.goalsConfigListOrg.push(config);
        state.goalsConfigList.sort((a, b) => a.year * 100 + a.month - b.year * 100 + b.month);
        state.goalsConfigListOrg.sort((a, b) => a.year * 100 + a.month - b.year * 100 + b.month);
      }
    },
    [fetchGoalsConfigUpdate.fulfilled.toString()]: (state, action: fetchGoalsConfigUpdateAction) => {
      const [year, month, result] = [
        action.payload.request.year,
        action.payload.request.month,
        action.payload.response.result,
      ];
      if (result.toUpperCase() !== 'OK') {
        // 月をまたいだ後に前月の目標を設定しようとする等、クライアントが保持しているデータが古いためリロードさせる
        // alert(action.payload.response.result);
        window.location.reload();
      }else{
        const index = state.goalsConfigList.findIndex((c) => c.year === year && c.month === month);
        state.goalsConfigListOrg.splice(index, 1, state.goalsConfigList[index]);
      }
    },
    [fetchActorInfo.fulfilled.toString()]: (state, action: fetchActorInfoAction) => {
      state.大目標 = action.payload.response.大目標;
    },
  },
});

export const { clearGoalsConfigList, setGoalsConfig, set大目標 } = myPageSlice.actions;

export const selectMyPage = (state: RootState) => state.myPage;

export default myPageSlice.reducer;
