import React, { Fragment, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';
import { useDispatch, useSelector } from 'react-redux';

import { StandardButton } from '../StandardButton';
import { NotificationMessage, fetchNotificationConfirm } from '../../lib/api';
import { showSuccessAlert } from '../../app/applicationSlice';
import { isNotificationDisabled } from '../../lib/dataFunctions';
import {selectHome} from "../../app/homeSlice";

const horizontalPadding = 24;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    createdAt: {
      paddingTop: 8,
      fontSize: 13,
      color: theme.palette.text.secondary,
    },
    title: {
      paddingTop: 6,
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
    },
    message: {
      paddingTop: 26,
      paddingBottom: 50,
      fontSize: 14,
      lineHeight: 1.7,
      color: '#5D5D5D',
    },
    right: {
      textAlign: 'right',
    },
    button: {
      minWidth: 110,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 32,
    },
  }),
);

type Props = {
  notification: NotificationMessage;
  handleOnClose: () => void;
};

export function NotificationDetailContents確認型(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const home = useSelector(selectHome);

  const onClose = () => {
    setLoading(false);
    props.handleOnClose();
  };

  const onConfirm = async () => {
    if (loading || !home.loaded) return;
    if(!!home.switchableActorInfos){
      alert('スタッフログインでは通知確認はできません');
      return;
    }
    setLoading(true);
    await dispatch(fetchNotificationConfirm({ id: props.notification.id, apply: true }));
    // dispatch(clearNotificationList());
    dispatch(showSuccessAlert('完了しました'));
    onClose();
  };

  const buttonDisabled = loading || props.notification.completed || isNotificationDisabled(props.notification);
  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.createdAt}>
        {moment(props.notification.createdAt).format('YYYY/MM/DD HH:mm')}
      </Grid>

      <Grid item xs={12} className={classes.title}>
        {props.notification.title}
      </Grid>

      <Grid item xs={12} className={classes.message}>
        {props.notification.message.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Grid>

      <Grid item xs={6}>
        <StandardButton onClick={onConfirm} disabled={buttonDisabled} className={classes.button}>
          確認しました
        </StandardButton>
      </Grid>
      <Grid item xs={6} className={classes.right}>
        <StandardButton onClick={onClose} className={classes.button}>
          閉じる
        </StandardButton>
      </Grid>
    </Grid>
  );
}
