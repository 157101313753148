import { createSelector, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { RootState } from './store';

import {
  ActorBadge,
  EmptyHomeResponse,
  HomeResponse,
  fetchBadge,
  fetchBadgeAction,
  fetchGoalsConfigUpdate,
  fetchGoalsConfigUpdateAction,
  fetchHome,
  fetchHomeAction, SwitchableActorInfo, fetchSwitchableActor, fetchSwitchableActorAction,
} from 'lib/api';

interface HomeState {
  homeResponse: HomeResponse;
  badgeList: ActorBadge[];
  loaded: boolean;
  now: string;
  switchableActorInfos: SwitchableActorInfo[]; // (メンター用)切り替え可能なキャストリスト
}

const initialState: HomeState = {
  homeResponse: EmptyHomeResponse,
  badgeList: [],
  loaded: false,
  now: '',
  switchableActorInfos: [],
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    clearState: (state) => {
      // 読み込み完了まで空白になるのと、loadedで再読み込みを行うのでここではクリアしない
      // state.homeResponse = EmptyHomeResponse;
      state.loaded = false;
    },
    setNow: (state, action: PayloadAction<string|null>) => {
      state.now = action.payload ? action.payload : '';
    },
  },
  extraReducers: {
    [fetchHome.fulfilled.toString()]: (state, action: fetchHomeAction) => {
      state.homeResponse = action.payload.response;
      state.loaded = true;
    },
    [fetchBadge.fulfilled.toString()]: (state, action: fetchBadgeAction) => {
      state.badgeList = action.payload.response.badgeList;
    },
    [fetchGoalsConfigUpdate.fulfilled.toString()]: (state, action: fetchGoalsConfigUpdateAction) => {
      state.loaded = false;
    },
    [fetchSwitchableActor.fulfilled.toString()]: (state, action: fetchSwitchableActorAction) => {
      state.switchableActorInfos = action.payload.response.switchableActorInfos;
    },
  },
});

// export const incrementAsync = (amount: number): AppThunk => (dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount));
//   }, 1000);
// };

export const {
  clearState,
  setNow
} = homeSlice.actions;

export const selectHome = (state: RootState) => state.home;

export const selectLoaded = createSelector(selectHome, (home) => {
  if (home.loaded) return 'ok';
  return 'ng';
});

export default homeSlice.reducer;
