import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import moment from 'moment';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import { Divider } from '@material-ui/core';

import {
  setCalendarIconHelpDialogShow,
  setMonthlyScoreDetailDialogParams,
  setShiftOfferConfirmDialogShow,
} from '../app/applicationSlice';
import { clearSelectedDate, selectSchedule, setDateSelectMode, useFetchCalendarWithCache } from '../app/scheduleSlice';

import { StandardButton } from './StandardButton';

import { MonthlyCalendarCell } from 'components';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.primary,
      boxSizing: 'border-box',
    },
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    r1: {
      height: 69.5,
      paddingTop: 16,
      display: 'flex',
      justifyContent: 'flex-end',
    },
    r1Title: {
      height: 33,
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      width: 90,
      marginLeft: 15,
      marginRight: 'auto',
    },
    r1Month: {
      fontSize: 20,
    },
    r1YearMonth: {
      fontSize: 14,
    },
    r1Button2Margin: {
      // width:120,
      // marginRight: 15,
      marginRight: 8,
    },
    r2: {
      // backgroundColor: '#cccccc',
      height: 28,
      fontSize: 10,
      // paddingTop: '12px',
      // lineHeight: '1.5rem',
      display: 'flex',
      // flexDirection: 'column',
      // justifyContent: 'center',
      color: '#3D3D3D',
    },
    r2Cell: {
      // height: '100%',
      width: `${100 / 7}%`,
      textAlign: 'center',
    },
    holiday: {
      color: theme.palette.primary.main,
    },
    saturday: {
      color: '#1499CA',
    },
    r3: {
      // backgroundColor: 'black',
      height: 106.5 * 6,
      display: 'flex',
      flexFlow: 'row wrap',
      width: '100%',
    },
    rightButton: {
      width: 120,
      whiteSpace: 'nowrap',
    },
  }),
);

const CELL_NUM = 7 * 6;

type Props = {
  index: number; // TODO 削除 デバッグ用
  year: number;
  month: number;
  monthOffset: number;

  active: boolean;
  // onPrevClicked: () => void;
  // onNextClicked: () => void;
};
// TODO 再描画タイミング
export const MonthlyCalendar = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { dateSelectMode, selectedDates } = useSelector(selectSchedule);
  const fetchCalendarWithCache = useFetchCalendarWithCache();

  const startOfMonth = moment([props.year, props.month, 1]).add(props.monthOffset, 'months');
  // 日曜が0
  const startDate = moment(startOfMonth).add((startOfMonth.isoWeekday() % 7) * -1, 'd');
  startDate.format();

  useEffect(() => {
    if (props.active) {
      fetchCalendarWithCache(startOfMonth.year(), startOfMonth.month() + 1);
    }
  }, [props.year, props.month, props.active]);

  const button1ClickHandler = () => {
    if (dateSelectMode) {
      dispatch(clearSelectedDate());
      dispatch(setDateSelectMode(false));
    } else {
      dispatch(setCalendarIconHelpDialogShow(true));
    }
  };
  const button2ClickHandler = async () => {
    if (dateSelectMode) {
      dispatch(setShiftOfferConfirmDialogShow(true));
    } else {
      dispatch(setDateSelectMode(true));
    }
  };

  const dates = [];
  for (let i = 0; i < CELL_NUM; i++) {
    const d = moment(startDate).add(i, 'd');
    dates.push(d);
  }

  const dateCells = [];
  for (const d of dates) {
    dateCells.push(
      <MonthlyCalendarCell
        key={d.format('MMDD')}
        year={d.year()}
        month={d.month()}
        day={d.date()}
        parentMonth={startOfMonth.month()}
      />,
    );
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classNames(classes.r1)}>
          <div
            className={classNames(classes.r1Title, classes.verticalCenter)}
            onClick={(e) => {
              // useMonthlyScoreDetailDialog(startOfMonth.year(), startOfMonth.month())
              dispatch(setMonthlyScoreDetailDialogParams({ year: startOfMonth.year(), month: startOfMonth.month() }));
            }}
          >
            {startOfMonth.year() === props.year ? (
              <span className={classNames(classes.r1Month)}>{startOfMonth.month() + 1}月</span>
            ) : (
              <span className={classNames(classes.r1YearMonth)}>
                {startOfMonth.year()}年{startOfMonth.month() + 1}月
              </span>
            )}
          </div>

          <div className={classes.r1Button2Margin}>
            <StandardButton
              onClick={button2ClickHandler}
              disabled={dateSelectMode && selectedDates.length === 0}
              appearance='outlined'
            >
              {dateSelectMode ? 'シフト送信' : 'シフト申請'}
            </StandardButton>
          </div>

          <div className={classes.r1Button2Margin}>
            <StandardButton onClick={button1ClickHandler} appearance='grey' className={classes.rightButton}>
              {dateSelectMode ? 'キャンセル' : 'アイコン説明'}
            </StandardButton>
          </div>
        </div>
        <div className={classes.r2}>
          <div className={classNames(classes.r2Cell, classes.verticalCenter, classes.holiday)}>日</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter)}>月</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter)}>火</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter)}>水</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter)}>木</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter)}>金</div>
          <div className={classNames(classes.r2Cell, classes.verticalCenter, classes.saturday)}>土</div>
        </div>
        <div className={classes.r3}>{dateCells}</div>
        <div>
          <Divider />
        </div>
      </div>
    </>
  );
};
