import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {EffectDetail, effectImageHeight, effectImageWidth} from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  calendarImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(971),
    height: effectImageHeight(75),
    textAlign: 'right',
  },
  month: {
    right: effectImageWidth(638),
  },
  day: {
    right: effectImageWidth(429),
  },
  yearImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(1169),
    height: effectImageHeight(115),
    textAlign: 'center',
    transform: 'translate(6%)',
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
    paddingLeft: 2,
    paddingRight: 1,
  },
}));

export function getEffectSheetType7PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}

// SheetLayoutType.在籍日数
export function EffectSheetType7(props: Props) {
  const classes = useStyles();

  const year = Math.floor(props.sheet.dto.typeValue / 12)
  const day = Number(props.sheet.dto.param2) < 10 ? '0' + props.sheet.dto.param2 : props.sheet.dto.param2;

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <div className={classNames(classes.calendarImageListContainer, classes.month)}>
      {props.sheet.dto.param1.split('').map((s, i) =>
        <img key={i} className={classNames(classes.imageList)} src={assetPath(`img/effect/hizuke/hizuke/pink_${s}.png`)} alt="month" />
      )}
    </div>
    <div className={classNames(classes.calendarImageListContainer, classes.day)}>
      {day.split('').map((s, i) =>
        <img key={i} className={classNames(classes.imageList)} src={assetPath(`img/effect/hizuke/hizuke/pink_${s}.png`)} alt="day" />
      )}
    </div>
    {year > 1 &&
      <div className={classNames(classes.yearImageListContainer)}>
        {String(year).split('').map((s, i) =>
          <img key={i} className={classNames(classes.imageList)}
               src={assetPath(`img/effect/hizuke/hizuke/gray_${s}.png`)} alt="year"/>
        )}
      </div>
    }
  </div>;
}
