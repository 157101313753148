import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      paddingTop: 5,
      paddingBottom: 2,
      paddingLeft: 12,
      paddingRight: 12,
    },
    name: {
      fontSize: 8,
    },
  }),
);
type Props = {
  names: string[];
};
export const BirthdayCustomerNames = (props: Props) => {
  const classes = useStyles();
  const names = props.names.map((name, index) => (
    <p className={classes.name} key={index}>
      {name}
    </p>
  ));
  return <div className={classes.container}>{names}</div>;
};
