export enum GraphType {
  成績,
  給与総支給額,
  同伴数,
  指名本数,
  出勤時間,
  フリー件数,
  店舗ポイントランキング,
  グループポイントランキング,
}
export const 場内指名件数 = "場内指名件数";
