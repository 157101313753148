import {assetPath} from "./pathes";
import { EffectDto, EffectType, TenpoPointUnitType, TenpoPointUnitTypeValue, fetchEffectView } from "./api";
import {store} from "../app/store";
import {selectEffect, setEffectArg} from "../app/effectSlice";
import { useSelector } from "react-redux";
import {getEffectSheetType1PreloadImageUrls} from "../components/Effect/EffectSheetType1";
import {getEffectSheetType2PreloadImageUrls} from "../components/Effect/EffectSheetType2";
import {getEffectSheetType3PreloadImageUrls} from "../components/Effect/EffectSheetType3";
import {getEffectSheetType4PreloadImageUrls} from "../components/Effect/EffectSheetType4";
import {getEffectSheetType5PreloadImageUrls} from "../components/Effect/EffectSheetType5";
import {getEffectSheetType6PreloadImageUrls} from "../components/Effect/EffectSheetType6";
import {getEffectSheetType7PreloadImageUrls} from "../components/Effect/EffectSheetType7";
import {getEffectSheetType8PreloadImageUrls} from "../components/Effect/EffectSheetType8";
import { selectHome } from "app/homeSlice";

export const SHEET_WIDTH = 990;
export const SHEET_HEIGHT = 1805;
export function effectImageWidth(pixelSize:number): string {
  return `${pixelSize / SHEET_WIDTH * 100}%`
}
export function effectImageHeight(pixelSize:number): string {
  return `${pixelSize / SHEET_HEIGHT * 100}%`
}
export function getEffectResourceUrl(filename: string): string {
  const basePath = process.env.REACT_APP_EFFECT_RESOURCE_PATH;
  if(!basePath) return filename;
  if(basePath.startsWith('http')){
    return basePath + filename;
  }else{
    return assetPath(basePath + filename);
  }
  // return process.env.REACT_APP_EFFECT_RESOURCE_PATH + filename;
}
export function getEffectMovieUrl(movieName: string): string {
  return getEffectResourceUrl(movieName + (isSafariOrIOS() ? '.mov' : '.webm'));
}
function isSafariOrIOS() {
  // console.log('❗' + navigator.userAgent)
  return !navigator.userAgent.match(/.*Chrome/)
  //|| !window.Android
}
export function useEndEffect(){
  const { effects } = useSelector(selectEffect);

  return () => {
    if(effects) {
      const ids = effects.filter(e => !!e.id).map(e => e.id);
      if(ids.length > 0)  store.dispatch(fetchEffectView({ids: ids}));
    }
    store.dispatch(setEffectArg(null));
  }
}
export type EffectArg = {
  animation: EffectDetail | null;
  sheets: EffectDetail[] | null;
};
export type MovieFiles = {
  movie: string;
  audio: string;
}
export type EffectDetail = {
  dto: EffectDto;
  movieFiles: MovieFiles | null;
  sheetLayoutType: SheetLayoutType | null;
  sheetImagePath: string | null;
  エフェクト優先度: number;
  アニメーション優先度: number;
}

// 表彰状あり
//   アニメーション優先度
//     エフェクト優先度
//       表彰状なし
//         エフェクト優先度
//           typeValue
function sortBy優先度(a: EffectDetail, b: EffectDetail): number{
  // 表彰状ソート 有り -> 無し
  const as = a.sheetLayoutType !== null;
  const bs = b.sheetLayoutType !== null;
  if(as !== bs) {
    return (as && !bs) ? -1 : 1;
  }
  // アニメーション優先度 昇順
  if(a.アニメーション優先度 !== b.アニメーション優先度){
    return a.アニメーション優先度 - b.アニメーション優先度;
  }
  // エフェクト優先度 昇順
  if(a.エフェクト優先度 !== b.エフェクト優先度){
    return a.エフェクト優先度 - b.エフェクト優先度;
  }
  // typeValue 降順
  return b.dto.typeValue - a.dto.typeValue;
}

export function buildEffectArg(effects: EffectDto[]): EffectArg | null {
  // console.log(JSON.stringify(effects, null, '  '))
  if(effects.length === 0) return null;
  const details: EffectDetail[] = effects.map((dto) => {
    return {
      dto: dto,
      movieFiles: getMovieFiles(dto.effectType, dto.typeValue),
      sheetLayoutType: getSheetLayoutType(dto.effectType),
      sheetImagePath: getSheetImagePath(dto.effectType, dto.typeValue),
      エフェクト優先度: getエフェクト優先度(dto.effectType),
      アニメーション優先度: getアニメーション優先度(dto.effectType, dto.typeValue),
    }
  }).sort(sortBy優先度);

  // console.log(JSON.stringify(details, null, '  '))

  // アニメーション優先度処理
  const animation = !!details[0].movieFiles ? details[0] : null;
  const sheets: EffectDetail[] = [];
  details.forEach((e) => {
    if(e.sheetLayoutType === null || !e.sheetImagePath) return;
    if(sheets.some(e2 => e2.dto.effectType === e.dto.effectType)) return;
    sheets.push(e);
  });
  sheets.reverse();

  const res = {
    animation: animation,
    sheets: sheets.length === 0 ? null : sheets,
  }
  if(!res.animation && !res.sheets) return null;

  // console.log('❓', JSON.stringify(res, null, '  '))

  return res;
}

// const moviePostfix = '';
const moviePostfix = '.png';
// const audioPostfix = '.mp3';
const audioPostfix = '_20221113.mp3';

function getMovieFiles(t: EffectType, v:number): MovieFiles | null {
  const m = _getMovieFiles(t, v);
  if(!m) return m;
  // console.log(t, v, m.audio, m.movie)
  return {
    movie: getEffectMovieUrl(m.movie + moviePostfix),
    audio: getEffectResourceUrl(m.audio + audioPostfix),
  };
}
function _getMovieFiles(t: EffectType, v:number): MovieFiles | null {
  switch(t){
    case "達成率": return {movie: 'ganbatterune_animation03', audio: 'ganbateru_animation03'};
    case "目標達成": return {movie: 'yattane_animation05', audio: 'yattane_animation05'};
    case "出勤時間": {
      if(v === 72) return {movie: 'ganbatteru_animation05', audio: 'ganbatteru_animation05'};
      if(v === 84) return {movie: 'ganbatteru_animation04', audio: 'ganbateru_animation04'};
      break;
    }
    case "同伴件数":{
      if(v === 12)return {movie: 'ganbatteru_animation04', audio: 'istumodouhan_animation04'};
      if(v === 20)return {movie: 'sugoi_animation03', audio: 'sugoi_animation03'};
      break;
    }
    case "指名本数":{
      if(v === 5)return {movie: 'ganbatteru_animation05', audio: 'ganbatteru_animation05'};
      if(v === 15)return {movie: 'ganbatteru_animation05', audio: 'ganbatteru_animation05'};
      if(v === 25)return {movie: 'ganbatteru_animation04', audio: 'ganbateru_animation04'};
      if(v === 50)return {movie: 'ganbatteru_animation04', audio: 'ganbateru_animation04'};
      if(v === 75)return {movie: 'sugoi_animation03', audio: 'sugoi_animation03'};
      if(v === 100)return {movie: 'suggoi_animation02', audio: 'sugoi_animation02'};
      break;
    }
    case "ポイントランキング_店舗_年間":
    case "ポイントランキング_店舗_月間":{
      if(v <= 3)return {movie: 'omedetou_animation02', audio: 'omedetou_animation02'};
      if(v <= 6)return {movie: 'omedetou_animation03', audio: 'omedetou_animation03'};
      if(v <= 10)return {movie: 'omedetou_animation04', audio: 'omedetou_animation04'};
      break;
    }
    case "ポイントランキング_グループ_年間":
    case "ポイントランキング_グループ_月間":
    case "同伴ランキング_グループ_年間":
    case "同伴ランキング_グループ_月間":
    case "指名ランキング_グループ_年間":
    case "指名ランキング_グループ_月間":{
      if(v <= 10)return {movie: 'omedetou_animation02', audio: 'omedetou_animation02'};
      if(v <= 20)return {movie: 'yattane_animation03', audio: 'yattane_animation03'};
      if(v <= 30)return {movie: 'yattane_animation04', audio: 'yattane_animation04'};
      break;
    }
    case "個別レース":{
      if(v === 1)return {movie: 'omedetou_animation02', audio: 'omedetou_animation02'};
      if(v === 2)return {movie: 'yattane_animation03', audio: 'yattane_animation03'};
      if(v === 3)return {movie: 'yattane_animation04', audio: 'yattane_animation04'};
      break;
    }
    case "ポイントベスト更新_年間":
    case "ポイントベスト更新_月間":
    case "同伴数ベスト更新_年間":
    case "同伴数ベスト更新_月間":
    case "指名数ベスト更新_年間":
    case "指名数ベスト更新_月間":
      return {movie: 'yattane_animation02', audio: 'yattane_animation02'};
    case "ポイントレコード_店舗_年間":
    case "ポイントレコード_店舗_月間":
    case "ポイントレコード_グループ_年間":
    case "ポイントレコード_グループ_月間":
    case "同伴数レコード_店舗_年間":
    case "同伴数レコード_店舗_月間":
    case "同伴数レコード_グループ_年間":
    case "同伴数レコード_グループ_月間":
    case "指名数レコード_店舗_年間":
    case "指名数レコード_店舗_月間":
    case "指名数レコード_グループ_年間":
    case "指名数レコード_グループ_月間":
      return {movie: 'suggoi_animation02', audio: 'sugoi_animation02'};
    case "在籍日数": return {movie: 'itsumoarigatou_animation03', audio: 'istumoarigatou_animation03'};
    case "お誕生日": return {movie: 'otanjoubi_animation02', audio: 'omedetou_animation02'};
    case "指名があった日": return {movie: 'itsumosimeiganbatteru_animation05', audio: 'ganbatteru_animation05'};
    case "指名連日獲得":{
      if(v <= 2)break;
      if(v <= 4)return {movie: `simei0${v}_animation05`, audio: 'ganbatteru_animation05'};
      if(v <= 7)return {movie: `simei0${v}_animation04`, audio: 'ganbateru_animation04'};
      if(v <= 10)return {movie: `simei${zeroPadding(v, 2)}_animation03`, audio: 'ganbateru_animation03'};
      if(v <= 14)return {movie: `simei${zeroPadding(v, 2)}_animation03`, audio: 'sugoi_animation03'};
      if(v <= 30)return {movie: `simei${zeroPadding(v, 2)}_animation02`, audio: 'sugoi_animation02'};
      return {movie: 'simei3x_animation02', audio: 'sugoi_animation02'};
    }
    case "Instagram初指名": return {movie: 'insta_animation05', audio: 'yattane_animation05'};
    case "ナイツ初指名": return {movie: 'naitsusimei_animation05', audio: 'yattane_animation05'};
    case "ポケパラ初指名": return {movie: 'pokeparashimei_animation05', audio: 'yattane_animation05'};
    case "同伴があった日": return {movie: 'itsumodouhanganbatteru_animation05', audio: 'itsumodouhan_animation05'};
    case "初アルマンド": return {movie: 'hatsuarumando_animation03', audio: 'yattane_animation03'};
    case "初ソウメイ": return {movie: 'hatsusoumei_animation03', audio: 'yattane_animation03'};
    case "ランダム":{
      if(v === 1)return {movie: 'konshuumoganbatteru_animation05', audio: 'kyoumokawaiiyo_animation05'};
      if(v === 2)return {movie: 'konshuumoganbatteru_animation05', audio: 'itsumoouensitemasuyo_animation05'};
      if(v === 3)return {movie: 'konshuumoganbatteru_animation05', audio: 'itsumoganbatterune_animation05'};
      if(v === 4)return {movie: 'konshuumoganbatteru_animation05', audio: 'taichounikiwotsukete_animation05'};
      if(v === 5)return {movie: 'konshuumoganbatteru_animation05', audio: 'ganbaruanatagasuteki_animation05'};
      if(v === 6)return {movie: 'konshuumoganbatteru_animation05', audio: 'kyoumoegao_animation05'};
      break;
    }
  }
  return null;
}
function zeroPadding(num:number, len:number){
  return ( Array(len).join('0') + num ).slice( -len );
}
function getSheetImagePath(t: EffectType, v:number): string | null {
  switch(t){
    case "達成率": return 'img/effect/zetaisu/zetaisu/tasei/ze_tassei_base.png';
    case "目標達成": return 'img/effect/zetaisu/zetaisu/mokuhyou/ze_syokei_base.png';
    case "出勤時間": {
      if(v === 72) return 'img/effect/zetaisu/zetaisu/syukin/ze_syukin_72.png';
      if(v === 84) return 'img/effect/zetaisu/zetaisu/syukin/ze_syukin_84.png';
      break;
    }
    case "同伴件数":{
      if(v === 5)return 'img/effect/zetaisu/zetaisu/douhan/ze_douhan5.png';
      if(v === 8)return 'img/effect/zetaisu/zetaisu/douhan/ze_douhan8.png';
      if(v === 12)return 'img/effect/zetaisu/zetaisu/douhan/ze_douhan12.png';
      if(v === 20)return 'img/effect/zetaisu/zetaisu/douhan/ze_douhan20.png';
      break;
    }
    case "指名本数":{
      if(v === 5)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_5.png';
      if(v === 15)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_15.png';
      if(v === 25)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_25.png';
      if(v === 50)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_50.png';
      if(v === 75)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_75.png';
      if(v === 100)return 'img/effect/zetaisu/zetaisu/shimei/ze_shimei_100.png';
      break;
    }
    case "ポイントランキング_店舗_年間":{
      if(v <= 3)return 'img/effect/ranking/pt/ra_pt_tenpo01.png';
      if(v <= 6)return 'img/effect/ranking/pt/ra_pt_tenpo02.png';
      if(v <= 10)return 'img/effect/ranking/pt/ra_pt_tenpo03.png';
      break;
    }
    case "ポイントランキング_店舗_月間":{
      if(v <= 3)return 'img/effect/ranking/pt/ra_pt_tenpo04.png';
      if(v <= 6)return 'img/effect/ranking/pt/ra_pt_tenpo05.png';
      if(v <= 10)return 'img/effect/ranking/pt/ra_pt_tenpo06.png';
      break;
    }
    case "ポイントランキング_グループ_年間":{
      if(v <= 10)return 'img/effect/ranking/pt/ra_pt_group01.png';
      if(v <= 20)return 'img/effect/ranking/pt/ra_pt_group02.png';
      if(v <= 30)return 'img/effect/ranking/pt/ra_pt_group03.png';
      break;
    }
    case "ポイントランキング_グループ_月間":{
      if(v <= 10)return 'img/effect/ranking/pt/ra_pt_group04.png';
      if(v <= 20)return 'img/effect/ranking/pt/ra_pt_group05.png';
      if(v <= 30)return 'img/effect/ranking/pt/ra_pt_group06.png';
      break;
    }
    case "同伴ランキング_グループ_年間":{
      if(v <= 10) return 'img/effect/ranking/douhan/ra_do_group01.png';
      if(v <= 20) return 'img/effect/ranking/douhan/ra_do_group02.png';
      if(v <= 30) return 'img/effect/ranking/douhan/ra_do_group03.png';
      break;
    }
    case "同伴ランキング_グループ_月間":{
      if(v <= 10) return 'img/effect/ranking/douhan/ra_do_group04.png';
      if(v <= 20)return 'img/effect/ranking/douhan/ra_do_group05.png';
      if(v <= 30)return 'img/effect/ranking/douhan/ra_do_group06.png';
      break;
    }
    case "指名ランキング_グループ_年間":{
      if(v <= 10) return 'img/effect/ranking/shimei/ra_shi_group01.png';
      if(v <= 20)return 'img/effect/ranking/shimei/ra_shi_group02.png';
      if(v <= 30)return 'img/effect/ranking/shimei/ra_shi_group03.png';
      break;
    }
    case "指名ランキング_グループ_月間":{
      if(v <= 10)return 'img/effect/ranking/shimei/ra_shi_group04.png';
      if(v <= 20)return 'img/effect/ranking/shimei/ra_shi_group05.png';
      if(v <= 30)return 'img/effect/ranking/shimei/ra_shi_group06.png';
      break;
    }
    case "個別レース":{
      if(v === 1)return 'img/effect/ranking/kobetu/ra_kobetu01.png';
      if(v === 2)return 'img/effect/ranking/kobetu/ra_kobetu02.png';
      if(v === 3)return 'img/effect/ranking/kobetu/ra_kobetu03.png';
      break;
    }
    case "ポイントベスト更新_年間": return 'img/effect/ziko_best/ziko_uriage_year.png';
    case "ポイントベスト更新_月間": return 'img/effect/ziko_best/ziko_uriage.png';
    case "同伴数ベスト更新_年間": return 'img/effect/ziko_best/ziko_douhan_year.png';
    case "同伴数ベスト更新_月間": return 'img/effect/ziko_best/ziko_douhan.png';
    case "指名数ベスト更新_年間": return 'img/effect/ziko_best/ziko_shimei_year.png';
    case "指名数ベスト更新_月間": return 'img/effect/ziko_best/ziko_shimei.png';

    case "ポイントレコード_店舗_年間": return 'img/effect/barcelona_record/ba_pt_tenpo02.png';
    case "ポイントレコード_店舗_月間": return 'img/effect/barcelona_record/ba_pt_tenpo01.png';
    case "ポイントレコード_グループ_年間": return 'img/effect/barcelona_record/ba_pt_group02.png';
    case "ポイントレコード_グループ_月間": return 'img/effect/barcelona_record/ba_pt_group01.png';
    case "同伴数レコード_店舗_年間": return 'img/effect/barcelona_record/ba_do_tenpo02.png';
    case "同伴数レコード_店舗_月間": return 'img/effect/barcelona_record/ba_do_tenpo01.png';
    case "同伴数レコード_グループ_年間": return 'img/effect/barcelona_record/ba_do_group02.png';
    case "同伴数レコード_グループ_月間": return 'img/effect/barcelona_record/ba_do_group01.png';
    case "指名数レコード_店舗_年間": return 'img/effect/barcelona_record/ba_si_tenpo02.png';
    case "指名数レコード_店舗_月間": return 'img/effect/barcelona_record/ba_si_tenpo01.png';
    case "指名数レコード_グループ_年間": return 'img/effect/barcelona_record/ba_si_group02.png';
    case "指名数レコード_グループ_月間": return 'img/effect/barcelona_record/ba_si_group01.png';
    case "在籍日数": {
      if(v === 3) return 'img/effect/hizuke/hizuke/hizuke_base01.png';
      if(v === 6) return 'img/effect/hizuke/hizuke/hizuke_base02.png';
      if(v === 12) return 'img/effect/hizuke/hizuke/hizuke_base03.png';
      return 'img/effect/hizuke/hizuke/hizuke_base04.png';
    }
    case "お誕生日": return 'img/effect/hizuke/hizuke/hizuke_birthday.png';
  }

  return '';
}
export enum SheetLayoutType {
  月, // 月
  目標達成, // 月, 値
  ランキング,// 年or月, 店舗名(画像)
  個別ランキング, // ランキング名(システムフォント)
  自己ベスト, // 年or年月日, 値
  バルセロナレコード, // 年or月, 古い値, 新しい値, 店舗名(画像)
  // グループバルセロナレコード, // 年or月, 古い値, 新しい値
  在籍日数, // 月, 日, 在籍年数
  お誕生日,// 名前
}
function getSheetLayoutType(t: EffectType): SheetLayoutType | null {
  switch(t){
    case "達成率":
    case "出勤時間":
    case "同伴件数":
    case "指名本数":
      return SheetLayoutType.月;
    case "目標達成": return SheetLayoutType.目標達成;

    case "ポイントランキング_店舗_年間":
    case "ポイントランキング_店舗_月間":
    case "ポイントランキング_グループ_年間":
    case "ポイントランキング_グループ_月間":
    case "同伴ランキング_グループ_年間":
    case "同伴ランキング_グループ_月間":
    case "指名ランキング_グループ_年間":
    case "指名ランキング_グループ_月間":
      return SheetLayoutType.ランキング;

    case "個別レース":
      return SheetLayoutType.個別ランキング;

    case "ポイントベスト更新_年間":
    case "同伴数ベスト更新_年間":
    case "指名数ベスト更新_年間":
    case "ポイントベスト更新_月間":
    case "同伴数ベスト更新_月間":
    case "指名数ベスト更新_月間":
      return SheetLayoutType.自己ベスト;

    case "ポイントレコード_店舗_年間":
    case "ポイントレコード_店舗_月間":
    case "同伴数レコード_店舗_年間":
    case "同伴数レコード_店舗_月間":
    case "指名数レコード_店舗_年間":
    case "指名数レコード_店舗_月間":
    case "ポイントレコード_グループ_年間":
    case "ポイントレコード_グループ_月間":
    case "同伴数レコード_グループ_年間":
    case "同伴数レコード_グループ_月間":
    case "指名数レコード_グループ_年間":
    case "指名数レコード_グループ_月間":
      return SheetLayoutType.バルセロナレコード;

    case "在籍日数": return SheetLayoutType.在籍日数;
    case "お誕生日": return SheetLayoutType.お誕生日;
  }
  return null;
}
function getアニメーション優先度(t: EffectType, v:number): number {
  const f = _getMovieFiles(t, v);
  if(!f) return 9;
  return Number(f.movie.slice(-2));
}
function getエフェクト優先度(t: EffectType): number {
  switch(t) {
    case '達成率'	: return 28;
    case '目標達成'	: return 29;
    case '出勤時間'	: return 30;
    case '同伴件数'	: return 31;
    case '指名本数'	: return 32;
    case 'ポイントランキング_店舗_年間'	: return 22;
    case 'ポイントランキング_店舗_月間'	: return 23;
    case 'ポイントランキング_グループ_年間'	: return 20;
    case 'ポイントランキング_グループ_月間'	: return 21;
    case '同伴ランキング_グループ_年間'	: return 24;
    case '同伴ランキング_グループ_月間'	: return 25;
    case '指名ランキング_グループ_年間'	: return 26;
    case '指名ランキング_グループ_月間'	: return 27;
    case '個別レース'	: return 19;
    case 'ポイントベスト更新_年間'	: return 13;
    case 'ポイントベスト更新_月間'	: return 14;
    case '同伴数ベスト更新_年間'	: return 15;
    case '同伴数ベスト更新_月間'	: return 16;
    case '指名数ベスト更新_年間'	: return 17;
    case '指名数ベスト更新_月間'	: return 18;
    case 'ポイントレコード_店舗_年間'	: return 3;
    case 'ポイントレコード_店舗_月間'	: return 4;
    case 'ポイントレコード_グループ_年間'	: return 1;
    case 'ポイントレコード_グループ_月間'	: return 2;
    case '同伴数レコード_店舗_年間'	: return 9;
    case '同伴数レコード_店舗_月間'	: return 10;
    case '同伴数レコード_グループ_年間'	: return 5;
    case '同伴数レコード_グループ_月間'	: return 6;
    case '指名数レコード_店舗_年間'	: return 11;
    case '指名数レコード_店舗_月間'	: return 12;
    case '指名数レコード_グループ_年間'	: return 7;
    case '指名数レコード_グループ_月間'	: return 8;
    case '在籍日数'	: return 33;
    case 'お誕生日'	: return 34;
    case '指名があった日'	: return 42;
    case '指名連日獲得'	: return 37;
    case 'Instagram初指名'	: return 38;
    case 'ナイツ初指名'	: return 39;
    case 'ポケパラ初指名'	: return 40;
    case '同伴があった日'	: return 41;
    case '初アルマンド'	: return 35;
    case '初ソウメイ'	: return 36;
    case 'ランダム'	: return 43;
  }
}

async function loadImage(src: string){
  const img = new Image()
  img.src = src
  await img.decode()
  return img
}

export function preloadSheetImages(sheets: EffectDetail[]){
  return Promise.all(getPreloadImageUrls(sheets).map(src => loadImage(src)))
}

function getPreloadImageUrls(sheets: EffectDetail[]): string[]{
  const res: string[] = [];
  sheets.forEach((e) => {
    switch(e.sheetLayoutType){
      case SheetLayoutType.月:
        res.push(...getEffectSheetType1PreloadImageUrls(e));
        break;
      case SheetLayoutType.目標達成:
        res.push(...getEffectSheetType2PreloadImageUrls(e));
        break;
      case SheetLayoutType.ランキング:
        res.push(...getEffectSheetType3PreloadImageUrls(e));
        break;
      case SheetLayoutType.個別ランキング:
        res.push(...getEffectSheetType4PreloadImageUrls(e));
        break;
      case SheetLayoutType.自己ベスト:
        res.push(...getEffectSheetType5PreloadImageUrls(e));
        break;
      case SheetLayoutType.バルセロナレコード:
        res.push(...getEffectSheetType6PreloadImageUrls(e));
        break;
      case SheetLayoutType.在籍日数:
        res.push(...getEffectSheetType7PreloadImageUrls(e));
        break;
      case SheetLayoutType.お誕生日:
        res.push(...getEffectSheetType8PreloadImageUrls(e));
        break;
    }
  })
  return Array.from(new Set(res));
}

export function getNumBy共通小計Str(共通小計: string, is小計: boolean): number {
  return Math.floor(Number(共通小計) / (is小計 ? 1 : 1000));
}

export const useTenpoPointUnitTypeLocal = function <T>(unitType: TenpoPointUnitType | undefined, pointValue: T, yenValue: T, defaultValue: T): T {
  const home = useSelector(selectHome);
  if (!home.loaded) return defaultValue;
  if (unitType === TenpoPointUnitTypeValue.ポイント) return pointValue;
  if (unitType === TenpoPointUnitTypeValue.小計) return yenValue;
  return defaultValue;
};