import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { Color } from '@material-ui/lab/Alert';

import { GraphType } from '../components/Graph/graphDefine';
import {ActorBadgeCategory, ActorVisitor} from '../lib/api';

import { RootState } from './store';
import {ScreenInfo} from "../layouts/theme";

type MonthlyScoreDetailDialogParams = { year: number; month: number } | null;
type DailyScoreDetailDialogParams = { year: number; month: number; day: number } | null;
type ShiftDetailDialogParams = { year: number; month: number; day: number } | null;
type CommonAlertParams = { message: string; severity: Color; autoHideDuration?: number } | null;

interface ApplicationState {
  // TODO 機能ごとの揮発性ステートはどこで持つか検討
  // local stateを引き回す？機能ごとに揮発・永続的なsliceを作成する？API Cacheを一つのsliceにまとめる？

  // 共通
  loading: boolean;
  commonAlertShow: boolean;
  commonAlertParams: CommonAlertParams;
  nextUrl: string;
  screenInfo: ScreenInfo | null;

  // 成績詳細系
  monthlyScoreDetailDialogParams: MonthlyScoreDetailDialogParams;
  dailyScoreDetailDialogParams: DailyScoreDetailDialogParams;

  // シフト系
  shiftDetailDialogParams: ShiftDetailDialogParams;
  visitorScheduleFormDialogParams: ActorVisitor | null;
  visitorScheduleFormDialogParamsOrg: ActorVisitor | null;
  shiftOfferConfirmDialogShow: boolean;
  shiftCancelOfferDialogParams: string | null;
  shiftMoveOfferDialogParams: string | null;
  calendarIconHelpDialogShow: boolean;

  // グラフ系
  selectedGraphEntryIndex: number;
  selectedGraphType: GraphType | null;
  graphSwiperIndex: number;

  // バッジ系
  badgeDialogCategory: ActorBadgeCategory | null;
}

const initialState: ApplicationState = {
  loading: false,
  commonAlertShow: false,
  commonAlertParams: null,
  nextUrl: '',
  screenInfo: null,

  // 成績詳細系
  monthlyScoreDetailDialogParams: null,
  dailyScoreDetailDialogParams: null,

  // シフト系
  shiftDetailDialogParams: null,
  visitorScheduleFormDialogParams: null,
  visitorScheduleFormDialogParamsOrg: null,
  shiftOfferConfirmDialogShow: false,
  shiftCancelOfferDialogParams: null,
  shiftMoveOfferDialogParams: null,
  calendarIconHelpDialogShow: false,

  // グラフ系
  selectedGraphEntryIndex: -1,
  selectedGraphType: null,
  graphSwiperIndex: 0,

  // バッジ系
  badgeDialogCategory: null,
};

export const applicationSlice = createSlice({
  name: 'application',
  initialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    showSuccessAlert: (state, action: PayloadAction<string>) => {
      state.commonAlertParams = { message: action.payload, severity: 'success', autoHideDuration: 3000 };
      state.commonAlertShow = true;
    },
    showErrorAlert: (state, action: PayloadAction<string>) => {
      state.commonAlertParams = { message: action.payload, severity: 'error' };
      state.commonAlertShow = true;
    },
    hideAlert: (state) => {
      state.commonAlertShow = false;
    },
    setMonthlyScoreDetailDialogParams: (state, action: PayloadAction<MonthlyScoreDetailDialogParams>) => {
      state.monthlyScoreDetailDialogParams = action.payload;
    },
    setDailyScoreDetailDialogParams: (state, action: PayloadAction<DailyScoreDetailDialogParams>) => {
      state.dailyScoreDetailDialogParams = action.payload;
    },
    setShiftDetailDialogParams: (state, action: PayloadAction<ShiftDetailDialogParams>) => {
      state.shiftDetailDialogParams = action.payload;
    },
    setVisitorScheduleFormDialogParams: (state, action: PayloadAction<ActorVisitor | null>) => {
      state.visitorScheduleFormDialogParams = action.payload;
      if (state.visitorScheduleFormDialogParamsOrg === null || action.payload === null) {
        state.visitorScheduleFormDialogParamsOrg = action.payload;
      }
    },
    setShiftOfferConfirmDialogShow: (state, action: PayloadAction<boolean>) => {
      state.shiftOfferConfirmDialogShow = action.payload;
    },
    setShiftCancelOfferDialogParams: (state, action: PayloadAction<string | null>) => {
      state.shiftCancelOfferDialogParams = action.payload;
    },
    setShiftMoveOfferDialogParams: (state, action: PayloadAction<string | null>) => {
      state.shiftMoveOfferDialogParams = action.payload;
    },
    setSelectedGraphEntryIndex: (state, action: PayloadAction<{ index: number; type: GraphType }>) => {
      state.selectedGraphEntryIndex = action.payload.index;
      state.selectedGraphType = action.payload.type;
    },
    clearSelectedGraphEntryIndex: (state) => {
      state.selectedGraphEntryIndex = -1;
      state.selectedGraphType = null;
    },
    setGraphSwiperIndex: (state, action: PayloadAction<{ index: number }>) => {
      state.graphSwiperIndex = action.payload.index;
    },
    setBadgeDialogCategory: (state, action: PayloadAction<{ category: ActorBadgeCategory | null }>) => {
      state.badgeDialogCategory = action.payload.category;
    },
    setCalendarIconHelpDialogShow: (state, action: PayloadAction<boolean>) => {
      state.calendarIconHelpDialogShow = action.payload;
    },
    setNextUrl: (state, action: PayloadAction<string>) => {
      state.nextUrl = action.payload;
    },
    setScreenInfo: (state, action: PayloadAction<ScreenInfo>) => {
      state.screenInfo = action.payload;
    },
  },
});

export const {
  setLoading,
  setMonthlyScoreDetailDialogParams,
  setDailyScoreDetailDialogParams,
  setSelectedGraphEntryIndex,
  clearSelectedGraphEntryIndex,
  setGraphSwiperIndex,
  setBadgeDialogCategory,
  setShiftDetailDialogParams,
  setVisitorScheduleFormDialogParams,
  setShiftOfferConfirmDialogShow,
  setShiftCancelOfferDialogParams,
  setShiftMoveOfferDialogParams,
  showSuccessAlert,
  showErrorAlert,
  hideAlert,
  setCalendarIconHelpDialogShow,
  setNextUrl,
  setScreenInfo,
} = applicationSlice.actions;
export const selectApplication = (state: RootState) => state.application;
export default applicationSlice.reducer;
