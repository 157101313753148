import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { selectHome } from '../app/homeSlice';
import { NoData } from '../components/NoData';
import { PyramidContents } from '../components/PyramidContents';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

export const PyramidPage: React.FC = () => {
  const { homeResponse, loaded } = useSelector(selectHome);
  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);

  if (!loaded) return <NoData />;
  const list = homeResponse.グループ内位置リスト.concat().reverse();
  return (
    <>
      <Swiper
        onSwiper={(e) => {
          setSwiper(e);
        }}
        onTransitionEnd={(e)=>{
          reportGA(GAEventValue.HOME_PYRAMID, String(list[e.activeIndex].yearMonth));
        }}
        initialSlide={list.length - 1}
        virtual
      >
        {list.map((v, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            <PyramidContents
              v={v}
              toPrev={
                0 < index
                  ? () => {
                      swiper?.slidePrev();
                    }
                  : undefined
              }
              toNext={
                index < list.length - 1
                  ? () => {
                      swiper?.slideNext();
                    }
                  : undefined
              }
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </>
  );
};
