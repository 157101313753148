import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { useCallback } from 'react';

import { AppDispatch, RootState } from './store';

import {
  ActorEventSchedule,
  ActorSchedule,
  ActorVisitor,
  CalendarDate,
  EmptyScoreDetailDailyResponse,
  EmptyScoreDetailMonthlyResponse,
  ScoreDetailDailyResponse,
  ScoreDetailMonthlyResponse,
  VipRoom,
  fetchCalendar,
  fetchCalendarAction,
  fetchScoreDetailDaily,
  fetchScoreDetailDailyAction,
  fetchScoreDetailMonthly,
  fetchScoreDetailMonthlyAction,
  fetchShiftCalendar,
  fetchShiftCalendarAction,
  fetchVipRoom,
  fetchVipRoomAction,
} from 'lib/api';

export type CalendarDailyData = {
  calendarDate: CalendarDate | null;
  actorVisitorList: ActorVisitor[];
  actorScheduleList: ActorSchedule[];
  actorEventScheduleList: ActorEventSchedule[];
};
function newCalendarDailyData(): CalendarDailyData {
  return {
    calendarDate: null,
    actorVisitorList: [],
    actorScheduleList: [],
    actorEventScheduleList: [],
  };
}
type CalendarDateMap = { [key: string]: CalendarDailyData };

interface ScheduleState {
  calendarDateMap: CalendarDateMap;
  scoreDetailMonthlyResponse: ScoreDetailMonthlyResponse;
  scoreDetailMonthlyLoaded: boolean;
  scoreDetailDailyResponse: ScoreDetailDailyResponse;
  scoreDetailDailyLoaded: boolean;
  vipRoomList: VipRoom[];
  dateSelectMode: boolean;
  selectedDates: string[];
}

const initialState: ScheduleState = {
  calendarDateMap: {},
  scoreDetailMonthlyResponse: EmptyScoreDetailMonthlyResponse,
  scoreDetailMonthlyLoaded: false,
  scoreDetailDailyResponse: EmptyScoreDetailDailyResponse,
  scoreDetailDailyLoaded: false,
  vipRoomList: [],
  dateSelectMode: false,
  selectedDates: [],
};

export const scheduleSlice = createSlice({
  name: 'schedule',
  initialState,
  reducers: {
    clearLoadingState: (state) => {
      state.scoreDetailMonthlyLoaded = false;
      state.scoreDetailDailyLoaded = false;
    },
    setDateSelectMode: (state, action: PayloadAction<boolean>) => {
      state.dateSelectMode = action.payload;
    },
    selectDate: (state, action: PayloadAction<string>) => {
      state.selectedDates.push(action.payload);
      state.selectedDates.sort();
    },
    unSelectDate: (state, action: PayloadAction<string>) => {
      const index = state.selectedDates.indexOf(action.payload);
      if (index !== -1) {
        state.selectedDates.splice(index, 1);
      }
    },
    clearSelectedDate: (state) => {
      state.selectedDates = [];
    },
  },
  extraReducers: {
    [fetchCalendar.fulfilled.toString()]: (state, action: fetchCalendarAction) => {
      const [result, dates] = [action.payload.response.result, action.payload.response.dates];
      if (result.toUpperCase() === 'OK' && dates) {
        dates.forEach((v) => {
          if (!state.calendarDateMap[v.date]) state.calendarDateMap[v.date] = newCalendarDailyData();
          state.calendarDateMap[v.date].calendarDate = v;
        });
      }
    },
    [fetchShiftCalendar.fulfilled.toString()]: (state, action: fetchShiftCalendarAction) => {
      const [year, month, day, result, visitorList, scheduleList, eventScheduleList] = [
        action.payload.request.year,
        action.payload.request.month,
        action.payload.request.day,
        action.payload.response.result,
        action.payload.response.visitorList,
        action.payload.response.scheduleList,
        action.payload.response.eventScheduleList,
      ];
      if (result.toUpperCase() === 'OK') {
        if (day !== 0) {
          // 日単位で取得
          // リクエストした日付を初期化 & 取得済データをクリア
          const date = moment([year, month - 1, day]).format('YYYYMMDD');
          if (!state.calendarDateMap[date]) state.calendarDateMap[date] = newCalendarDailyData();
          state.calendarDateMap[date].actorVisitorList = [];
          state.calendarDateMap[date].actorScheduleList = [];
          state.calendarDateMap[date].actorEventScheduleList = [];
        } else {
          // 月単位で取得
          // 取得した日付を初期化 & 取得済データをクリア
          [...new Set(visitorList.map((v) => v.date))].forEach((date) => {
            if (!state.calendarDateMap[date]) state.calendarDateMap[date] = newCalendarDailyData();
            state.calendarDateMap[date].actorVisitorList = [];
          });
          [...new Set(scheduleList.map((v) => v.date))].forEach((date) => {
            if (!state.calendarDateMap[date]) state.calendarDateMap[date] = newCalendarDailyData();
            state.calendarDateMap[date].actorScheduleList = [];
          });
          [...new Set(eventScheduleList.map((v) => moment(v.start).format('YYYYMMDD')))].forEach((date) => {
            if (!state.calendarDateMap[date]) state.calendarDateMap[date] = newCalendarDailyData();
            state.calendarDateMap[date].actorEventScheduleList = [];
          });
        }

        // データ追加
        visitorList.forEach((v) => {
          state.calendarDateMap[v.date].actorVisitorList.push(v);
        });
        scheduleList.forEach((v) => {
          state.calendarDateMap[v.date].actorScheduleList.push(v);
        });
        eventScheduleList.forEach((v) => {
          const date = moment(v.start).format('YYYYMMDD');
          state.calendarDateMap[date].actorEventScheduleList.push(v);
        });
      }
    },
    [fetchVipRoom.fulfilled.toString()]: (state, action: fetchVipRoomAction) => {
      // action.payload.request.
      const [vipRoomList] = [action.payload.response.vipRoomList];
      state.vipRoomList = vipRoomList;
    },
    // [fetchActorVisitorDelete.fulfilled.toString()]: (state, action: fetchActorVisitorDeleteAction) => {
    //   const [result] = [action.payload.response.result];
    //   if (result.toUpperCase() !== 'OK') {
    //     // TODO エラー表示
    //   }
    // },
    // [fetchShiftOffer.fulfilled.toString()]: (state, action: fetchShiftOfferAction) => {
    // },
    // [fetchShiftOffer.rejected.toString()]: (state, action: fetchShiftOfferAction) => {
    //   throw new Error();
    // },
    [fetchScoreDetailMonthly.fulfilled.toString()]: (state, action: fetchScoreDetailMonthlyAction) => {
      state.scoreDetailMonthlyResponse = action.payload.response;
      state.scoreDetailMonthlyLoaded = true;
    },
    [fetchScoreDetailDaily.fulfilled.toString()]: (state, action: fetchScoreDetailDailyAction) => {
      state.scoreDetailDailyResponse = action.payload.response;
      state.scoreDetailDailyLoaded = true;
    },
  },
});

export const {
  clearLoadingState,
  setDateSelectMode,
  selectDate,
  unSelectDate,
  clearSelectedDate,
} = scheduleSlice.actions;

export const selectSchedule = (state: RootState) => {
  return state.schedule;
};

export default scheduleSlice.reducer;

export const requestedCalendarYearMonths: string[] = [];
export const useFetchCalendarWithCache = () => {
  const dispatch = useDispatch();

  return useCallback(
    async (year: number, month: number) => {
      const ym = year + '' + ('00' + month).slice(-2);
      if (requestedCalendarYearMonths.indexOf(ym) === -1) {
        requestedCalendarYearMonths.push(ym);
        dispatch(fetchCalendar({ year: year, month: month }));
        dispatch(fetchShiftCalendar({ year: year, month: month, day: 0 }));
      }
    },
    [dispatch],
  );
};

export const reloadShiftCalendar = (dispatch: AppDispatch, dates: string[]) => {
  // ShiftOfferで状態コンフリクトが発生した場合は更新が行われないため、更新可能性のある月を一括リロード
  // 個別の日付のバッチ取得ができないため、多量(3以上)のリロードが必要な場合は同上
  if (dates && 0 < dates.length && dates.length <= 2) {
    dates.forEach((d) => {
      const m = moment(d);
      dispatch(fetchShiftCalendar({ year: m.year(), month: m.month() + 1, day: m.date() }));
    });
  } else {
    // 当月と次月をリロード
    const now = moment();
    dispatch(fetchShiftCalendar({ year: now.year(), month: now.month() + 1, day: 0 }));
  }
};
