import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Swiper, SwiperSlide, } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { selectMyPage } from '../../app/myPageSlice';

import { BadgeDetailContents } from './BadgeDetailContents';

import { selectApplication, setBadgeDialogCategory } from 'app/applicationSlice';
import { ActorBadge, ActorBadgeCategoryValue } from '../../lib/api';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

// swiperのonTransitionEnd関数はuseStateの更新が反映されない（renderで再生成がされない？）
let entered = false;
export const BadgeDetailSwiperDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { badgeDialogCategory } = useSelector(selectApplication);
  const { myPageResponse } = useSelector(selectMyPage);

  const onClose = () => {
    dispatch(setBadgeDialogCategory({ category: null }));
  };
  const badgeList = myPageResponse.allBadgeList.filter((b) => b.category === badgeDialogCategory);

  const slides = badgeList.map((badge, index) => (
    <BadgeDetailContents key={index} badge={badge} handleOnClose={onClose} />
  ));

  const localReportGA = (badge:ActorBadge) => {
    if (!badge) return;
    const gaUrlKeys = {
      [ActorBadgeCategoryValue.店舗ポイントランキング]: GAEventValue.MYPAGE_TENPO,
      [ActorBadgeCategoryValue.グループポイントランキング]: GAEventValue.MYPAGE_GROUP,
      [ActorBadgeCategoryValue.グループ同伴ランキング]: GAEventValue.MYPAGE_DOUHAN,
      [ActorBadgeCategoryValue.ポイント]: GAEventValue.MYPAGE_SAIKOU,
      [ActorBadgeCategoryValue.サンクスコイン]: GAEventValue.MYPAGE_THANKS,
    }
    reportGA(gaUrlKeys[badge.category], badge.monthName, badge.endMonthName);
  }

  return (
    <Dialog
      open={0 < badgeList.length}
      onClose={() => onClose()}
      onEntered={() => {
        // slideが1つの際には初期状態のonTransitionEndが発火されないため、Dialog開いた直後に送信
        localReportGA(badgeList[badgeList.length - 1])
        entered = true
      }}
      onExited={() => {
        entered = false
      }}
      maxWidth={false}
      PaperProps={{
        style: {
          backgroundColor: 'transparent',
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
    >
      <Swiper slidesPerView={1} width={1036 / 3} initialSlide={badgeList.length - 1} 
              onTransitionEnd={(swiper: SwiperClass) => {
                if(entered) localReportGA(badgeList[swiper.activeIndex])
              }}
      virtual>
        {slides.map((slideContent, index) => (
          <SwiperSlide key={index} virtualIndex={index}>
            {slideContent}
          </SwiperSlide>
        ))}
      </Swiper>
    </Dialog>
  );
};
