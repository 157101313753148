import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

import { getInnerContentHeight } from '../consts';
import { reloadShiftCalendar, selectSchedule } from '../app/scheduleSlice';
import {
  ActorVisitor,
  CastOfferType,
  CastOfferTypeValue,
  fetchActorVisitorDelete,
  fetchShiftCalendar,
  fetchShiftOffer,
  fetchVipRoom,
  シフト依頼状態Value,
  シフト確定状態Value,
} from '../lib/api';
import {
  selectApplication,
  setLoading,
  setShiftCancelOfferDialogParams,
  setShiftDetailDialogParams,
  setShiftMoveOfferDialogParams,
  setVisitorScheduleFormDialogParams,
  showSuccessAlert,
} from '../app/applicationSlice';
import { AppDispatch } from '../app/store';

import { StandardButton } from './StandardButton';

// export const useDailyScoreDetailDialog = (year: number, month: number)=>{
//   const dispatch = useDispatch();
//   dispatch(setDailyScoreDetailDialogParams({year: year, month: month}))
// }

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20; // footer分
const r1Height = 100;
const r3Height = 20;
const r2Height = containerHeight - r1Height - r3Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 13,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r2: {
      height: r2Height,
    },
    r2Table: {},
    r3: {
      height: r3Height,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 42,
      paddingBottom: 0,
    },
    noBorder: {
      border: 'none',
    },
    tdValue: {
      minWidth: 116,
      paddingRight: 0,
    },
    singleSmallButton: {
      minWidth: 120,
    },
  }),
);

const padding = '　';
export const ShiftDetailDialog: React.FC = () => {
  const classes = useStyles();
  const { shiftDetailDialogParams, loading } = useSelector(selectApplication);
  const { calendarDateMap } = useSelector(selectSchedule);

  const dispatch: AppDispatch = useDispatch();

  const rows = [];
  if (shiftDetailDialogParams) {
    const m = moment({
      years: shiftDetailDialogParams.year,
      months: shiftDetailDialogParams.month,
      days: shiftDetailDialogParams.day,
    });
    const date = m.format('YYYYMMDD');
    const dailyData = calendarDateMap[date];
    const showVisitorScheduleFormDialog = (v: ActorVisitor | null) => {
      dispatch(fetchVipRoom(shiftDetailDialogParams));
      if (v) {
        dispatch(setVisitorScheduleFormDialogParams(v));
      } else {
        const param = {
          id: '',
          date: date,
          title: '',
          douhan: false,
          vip: '',
        };
        dispatch(setVisitorScheduleFormDialogParams(param));
      }
    };

    const sendOffer = async (date: string, castOfferType: CastOfferType, confirmMessage: string) => {
      if (loading) {
        return;
      }
      if (!window.confirm(confirmMessage)) {
        return;
      }
      dispatch(setLoading(true));
      const payload = unwrapResult(
        await dispatch(
          fetchShiftOffer({
            offerList: [
              {
                date: date,
                castOfferType: castOfferType,
                toDate: '',
              },
            ],
          }),
        ),
      );
      reloadShiftCalendar(dispatch, payload.response.updatedDates);
      dispatch(setLoading(false));
    };

    if (dailyData) {
      if (dailyData.actorScheduleList.length > 0) {
        const lastOffer = dailyData.actorScheduleList.slice(-1)[0];
        if (
          lastOffer.status === シフト確定状態Value.出勤確定済 &&
          lastOffer.offer === シフト依頼状態Value.キャストオフ申請中
        ) {
          rows.push({
            key: null,
            title: '出勤',
            value: (
              <StandardButton
                size='small'
                className={classes.singleSmallButton}
                onClick={(e) => sendOffer(date, CastOfferTypeValue.取下, '取下申請をキャンセルしてよろしいですか？')}
              >
                取下げキャンセル
              </StandardButton>
            ),
          });
        } else if (
          lastOffer.status === シフト確定状態Value.出勤確定済 &&
          (lastOffer.offer === シフト依頼状態Value.キャスト移動出勤申請中 ||
            lastOffer.offer === シフト依頼状態Value.キャスト移動オフ申請中)
        ) {
          rows.push({
            key: null,
            title: '出勤',
            value: (
              <StandardButton
                size='small'
                className={classes.singleSmallButton}
                onClick={(e) => sendOffer(date, CastOfferTypeValue.取下, '移動申請をキャンセルしてよろしいですか？')}
              >
                移動キャンセル
              </StandardButton>
            ),
          });
        } else if (
          lastOffer.status === シフト確定状態Value.出勤確定済 &&
          lastOffer.offer === シフト依頼状態Value.フラット
        ) {
          rows.push({
            key: null,
            title: '出勤',
            value: (
              <>
                <StandardButton
                  size='small'
                  onClick={(e) => dispatch(setShiftCancelOfferDialogParams(date))}
                  style={{ marginRight: 8 }}
                >
                  取下げ
                </StandardButton>
                <StandardButton size='small' onClick={(e) => dispatch(setShiftMoveOfferDialogParams(date))}>
                  移動
                </StandardButton>
              </>
            ),
          });
        } else if (
          lastOffer.status === シフト確定状態Value.フラット &&
          lastOffer.offer === シフト依頼状態Value.キャスト移動出勤申請中
        ) {
          rows.push({
            key: null,
            title: '',
            value: (
              <StandardButton
                size='small'
                className={classes.singleSmallButton}
                onClick={(e) => sendOffer(date, CastOfferTypeValue.取下, '移動申請をキャンセルしてよろしいですか？')}
              >
                移動キャンセル
              </StandardButton>
            ),
          });
        } else if (
          lastOffer.status === シフト確定状態Value.フラット &&
          lastOffer.offer === シフト依頼状態Value.キャスト出勤申請中
        ) {
          rows.push({
            key: null,
            title: '',
            value: (
              <StandardButton
                size='small'
                className={classes.singleSmallButton}
                onClick={(e) => sendOffer(date, CastOfferTypeValue.取下, '出勤申請をキャンセルしてよろしいですか？')}
              >
                申請キャンセル
              </StandardButton>
            ),
          });
        } else if (lastOffer.status === シフト確定状態Value.出勤確定済) {
          rows.push({ key: null, title: '出勤', value: '' });
        } else {
          //          rows.push({ key: null, title: 'シフト履歴', value: '' });
        }

        rows.push({ key: null, title: 'シフト履歴', value: '' });

        const visibleHistories = lastOffer.rireki.filter((v) => v.label !== '表示なし');
        for (let i = 0; i < visibleHistories.length; i++) {
          const r = visibleHistories[i];
          rows.push({
            key: 'シフト履歴' + i,
            title: padding + moment(r.createdAt).format('M月D日 H:mm'),
            value: r.label + (r.staffName ? `(${r.staffName}さん)` : ''),
          });
        }
        rows.push({ key: 'spacer1', title: '', value: '' });
      }

      rows.push({
        key: null,
        title: 'お客様予定',
        value: (
          <StandardButton size='small' onClick={(e) => showVisitorScheduleFormDialog(null)}>
            追加
          </StandardButton>
        ),
      });
      if (dailyData.actorVisitorList.length > 0) {
        dailyData.actorVisitorList.forEach((r, i) => {
          rows.push({
            key: 'お客様予定' + i,
            title: padding + r.title + padding + (r.douhan ? ' 同伴' : '予定'),
            value: (
              <>
                <StandardButton
                  size='small'
                  onClick={(e) => showVisitorScheduleFormDialog(r)}
                  style={{ marginRight: 8 }}
                >
                  編集
                </StandardButton>
                <StandardButton
                  size='small'
                  onClick={async (e) => {
                    if (window.confirm('削除してよろしいですか？')) {
                      await dispatch(fetchActorVisitorDelete({ id: r.id }));
                      dispatch(showSuccessAlert('お客様予定を削除しました'));
                      // 完了後に当日分だけリロード
                      dispatch(
                        fetchShiftCalendar({
                          year: shiftDetailDialogParams.year,
                          month: shiftDetailDialogParams.month + 1,
                          day: shiftDetailDialogParams.day,
                        }),
                      );
                    }
                  }}
                >
                  削除
                </StandardButton>
              </>
            ),
          });
        });
        rows.push({ key: 'spacer2', title: '', value: '' });
      }

      if (dailyData.actorEventScheduleList.length > 0) {
        rows.push({ key: null, title: 'イベント', value: '' });
        for (let i = 0; i < dailyData.actorEventScheduleList.length; i++) {
          const r = dailyData.actorEventScheduleList[i];
          const date = moment(r.start);
          rows.push({ key: 'イベント' + i, title: padding + r.title, value: date.format('HH:mm') });
        }
      }

      if (
        dailyData.calendarDate &&
        dailyData.calendarDate.birthdayCustomerNames &&
        dailyData.calendarDate.birthdayCustomerNames.length > 0
      ) {
        rows.push({ key: null, title: 'お客様誕生日', value: '' });
        for (let i = 0; i < dailyData.calendarDate.birthdayCustomerNames.length; i++) {
          const r = dailyData.calendarDate.birthdayCustomerNames[i];
          rows.push({ key: 'お客様誕生日' + i, title: '', value: padding + r });
        }
      }
    }
  }

  const onClose = () => {
    dispatch(setShiftDetailDialogParams(null));
  };

  return (
    <Dialog open={shiftDetailDialogParams !== null} onClose={() => onClose()} maxWidth={false}>
      {!!shiftDetailDialogParams && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
              <Grid item xs={8} className={classNames(classes.r1C2)}>
                <div>
                  <div className={classes.title}>
                    {shiftDetailDialogParams.year}年{shiftDetailDialogParams.month + 1}月{shiftDetailDialogParams.day}日
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} className={classNames(classes.r1C3)}>
                <CloseIcon onClick={() => onClose()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
              <Table size='small'>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={!row.key ? row.title : row.key}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classes.noBorder}
                        style={{ paddingLeft: 10, paddingRight: 4 }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classNames(classes.noBorder, classes.tdValue)}
                        style={{ paddingLeft: 4 }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={12} className={classes.r3}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
