import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles, withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { FixedSizeList } from 'react-window';
import { Dialog, FormControl, InputBase, Select } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteLoader from 'react-window-infinite-loader';
import { unwrapResult } from '@reduxjs/toolkit';

import { StandardButton } from '../components/StandardButton';
import { Path } from '../routes';
import { getPhotoPath, imagePath } from '../lib/pathes';
import { SloppyScalableText } from '../components';
import { selectGacha, setGachaLoading } from '../app/gachaSlice';
import { GachaExchangeItemDto, fetchGachaExchange, fetchGachaExchangeItemList, fetchGachaInfo } from '../lib/api';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { NoData } from '../components/NoData';
import { AppDispatch } from '../app/store';
import {nl2br, 改行2nl} from "../lib/TextFunctions";

import { reportGA, GAEventValue } from "../lib/googleAnalytics";
import {selectHome} from "../app/homeSlice";

const separatorAreaHeight = 1;
const titleAreaHeight = 74;
const listItemHeight = 118;
const listItemHorizontalMargin = 20;
const listItemArea1Width = 73;
const listItemArea3Width = 70;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      backgroundColor: '#dc3a70',
    },
    separator: {
      height: separatorAreaHeight,
      backgroundColor: 'white',
    },
    titleArea: {
      position: 'relative',
      height: titleAreaHeight,
      textAlign: 'center',
      paddingTop: 24,
    },
    titleAreaText: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: 2.6,
    },
    titleAreaButton: {
      position: 'absolute',
      right: 20,
      top: 0,
      bottom: 0,
      margin: 'auto',
      letterSpacing: 1.0,
    },
    listArea: {
      height: `calc(100% - ${titleAreaHeight + separatorAreaHeight}px)`,
    },
    listItemContainer: {},
    listItemSeparator: {
      height: separatorAreaHeight,
      backgroundColor: 'white',
      opacity: 0.7,
      marginLeft: 20,
      marginRight: 20,
    },
    listItemBody: {
      height: listItemHeight - separatorAreaHeight,
      marginLeft: listItemHorizontalMargin,
      marginRight: listItemHorizontalMargin,
      display: 'flex',
      // justifyContent: 'center',
    },
    listItemArea1: {
      width: listItemArea1Width,
    },
    listItemImage: {
      width: 70,
      height: 70,
      marginTop: 24,
      marginLeft: 3,
      objectFit: 'cover',
    },
    listItemArea2: {
      width: `calc(100% - ${listItemArea1Width + listItemArea3Width}px)`,
      height: '100%',
      color: 'white',
      paddingTop: 29,
      paddingLeft: 18,
      paddingRight: 18,
    },
    listItemArea2Title: {
      fontWeight: 'bold',
      fontSize: 14,
      overflowX: 'hidden',
    },
    listItemArea2Body: {
      fontSize: 12,
      letterSpacing: 0.3,
      display: '-webkit-box',
      overflow: 'hidden',
      wordBreak: 'break-all',
      '-webkitLineClamp': 2,
      '-webkitBoxOrient': 'vertical',
      width: '100%',
      marginTop: 3,
      height: 35,
    },
    listItemArea3: {
      width: listItemArea3Width,
      textAlign: 'right',
    },
    listItemArea3Text: {
      whiteSpace: 'nowrap',
      color: 'white',
      fontWeight: 'bold',
      fontSize: 20,
      paddingLeft: 0,
      paddingRight: 0,
      paddingTop: 24,
    },
    listItemArea3TextUnit: {
      fontSize: '0.7em',
      fontWeight: 'normal',
    },
    listItemArea3Button: {
      marginLeft: 5,
      zIndex: 9999,
    },

    dialogContainer: {
      width: 316,
      height: 542,
      paddingLeft: 35,
      paddingRight: 35,
    },
    dialogText1: {
      textAlign: 'center',
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 20,
      marginTop: 33,
      letterSpacing: 0.3,
      overflowX: 'hidden',
    },
    dialogImageContainer: {
      marginTop: 5,
      width: 226,
      height: 226,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    dialogImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    dialogDescriptionArea: {
      marginTop: 5,
      height: 126,
      overflowY: 'scroll',
    },
    dialogText2: {
      fontWeight: 'bold',
      fontSize: 15,
      letterSpacing: 1.0,
      overflowX: 'hidden',
    },
    dialogText3: {
      marginTop: 5,
      fontSize: 13,
      letterSpacing: 0.5,
      lineHeight: 1.7,
      wordBreak: 'break-all',
    },
    dialogSeparator: {
      height: 1,
      backgroundColor: 'black',
      opacity: 0.3,
    },
    dialogText4: {
      height: 36,
      textAlign: 'center',
      paddingTop: 16,
    },
    dialogText4Title: {
      fontWeight: 'bold',
      fontSize: 14,
      letterSpacing: 0.8,
    },
    dialogText4Point: {
      paddingLeft: 7,
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: 0.3,
    },
    dialogText4Unit: {
      fontWeight: 'normal',
      paddingLeft: 3,
      fontSize: '0.7em',
    },
    dialogFormArea: {
      height: 126,
      paddingTop: 20,
    },
    dialogFormText: {
      color: theme.palette.text.primary,
    },
    dialogFormContainer: {
      display: 'table',
      width: 138,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    dialogSelectLabelContainer: {
      width: 57,
      display: 'table-cell',
      verticalAlign: 'middle',
      textAlign: 'right',
      fontSize: 16,
      fontWeight: 'bold',
      letterSpacing: 1.0,
      paddingRight: 10,
    },
    dialogSelectFormContainer: {
      width: 81,
      display: 'table-cell',
      verticalAlign: 'middle',
    },
    dialogSelectFormControl: {
      width: 81,
    },
    dialogButtonArea: {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    dialogButton: {
      width: 348 / 3,
      height: 102 / 3,
    },
    dialogButtonDisabled: {
      filter: 'grayscale(100%)',
      opacity: 0.5,
    },
    dialogButtonMargin: {
      width: 14,
    },
    dialogCloseButton: {
      display: 'block',
      position: 'absolute',
      top: 50,
      left: 34,
      width: 30,
      height: 30,
      zIndex: 9999,
    },

    completeDialogContainer: {
      width: 320,
      height: 182,
      textAlign: 'center',
      marginLeft: 8,
      paddingTop: 46,
    },
    completeDialogText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 20,
      marginTop: 1,
      letterSpacing: 0.3,
    },
    completeDialogButtonArea: {
      marginTop: 36,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    completeDialogButton: {
      width: 116,
    },
  }),
);

const BootstrapInput = withStyles((theme: Theme) =>
  createStyles({
    root: {
      'label + &': {
        marginTop: theme.spacing(3),
      },
    },
    input: {
      borderRadius: 20,
      position: 'relative',
      backgroundColor: theme.palette.background.paper,
      border: '1px solid #595757',
      fontSize: 18,
      fontWeight: 'bold',
      fontStyle: 'normal',
      padding: '5px 20px 4px 18px',
      transition: theme.transitions.create(['border-color']),
      '&:focus': {
        borderRadius: 20,
        borderColor: theme.palette.primary.main,
      },
    },
  }),
)(InputBase);

const FETCH_NUM = 30;
const options = [...Array(999)].map((_, i) => (
  <option key={i} value={i + 1}>
    {i + 1}
  </option>
));

export const GachaExchangePage: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const elm = useRef<HTMLDivElement>(null);
  const [listContainerHeight, setListContainerHeight] = useState(listItemArea3Width);
  const { exchangeItemCount, exchangeItemList, loading, info, loaded } = useSelector(selectGacha);
  const count = exchangeItemCount;
  const list = exchangeItemList;
  const [prevCount, setPrevCount] = useState(0);

  const [detail, setDetail] = useState<GachaExchangeItemDto | null>(null);
  const [confirm, setConfirm] = React.useState(false);
  const [num, setNum] = React.useState(1);
  const [completed, setCompleted] = React.useState(false);
  const home = useSelector(selectHome);

  const handleShowDetail = (index: number) => {
    console.log('handleShowDetail');
    setConfirm(false);
    setNum(1);
    setDetail(list[index]);
  };
  const handleShowConfirm = (index: number) => {
    console.log('handleShowConfirm');
    setConfirm(true);
    setNum(1);
    setDetail(list[index]);
  };
  const handleClose = () => {
    setDetail(null);
  };

  const handleExchange = async (id: string, 個数: number) => {
    if (loading || !home.loaded) return;
    if(!!home.switchableActorInfos){
      alert('スタッフログインでは景品交換はできません');
      return;
    }

    dispatch(setGachaLoading(true));
    const { response } = unwrapResult(await dispatch(fetchGachaExchange({ id: id, 個数: 個数 })));
    dispatch(setGachaLoading(false));
    handleClose();
    if (response.result.toUpperCase() === 'OK') {
      setCompleted(true);
    } else {
      alert('ビューティーコインがが足りません');
      reloadInfo();
    }
  };

  useEffect(() => {
    if (!loaded) {
      reloadInfo();
    }

    reportGA(GAEventValue.GACHA_KEIHIN);
  }, []);

  const reloadInfo = () => {
    dispatch(fetchGachaInfo({}));
  };

  const loadMoreItems = useCallback(async (startIndex: number, stopIndex: number) => {
    dispatch(setGachaLoading(true));
    await dispatch(
      fetchGachaExchangeItemList({
        limit: stopIndex - startIndex + 1,
        offset: startIndex,
      }),
    );
    dispatch(setGachaLoading(false));
  }, []);

  useEffect(() => {
    if (count !== prevCount) {
      setPrevCount(count);
      loadMoreItems(0, FETCH_NUM);
    }
  }, [prevCount, count]);

  useLayoutEffect(() => {
    if (!!elm && !!elm.current) {
      const rect = elm.current.getBoundingClientRect();
      setListContainerHeight(rect.height);
    }
  }, [elm, elm.current]);

  let contents = (
    <InfiniteLoader
      isItemLoaded={(index) => !!list[index]}
      itemCount={count}
      loadMoreItems={loadMoreItems}
      minimumBatchSize={FETCH_NUM}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={listContainerHeight}
          width={'100%'}
          itemSize={listItemHeight}
          itemCount={list.length}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {({ index, style }) => (
            <div style={style} className={classes.listItemContainer} onClick={() => handleShowDetail(index)}>
              <div className={classes.listItemSeparator}></div>
              <div className={classes.listItemBody}>
                <div className={classes.listItemArea1}>
                  {list[index].画像id && (
                    <img src={getPhotoPath(list[index].画像id)} className={classes.listItemImage} alt="商品画像" />
                  )}
                </div>
                <div className={classes.listItemArea2}>
                  <div className={classes.listItemArea2Title}>
                    <SloppyScalableText content={list[index].交換物名} size={22} />
                  </div>
                  <div className={classes.listItemArea2Body}>
                    {list[index].説明見出し} {list[index].説明詳細}
                  </div>
                </div>
                <div className={classes.listItemArea3}>
                  <div className={classes.listItemArea3Text}>
                    <SloppyScalableText content={list[index].必要ポイント + ''} size={4} />
                    <span className={classes.listItemArea3TextUnit}> 枚</span>
                  </div>
                  <StandardButton
                    className={classes.listItemArea3Button}
                    appearance={'outlined'}
                    size={'medium'}
                    onClick={(e) => {
                      // e.preventDefault();
                      e.stopPropagation();
                      handleShowConfirm(index);
                    }}
                  >
                    交 換
                  </StandardButton>
                </div>
              </div>
            </div>
          )}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );

  if (count <= 0 || list.length === 0) {
    if (loading) {
      contents = <LoadingOverlay />;
    } else {
      contents = <NoData color={'white'} />;
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.separator}></div>
        <div className={classes.titleArea}>
          <span className={classes.titleAreaText}>景品交換所</span>
          <StandardButton
            className={classes.titleAreaButton}
            appearance={'transparent'}
            size={'medium'}
            onClick={() => history.push(Path.gachaExchangeHistory)}
          >
            交換履歴
          </StandardButton>
        </div>
        <div className={classes.listArea} ref={elm}>
          {contents}
        </div>
      </div>

      {!!detail && (
        <>
          <img src={imagePath('gacha/4_X.png')} className={classes.dialogCloseButton} onClick={handleClose} alt="閉じる" />
          <Dialog
            open={!!detail}
            maxWidth={false}
            onClose={handleClose}
            transitionDuration={0}
            PaperProps={{
              style: {
                borderRadius: 14,
              },
            }}
          >
            <div className={classNames(classes.dialogContainer)}>
              <div className={classNames(classes.dialogText1)}>
                <SloppyScalableText content={detail.交換物名} size={23} />
              </div>
              <div className={classes.dialogImageContainer}>
                {detail.画像id && <img src={getPhotoPath(detail.画像id)} className={classes.dialogImage} alt="商品画像" />}
              </div>

              {confirm ? (
                <div className={classes.dialogFormArea}>
                  <div className={classes.dialogFormContainer}>
                    <div className={classes.dialogSelectLabelContainer}>個数</div>
                    <div className={classes.dialogSelectFormContainer}>
                      <FormControl className={classes.dialogSelectFormControl}>
                        <Select
                          value={num}
                          onChange={(e) => {
                            if (e.target.value) {
                              setNum(parseInt(e.target.value + ''));
                            }
                          }}
                          input={<BootstrapInput />}
                          native={true}
                        >
                          {options}
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                  <div className={classNames(classes.dialogText4)}>
                    <span className={classes.dialogText4Title}>合計必要枚数</span>
                    <span className={classNames(classes.dialogText4Point, classes.dialogFormText)}>
                      {detail.必要ポイント * num}
                      <span className={classes.dialogText4Unit}>枚</span>
                    </span>
                  </div>
                </div>
              ) : (
                <div className={classes.dialogDescriptionArea}>
                  <div className={classNames(classes.dialogText2)}>{detail.説明見出し}</div>
                  <div className={classNames(classes.dialogText3)}>
                    <span dangerouslySetInnerHTML={{ __html: nl2br(改行2nl(detail.説明詳細))}}/>
                    {detail.説明詳細url && <><br /><br /><a href={detail.説明詳細url} target="_blank" rel="noopener noreferrer">詳細リンクを開く</a></>}
                    <br /><br />必要枚数：{Number(detail.必要ポイント).toLocaleString()}枚
                  </div>
                </div>
              )}

              <div className={classNames(classes.dialogSeparator)}></div>

              <div className={classNames(classes.dialogText4)}>
                <span className={classes.dialogText4Title}>保有枚数</span>
                <span className={classes.dialogText4Point}>
                  {info.美容ポイント}
                  <span className={classes.dialogText4Unit}>枚</span>
                </span>
              </div>

              <div className={classNames(classes.dialogButtonArea)}>
                <img
                  src={imagePath('gacha/4_button01.png')}
                  className={classNames(classes.dialogButton, {
                    [classes.dialogButtonDisabled]: confirm && info.美容ポイント < detail.必要ポイント * num,
                  })}
                  onClick={async () => {
                    if (confirm) {
                      if (info.美容ポイント < detail.必要ポイント * num) return;
                      await handleExchange(detail.id, num);
                    } else {
                      setConfirm(true);
                    }
                  }}
                  alt="交換"
                />
              </div>
            </div>
          </Dialog>
        </>
      )}

      <Dialog
        open={completed}
        maxWidth={false}
        transitionDuration={0}
        PaperProps={{
          style: {
            borderRadius: 14,
          },
        }}
      >
        <div className={classNames(classes.completeDialogContainer)}>
          <div className={classNames(classes.completeDialogText)}>交換完了しました！</div>
          <div className={classNames(classes.completeDialogButtonArea)}>
            <StandardButton
              size={'large'}
              className={classNames(classes.completeDialogButton)}
              onClick={() => setCompleted(false)}
            >
              戻る
            </StandardButton>
          </div>
        </div>
      </Dialog>
    </>
  );
};
