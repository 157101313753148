import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Swiper, SwiperSlide } from 'swiper/react';
import { useDispatch, useSelector } from 'react-redux';
import moment from 'moment';
import TextField from '@material-ui/core/TextField/TextField';

import { GoalsConfigForm } from '../components/GoalsConfigForm';
import { clearGoalsConfigList, selectMyPage, set大目標 } from '../app/myPageSlice';
// import { getInnerContentHeight } from '../consts';
import { selectActor } from '../app/actorSlice';
import { fetchActorInfo } from '../lib/api';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

// const contentHeight = getInnerContentHeight();

const horizontalPadding = 20;
const r1Height = 28;
const r2Height = 109;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      // height: 'calc(100% - 4px)',
      height: '100%',
    },
    r1: {
      height: r1Height,
      textAlign: 'center',
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0.6,
      paddingTop: 12,
    },
    r2: {
      height: r2Height,
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    r3: {
      //      height: contentHeight - r1Height - r2Height,
    },
  }),
);

const MAX_大目標_LENGTH = 256;
export const GoalsConfigPage: React.FC = () => {
  const classes = useStyles();
  const { loaded, actorInfo } = useSelector(selectActor);
  const { 大目標 } = useSelector(selectMyPage);
  const dispatch = useDispatch();

  useEffect(() => {
    // TODO キャッシュ
    dispatch(clearGoalsConfigList());
  }, []);

  useEffect(() => {
    // 大目標クリア
    if (loaded) {
      dispatch(set大目標(actorInfo?.大目標 ?? ''));
    } else {
      dispatch(fetchActorInfo({}));
    }
  }, [loaded]);

  // 当月分は入力編集可能
  // 前月分以前は編集できない
  // 当月分より遡って当月を含む13ヶ月分を参照可能とする
  // 今月を含む13か月先まで1ヵ月ごとに入力可能
  // -13 ~ +13
  const limit = 13;
  const dateList: moment.Moment[] = [];
  for (let i = limit * -1; i <= limit; i++) {
    dateList.push(moment().add(i, 'month'));
  }

  const slides = dateList.map((m, index) => (
    <GoalsConfigForm key={index} year={m.year()} month={m.month() + 1} enable={index >= limit} />
  ));

  return (
    <div className={classes.root}>
      <div className={classes.r1}>目標設定</div>
      <div className={classes.r2}>
        <TextField
          error={大目標 !== actorInfo?.大目標}
          fullWidth
          label={
            '大目標' +
            (大目標 !== actorInfo?.大目標 ? ' (未保存)' : '') +
            ' - ' +
            大目標.length +
            '/' +
            MAX_大目標_LENGTH
          }
          multiline
          rows={4}
          value={大目標}
          variant='outlined'
          // variant="filled"
          size='small'
          // margin="dense"
          margin='normal'
          onChange={(e) => {
            const v = e.target.value.substr(0, MAX_大目標_LENGTH);
            dispatch(set大目標(v));
          }}
        />
      </div>

      <div className={classes.r3}>
        <Swiper slidesPerView={1} initialSlide={limit} virtual 
          onTransitionEnd={(e) => {
            reportGA(GAEventValue.MYPAGE_MOKUHYO, dateList[e.activeIndex].toDate())
          }}>
          {slides.map((slideContent, index) => (
            <SwiperSlide key={index} virtualIndex={index}>
              {slideContent}
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
    </div>
  );
};
