import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { fetchActorSwitch, fetchSwitchableActor } from "../lib/api";
import { MenuItem, Input } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { selectHome } from "../app/homeSlice";
import Dialog from '@material-ui/core/Dialog';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import { getInnerContentHeight } from '../consts';
import { StandardButton } from '../components/StandardButton';

const contentHeight = getInnerContentHeight();
// const containerHeight = contentHeight + 20 - 100; // footer分
const containerHeight = contentHeight;
const r1Height = 38;
const r2Height = 58;
const r4Height = 20;
const r3Height = containerHeight - r1Height - r4Height - r2Height;

const useStyles = makeStyles((theme: Theme) => createStyles({
  container: {
    width: 345,
    height: containerHeight,
  },
  switchButton: {
    letterSpacing: 1.0,
  },
  r1: {
    height: r1Height,
  },
  r1C1: {},
  r1C2: {
    textAlign: 'center',
  },
  r1C3: {
    paddingRight: 0,
    paddingLeft: 18,
    paddingTop: 20,
    color: theme.palette.text.hint,
  },
  r2: {
    height: r2Height,
  },
  r2Input: {
    width: "100%",
    backgroundColor: "#f6f6f6",
    padding: 6,
    paddingLeft: 12,
  },
  r3: {
    height: r3Height,
    overflowY: "auto",
  },
  r3C3: {
    textAlign: "right",
  },
  r3C3Body: {
    fontSize: "115%",
  },
  r3C3Prefix: {
    fontSize: "75%",
    paddingRight: 4,
    color: "#777",
  },
  r3C3Postfix: {
    fontSize: "75%",
    paddingLeft: 4,
    color: "#777",
  },
  r4: {
    height: r4Height,
  },
}));


type Props = {
};

export function ActorSwitcher(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { switchableActorInfos } = useSelector(selectHome);
  const defaultValue = switchableActorInfos.find((e) => e.selected)
  const [ open, setOpen ] = useState(false);
  const [ filterWords, setFilterWords] = useState("");

  const onClose = () => {
    setOpen(false)
  }

  const searchWordsMatches = (w:string) => {
    if (!filterWords) return true;
    return filterWords.toLowerCase().split(/[ |　]/).every((e) => w.toLowerCase().match(e));
  }

  return <>
      <StandardButton
        className={classes.switchButton}
        appearance={'transparent'}
        size={'medium'}
        onClick={async () => {
            setOpen(true);
            await dispatch(fetchSwitchableActor({}));
          }
        }>
          {switchableActorInfos.filter(e => e.id === defaultValue?.id)[0]?.name}
      </StandardButton>
      <Dialog open={open} onClose={onClose} maxWidth={false}
      PaperProps={{
        style: {
          marginTop: 0,
        },
      }}>
        <Grid container className={classes.container}>
          <Grid item xs={10} className={classNames(classes.r1, classes.r1C1)}></Grid>
          <Grid item xs={2} className={classNames(classes.r1, classes.r1C3)}>
            <CloseIcon onClick={onClose} />
          </Grid>

          <Grid item xs={2}></Grid>
          <Grid item xs={8} className={classes.r2}>
            <Input onChange={e => setFilterWords(e.target.value)}
              className={classes.r2Input}
              value={filterWords}
              placeholder="絞り込み" />
          </Grid>
          <Grid item xs={2}></Grid>

          <Grid item xs={12} className={classes.r3}>
            {switchableActorInfos.filter(e => searchWordsMatches(e.searchWords)).map((e) => 
              <MenuItem key={e.id} value={e.id} onClick={async (ev) => {
                if (e.id && e.id !== defaultValue?.id) {
                  await dispatch(fetchActorSwitch({actorId: e.id + ''}));
                  // 成功、失敗問わずにリロードさせる
                  window.location.reload();
                }
              }}>
                <Grid item xs={1}>
                {e.id === defaultValue?.id ? "✔" : ""}
                </Grid>
                <Grid item xs={1}>
                  {`${e.tenpoShortName}`}
                </Grid>
                <Grid item xs={7}>
                  {`${e.name}`}
                </Grid>
                <Grid item xs={3} className={classes.r3C3}>
                  <span className={classes.r3C3Prefix}>年間</span>
                  <span className={classes.r3C3Body}>{`${e.groupRanking}`}</span>
                  <span className={classes.r3C3Postfix}>位</span>
                </Grid>
              </MenuItem>
            )}
          </Grid>
          <Grid item xs={12} className={classes.r4}></Grid>
        </Grid>
    </Dialog>
  </>;
}
