import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

import { imagePath } from 'lib/pathes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    arrowImage: {
      width: 34 / 3.4,
      height: 49 / 3.4,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    notChanged: {
      color: theme.palette.primary.light,
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 14,
      fontWeight: 'bold',
    },
  }),
);

type Props = {
  isUp?: boolean;
  diff?: number;
};

export function UpDownIcon(props: Props) {
  const classes = useStyles();
  let ArrowIcon;
  let isUp = props.isUp;

  if (props.diff !== undefined) {
    if (0 < props.diff) {
      isUp = true;
    } else if (props.diff < 0) {
      isUp = false;
    } else {
      isUp = undefined;
    }
  }

  if (isUp === true) {
    ArrowIcon = <img src={imagePath('icon_home_arrow_up.png')} className={classes.arrowImage} alt='up' />;
  } else if (isUp === false) {
    ArrowIcon = <img src={imagePath('icon_home_arrow_down.png')} className={classes.arrowImage} alt='down' />;
  } else {
    ArrowIcon = <div className={classes.notChanged}>―</div>;
  }
  return <>{ArrowIcon}</>;
}
