import React, { useEffect, useLayoutEffect, useState } from 'react';
import { createBrowserHistory } from 'history';
import { Router } from 'react-router-dom';
import { MuiThemeProvider, Theme, createMuiTheme, createStyles, makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import deepmerge from 'deepmerge';
import ReactGA from "react-ga4";

import { Auth } from '../components';
import { CommonAlert } from '../components/CommonAlert';
import { callNativePrepareGachaResources, registerHandlers } from '../lib/nativeBridge';

import { generateAdjustedThemeChunk, theme } from './theme';

import { adjustBodyHeight, adjustViewPort, getAdjustedContentAreaHeight } from 'lib/adjustViewport';
import { Footer, Header } from 'layouts';
import routes, { Path } from 'routes';
import {EffectPlayer} from "../components/Effect/EffectPlayer";
import classNames from 'classnames';
import {setScreenInfo} from "../app/applicationSlice";
import { useSelector, useDispatch } from 'react-redux';
import { selectHome } from "../app/homeSlice";
import { reportGAAsync } from "../lib/googleAnalytics";
import {selectEffect} from "../app/effectSlice";
import {fetchEffectList} from "../lib/api";
import {selectAuth} from "../app/authSlice";

const history = createBrowserHistory();

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    // blur: {
    //   filter: 'blur(3px)',
    // },
    pageContentsArea: {
      overflowY: 'scroll',
    },
  }),
);

export const Layout: React.FC = () => {
  const [stateTheme, setTheme] = useState(theme);
  const [count, setCount] = useState(0);
  const [contentAreaHeight, setContentAreaHeight] = useState('auto');
  const classes = useStyles();
  const { showEffect, maskBlurPx, effectListLoaded } = useSelector(selectEffect);
  const dispatch = useDispatch();
  const home = useSelector(selectHome);
  const { isAuthenticated } = useSelector(selectAuth);

  useEffect(() => {
    registerHandlers();
    callNativePrepareGachaResources();
  }, []);

  useEffect(() => {
    if (!home.loaded) return;
    if (!!home.switchableActorInfos) {
      console.log("Omit GA staff login");
      return;
    }
    if (process.env.REACT_APP_GA4_STREAM) {
      console.log("ReactGA.initialize", process.env.REACT_APP_GA4_STREAM);
      ReactGA.initialize(process.env.REACT_APP_GA4_STREAM);
    } else {
      console.log("couldn't ReactGA.initialize", process.env.REACT_APP_GA4_STREAM);
    }
    reportGAAsync();
  }, [home.loaded]);

  useEffect(() => {
    if (!home.loaded || !isAuthenticated || effectListLoaded) return;
    if(window.location.pathname !== Path.home){
      dispatch(fetchEffectList({}))
    }
  }, [home.loaded, isAuthenticated, effectListLoaded]);

  useLayoutEffect(() => {
    const screenInfo = adjustViewPort();
    dispatch(setScreenInfo(screenInfo));
    adjustBodyHeight(screenInfo);
    setContentAreaHeight(getAdjustedContentAreaHeight(screenInfo));
    const tmp = deepmerge(stateTheme, generateAdjustedThemeChunk(screenInfo));
    // console.log('useLayoutEffect:screenInfo');
    // console.log(screenInfo);
    // console.log(tmp);
    setTheme(tmp);

    // env()による値の取得は遅延するため再取得を試みる
    if (screenInfo.supportedFuncName !== '' && count === 0) {
      const timer = setTimeout(() => setCount((c) => c + 1), 300);
      return () => {
        clearTimeout(timer);
      };
    }
  }, [count]);

  const maskFilter = showEffect ? `blur(${maskBlurPx}px)` : 'none'
  return (
    <>
      <MuiThemeProvider theme={createMuiTheme(stateTheme)}>
        <CssBaseline />
        <Router history={history}>
          <Auth loginPath={Path.login}>
            <Header
              // className={classNames({[classes.blur] : showEffect})}
              style={{filter: maskFilter}}
            />
            <div
              className={classNames(classes.pageContentsArea,
                // {[classes.blur] : showEffect}
              )}
              style={{
                height: contentAreaHeight,
                filter: maskFilter,
            }}>
              {routes}
            </div>
            <Footer
              // className={classNames({[classes.blur] : showEffect})}
              style={{filter: maskFilter}}
            />
          </Auth>
        </Router>
        <CommonAlert />
        <EffectPlayer />
      </MuiThemeProvider>
    </>
  );
};
