import { consts } from 'consts';

export const dump = () => {
  alert(
    document.body.clientWidth +
      '\n' +
      document.body.clientHeight +
      '\n' +
      document.documentElement.clientWidth +
      '\n' +
      document.documentElement.clientHeight +
      '\n' +
      // window.innerWidth + "\n" +
      // window.innerHeight + "\n" +
      window.parent.innerWidth +
      '\n' +
      window.parent.innerHeight,
  );
};

function setFixedViewport(contentWidth, screenWidth) {
  // const scale = Math.round((screenWidth / contentWidth) * 1000) / 1000;
  const scale = screenWidth / contentWidth;
  const width = Math.round(contentWidth);
  const viewportContent = 'width=' + width + ', initial-scale=' + scale + ', user-scalable=no, viewport-fit=cover';
  // console.log(viewportContent);

  // XXX: head内にviewport指定が先頭にある前提
  const m = document.getElementsByTagName('META')[0];
  m.content = viewportContent;

  return {
    viewportWidth: width,
    viewportScale: scale,
  };
}

function setAspectFitViewport(contentHeight, screenWidth, innerWidth, innerHeight) {
  return setFixedViewport(contentHeight / (innerHeight / innerWidth), screenWidth);
}

function getSafeAreaInsetFromElement(funcName) {
  const div = document.createElement('div');
  div.style.paddingTop = funcName + '(safe-area-inset-top)';
  div.style.paddingBottom = funcName + '(safe-area-inset-bottom)';
  div.style.paddingLeft = funcName + '(safe-area-inset-left)';
  div.style.paddingRight = funcName + '(safe-area-inset-right)';
  div.style.display = 'none';
  document.body.appendChild(div);
  const style = window.getComputedStyle(div);
  const top = parseInt(style.paddingTop, 10);
  const bottom = parseInt(style.paddingBottom, 10);
  const left = parseInt(style.paddingLeft, 10);
  const right = parseInt(style.paddingRight, 10);
  document.body.removeChild(div);

  return {
    supportedFuncName: funcName,
    top: top,
    bottom: bottom,
    left: left,
    right: right,
  };
}

function getDefaultSafeAreaInset() {
  return {
    supportedFuncName: '',
    top: consts.defaultSafeAreaInsetTop,
    bottom: consts.defaultSafeAreaInsetBottom,
    left: 0,
    right: 0,
  };
}

export function getSafeAreaInset() {
  if (CSS.supports('padding-top: env(safe-area-inset-top)')) {
    return getSafeAreaInsetFromElement('env');
  } else if (CSS.supports('padding-top: constant(safe-area-inset-top)')) {
    return getSafeAreaInsetFromElement('constant');
  } else {
    return getDefaultSafeAreaInset();
  }
}

export function adjustViewPort() {
  const contentWidth = consts.estimatedContentWidth;
  const contentHeight = consts.estimatedContentHeight;
  // iphone x: 375
  const screenWidth = window.parent.screen.width;
  // iphone x: 812
  // var screenHeight = window.parent.screen.height;
  // iphone x: 734(safe areaを除外した値)
  // var screenHeight = window.parent.outerHeight;

  // const innerWidth = window.parent.innerWidth;
  // const innerHeight = window.parent.innerHeight;
  const innerWidth = window.parent.screen.width;
  const inset = getSafeAreaInset();
  const verticalInset = inset.top + inset.bottom;

  const innerHeight = window.parent.screen.height - verticalInset;
  // console.log(
  //   'window.parent.screen.width:' +
  //   window.parent.screen.width +
  //   ', window.parent.screen.height:' +
  //   window.parent.screen.height +
  //   ', innerWidth:' +
  //   innerWidth +
  //   ', innerHeight:' +
  //   innerHeight +
  //   ', inset.top:' +
  //   inset.top +
  //   ', inset.bottom:' +
  //   inset.bottom +
  //   ', verticalInset:' +
  //   verticalInset,
  // );

  let viewportResult;
  if (contentHeight / contentWidth < innerHeight / innerWidth) {
    // iphone Xより縦長の画面であれば横で合わせ、下部に余白が出るように
    viewportResult = setFixedViewport(contentWidth, screenWidth);
  } else {
    // 縦で合わせ、横幅を伸ばす
    viewportResult = setAspectFitViewport(contentHeight, screenWidth, innerWidth, innerHeight);
  }
  return Object.assign(viewportResult, inset);
}

export function adjustBodyHeight(screenInfo) {
  document.body.style.height =
    //  - 0.5pxは誤差対策
    screenInfo.supportedFuncName === ''
      ? `calc(100vh - ${screenInfo.top / screenInfo.viewportScale}px)`
      : `calc(100vh - (${screenInfo.supportedFuncName}(safe-area-inset-top) / ${screenInfo.viewportScale}) - 0.5px)`;
}

export function getAdjustedContentAreaHeight(screenInfo) {
  return screenInfo.supportedFuncName === ''
    ? `calc(100vh - ${(screenInfo.top + screenInfo.bottom) / screenInfo.viewportScale}px - ${consts.headerSize}px - ${
        consts.footerSize
      }px)`
    : `calc(100vh - (${screenInfo.supportedFuncName}(safe-area-inset-top) + ${screenInfo.supportedFuncName}(safe-area-inset-bottom)) / ${screenInfo.viewportScale} - ${consts.headerSize}px - ${consts.footerSize}px)`;
}
