import React, { useCallback, useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
} from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';

import {
  TenpoPointUnitTypeValue,
  fetchActorInfo,
  fetchGoalsConfig,
  fetchGoalsConfigUpdate,
  fetchGraphGoals,
} from '../lib/api';
import { GoalsConfig, selectMyPage, setGoalsConfig } from '../app/myPageSlice';
import { selectHome } from '../app/homeSlice';
import { showSuccessAlert } from '../app/applicationSlice';
import { useTenpoPointUnitType } from '../hooks/useTenpoPointUnitType';

import { LoadingOverlay } from './LoadingOverlay';
import { NoData } from './NoData';
import { StandardButton } from './StandardButton';
import { GraphType } from './Graph/graphDefine';
import moment from 'moment';

const horizontalPadding = 24;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
      width: '100%',
    },
    container: {
      height: '100%',
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
      display: 'flex',
      flexDirection: 'column',
    },

    r1: {
      height: 40,
      textAlign: 'center',
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 0.6,
    },
    month: {
      fontSize: 18,
      paddingTop: 12,
      paddingBottom: 0,
    },

    r2: {
      flex: 1,
      // backgroundColor: 'lightgray',
      // height: 500,
    },
    r2Table: {
      // height: 500,
    },
    noBorder: {
      border: 'none',
    },
    thValue: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      width: 200,
      paddingBottom: 26,
    },
    tdValue: {
      minWidth: 95,
      paddingRight: 10,
    },

    buttonArea: {
      height: 79,
      textAlign: 'center',
    },
    buttonImage: {
      width: 142,
      marginBottom: 43,
    },
  }),
);

// const formGraphTypes = [
//   GraphType.成績,
//   GraphType.給与総支給額,
//   GraphType.店舗ポイントランキング,
//   GraphType.グループポイントランキング,
//   GraphType.同伴数,
//   GraphType.指名本数,
//   GraphType.出勤時間,
// ];

function useFormTitle(type: GraphType): string {
  const 成績タイトル = useTenpoPointUnitType('ポイント', '小計', '');
  switch (type) {
    case GraphType.成績:
      return 成績タイトル;
    case GraphType.給与総支給額:
      return '給与総支給額';
    case GraphType.同伴数:
      return '同伴数';
    case GraphType.指名本数:
      return '指名本数';
    case GraphType.出勤時間:
      return '勤務時間';
    case GraphType.店舗ポイントランキング:
      return '店舗ポイントランキング';
    case GraphType.グループポイントランキング:
      return 'グループポイントランキング';
  }
  return '';
}

function useConfig(props: Props): GoalsConfig | undefined {
  const { goalsConfigList } = useSelector(selectMyPage);
  return goalsConfigList.find((c) => c.year === props.year && c.month === props.month);
}

function useFormValue(props: Props, type: GraphType): string {
  const config = useConfig(props);
  if (!config) return '';
  switch (type) {
    case GraphType.成績:
      return config.成績;
    case GraphType.給与総支給額:
      return config.支給総額;
    case GraphType.同伴数:
      return config.同伴本数;
    case GraphType.指名本数:
      return config.指名本数;
    case GraphType.出勤時間:
      return config.出勤時間;
    case GraphType.店舗ポイントランキング:
      return config.店舗内ランキング;
    case GraphType.グループポイントランキング:
      return config.グループ内ランキング;
  }
  return '';
}

function useFormErrorMessage(props: Props, type: GraphType): string {
  const v = useFormValue(props, type);
  const config = useConfig(props);
  if (!config) return '';

  if (!v) return '入力してください';
  if (isNaN(Number(v))) return '数値ではありません';
  if (Number(v) < 0) return 'マイナスは入力できません';
  if (type === GraphType.成績) {
    const numV = Number(v);
    if (numV < config.成績最低値) return config.成績最低値 + '以上の入力が必要です';
  }
  if (type === GraphType.出勤時間) {
    const numV = Number(v);
    if (numV > 72) return '72以下に設定してください';
  }
  return '';
}

function useValidateForm(props: Props): boolean {
  return ![
    useFormErrorMessage(props, GraphType.成績),
    useFormErrorMessage(props, GraphType.給与総支給額),
    useFormErrorMessage(props, GraphType.店舗ポイントランキング),
    useFormErrorMessage(props, GraphType.グループポイントランキング),
    useFormErrorMessage(props, GraphType.同伴数),
    useFormErrorMessage(props, GraphType.指名本数),
    useFormErrorMessage(props, GraphType.出勤時間),
  ].find((v) => !!v);
}

function useFormValueKey(type: GraphType): string {
  switch (type) {
    case GraphType.成績:
      return '成績';
    case GraphType.給与総支給額:
      return '支給総額';
    case GraphType.同伴数:
      return '同伴本数';
    case GraphType.指名本数:
      return '指名本数';
    case GraphType.出勤時間:
      return '勤務時間';
    case GraphType.店舗ポイントランキング:
      return '店舗内ランキング';
    case GraphType.グループポイントランキング:
      return 'グループ内ランキング';
  }
  return '';
}

function useFormUnit(type: GraphType): string {
  const 成績単位 = useTenpoPointUnitType('pt', '円', '');
  switch (type) {
    case GraphType.成績:
      return 成績単位;
    case GraphType.給与総支給額:
      return '円';
    case GraphType.同伴数:
      return '件';
    case GraphType.指名本数:
      return '本';
    case GraphType.出勤時間:
      return '時間';
    case GraphType.店舗ポイントランキング:
      return '位';
    case GraphType.グループポイントランキング:
      return '位';
  }
  return '';
}

function useFormInput(props: Props, type: GraphType, index: number): JSX.Element {
  const defaultValue = useFormValue(props, type);
  const unit = useFormUnit(type);
  const key = useFormValueKey(type);
  const errorMessage = useFormErrorMessage(props, type);
  const config = useConfig(props);
  const dispatch = useDispatch();

  const home = useSelector(selectHome);

  // <InputLabel htmlFor={"component-helper-" + id}>Name</InputLabel>
  return (
    <FormControl variant='standard' error={!!errorMessage} key={index}>
      <Input
        id={'component-helper-' + index}
        aria-describedby={'component-helper-text-' + index}
        fullWidth={false}
        margin={'dense'}
        // defaultValue={defaultValue}
        value={defaultValue}
        endAdornment={<InputAdornment position='end'>{unit}</InputAdornment>}
        type={'number'}
        disabled={!props.enable}
        onBlur={(e) => {
          if (!config) return;
          if (!e.target.value) return;
          const numValue = Number(e.target.value);
          dispatch(
            setGoalsConfig({
              ...config,
              [key]: String(numValue),
            }),
          );
        }}
        onChange={(e) => {
          if (!config) return;
          // console.log('onChange', type, defaultValue, typeof defaultValue, typeof e.target.value)
          // 自動更新
          // 成績(キャストポイント)（1の位を切り捨て） * 500 = 給与総支給額
          // 成績(小計)（1の位を切り捨て） * 0.6 = 給与総支給額
          if (home.loaded && (type === GraphType.給与総支給額 || type === GraphType.成績)) {
            const numValue = Math.floor(Number(e.target.value) / 10) * 10;
            if (type === GraphType.成績) {
              if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.ポイント) {
                dispatch(
                  setGoalsConfig({
                    ...config,
                    [key]: e.target.value,
                    ['支給総額']: String(Math.floor(numValue * 500)),
                  }),
                );
              } else {
                dispatch(
                  setGoalsConfig({
                    ...config,
                    [key]: e.target.value,
                    ['支給総額']: String(Math.floor(numValue * 0.6)),
                  }),
                );
              }
            }
            if (type === GraphType.給与総支給額) {
              const numValue = Number(e.target.value);
              if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.ポイント) {
                dispatch(
                  setGoalsConfig({
                    ...config,
                    [key]: e.target.value,
                    ['成績']: String(Math.floor(numValue / 500)),
                  }),
                );
              } else {
                dispatch(
                  setGoalsConfig({
                    ...config,
                    [key]: e.target.value,
                    ['成績']: String(Math.floor(numValue / 0.6)),
                  }),
                );
              }
            }
          } else if (home.loaded && type === GraphType.出勤時間) {
            dispatch(
              setGoalsConfig({
                ...config,
                出勤時間: e.target.value,
              }),
            );
          } else {
            dispatch(
              setGoalsConfig({
                ...config,
                [key]: e.target.value,
              }),
            );
          }
        }}
      />
      <FormHelperText id={'component-helper-text-' + index}>
        {/*{errorMessage}*/}
        {errorMessage ? errorMessage : ' '}
      </FormHelperText>
    </FormControl>
  );
}

function useFormDef(props: Props, type: GraphType, index: number): { title: string; value: JSX.Element } {
  return { title: useFormTitle(type), value: useFormInput(props, type, index) };
}

type Props = {
  year: number; // 年
  month: number; // 月 1~12
  enable: boolean;
};
export const GoalsConfigForm = (props: Props) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const [sending, setSending] = useState(false);
  const { goalsConfigList, goalsConfigListOrg, 大目標 } = useSelector(selectMyPage);
  const config = goalsConfigList.find((c) => c.year === props.year && c.month === props.month);
  const configOrg = goalsConfigListOrg.find((c) => c.year === props.year && c.month === props.month);

  useEffect(() => {
    if (!config) {
      dispatch(fetchGoalsConfig({ year: props.year, month: props.month }));
    }
  }, [config]);

  const save = useCallback(async () => {
    if (!config || !configOrg || sending) return;

    // ガチャチケット不正付与防止の為、当月6日以降に成績, 支給総額を下降更新の場合確認
    const now = moment();
    console.log(config.成績, configOrg.成績, config.支給総額, configOrg.支給総額);
    if(now.year() === props.year && now.month() + 1 === props.month &&  now.date() >= 6 && (Number(config.成績) < Number(configOrg.成績) || Number(config.支給総額) < Number(configOrg.支給総額))){
      if(!window.confirm('6日以降の変更は、BP付与の対象外となります')) return;
    }

    setSending(true);
    const graphGoals = {
      成績: Number(config.成績),
      支給総額: Number(config.支給総額),
      店舗内ランキング: Number(config.店舗内ランキング),
      グループ内ランキング: Number(config.グループ内ランキング),
      指名本数: Number(config.指名本数),
      同伴本数: Number(config.同伴本数),
      出勤時間: Number(config.出勤時間) * 60,
    };
    await dispatch(
      fetchGoalsConfigUpdate({
        year: config.year,
        month: config.month,
        グラフ目標: graphGoals,
        大目標: 大目標,
      }),
    );
    await dispatch(fetchActorInfo({}));
    dispatch(fetchGraphGoals({}));
    dispatch(showSuccessAlert('保存が完了しました'));
    setSending(false);
  }, [dispatch, config, configOrg, 大目標]);

  const rows = [];
  rows.push(useFormDef(props, GraphType.成績, rows.length));
  rows.push(useFormDef(props, GraphType.給与総支給額, rows.length));
  rows.push(useFormDef(props, GraphType.店舗ポイントランキング, rows.length));
  rows.push(useFormDef(props, GraphType.グループポイントランキング, rows.length));
  rows.push(useFormDef(props, GraphType.同伴数, rows.length));
  rows.push(useFormDef(props, GraphType.指名本数, rows.length));
  rows.push(useFormDef(props, GraphType.出勤時間, rows.length));

  const enable = useValidateForm(props) && props.enable && !sending;
  let contents = <LoadingOverlay />;
  if (config) {
    if (config.result.toUpperCase() !== 'OK') {
      contents = <NoData />;
    } else {
      contents = (
        <>
          <div className={classNames(classes.r2)}>
            <TableContainer
              className={classNames(classes.r2Table)}
              /*component={Paper}*/
            >
              <Table size='small'>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={row.title}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classNames(classes.noBorder, classes.thValue)}
                        style={{ paddingLeft: 10, paddingRight: 4 }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classNames(classes.noBorder, classes.tdValue)}
                        style={{ paddingLeft: 4 }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </div>

          <div className={classes.buttonArea}>
            <StandardButton size='large' className={classes.buttonImage} onClick={save} disabled={!enable}>
              保存する
            </StandardButton>
          </div>
        </>
      );
    }
  }
  return (
    <>
      <div className={classes.root}>
        <div className={classes.container}>
          <div className={classes.r1}>
            <div className={classes.month}>{`${props.year}年${props.month}月`}</div>
          </div>
          {contents}
        </div>
      </div>
    </>
  );
};
