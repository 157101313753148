import React, { useEffect, useRef, useLayoutEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import {AudioPlayer} from "../../lib/audioPlayer";
import { useDispatch, useSelector } from 'react-redux';
import {selectApplication} from "../../app/applicationSlice";
import {consts} from "../../consts";
import {getHeaderHeightStyle} from "../../layouts/theme";
import {starAudioPlayer} from "../../lib/effectAudioPlayers";
import {EffectSheets} from "./EffectSheets";
import {getEffectMovieUrl, preloadSheetImages, useEndEffect} from "../../lib/effectLib";
import {selectEffect, setShowEffect} from "../../app/effectSlice";

const useStyles = makeStyles((theme: Theme) => createStyles({
  container:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    // height: '100vh',
    height: 'calc(100vh - 0.5px)', // スクロールしないよう調整
    overflow: 'hidden',
    zIndex: 10000,
  },
  cover:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
  },
  mask:{
    // backgroundColor: 'rgba(255, 255, 255, 0.3)',
    // zIndex: 10000,
  },
  video1: {
    position: 'absolute',
    left: 0,
    width: '100%',
    aspectRatio: `${1080 / 1000}`,
    backgroundColor: 'rgba(0,0,0,0)',
    // zIndex: 10001,
  },
  sheets:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%) scale(0)',
    width: '100%',
    height: '100%',
    // zIndex: 10002,
  },
  sheetAnimation:{
    animation: `$sheetZoom ${1 / 24 * 5.5}s`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'steps(5, jump-both)',
  },
  '@keyframes sheetZoom': {
    '0%': { transform: 'translate(-50%, -50%) scale(0.26)' },
    '100%': { transform: 'translate(-50%, -50%) scale(1)' }
  },
  sheetAnimationEnded:{
    transform: 'translate(-50%, -50%) scale(1)'
  },
  video2: {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%',
    aspectRatio: `${880 / 1896}`,
    // zIndex: 10003,
  },
  clickable:{
    // zIndex: 10010,
  },
  transparent: {
    opacity: 0,
  },
  hidden: {
    display: 'none',
  },
}));

type Props = {};
const movieAudioPlayer = new AudioPlayer()
let playStarTimer = 0;

enum Phase {
  NONE = 0,
  LOADING = 1,
  MOVIE_PLAYING = 2,
  SHEET_ZOOMING = 3,
  SHEET = 4,
}
let t = (new Date()).getTime();
function log(msg: string, t:number){
  console.log(`❓${msg} t:${(new Date()).getTime() - t}`);
}
export function EffectPlayer(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();

  const { screenInfo } = useSelector(selectApplication);
  const { showEffect, effectArg, maskColorR, maskColorG, maskColorB, maskOpacity } = useSelector(selectEffect);
  const [videoTopStyle, setVideoTopStyle] = useState<string|number>(consts.headerSize);

  const videoRef1 = useRef<HTMLVideoElement>(null);
  const videoRef2 = useRef<HTMLVideoElement>(null);
  const [video1Visible, setVideo1Visible] = useState(false);
  const [phase, setPhase] = useState(Phase.NONE);
  const endEffect = useEndEffect();

  const maskBackgroundColorStyle = `rgba(${maskColorR}, ${maskColorG}, ${maskColorB}, ${maskOpacity})`;

  // init
  useEffect(() => {
    if(!effectArg) return;

    if(!effectArg.animation?.movieFiles){
      if(!!effectArg.sheets){
        (async(sheets) => {
          await preloadSheetImages(sheets);
          setPhase(Phase.SHEET);
          dispatch(setShowEffect(true))
        })(effectArg.sheets);
      }
      return
    }

    setPhase(Phase.LOADING)
  }, [effectArg]);

  // load & play
  useEffect(() => {
    switch(phase){
      case Phase.LOADING: {
        if(!effectArg?.animation?.movieFiles) return;
        if(!videoRef1?.current || !videoRef2?.current) return;
        setVideo1Visible(false);

        (async(movieFiles, video1, video2, sheets) => {
          const [movieVideoBuffer, starVideoBuffer] = await Promise.all([
            fetch(movieFiles.movie).then(e => e.blob()),
            fetch(getEffectMovieUrl('star.png')).then(e => e.blob()),
            movieAudioPlayer.load(movieFiles.audio),
          ]);
          t = (new Date()).getTime();
          video2.src = URL.createObjectURL(starVideoBuffer);
          if(sheets != null) await preloadSheetImages(sheets);
          log('init', t);
          video1.src = URL.createObjectURL(movieVideoBuffer);
          setPhase(Phase.MOVIE_PLAYING);
          dispatch(setShowEffect(true));
          setVideo1Visible(true)
        })(effectArg?.animation?.movieFiles, videoRef1.current, videoRef2.current, effectArg?.sheets);
        break;
      }
    }
  }, [phase, videoRef1.current, videoRef2.current, effectArg]);

  // 褒めアニメーションの上部マージン量取得
  useLayoutEffect(() => {
    if(!screenInfo) return;
    setVideoTopStyle(getHeaderHeightStyle(screenInfo));
  }, [screenInfo])

  return <>
    <div className={classNames(
      classes.container,
      { [classes.hidden]: !showEffect })
    }>
      <div className={classNames(classes.cover, classes.mask)} style={{backgroundColor: maskBackgroundColorStyle}}/>

      {/*褒めアニメーション*/}
      <video
        playsInline
        muted
        autoPlay
        preload={'auto'}
        className={classNames(
          classes.video1,
          { [classes.transparent]: !video1Visible || [Phase.SHEET].includes(phase) }
        )}
        style={{top: videoTopStyle}}
        ref={videoRef1}
        onPlaying={(v)=>{
          log('onPlaying', t);
          const el = (v.target as HTMLVideoElement);
          if(!el.paused){
            // 動画音声再生開始
            movieAudioPlayer.start();

            if(!!effectArg?.sheets) {
              // star動画再生予約 3秒後
              playStarTimer = window.setTimeout(() => {
                // star動画再生開始
                videoRef2?.current?.play();
              }, 3000 - 300); // 実際の再生開始までラグがあるので早めに実行
            }
          }
        }}
        onEnded={(v) => {
          movieAudioPlayer.clear()
          setVideo1Visible(false)
          if(!effectArg?.sheets){
            endEffect()
          }
        }}

        onPlay={(v)=>{
          log('onPlay', t);
        }}
        onCanPlay={(v)=>{
          log('onCanPlay', t);
        }}
        onCanPlayThrough={(v)=>{
          log('onCanPlayThrough', t);
        }}
        onLoadedMetadata={(v)=>{
          log('onLoadedMetadata', t);
        }}
      />

      {/*表彰状*/}
      { effectArg?.sheets &&
        <EffectSheets
          className={classNames(
            classes.sheets,
            {[classes.sheetAnimation]: [Phase.SHEET_ZOOMING].includes(phase)},
            {[classes.sheetAnimationEnded]: [Phase.SHEET].includes(phase)}
          )}
          sheets={effectArg.sheets}
        />
      }

      {/*スター*/}
      <video
        playsInline
        muted
        preload={'auto'}
        className={classNames(
          classes.video2,
          { [classes.hidden]: [Phase.SHEET].includes(phase) }
        )}
        ref={videoRef2}
        onPlaying={(v)=>{
          const el = (v.target as HTMLVideoElement);
          if(!el.paused){
            // star音声再生開始
            starAudioPlayer.start();

            // 表彰状拡大アニメーション開始
            setPhase(Phase.SHEET_ZOOMING);
          }
        }}
        onEnded={(v) => {
          setPhase(Phase.SHEET);
        }}
      />

      {/*タップレイヤー*/}
      <div
        className={classNames(
          classes.cover,
          classes.clickable,
          { [classes.hidden]: [Phase.SHEET].includes(phase) },
        )}
        onClick={(v)=>{
          if(!videoRef1?.current || !videoRef2?.current) return;
          clearTimeout(playStarTimer)
          const el1 = videoRef1.current;
          const el2 = videoRef2.current;
          el1.pause()
          el2.pause()
          el1.src = '';
          movieAudioPlayer.clear()
          starAudioPlayer.pause()

          if(!effectArg?.sheets){
            endEffect()
          }else{
            setPhase(Phase.SHEET);
          }
        }}
      />
    </div>
  </>;
}
