import React, { useEffect, useRef } from 'react';

type Canvas = CanvasRenderingContext2D | null | undefined;

function drawMultiRadiantCircle(
  ctx: Canvas,
  centerX: number, // 円弧の中心のx座標値
  centerY: number, // 円弧の中心のy座標値
  radius: number, // 円弧の半径
  lineWidth: number, // 円弧の太さ
  fillRate: number, // 描画するバー長の割合 0 ~ 1.0
  radientColors: string[], // グラディエーション色指定
  colorLengthRates?: number[], // グラディエーション色の描画長さを明示的に指定 0~1
) {
  if (!ctx) return;

  // 円弧の描画周数
  const laps = 2;
  // 円弧の1周分の長さ
  const circleLength = 2 * Math.PI;
  // 円弧の最大描画長
  const maxLength = circleLength * laps;
  // 円弧の描画長
  const fillLength = maxLength * (fillRate / laps);
  // 1色分の円弧の描画長
  const partLength = maxLength / radientColors.length;
  let start = 0;
  let gradient = null;
  let startColor = null,
    endColor = null;

  for (let i = 0; i < radientColors.length; i++) {
    // 明示的な各色の描画長指定がなければ均等割
    let currentPartLength = partLength;
    if (colorLengthRates) {
      currentPartLength = maxLength * colorLengthRates[i];
    }

    let end = Math.min(start + currentPartLength, fillLength);
    // 0~360度指定では何も描画されないため、最大長を1周弱におさめる
    if (end - start >= circleLength) end = start + circleLength * 0.9999;
    if (end <= start) continue;

    startColor = radientColors[i];
    endColor = i + 1 === radientColors.length ? radientColors[i] : radientColors[i + 1];

    // x start / end of the next arc to draw
    const xStart = centerX + Math.cos(start) * radius;
    const xEnd = centerX + Math.cos(end) * radius;
    // y start / end of the next arc to draw
    const yStart = centerY + Math.sin(start) * radius;
    const yEnd = centerY + Math.sin(end) * radius;

    ctx.beginPath();

    /*
    createLinearGradient(
      x0: number, // The x-axis coordinate of the start point.
      y0: number, // The y-axis coordinate of the start point.
      x1: number, // The x-axis coordinate of the end point.
      y1: number // The y-axis coordinate of the end point.
    ): CanvasGradient;
    */
    gradient = ctx.createLinearGradient(xStart, yStart, xEnd, yEnd);
    // Adds a color stop with the given color to the gradient at the given offset.
    // 0.0 is the offset at one end of the gradient, 1.0 is the offset at the other end.
    gradient.addColorStop(0, startColor);
    gradient.addColorStop(1.0, endColor);

    ctx.strokeStyle = gradient;
    /*
    arc(
      x: number, // 円弧の中心のx座標値
      y: number, // 円弧の中心のy座標値
      radius: number, // 円弧の半径
      startAngle: number, // 円弧の始まりの角度。x軸の正方向から時計回りに定められるラジアン角
      endAngle: number, // 円弧の終わりの角度。x軸の正方向から時計回りに定められるラジアン角
      anticlockwise?: boolean // 省略可能なBoolean。trueは、円弧を反時計回りに始まりから終わりの角度に向けて描きます。 デフォルトは時計回り。
    ): void;
    */
    ctx.arc(centerX, centerY, radius, start, end);
    ctx.lineWidth = lineWidth;
    ctx.lineCap = 'round';
    ctx.stroke();
    ctx.closePath();

    start = end;
  }
}

type Props = {
  size: number;
  value: number;
  bgColor?: string;
};
const lineWidth = 15;

const canvasStyle = {
  // border: '1px solid gray',
  // backgroundColor: 'white',
  transform: 'scale(0.5,0.5) translate(-50%, -50%) rotate(-90deg)',
};

export function GradientCircle(props: Props) {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const getContext = (): Canvas => {
    const canvas: HTMLCanvasElement | null = canvasRef.current;
    return canvas?.getContext('2d');
  };

  useEffect(() => {
    const ctx: Canvas = getContext();
    const someColors = [];
    const colorLengthRates = [];

    // 色相環 @see https://www.petitmonte.com/javascript/color_wheel.html
    // 各色の長さを明示的に指定
    someColors.push('#ffff00');
    colorLengthRates.push((3 - 0) / 24); // 0
    someColors.push('#ff7f00');
    colorLengthRates.push((6 - 3) / 24); // 3
    someColors.push('#d92232');
    colorLengthRates.push((9 - 6) / 24); // 6
    someColors.push('#d7157e');
    colorLengthRates.push((12 - 9) / 24); // 9
    someColors.push('#0078b1');
    colorLengthRates.push((16 - 12) / 24); // 12
    someColors.push('#00b8e0');
    colorLengthRates.push((24 - 16) / 24); // 16

    drawMultiRadiantCircle(ctx, props.size, props.size, props.size - lineWidth, lineWidth * 2, 1.0, ['#e6e9eb']);
    drawMultiRadiantCircle(
      ctx,
      props.size,
      props.size,
      props.size - lineWidth,
      lineWidth * 2,
      props.value,
      someColors,
      colorLengthRates,
    );
  });

  return (
    <div
      style={{
        position: 'absolute',
        width: props.size,
        height: props.size,
        left: 0,
        right: 0,
        // top: 0,
        // bottom: 0,
        margin: 'auto',
        overflow: 'hidden',
      }}
    >
      {!!props.bgColor && (
        <div
          style={{
            position: 'absolute',
            width: props.size,
            height: props.size,
            left: 0,
            right: 0,
            borderRadius: props.size,
            backgroundColor: props.bgColor,
          }}
        ></div>
      )}
      <canvas ref={canvasRef} width={props.size * 2 + 'px'} height={props.size * 2 + 'px'} style={canvasStyle} />
    </div>
  );
}
