import React, { useState, useLayoutEffect } from 'react';
import { createStyles, makeStyles } from '@material-ui/core/styles';
import {consts} from "../../consts";
import classNames from 'classnames';
import {EffectDetail, SheetLayoutType, useEndEffect} from "../../lib/effectLib";
import {swipeAudioPlayer} from "../../lib/effectAudioPlayers";
import {EffectSheetType1} from "./EffectSheetType1";
import {EffectSheetType2} from "./EffectSheetType2";
import {EffectSheetType3} from "./EffectSheetType3";
import {EffectSheetType4} from "./EffectSheetType4";
import {EffectSheetType5} from "./EffectSheetType5";
import {EffectSheetType6} from "./EffectSheetType6";
import {EffectSheetType7} from "./EffectSheetType7";
import {EffectSheetType8} from "./EffectSheetType8";

const sheetXRate = consts.estimatedContentWidth / 990;
const sheetPadding = 67.5 * sheetXRate;

const duration = 0.24
const useStyles = makeStyles((theme) => createStyles({
  container:{
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    transform: 'translate(0%)',
  },
  sheet:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: consts.estimatedContentWidth - sheetPadding * 2,
    aspectRatio: `${990 / 1805}`,
    backgroundSize: 'contain',
    backgroundPosition: 'center',
    backgroundRepeat: 'no-repeat',
  },
  leftSlided:{
    transform: 'translate(-100%)',
  },
  slideLeftAnimation:{
    animation: `$slideLeft ${duration}s`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
  },
  '@keyframes slideLeft': {
    '0%': { transform: 'translate(0%)' },
    '20%': { transform: 'translate(-6%)' },
    '40%': { transform: 'translate(-12%)' },
    '60%': { transform: 'translate(-18%)' },
    '80%': { transform: 'translate(-24%)' },
    '100%': { transform: 'translate(-100%)' },
  },
  slideRightAnimation:{
    animation: `$slideRight ${duration}s`,
    animationFillMode: 'forwards',
    animationTimingFunction: 'linear',
  },
  '@keyframes slideRight': {
    '0%': { transform: 'translate(-100%)' },
    '20%': { transform: 'translate(-94%)' },
    '40%': { transform: 'translate(-88%)' },
    '60%': { transform: 'translate(-82%)' },
    '80%': { transform: 'translate(-76%)' },
    '100%': { transform: 'translate(0%)' },
  },
}));

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  sheets: EffectDetail[];
}

export const EffectSheets = (props: Props) => {
  const classes = useStyles();
  const [touchPosition, setTouchPosition] = useState<null|number>(null)
  const [currentIndex, setCurrentIndex] = useState(-1)
  const [slideToIndex, setSlideToIndex] = useState(-1)
  const endEffect = useEndEffect();

  const lastIndex = props.sheets.length - 1;

  useLayoutEffect(()=>{
    setCurrentIndex(lastIndex)
    setSlideToIndex(lastIndex)
  }, [props.sheets])

  const slide = (assending:boolean) => {
    if(currentIndex !== slideToIndex) return;
    const newIndex = currentIndex + (assending ? 1 : -1)
    if(newIndex < -1){
      return;
    }
    if(newIndex >= props.sheets.length){
      return;
    }
    setSlideToIndex(newIndex)
    setTimeout(()=>{
      swipeAudioPlayer.start()
    }, 120)
    setTimeout(()=>{
      setCurrentIndex(newIndex)
      if(newIndex < 0){
        endEffect()
      }
    }, duration * 1000)
  }

  function getSheetLayout(e: EffectDetail){
    switch(e.sheetLayoutType){
      case SheetLayoutType.月:
        return <EffectSheetType1 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.目標達成:
        return <EffectSheetType2 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.ランキング:
        return <EffectSheetType3 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.個別ランキング:
        return <EffectSheetType4 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.自己ベスト:
        return <EffectSheetType5 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.バルセロナレコード:
        return <EffectSheetType6 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.在籍日数:
        return <EffectSheetType7 sheet={e} className={classes.sheet}/>
      case SheetLayoutType.お誕生日:
        return <EffectSheetType8 sheet={e} className={classes.sheet}/>
    }
  }

  return (
    <div className={props.className}
       onTouchStart={(e)=>{
         setTouchPosition(e.touches[0].clientX)
       }}
       onTouchMove={(e)=>{
         const touchDown = touchPosition
         if(touchDown === null) return;
         const diff = touchDown - e.touches[0].clientX
         if (diff > 5) {
           console.log('next')
           slide(false)
         }
         if (diff < -5) {
           console.log('prev')
           slide(true)
         }
         setTouchPosition(null)
       }}
       onClick={(e)=>{
         slide(false)
       }}
    >
        {props.sheets.map((sheet, index) =>
          <div key={index} className={classNames(
            classes.container,
            {[classes.leftSlided]: index > currentIndex},
            {[classes.slideLeftAnimation]: index === currentIndex && currentIndex > slideToIndex},
            {[classes.slideRightAnimation]: index === currentIndex + 1 && currentIndex < slideToIndex},
          )}>
            {getSheetLayout(sheet)}
          </div>
        )}
    </div>
  );
};
