import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperClass from 'swiper/types/swiper-class';

import { getInnerContentHeight } from '../consts';
import { RankingDefine, fetchCustomRanking, fetchRegularRanking, selectRanking } from '../app/rankingSlice';
import {
  ActorCustomRankingPeriodTypeValue,
  ActorRegularRankingPeriodType,
  ActorRegularRankingPeriodTypeValue,
  ActorRegularRankingType,
  ActorRegularRankingTypeValue,
  CustomRanking,
  fetchRanking,
} from '../lib/api';
import { RankingDetailDialog, RankingListItem } from '../components';
import { getCustomRankingValueByIndex, getRegularRankingValueByIndex, isShowAllow } from '../lib/dataFunctions';
import { imagePath } from '../lib/pathes';

import { 未集計 } from 'components/未集計';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";
import moment from 'moment';

// iphone Xで縦スクロールが出ない高さ
const contentHeight = getInnerContentHeight();

const heightR1 = 410;
// const heightR1R1 = 0;
const heightR1R2 = 87;

const LIST_NUM = 5;
const FETCH_NUM = 10;
const listHeight = 59;
const heightR1R3 = listHeight * LIST_NUM; // 295

const heightR1R4 = 28;
const heightR2 = contentHeight - heightR1;
const customRankingScale = 0.428 * 1.03;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
      color: theme.palette.text.primary,
    },
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    r1: {
      height: heightR1,
    },
    r1c1: {
      paddingTop: 20,
      paddingLeft: 18,
    },
    r1c3: {
      paddingTop: 20,
      paddingRight: 18,
    },
    r1r2: {
      height: heightR1R2,
      textAlign: 'center',
    },
    r1r3: {
      height: heightR1R3,
    },
    r1r4: {
      color: '#3F3B3A',
      height: heightR1R4,
      fontSize: 10,
      letterSpacing: -0.5,
      textAlign: 'right',
      paddingTop: 4,
      paddingRight: 18,
    },
    r2: {
      height: heightR2,
      backgroundColor: '#f3ead6',
    },
    table: {
      width: 299,
      height: heightR1R3,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 27,
      paddingBottom: 0,
    },
    selfRank: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 19,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 4,
    },
    selfRankValue: {
      color: theme.palette.primary.light,
      fontSize: 26,
      letterSpacing: 0.1,
      paddingLeft: 13,
      paddingRight: 15,
    },
    detailLink: {
      // backgroundColor: 'green',
    },
    detailLinkIcon: {
      width: 49 / 3.3,
      height: 34 / 3.3,
      verticalAlign: 'middle',
      marginBottom: 3,
      marginLeft: 5,
    },
    navArrow: {
      width: 76 / 3,
      height: 68 / 3,
    },
    navArrowLabel: {
      color: theme.palette.text.hint,
      paddingTop: 5,
    },

    customRankingAreaSwiper: {},
    customRankingAreaTitle: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 15,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 16,
      paddingBottom: 11,
      textAlign: 'center',
    },
    customRankingAreaPager: {},
    customRankingBase: {
      backgroundColor: 'white',
      width: 158,
      height: 158,
      marginLeft: 10,
    },
    customRankingBaseLast: {
      marginRight: 10,
    },
    customRankingTitle: {
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 13,
      textAlign: 'center',
      paddingTop: 10,
      paddingBottom: 1,
    },
    customRankingsEntries: {
      paddingLeft: 30,
      transformOrigin: 'left top',
      transform: `scale(${customRankingScale})`,
    },
  }),
);

function newRegularRankingDefine(
  name: string,
  rankingType: ActorRegularRankingType,
  periodType: ActorRegularRankingPeriodType,
): RankingDefine {
  return {
    isRegular: true,
    name: name,

    periodIndex: 0,

    // for RegularRanking
    rankingType: rankingType,
    periodType: periodType,

    // for CustomRanking
    rankingId: '',
    customRankingPeriodType: ActorCustomRankingPeriodTypeValue.毎月,
  };
}

function newCustomRankingDefine(customRanking: CustomRanking): RankingDefine {
  return {
    isRegular: false,
    name: customRanking.name,

    periodIndex: customRanking.latestPeriodIndex,

    // for RegularRanking ダミー値 TODO null許容検討
    rankingType: ActorRegularRankingTypeValue.店内ポイントランキング,
    periodType: ActorRegularRankingPeriodTypeValue.毎月,

    // for CustomRanking
    rankingId: customRanking.rankingId,
    customRankingPeriodType: customRanking.periodType,
  };
}

const regularRankingDefineList = [
  // 画面上部
  newRegularRankingDefine(
    '店舗ランキング',
    ActorRegularRankingTypeValue.店内ポイントランキング,
    ActorRegularRankingPeriodTypeValue.毎月,
  ),
  newRegularRankingDefine(
    '店舗ランキング',
    ActorRegularRankingTypeValue.店内ポイントランキング,
    ActorRegularRankingPeriodTypeValue.毎年,
  ),
  newRegularRankingDefine(
    '店舗半年ランキング',
    ActorRegularRankingTypeValue.店内ポイントランキング,
    ActorRegularRankingPeriodTypeValue.半年,
  ),
  newRegularRankingDefine(
    '年間グループランキング',
    ActorRegularRankingTypeValue.グループポイントランキング,
    ActorRegularRankingPeriodTypeValue.年間,
  ),
  // 画面下部
  newRegularRankingDefine(
    'お客様ランキング',
    ActorRegularRankingTypeValue.お客様ランキング,
    ActorRegularRankingPeriodTypeValue.毎月,
  ),
  newRegularRankingDefine(
    'グループランキング',
    ActorRegularRankingTypeValue.グループポイントランキング,
    ActorRegularRankingPeriodTypeValue.毎月,
  ),
  newRegularRankingDefine(
    '同伴ランキング',
    ActorRegularRankingTypeValue.グループ同伴ランキング,
    ActorRegularRankingPeriodTypeValue.毎月,
  ),
  newRegularRankingDefine(
    '指名ランキング',
    ActorRegularRankingTypeValue.グループ指名ランキング,
    ActorRegularRankingPeriodTypeValue.毎月,
  ),
];

export const RankingPage: React.FC = () => {
  const classes = useStyles();
  const {
    customRankings,
    customRankingData,
    customRankingSummary,
    regularRankingData,
    regularRankingSummary,
  } = useSelector(selectRanking);
  const dispatch = useDispatch();

  const [detailRankingDefine, setDetailRankingDefine] = useState<RankingDefine | undefined>(undefined);

  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
  const [swiper2, setSwiper2] = useState<SwiperClass | undefined>(undefined);

  const [tenpoPeriodIndex, setTenpoPeriodIndex] = useState<number>(0);

  // 左画面に遷移
  const onPrevClicked = useCallback(() => {
    swiper?.slidePrev();
  }, [swiper]);

  // 右画面に遷移
  const onNextClicked = useCallback(() => {
    swiper?.slideNext();
  }, [swiper]);

  const handleOnClose = useCallback(() => {
    setDetailRankingDefine(undefined);
  }, [setDetailRankingDefine]);

  const fetchAnyRanking = useCallback(
    async (define: RankingDefine, periodIndex: number, limit: number, offset: number) => {
      if (define.isRegular) {
        console.log('fetchAnyRanking R:', define.rankingType, define.periodType, periodIndex, limit, offset);
        await fetchRegularRanking(
          regularRankingSummary,
          regularRankingData,
          define.rankingType,
          define.periodType,
          periodIndex,
          limit,
          offset,
        );
      } else {
        // console.log('fetchAnyRanking C:', define.rankingId, periodIndex, limit, offset);
        fetchCustomRanking(customRankingSummary, customRankingData, define.rankingId, periodIndex, limit, offset);
      }
    },
    [customRankingData, customRankingSummary, regularRankingData, regularRankingSummary],
  );

  useEffect(
    () => {
      // お客様ランキングのperiodTypeだけ変更
      regularRankingDefineList[4].periodType = ActorRegularRankingPeriodTypeValue.毎年;
      // RegularRanking初期データ取得
      regularRankingDefineList.forEach((v) =>
        fetchRegularRanking(
          regularRankingSummary,
          regularRankingData,
          v.rankingType,
          v.periodType,
          v.periodIndex,
          FETCH_NUM,
          0,
        ),
      );

      dispatch(fetchRanking({}));
    },
    [
      /* TODO サーバ側で集計バッチが完了し、新しい結果が作成されたことを検出する */
    ],
  );

  useEffect(() => {
    // CustomRanking初期データ取得
    if (!!customRankings && 0 < customRankings.length) {
      customRankings.forEach((v) =>
        fetchCustomRanking(customRankingSummary, customRankingData, v.rankingId, v.latestPeriodIndex, FETCH_NUM, 0),
      );
    }
  }, [customRankings]);

  const regularRankingDataList = regularRankingDefineList.map((v) => {
    const define = v;
    const summary = getRegularRankingValueByIndex(regularRankingSummary, 0, v.periodType, v.rankingType);
    const entries = getRegularRankingValueByIndex(regularRankingData, 0, v.periodType, v.rankingType)
      ?.slice(0, LIST_NUM)
      .map((e, i) => (
        <RankingListItem key={i} entry={e} isFirst={i === 0} showAllow={isShowAllow(v)}></RankingListItem>
      ));

    return { define, summary, entries };
  });

  let 各種ランキングリスト = regularRankingDataList.slice(4);
  if (!!customRankings && 0 < customRankings.length) {
    const customRankingDataList = customRankings
      .map((customRanking) => newCustomRankingDefine(customRanking))
      .map((v) => {
        const define = v;
        const summary = getCustomRankingValueByIndex(customRankingSummary, v.rankingId, v.periodIndex);
        const entries = getCustomRankingValueByIndex(customRankingData, v.rankingId, v.periodIndex)
          ?.slice(0, LIST_NUM)
          .map((e, i) => (
            <RankingListItem
              key={各種ランキングリスト.length + i}
              entry={e}
              isFirst={i === 0}
              showAllow={isShowAllow(v)}
            ></RankingListItem>
          ));

        return { define, summary, entries };
      });
    各種ランキングリスト = 各種ランキングリスト.concat(customRankingDataList);
    swiper2?.updateSlides();
  }

  // // Detail自動オープン
  // useEffect(() => {
  //   setDetailRankingDefine(regularRankingDataList[2].define);
  // }, []);

  const onRegularRankingPeriodTypeChanged = useCallback(
    (v: ActorRegularRankingPeriodType) => {
      if (detailRankingDefine) {
        setDetailRankingDefine({
          ...detailRankingDefine,
          periodType: v,
        });
      }
    },
    [detailRankingDefine, setDetailRankingDefine],
  );

  const periodTitle2Date = (periodTitle: string) => {
    if (!periodTitle) return;
    const startDate = periodTitle.substring(0, periodTitle.indexOf(" ~ "));
    return moment(startDate, 'YYYY.M.D').toDate();
  }

  useEffect(() => {
    let tenpoMonthPeriodTitle;
    if (regularRankingDataList[tenpoPeriodIndex].summary) {
      tenpoMonthPeriodTitle = regularRankingDataList[tenpoPeriodIndex].summary?.periodTitle;
    }

    if (!tenpoMonthPeriodTitle) return;
    const period = periodTitle2Date(tenpoMonthPeriodTitle)
    if (tenpoPeriodIndex === 1) {
      // 今年
      reportGA(GAEventValue.RANKING_TENPO_YEAR, period)
    } else {
      // 今月
      reportGA(GAEventValue.RANKING_TENPO_MONTH, period)
    }
  }, [regularRankingDataList[tenpoPeriodIndex].summary, tenpoPeriodIndex]);

  return (
    <>
      <div className={classes.root}>
        <Grid container>
          <Grid item xs={12} className={classes.r1}>
            <Swiper
              // 明示的な遷移用にインスタンスをstateに保持
              onSwiper={(e) => {
                setSwiper(e);
                setTenpoPeriodIndex(e.activeIndex)
              }}
              onTransitionEnd={(e) => {
                setTenpoPeriodIndex(e.activeIndex)
              }}
            >
              <SwiperSlide>
                <Grid container>
                  <Grid item xs={2} className={classNames(classes.r1c1)}></Grid>
                  <Grid item xs={8} className={classNames(classes.r1r2)}>
                    <div className={classes.title}>{regularRankingDataList[0].define.name}</div>
                    <div className={classes.selfRank}>
                      今月
                      <span className={classes.selfRankValue}>
                        {regularRankingDataList[0].summary?.selfRank || '-'}
                      </span>
                      位
                    </div>
                  </Grid>
                  <Grid item xs={2} className={classNames(classes.r1c3)}>
                    <div onClick={onNextClicked}>
                      <img className={classes.navArrow} src={imagePath('icon_ranking_detail_next.png')} alt="今年" />
                      <div className={classes.navArrowLabel}>今年</div>
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classes.r1r3}>
                    <div className={classes.table}>
                      {regularRankingDataList[0].entries?.length === 0 ? <未集計 /> : regularRankingDataList[0].entries}
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classNames(classes.r1r4)}>
                    <a
                      className={classes.detailLink}
                      onClick={(e) => setDetailRankingDefine(regularRankingDataList[0].define)}
                    >
                      くわしくみる
                      <img className={classes.detailLinkIcon} src={imagePath('icon_ranking_detail_arrow.png')} alt="くわしくみる" />
                    </a>
                  </Grid>
                </Grid>
              </SwiperSlide>
              <SwiperSlide>
                <Grid container>
                  <Grid item xs={2} className={classNames(classes.r1c1)}>
                    <div onClick={onPrevClicked}>
                      <img className={classes.navArrow} src={imagePath('icon_ranking_detail_prev.png')} alt="今月" />
                      <div className={classes.navArrowLabel}>今月</div>
                    </div>
                  </Grid>
                  <Grid item xs={8} className={classNames(classes.r1r2)}>
                    <div className={classes.title}>{regularRankingDataList[1].define.name}</div>
                    <div className={classes.selfRank}>
                      今年
                      <span className={classes.selfRankValue}>
                        {regularRankingDataList[1].summary?.selfRank || '-'}
                      </span>
                      位
                    </div>
                  </Grid>
                  <Grid item xs={2} className={classNames(classes.r1c3)}></Grid>
                  <Grid item xs={12} className={classes.r1r3}>
                    <div className={classes.table}>
                      {regularRankingDataList[1].entries?.length === 0 ? <未集計 /> : regularRankingDataList[1].entries}
                    </div>
                  </Grid>
                  <Grid item xs={12} className={classNames(classes.r1r4)}>
                    <a
                      className={classes.detailLink}
                      onClick={(e) => setDetailRankingDefine(regularRankingDataList[1].define)}
                    >
                      くわしくみる
                      <img className={classes.detailLinkIcon} src={imagePath('icon_ranking_detail_arrow.png')} alt="くわしくみる" />
                    </a>
                  </Grid>
                </Grid>
              </SwiperSlide>
            </Swiper>
          </Grid>

          <Grid item xs={12} className={classNames(classes.r2)}>
            <Grid container>
              <Grid item xs={3}></Grid>
              <Grid item xs={6} className={classes.customRankingAreaTitle}>
                各種ランキング
              </Grid>
              <Grid item xs={3} className={classes.customRankingAreaPager}></Grid>
            </Grid>
            <div className={classNames(classes.customRankingAreaSwiper)}>
              <Swiper
                // slidesPerView={'auto'}
                slidesPerView={2.2}
                spaceBetween={0}
                freeMode={true}
                onSwiper={(e) => {
                  setSwiper2(e);
                }}
              >
                {各種ランキングリスト.map((v, i) => (
                  <SwiperSlide key={i}>
                    <div className={classes.customRankingBase} onClick={(e) => setDetailRankingDefine(v.define)}>
                      <div className={classes.customRankingTitle}>{v.define.name}</div>
                      <div className={classes.customRankingsEntries}>
                        <div className={classes.table}>{v.entries?.length === 0 ? <未集計 /> : v.entries}</div>
                      </div>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          </Grid>
        </Grid>
      </div>
      <RankingDetailDialog
        handleOnClose={handleOnClose}
        define={detailRankingDefine}
        fetchRanking={fetchAnyRanking}
        onRegularRankingPeriodTypeChanged={onRegularRankingPeriodTypeChanged}
      ></RankingDetailDialog>
    </>
  );
};
