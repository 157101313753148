import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

import { GraphPeriodTypeValue } from "../../lib/api";
import { clearSelectedGraphEntryIndex } from "../../app/applicationSlice";
import { SwitchButtons } from "../Common/SwitchButtons";

import { useFetchGraphData } from "./graphDataLib";

import { selectGraph, setPeriodType } from "app/graphSlice";

const width = 294;

const now = new Date();
const currentYear = now.getFullYear().toString();

const list = [
  { title: currentYear + "年", periodType: GraphPeriodTypeValue.YEAR },
  { title: "1年", periodType: GraphPeriodTypeValue.ONEYEAR },
  { title: "全期間", periodType: GraphPeriodTypeValue.ALL },
];
export const GraphButtons: React.FC = () => {
  const dispatch = useDispatch();
  const { periodType } = useSelector(selectGraph);
  const fetchGraphData = useFetchGraphData();

  useEffect(() => {
    dispatch(clearSelectedGraphEntryIndex());
    fetchGraphData();
  }, [periodType]);

  return (
    <SwitchButtons
      onSelected={(i) => {
        dispatch(setPeriodType({ type: list[i].periodType }));
      }}
      width={width}
      titles={list.map((v) => v.title)}
      selectedIndex={list.findIndex((v) => v.periodType === periodType)}
      appearance={"invert"}
    />
  );
};
