import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import moment from 'moment';
import InfiniteLoader from 'react-window-infinite-loader';
import { VariableSizeList } from 'react-window';

import { LoadingOverlay } from '../components/LoadingOverlay';
import { Path } from '../routes';
import { fetchNotificationList } from '../lib/api';
import { selectNotification, setLoadingNotification } from '../app/notificationSlice';
import { getInnerContentHeight } from '../consts';
import { NotificationDetailDialog } from '../components/Notification/NotificationDetailDialog';
import { isNotificationDisabled } from '../lib/dataFunctions';
import { NoData } from '../components/NoData';
import { imagePath } from '../lib/pathes';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

const contentHeight = getInnerContentHeight();
const verticalPadding = 8;
const horizontalPadding = 18;
const listHeight = 82;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      height: '100%',
    },
    listItemContainer: {
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    listItemFirst: {
      paddingTop: verticalPadding,
      borderTop: 'none',
    },
    listItemLast: {
      paddingBottom: verticalPadding,
    },
    listItemSelected: {
      backgroundColor: '#fef4f4',
    },
    listItemDisabled: {
      opacity: 0.6,
    },
    grid: {
      borderTop: '1px #D5D6D6 solid',
    },

    gridCell1: {
      paddingTop: 19,
      paddingLeft: 1,
      paddingRight: 10,
    },
    gridCell2: {
      paddingTop: 47,
      paddingLeft: 2,
    },
    gridCell3: {
      paddingTop: 33,
      textAlign: 'center',
    },

    overflowableText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    title: {
      fontSize: 15,
      fontWeight: theme.typography.fontWeightBold,
    },
    body: {
      fontSize: 12,
      paddingTop: 7,
      paddingLeft: 2,
      color: theme.palette.text.hint,
    },
    createdAt: {
      fontSize: 12,
      color: theme.palette.text.secondary,
    },
    seenMark: {
      width: 33 / 3,
      height: 32 / 3,
    },
  }),
);

const FETCH_NUM = 30;
export const NotificationPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const elm = useRef<HTMLDivElement>(null);
  const [listContainerHeight, setListContainerHeight] = useState(contentHeight);
  const { notificationId } = useParams();
  const { count, list, loading } = useSelector(selectNotification);
  const [prevCount, setPrevCount] = useState(0);

  const loadMoreItems = useCallback(async (startIndex: number, stopIndex: number) => {
    console.log('loadMoreItems', startIndex, stopIndex);
    dispatch(setLoadingNotification(true));
    await dispatch(
      fetchNotificationList({
        limit: stopIndex - startIndex + 1,
        offset: startIndex,
        cause: 'list',
      }),
    );
    dispatch(setLoadingNotification(false));
  }, []);

  useEffect(() => {
    if (count !== prevCount) {
      setPrevCount(count);
      //      dispatch(clearNotificationList())
      loadMoreItems(0, FETCH_NUM);
    }
  }, [prevCount, count]);

  useLayoutEffect(() => {
    if (!!elm && !!elm.current) {
      const rect = elm.current.getBoundingClientRect();
      setListContainerHeight(rect.height);
    }
  }, [elm, elm.current]);

  useEffect(() => {
    reportGA(GAEventValue.NOTIFICATION);
  }, []);

  const rows = list.map((v, i) => {
    return {
      id: v.id,
      key: 'notification' + i,
      createdAt: moment(v.createdAt).format('M月D日'),
      title: v.title,
      body: v.summary,
      // body: v.message,
      showDot: !v.completed,
      disabled: isNotificationDisabled(v),
    };
  });

  let contents = (
    <InfiniteLoader
      isItemLoaded={(index) => !!list[index]}
      itemCount={count}
      loadMoreItems={loadMoreItems}
      minimumBatchSize={FETCH_NUM}
    >
      {({ onItemsRendered, ref }) => (
        <VariableSizeList
          height={listContainerHeight}
          width={'100%'}
          itemSize={(index) => (index === 0 || index === count - 1 ? listHeight + verticalPadding : listHeight)}
          itemCount={list.length}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {({ index, style }) => (
            <div
              style={style}
              className={classNames(
                classes.listItemContainer,
                { [classes.listItemFirst]: index === 0 },
                { [classes.listItemLast]: index === count - 1 },
                { [classes.listItemSelected]: rows[index].id === notificationId },
                { [classes.listItemDisabled]: rows[index].disabled },
              )}
              onClick={(e) => history.push(Path.notifications + '/' + rows[index].id)}
            >
              <Grid container className={classNames({ [classes.grid]: index !== 0 })}>
                <Grid item xs={9} className={classes.gridCell1}>
                  <div className={classNames(classes.overflowableText, classes.title)}>
                    {/*{index + 1}{rows[index].title}*/}
                    {rows[index].title}
                  </div>
                  <div className={classNames(classes.overflowableText, classes.body)}>
                    {/*{rows[index].id}*/}
                    {rows[index].body}
                  </div>
                </Grid>

                <Grid item xs={2} className={classes.gridCell2}>
                  <div className={classes.createdAt}>{rows[index].createdAt}</div>
                </Grid>

                <Grid item xs={1} className={classNames(classes.gridCell3)}>
                  {!rows[index].disabled && rows[index].showDot && (
                    <img className={classes.seenMark} src={imagePath('notification_green.png')} alt="未読" />
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </VariableSizeList>
      )}
    </InfiniteLoader>
  );

  if (count <= 0 || list.length === 0) {
    if (loading) {
      contents = <LoadingOverlay />;
    } else {
      contents = <NoData />;
    }
  }

  return (
    <>
      <div className={classes.root} ref={elm}>
        {contents}
      </div>
      <NotificationDetailDialog />
    </>
  );
};
