import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';
import { unwrapResult } from '@reduxjs/toolkit';

import { getInnerContentHeight } from '../consts';
import {
  clearSelectedDate,
  reloadShiftCalendar,
  selectSchedule,
  setDateSelectMode,
  unSelectDate,
} from '../app/scheduleSlice';
import {
  CastOfferType,
  CastOfferTypeValue,
  fetchShiftOffer,
  シフト依頼状態Value,
  シフト確定状態Value,
} from '../lib/api';
import {
  selectApplication,
  setLoading,
  setShiftOfferConfirmDialogShow,
  showSuccessAlert,
} from '../app/applicationSlice';
import { AppDispatch } from '../app/store';

import { StandardButton } from './StandardButton';

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20; // footer分
const r1Height = 100;
const r3Height = 100;
const r4Height = 20;
const r2Height = containerHeight - r1Height - r3Height - r4Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 13,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r2: {
      height: r2Height,
    },
    r2Table: {},
    r3: {
      height: r3Height,
    },
    r3c1: {
      paddingTop: 25,
      paddingLeft: 10,
      textAlign: 'left',
    },
    r3c2: {
      paddingTop: 25,
      paddingRight: 10,
      textAlign: 'right',
    },
    r4: {
      height: r4Height,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 42,
      paddingBottom: 0,
    },
    noBorder: {
      border: 'none',
    },
    tdValue: {
      minWidth: 116,
      paddingRight: 0,
    },
    button: {
      minWidth: 100,
    },
  }),
);

// type TableRow = {
//   key: string|null,
//   title: string|{}|null,
//   value: string|{}|null,
// }
export const ShiftOfferConfirmDialog: React.FC = () => {
  const classes = useStyles();
  const { shiftOfferConfirmDialogShow, loading } = useSelector(selectApplication);
  const [open, setOpen] = React.useState(false);

  const { calendarDateMap, selectedDates } = useSelector(selectSchedule);

  const dispatch: AppDispatch = useDispatch();

  const onClose = () => {
    dispatch(setShiftOfferConfirmDialogShow(false));
  };

  useEffect(() => {
    if (!shiftOfferConfirmDialogShow) {
      setOpen(false);
      return;
    }

    if (!selectedDates.length) {
      alert('申請する日付を選択してください。');
      return;
    }

    // カレンダーから申請可能な日であるか判定
    const currentDate = moment().format('YYYYMMDD');
    const endDate = moment().add('months', 2).endOf('month').format('YYYYMMDD');
    function isValidDate(date: string) {
      if (currentDate >= date) return false;
      if (endDate < date) return false;
      const dailyData = calendarDateMap[date];
      if (!dailyData) return false;
      const lastOffer = dailyData.actorScheduleList.slice(-1)[0];
      if (!lastOffer) return true;
      if (lastOffer.status !== シフト確定状態Value.フラット) return false;
      if (lastOffer.offer === シフト依頼状態Value.フラット) return true;
      if (lastOffer.offer === シフト依頼状態Value.キャスト出勤申請中) return true;
      return false;
    }

    selectedDates.forEach((date) => {
      if (!isValidDate(date)) {
        dispatch(unSelectDate(date));
      }
    });

    setOpen(true);
  }, [shiftOfferConfirmDialogShow]);

  // const rows : TableRow[] = [];
  if (!shiftOfferConfirmDialogShow) {
    return <></>;
  }

  const offerList = selectedDates.map((date) => {
    const dailyData = calendarDateMap[date];
    const lastOffer = !dailyData ? null : dailyData.actorScheduleList.slice(-1)[0];
    let castOfferType: CastOfferType = CastOfferTypeValue.出勤;
    if (!!lastOffer && lastOffer.offer === シフト依頼状態Value.キャスト出勤申請中) {
      castOfferType = CastOfferTypeValue.取下;
    }
    return {
      date: date,
      castOfferType: castOfferType,
      toDate: '',
    };
  });

  const rows = offerList.map((offer) => {
    return { key: 'offer' + offer.date, title: moment(offer.date).format('YYYY年M月D日'), value: offer.castOfferType };
  });

  // TODO 申請系ロジックまとめる
  const sendOffer = async () => {
    if (loading) {
      return;
    }
    dispatch(setLoading(true));
    // シフト送信
    const payload = unwrapResult(await dispatch(fetchShiftOffer({ offerList: offerList })));
    dispatch(showSuccessAlert('申請を送信しました'));
    dispatch(clearSelectedDate());
    dispatch(setDateSelectMode(false));
    onClose();
    reloadShiftCalendar(dispatch, payload.response.updatedDates);
    dispatch(setLoading(false));
  };

  return (
    <Dialog open={open} onClose={() => onClose()} maxWidth={false}>
      {!!open && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
              <Grid item xs={8} className={classNames(classes.r1C2)}>
                <div>
                  <div className={classes.title}>送信内容確認</div>
                </div>
              </Grid>
              <Grid item xs={2} className={classNames(classes.r1C3)}>
                <CloseIcon onClick={() => onClose()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
              <Table size='small'>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={!row.key ? row.title : row.key}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classes.noBorder}
                        style={{ paddingLeft: 10, paddingRight: 4 }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classNames(classes.noBorder, classes.tdValue)}
                        style={{ paddingLeft: 4 }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={1} className={classes.r3}></Grid>
          <Grid item xs={5} className={classNames(classes.r3, classes.r3c1)}>
            <StandardButton size='large' onClick={sendOffer} className={classes.button}>
              OK
            </StandardButton>
          </Grid>
          <Grid item xs={5} className={classNames(classes.r3, classes.r3c2)}>
            <StandardButton size='large' onClick={onClose} className={classes.button}>
              戻る
            </StandardButton>
          </Grid>
          <Grid item xs={1} className={classes.r3}></Grid>
          <Grid item xs={12} className={classes.r4}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
