import React, { Fragment } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { VariableSizeList } from 'react-window';
import CloseIcon from '@material-ui/icons/Close';

import { getInnerContentHeight } from '../consts';
import { selectApplication, setCalendarIconHelpDialogShow } from '../app/applicationSlice';
import { calendarIconHelpList } from '../lib/dataFunctions';
import { imagePath } from '../lib/pathes';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20 - 30; // -footer分, スクロールが出ない高さに調整
const verticalPadding = 18;
const horizontalPadding = 18;
const listHeight = 96;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      width: 345,
      height: containerHeight,
    },
    listItemContainer: {
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    listItemFirst: {
      paddingTop: verticalPadding,
    },
    listItemLast: {
      paddingBottom: verticalPadding,
    },
    grid: {
      borderTop: '1px #D5D6D6 solid',
    },
    imgCell: {
      textAlign: 'center',
      height: listHeight,
    },
    listImage: {
      width: 140 / 3,
      height: 103 / 3,
    },
    textCell: {
      height: listHeight,
      paddingLeft: 6,
      paddingTop: 5,
    },
    title: {
      fontSize: 13,
    },
    body: {
      fontSize: 10,
      paddingTop: 7,
      color: theme.palette.text.hint,
    },
    closeIcon: {
      position: 'absolute',
      width: 30,
      height: 30,
      top: 10,
      right: 0,
    },
  }),
);

export const CalendarIconHelpDialog: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { calendarIconHelpDialogShow } = useSelector(selectApplication);

  const onClose = () => {
    dispatch(setCalendarIconHelpDialogShow(false));
  };

  return (
    <Dialog open={calendarIconHelpDialogShow} onClose={() => onClose()} maxWidth={false} 
      onEntered={() => {reportGA(GAEventValue.CALENDAR_ICON)}}>
      {!!calendarIconHelpDialogShow && (
        <div className={classes.root}>
          <VariableSizeList
            height={containerHeight}
            width={'100%'}
            itemSize={(index) =>
              index === 0 || index === calendarIconHelpList.length - 1 ? listHeight + verticalPadding : listHeight
            }
            itemCount={calendarIconHelpList.length}
          >
            {({ index, style }) => (
              <div
                style={style}
                className={classNames(
                  classes.listItemContainer,
                  { [classes.listItemFirst]: index === 0 },
                  { [classes.listItemLast]: index === calendarIconHelpList.length - 1 },
                )}
              >
                <Grid container className={classNames({ [classes.grid]: index !== 0 })}>
                  <Grid item xs={2} className={classNames(classes.imgCell, classes.verticalCenter)}>
                    <div className={classes.listImage}>
                      {calendarIconHelpList[index].image && (
                        <img className={classes.listImage} src={imagePath(calendarIconHelpList[index].image)} alt="対象アイコン" />
                      )}
                    </div>
                  </Grid>
                  <Grid item xs={10} className={classNames(classes.textCell, classes.verticalCenter)}>
                    <div className={classNames(classes.title)}>
                      {calendarIconHelpList[index].title.split('\n').map((item, key) => {
                        return (
                          <Fragment key={key}>
                            {item}
                            <br />
                          </Fragment>
                        );
                      })}
                    </div>
                    {!!calendarIconHelpList[index].body && (
                      <div className={classNames(classes.body)}>{calendarIconHelpList[index].body}</div>
                    )}
                  </Grid>
                </Grid>
              </div>
            )}
          </VariableSizeList>
          <div className={classes.closeIcon}>
            <CloseIcon onClick={onClose} />
          </div>
        </div>
      )}
    </Dialog>
  );
};
