import React, { useEffect } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';

import { selectNotification } from '../app/notificationSlice';
import { fetchNotificationList } from '../lib/api';

const height = 19;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'contemt-box',
      position: 'absolute',
      marginTop: -2,
      left: 24,
      height: height,
      borderRadius: height / 2,
      backgroundColor: '#ffe26f',
      color: theme.palette.primary.main,
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: 4,
      paddingRight: 4,
      textAlign: 'center',
    },
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
  }),
);

type Props = {};

export function CountMark(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { count, restCount } = useSelector(selectNotification);

  useEffect(() => {
    if (count === -1) {
      dispatch(fetchNotificationList({ limit: 0, offset: 0, cause: 'mark' }));
    }
  }, [count]);

  if (restCount === 0) {
    return <></>;
  }
  return (
    <div className={classes.root}>
      <div className={classes.verticalCenter}>{restCount}</div>
    </div>
  );
}
