import React, { CSSProperties } from 'react';
import { Link, useHistory, useLocation } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { BottomNavigation, BottomNavigationAction } from '@material-ui/core';
import classNames from 'classnames';

import { imagePath } from '../lib/pathes';

import { Path } from 'routes';
import {isBridgeDefined} from "../lib/nativeBridge";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    stickToBottom: {
      zIndex: 1,
    },
    linkIcon: {
      width: 151 / 3.1,
      height: 126 / 3.1,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundSize: 'contain',
    },
    linkIcon1On: {
      backgroundImage: `url('${imagePath('13_footer/footer_01_on.png')}')`,
    },
    linkIcon1Off: {
      backgroundImage: `url('${imagePath('13_footer/footer_01_off.png')}')`,
    },
    linkIcon2On: {
      backgroundImage: `url('${imagePath('13_footer/footer_02_on.png')}')`,
    },
    linkIcon2Off: {
      backgroundImage: `url('${imagePath('13_footer/footer_02_off.png')}')`,
    },
    linkIcon3On: {
      backgroundImage: `url('${imagePath('13_footer/footer_03_on.png')}')`,
    },
    linkIcon3Off: {
      backgroundImage: `url('${imagePath('13_footer/footer_03_off.png')}')`,
    },
    linkIcon4On: {
      backgroundImage: `url('${imagePath('13_footer/footer_04_on.png')}')`,
    },
    linkIcon4Off: {
      backgroundImage: `url('${imagePath('13_footer/footer_04_off.png')}')`,
    },
    linkIcon5On: {
      backgroundImage: `url('${imagePath('13_footer/footer_05_on.png')}')`,
    },
    linkIcon5Off: {
      backgroundImage: `url('${imagePath('13_footer/footer_05_off.png')}')`,
    },
    inPreparation: {
      textAlign: 'center',
      fontSize: 7,
      marginTop: '-1em',
      color: '#444',
    },
  }),
);
type Props = {
  className?: string;
  style?: CSSProperties;
};
export const Footer = (props: Props) => {
  const classes = useStyles();
  // const [value, setValue] = React.useState(window.location.pathname);
  const location = useLocation();
  const history = useHistory();

  return (
    <>
      <BottomNavigation
        value={location.pathname}
        onChange={(event, newValue) => {
          history.push(newValue);
        }}
        showLabels
        className={classNames(
          classes.stickToBottom,
          props.className,
        )}
        style={props.style}
      >
        <BottomNavigationAction
          disableRipple={true}
          component={Link}
          to={Path.home}
          value={Path.home}
          icon={
            <div
              className={classNames(
                classes.linkIcon,
                location.pathname === Path.home || location.pathname.startsWith(Path.pyramid)
                  ? classes.linkIcon1On
                  : classes.linkIcon1Off,
              )}
            ></div>
          }
        />
        <BottomNavigationAction
          disableRipple={true}
          component={Link}
          to={Path.ranking}
          value={Path.ranking}
          icon={
            <div
              className={classNames(
                classes.linkIcon,
                location.pathname.startsWith(Path.ranking) ? classes.linkIcon2On : classes.linkIcon2Off,
              )}
            ></div>
          }
        />
        <BottomNavigationAction
          disableRipple={true}
          component={Link}
          to={Path.graph}
          value={Path.graph}
          icon={
            <div
              className={classNames(
                classes.linkIcon,
                location.pathname.startsWith(Path.graph) ? classes.linkIcon3On : classes.linkIcon3Off,
              )}
            ></div>
          }
        />
        <BottomNavigationAction
          disableRipple={true}
          component={Link}
          to={Path.calendar}
          value={Path.calendar}
          icon={
            <div
              className={classNames(
                classes.linkIcon,
                location.pathname.startsWith(Path.calendar) ? classes.linkIcon4On : classes.linkIcon4Off,
              )}
            ></div>
          }
        />
        <BottomNavigationAction
          disableRipple={true}
          onClick={(e)=>{
            if(!isBridgeDefined('apiPlayGachaMovies')){
              alert('アプリを更新してください')
            }else{
              history.push(Path.gacha);
            }
          }}
          icon={
            <div
              className={classNames(
                classes.linkIcon,
                location.pathname.startsWith(Path.gacha) ? classes.linkIcon5On : classes.linkIcon5Off,
              )}
            ></div>
          }
        />
      </BottomNavigation>
    </>
  );
};
