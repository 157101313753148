import React, { useState } from 'react';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';
import { withRouter } from 'react-router';

import { ActorLoginRequest, sendActorLogin } from '../lib/api';

import { Path } from 'routes';

export const LoginPage = withRouter(() => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');

  return (
    <>
      <span>login</span>
      <div>
        <TextField
          id='standard-basic'
          label='username'
          variant='outlined'
          value={username}
          onChange={(e) => setUsername(e.target.value)}
        />
      </div>
      <div>
        <TextField
          id='standard-basic'
          label='password'
          variant='outlined'
          value={password}
          onChange={(e) => setPassword(e.target.value)}
        />
      </div>
      <div>
        <Button
          variant='outlined'
          color='primary'
          onClick={(e) => {
            // dispatch(setIsAuthenticated(true))

            const req: ActorLoginRequest = {
              username: username,
              password: password,
              clientId: '',
            };

            sendActorLogin(
              req,
              (res) => {
                if (res.result.toUpperCase() === 'OK') {
                  window.location.href = Path.home;
                  // props.history.push(Path.home);
                } else {
                  alert('ログイン失敗');
                }
              },
              (error) => {
                alert(error);
              },
            );
          }}
        >
          ログイン
        </Button>
      </div>
    </>
  );
});
