import React from "react";
import ReactApexChart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { useDispatch, useSelector } from "react-redux";
import moment from "moment";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { imagePath } from "lib/pathes";
import { setSelectedGraphEntryIndex } from "../../app/applicationSlice";
import { GraphValue, selectGraph } from "../../app/graphSlice";

import {
  useDateFormat,
  useRange,
  useSelectGraphData,
  useYAxisFormatter,
  roundToNearestGoodNumber,
  rankingRoundToNearestGoodNumber,
  clearOddIndexedElements,
} from "./graphDataLib";
import { GraphType } from "./graphDefine";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    graphBodyContainer: {
      flex: 1,
      display: "flex",
      width: "375px",
      justifyContent: "flex-end",
      alignItems: "flex-end",
      // backgroundColor: "#00ff00",
    },
    graphBody: {
      alignSelf: "flex-end",
      // backgroundColor: "#ffff00",
    },
  })
);

// ランキング系の0値はグラフにプロットしない
function filterRankingValues(
  type: GraphType,
  values: GraphValue[]
): GraphValue[] {
  if (type === GraphType.フリー件数) {
    return values.map((v) => v ?? 0);
  } else {
    return values;
  }
}

const periodTypeValueDict = {
  YEAR: "今年",
  MONTH: "月",
  ONEYEAR: "1年",
  ALL: "全期間",
};

function useApexOptionsAndValues(
  type: GraphType,
  count?: number
): [ApexOptions, GraphValue[], number] {
  const dispatch = useDispatch();
  var { labels, values, rankingCounts } = useSelectGraphData(type.toString());
  const selectPeriodType =
    periodTypeValueDict[useSelector(selectGraph).periodType];
  const 場内指名件数Data = useSelectGraphData("場内指名件数");
  const 勤務時間Data = useSelectGraphData("4");
  const firstNonZeroIndex = 勤務時間Data.values.findIndex(
    (element) => element !== 0
  );
  var removeLastIndex =
    firstNonZeroIndex === -1 ? 勤務時間Data.values.length : firstNonZeroIndex;
  labels = labels.slice(removeLastIndex);
  values = values.slice(removeLastIndex);
  if (type === GraphType.フリー件数) {
    if (場内指名件数Data !== null && values !== null) {
      const 場内指名件数DataValues = 場内指名件数Data.values.map((v) => v ?? 0);
      values = values.map((v) => v ?? 0);
      for (let i = 0; i < values.length; i++) {
        const value = values[i];
        const 場内指名件数DataValue = 場内指名件数DataValues[i];
        if (value === null || 場内指名件数DataValue === null) {
          values[i] = 0;
        } else {
          var calVal =
            Math.round(
              (1000 *
                (場内指名件数DataValue === null ? 0 : 場内指名件数DataValue)) /
                (value === null ? 0 : value)
            ) / 10;
          calVal = calVal > 100 ? 100 : calVal;
          if (value === 0 || isNaN(calVal)) {
            values[i] = 0;
          } else {
            values[i] = calVal;
          }
        }
      }
    }
  }

  var [yMin, yMax, goal] = useRange(type, values, rankingCounts);
  const yAxisFormatter = useYAxisFormatter(type);
  const dateFormat = useDateFormat();
  const annotationsYaxis = [];
  var yaxisTickAmount = 4;

  var goalUpText = "";
  if (type === GraphType.フリー件数) {
    goalUpText = "Groupe 平均";
  } else if (type === GraphType.出勤時間) {
    goalUpText = "";
    goal = 72 * 60;
    if (yMax < goal) {
      yMax = goal;
    }
  } else {
    goalUpText = "";
    if (yMax < goal) {
      yMax = goal;
    }
  }

  if (
    type !== GraphType.店舗ポイントランキング &&
    type !== GraphType.グループポイントランキング
  ) {
    const memoryValue = roundToNearestGoodNumber(type, yMax);
    if (yMax <= memoryValue * 3) {
      yMax = memoryValue * 3;
      yaxisTickAmount = 3;
    } else {
      yMax = memoryValue * 4;
      yaxisTickAmount = 4;
    }
    values = values.map((v) => (v === null ? 0 : v));
  } else {
    yMax = 0;
    const memoryValue = rankingRoundToNearestGoodNumber(yMin * -1);
    if (yMin >= memoryValue * 3) {
      yMin = memoryValue * 3;
      yaxisTickAmount = 3;
    } else {
      yMin = memoryValue * 4;
      yaxisTickAmount = 4;
    }
    values = values.map((v) => (v === -0 ? yMin : v));
    values = values.map((v) => (v === -1 ? -0 : v));
  }

  if (goal !== 0) {
    annotationsYaxis.push({
      y:
        type === GraphType.店舗ポイントランキング ||
        type === GraphType.グループポイントランキング
          ? goal - Math.abs(yMin + 2) / 6
          : goal - yMax / 6,
      borderColor: "#dc3a7000",
      opacity: 0.0,
      label: {
        borderColor: "#dc3a7000",
        style: {
          color: "#fff005",
          background: "#dc3a7000",
        },
        text: goalUpText,
      },
    });
    annotationsYaxis.push({
      y: goal,
      strokeDashArray: 0,
      borderColor: "#fff005",
    });
  }

  const imageYposition =
    type === GraphType.店舗ポイントランキング ||
    type === GraphType.グループポイントランキング
      ? Math.abs(goal) * (130 / Math.abs(yMin))
      : 130 - goal * (130 / yMax);
  const hourYposition = 10 + 130 - goal * (130 / yMax);
  const yMaxLength = yAxisFormatter(yMax).length;
  const graphBodyWidth =
    yMaxLength === 1
      ? 375 - 6
      : yMaxLength === 2
      ? 375
      : yMaxLength === 3
      ? 375 + 8
      : yMaxLength === 4
      ? 375 + 10
      : 375 + 10 + 6 * (yMaxLength - 4);

  const options: ApexOptions = {
    chart: {
      type: "area",
      height: 350,
      zoom: {
        enabled: false,
      },
      toolbar: {
        show: false,
      },
      events: {
        markerClick: function (
          event,
          chartContext,
          { seriesIndex, dataPointIndex, config }
        ) {
          console.log("markerClick", seriesIndex, dataPointIndex, config);
          dispatch(
            setSelectedGraphEntryIndex({ index: dataPointIndex, type: type })
          );
        },
      },
    },
    legend: {
      show: false,
    },
    annotations: {
      yaxis: annotationsYaxis,
      images:
        goal !== 0 && type !== GraphType.出勤時間
          ? [
              {
                path: imagePath("graph_target.png"),
                x: graphBodyWidth - 30,
                y: imageYposition,
                width: 20,
                height: 20,
              },
            ]
          : [],
      texts:
        type === GraphType.出勤時間
          ? [
              {
                x: graphBodyWidth - 30,
                y: hourYposition,
                text: "72h",
                foreColor: "#fff005",
                fontSize: "11px",
                backgroundColor: "transparent",
              },
            ]
          : [],
    },
    plotOptions: {
      area: {
        fillTo: "end",
      },
    },
    colors: ["#FFFFFF"],
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: "straight",
      width: 1,
    },
    fill: {
      type: "gradient",
      gradient: {
        shadeIntensity: 0,
        opacityFrom: 0.8,
        opacityTo: 0.3,
        stops: [0, 90, 100],
      },
    },
    markers: {
      size: values.filter((e) => e != null).length === 1 ? 1 : 0,
    },
    tooltip: {
      enabled: true,
      enabledOnSeries: undefined,
      shared: true,
      marker: {
        show: true,
      },
      // 空のtooltip、マーカーのみ表示 ===
      custom: function ({ series, seriesIndex, dataPointIndex, w }) {
        return "";
      },
      fixed: {
        // customで空にしても極小のポイントが表示されるため、画面外に配置
        enabled: true,
        position: "topRight",
        offsetX: 1000,
        offsetY: 0,
      },
      // =================================x
    },
    xaxis: {
      type: "category",
      tickAmount: selectPeriodType === "1年" ? 12 : 5,
      labels: {
        style: {
          colors: "#FFFFFF",
          fontSize: "12px",
          fontFamily: "Roboto, sans-serif",
        },
        formatter: (value: string): string | string[] => {
          return value === "" ? "　" : moment(value).format(dateFormat);
        },
        rotate: 0,
        hideOverlappingLabels: true,
      },
      tooltip: {
        enabled: false,
      },
      axisTicks: {
        show: true,
      },
    },
    yaxis: {
      tickAmount: yaxisTickAmount,
      forceNiceScale: false,
      max: yMax,
      min: yMin,
      labels: {
        formatter: yAxisFormatter,
        style: {
          colors: ["#FFFFFF"],
          fontSize: "12px",
          fontFamily: "Roboto, sans-serif",
        },
        offsetX: 16,
        offsetY: 2.5,
      },
    },
    grid: {
      borderColor: "#FFFFFF48",
      show: true,
      xaxis: {
        lines: {
          show: true,
        },
      },
      yaxis: {
        lines: {
          show: true,
        },
      },
      padding: {
        // 軸ラベルを抜いたグラフ部分に効く
        top: -20,
        right: 45,
        bottom: 0,
        left: 28,
      },
    },
    labels:
      selectPeriodType === "1年" ? clearOddIndexedElements(labels) : labels,
  };
  return [options, filterRankingValues(type, values), graphBodyWidth];
}

type Props = {
  type: GraphType;
};

export function GraphBody(props: Props) {
  // console.log('GraphBody render');
  // const [c, setC] = React.useState(18);
  // const [options, values] = useApexOptionsAndValues(props.type, c);
  const classes = useStyles();
  const [options, values, graphBodyWidth] = useApexOptionsAndValues(props.type);
  const series = [
    {
      name: props.type.toString(),
      data: values,
    },
  ];
  return (
    <>
      {/*<div onClick={(e)=> setC(c+1)}>aaa</div>*/}
      <div className={classes.graphBodyContainer}>
        <div className={classes.graphBody}>
          <ReactApexChart
            options={options}
            series={series}
            type="area"
            // 幅の基準は2桁のグラフs
            width={graphBodyWidth}
            height={180}
          />
        </div>
      </div>
    </>
  );
}
