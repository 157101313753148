// Code generated by api_tool.
// DO NOT EDIT!
import {AxiosError} from 'axios';

import {toQueryString} from 'lib/toQueryString';
import {formClient} from 'lib/apiClients';
import {createAsyncThunk, PayloadAction} from '@reduxjs/toolkit';
import {store} from "../app/store";
import {showErrorAlert} from "../app/applicationSlice";
import {callNativeApiCallDone} from "./nativeBridge";

// ============================================================================
// enums
// ============================================================================
export const SampleEnumValue = {
  あああ: 'あああ', // マルチバイト
  AAA_BBB: 'AAA_BBB', // 定数
  Apple: 'Apple', // キャメル
} as const;
export const SampleEnumLabel = {
  あああ: 'あああ',
  AAA_BBB: 'AAA_BBB',
  Apple: 'Apple',
} as const;
export type SampleEnum = typeof SampleEnumValue[keyof typeof SampleEnumValue];

export const OSTypeValue = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
} as const;
export const OSTypeLabel = {
  ANDROID: 'ANDROID',
  IOS: 'IOS',
} as const;
export type OSType = typeof OSTypeValue[keyof typeof OSTypeValue];

export const ClientCacheSegmentTypeValue = {
  ALL: 'ALL', // キャッシュデータ全て
  VOLATILE: 'VOLATILE', // 揮発性の(リロードで消える)キャッシュデータ分類
  DAILY: 'DAILY', // 日次締めバッチで上書きされるキャッシュデータ分類
  SCHEDULE: 'SCHEDULE', // スケジュール関連のキャッシュデータ分類
} as const;
export const ClientCacheSegmentTypeLabel = {
  ALL: 'ALL',
  VOLATILE: 'VOLATILE',
  DAILY: 'DAILY',
  SCHEDULE: 'SCHEDULE',
} as const;
export type ClientCacheSegmentType = typeof ClientCacheSegmentTypeValue[keyof typeof ClientCacheSegmentTypeValue];

export const ActorBadgeCategoryValue = {
  店舗ポイントランキング: '店舗ポイントランキング',
  グループポイントランキング: 'グループポイントランキング',
  グループ同伴ランキング: 'グループ同伴ランキング',
  ポイント: 'ポイント',
  サンクスコイン: 'サンクスコイン',
} as const;
export const ActorBadgeCategoryLabel = {
  店舗ポイントランキング: '店舗ポイントランキング',
  グループポイントランキング: 'グループポイントランキング',
  グループ同伴ランキング: 'グループ同伴ランキング',
  ポイント: 'ポイント',
  サンクスコイン: 'サンクスコイン',
} as const;
export type ActorBadgeCategory = typeof ActorBadgeCategoryValue[keyof typeof ActorBadgeCategoryValue];

export const ActorBadgeTypeValue = {
  なし: 'なし',
  虹: '虹',
  金: '金',
  銀: '銀',
} as const;
export const ActorBadgeTypeLabel = {
  なし: 'なし',
  虹: '虹',
  金: '金',
  銀: '銀',
} as const;
export type ActorBadgeType = typeof ActorBadgeTypeValue[keyof typeof ActorBadgeTypeValue];

export const TenpoPointUnitTypeValue = {
  ポイント: 'ポイント',
  小計: '小計',
} as const;
export const TenpoPointUnitTypeLabel = {
  ポイント: 'ポイント',
  小計: '小計',
} as const;
export type TenpoPointUnitType = typeof TenpoPointUnitTypeValue[keyof typeof TenpoPointUnitTypeValue];

export const ActorRegularRankingTypeValue = {
  店内ポイントランキング: '店内ポイントランキング',
  グループポイントランキング: 'グループポイントランキング',
  店内同伴ランキング: '店内同伴ランキング',
  グループ同伴ランキング: 'グループ同伴ランキング',
  店舗指名ランキング: '店舗指名ランキング',
  グループ指名ランキング: 'グループ指名ランキング',
  お客様ランキング: 'お客様ランキング',
} as const;
export const ActorRegularRankingTypeLabel = {
  店内ポイントランキング: '店内ポイントランキング',
  グループポイントランキング: 'グループポイントランキング',
  店内同伴ランキング: '店内同伴ランキング',
  グループ同伴ランキング: 'グループ同伴ランキング',
  店舗指名ランキング: '店舗指名ランキング',
  グループ指名ランキング: 'グループ指名ランキング',
  お客様ランキング: 'お客様ランキング',
} as const;
export type ActorRegularRankingType = typeof ActorRegularRankingTypeValue[keyof typeof ActorRegularRankingTypeValue];

export const ActorRegularRankingPeriodTypeValue = {
  毎月: '毎月',
  毎年: '毎年',
  半年: '半年',
  年間: '年間',
} as const;
export const ActorRegularRankingPeriodTypeLabel = {
  毎月: '毎月',
  毎年: '毎年',
  半年: '半年',
  年間: '年間',
} as const;
export type ActorRegularRankingPeriodType = typeof ActorRegularRankingPeriodTypeValue[keyof typeof ActorRegularRankingPeriodTypeValue];

export const ActorCustomRankingPeriodTypeValue = {
  任意期間: '任意期間',
  全期間: '全期間',
  毎月: '毎月',
  毎年: '毎年',
} as const;
export const ActorCustomRankingPeriodTypeLabel = {
  任意期間: '任意期間',
  全期間: '全期間',
  毎月: '毎月',
  毎年: '毎年',
} as const;
export type ActorCustomRankingPeriodType = typeof ActorCustomRankingPeriodTypeValue[keyof typeof ActorCustomRankingPeriodTypeValue];

export const GraphPeriodTypeValue = {
  MONTH: 'MONTH',
  YEAR: 'YEAR',
  ONEYEAR: 'ONEYEAR',
  ALL: 'ALL',
} as const;
// export const GraphPeriodTypeLabel = {
//   MONTH: '1ヵ月',
//   YEAR: '1年',
//   ALL: '全期間',
// } as const;
export type GraphPeriodType = typeof GraphPeriodTypeValue[keyof typeof GraphPeriodTypeValue];

export const CalendarDailyScoreTypeValue = {
  同伴: '同伴',
  指名: '指名',
  その他: 'その他',
} as const;
export const CalendarDailyScoreTypeLabel = {
  同伴: '同伴',
  指名: '指名',
  その他: 'その他',
} as const;
export type CalendarDailyScoreType = typeof CalendarDailyScoreTypeValue[keyof typeof CalendarDailyScoreTypeValue];

export const シフト確定状態Value = {
  フラット: 'フラット',
  出勤確定済: '出勤確定済',
} as const;
export const シフト確定状態Label = {
  フラット: 'フラット',
  出勤確定済: '出勤確定済',
} as const;
export type シフト確定状態 = typeof シフト確定状態Value[keyof typeof シフト確定状態Value];

export const シフト依頼状態Value = {
  フラット: 'フラット',
  キャスト出勤申請中: 'キャスト出勤申請中',
  キャストオフ申請中: 'キャストオフ申請中',
  キャスト移動出勤申請中: 'キャスト移動出勤申請中',
  キャスト移動オフ申請中: 'キャスト移動オフ申請中',
  スタッフ出勤申請中: 'スタッフ出勤申請中',
  スタッフオフ申請中: 'スタッフオフ申請中',
  スタッフ移動出勤申請中: 'スタッフ移動出勤申請中',
  スタッフ移動オフ申請中: 'スタッフ移動オフ申請中',
} as const;
export const シフト依頼状態Label = {
  フラット: 'フラット',
  キャスト出勤申請中: 'キャスト出勤申請中',
  キャストオフ申請中: 'キャストオフ申請中',
  キャスト移動出勤申請中: 'キャスト移動出勤申請中',
  キャスト移動オフ申請中: 'キャスト移動オフ申請中',
  スタッフ出勤申請中: 'スタッフ出勤申請中',
  スタッフオフ申請中: 'スタッフオフ申請中',
  スタッフ移動出勤申請中: 'スタッフ移動出勤申請中',
  スタッフ移動オフ申請中: 'スタッフ移動オフ申請中',
} as const;
export type シフト依頼状態 = typeof シフト依頼状態Value[keyof typeof シフト依頼状態Value];

export const CastOfferTypeValue = {
  出勤: '出勤',
  取下: '取下',
  移動: '移動',
} as const;
export const CastOfferTypeLabel = {
  出勤: '出勤',
  取下: '取下',
  移動: '移動',
} as const;
export type CastOfferType = typeof CastOfferTypeValue[keyof typeof CastOfferTypeValue];

export const ActorNotificationMessageTypeValue = {
  シフト許可: 'シフト許可',
  シフト取下げ許可: 'シフト取下げ許可',
  POS側からの出勤依頼: 'POS側からの出勤依頼',
  POS側からの出勤申請変更依頼: 'POS側からの出勤申請変更依頼',
  POS側からの出勤移動依頼: 'POS側からの出勤移動依頼',
  POS側からの出勤取り下げ依頼: 'POS側からの出勤取り下げ依頼',
  本日シフト: '本日シフト',
  来週シフトとお客様誕生日予定: '来週シフトとお客様誕生日予定',
  イベント登録: 'イベント登録',
  イベント削除: 'イベント削除',
  イベント変更: 'イベント変更',
  本日イベント: '本日イベント',
  バルセロナポイントの通知: 'バルセロナポイントの通知',
  給与額目標を達成: '給与額目標を達成',
  バッジ獲得: 'バッジ獲得',
  ハピネスガチャチケット自動付与: 'ハピネスガチャチケット自動付与',
  ハピネスガチャチケット手動付与: 'ハピネスガチャチケット手動付与',
  美容ポイント手動付与: '美容ポイント手動付与',
} as const;
export const ActorNotificationMessageTypeLabel = {
  シフト許可: 'シフト許可',
  シフト取下げ許可: 'シフト取下げ許可',
  POS側からの出勤依頼: 'POS側からの出勤依頼',
  POS側からの出勤申請変更依頼: 'POS側からの出勤申請変更依頼',
  POS側からの出勤移動依頼: 'POS側からの出勤移動依頼',
  POS側からの出勤取り下げ依頼: 'POS側からの出勤取り下げ依頼',
  本日シフト: '本日シフト',
  来週シフトとお客様誕生日予定: '来週シフトとお客様誕生日予定',
  イベント登録: 'イベント登録',
  イベント削除: 'イベント削除',
  イベント変更: 'イベント変更',
  本日イベント: '本日イベント',
  バルセロナポイントの通知: 'バルセロナポイントの通知',
  給与額目標を達成: '給与額目標を達成',
  バッジ獲得: 'バッジ獲得',
  ハピネスガチャチケット自動付与: 'ハピネスガチャチケット自動付与',
  ハピネスガチャチケット手動付与: 'ハピネスガチャチケット手動付与',
  美容ポイント手動付与: '美容ポイント手動付与',
} as const;
export type ActorNotificationMessageType = typeof ActorNotificationMessageTypeValue[keyof typeof ActorNotificationMessageTypeValue];

export const 予告TypeValue = {
  N: 'N',
  HN: 'HN',
  R: 'R',
  HR: 'HR',
  SR: 'SR',
} as const;
export const 予告TypeLabel = {
  N: 'なし',
  HN: '過激',
  R: '熱い',
  HR: 'レインボー確定？',
  SR: 'レインボー確定！',
} as const;
export type 予告Type = typeof 予告TypeValue[keyof typeof 予告TypeValue];

export const ドレスTypeValue = {
  N: 'N',
  R: 'R',
  HR: 'HR',
} as const;
export const ドレスTypeLabel = {
  N: '白',
  R: '赤',
  HR: '虹',
} as const;
export type ドレスType = typeof ドレスTypeValue[keyof typeof ドレスTypeValue];

export const お客様TypeValue = {
  N: 'N', // 前沢似
  R: 'R', // 中尾似
} as const;
export const お客様TypeLabel = {
  N: 'A',
  R: 'B',
} as const;
export type お客様Type = typeof お客様TypeValue[keyof typeof お客様TypeValue];

export const シャンパンTypeValue = {
  N: 'N',
  R: 'R',
  HR: 'HR',
} as const;
export const シャンパンTypeLabel = {
  N: '金',
  R: '赤',
  HR: '虹',
} as const;
export type シャンパンType = typeof シャンパンTypeValue[keyof typeof シャンパンTypeValue];

export const 魚群TypeValue = {
  N: 'N',
  R: 'R',
} as const;
export const 魚群TypeLabel = {
  N: '無',
  R: '有',
} as const;
export type 魚群Type = typeof 魚群TypeValue[keyof typeof 魚群TypeValue];

export const 排出TypeValue = {
  F: 'F', // FAIL
  N: 'N',
  R: 'R',
  HR: 'HR',
} as const;
export const 排出TypeLabel = {
  F: 'はずれ',
  N: '白',
  R: '赤',
  HR: '虹',
} as const;
export type 排出Type = typeof 排出TypeValue[keyof typeof 排出TypeValue];

export const メッセージカードTypeValue = {
  N: 'N',
  R: 'R',
  HR: 'HR',
} as const;
export const メッセージカードTypeLabel = {
  N: '青',
  R: '赤',
  HR: '虹',
} as const;
export type メッセージカードType = typeof メッセージカードTypeValue[keyof typeof メッセージカードTypeValue];

export const CustomerAgeTypeValue = {
  二十代: '二十代',
  三十代: '三十代',
  四十代: '四十代',
  五十代: '五十代',
  六十代以上: '六十代以上',
} as const;
export const CustomerAgeTypeLabel = {
  二十代: '二十代',
  三十代: '三十代',
  四十代: '四十代',
  五十代: '五十代',
  六十代以上: '六十代以上',
} as const;
export type CustomerAgeType = typeof CustomerAgeTypeValue[keyof typeof CustomerAgeTypeValue];

export const CustomerProfessionTypeValue = {
  経営者個人事業主: '経営者個人事業主',
  会社役員: '会社役員',
  会社員公務員: '会社員公務員',
  投資家: '投資家',
  医療: '医療',
  士業: '士業',
  農業漁業: '農業漁業',
  神職: '神職',
  プロスポーツ選手: 'プロスポーツ選手',
  その他: 'その他',
} as const;
export const CustomerProfessionTypeLabel = {
  経営者個人事業主: '経営者個人事業主',
  会社役員: '会社役員',
  会社員公務員: '会社員公務員',
  投資家: '投資家',
  医療: '医療',
  士業: '士業',
  農業漁業: '農業漁業',
  神職: '神職',
  プロスポーツ選手: 'プロスポーツ選手',
  その他: 'その他',
} as const;
export type CustomerProfessionType = typeof CustomerProfessionTypeValue[keyof typeof CustomerProfessionTypeValue];

export const CustomerAreaTypeValue = {
  北海道: '北海道',
  青森県: '青森県',
  岩手県: '岩手県',
  宮城県: '宮城県',
  秋田県: '秋田県',
  山形県: '山形県',
  福島県: '福島県',
  茨城県: '茨城県',
  栃木県: '栃木県',
  群馬県: '群馬県',
  埼玉県: '埼玉県',
  千葉県: '千葉県',
  東京都: '東京都',
  神奈川県: '神奈川県',
  新潟県: '新潟県',
  富山県: '富山県',
  石川県: '石川県',
  福井県: '福井県',
  山梨県: '山梨県',
  長野県: '長野県',
  岐阜県: '岐阜県',
  静岡県: '静岡県',
  愛知県: '愛知県',
  三重県: '三重県',
  滋賀県: '滋賀県',
  京都府: '京都府',
  大阪府: '大阪府',
  兵庫県: '兵庫県',
  奈良県: '奈良県',
  和歌山県: '和歌山県',
  鳥取県: '鳥取県',
  島根県: '島根県',
  岡山県: '岡山県',
  広島県: '広島県',
  山口県: '山口県',
  徳島県: '徳島県',
  香川県: '香川県',
  愛媛県: '愛媛県',
  高知県: '高知県',
  福岡県: '福岡県',
  佐賀県: '佐賀県',
  長崎県: '長崎県',
  熊本県: '熊本県',
  大分県: '大分県',
  宮崎県: '宮崎県',
  鹿児島県: '鹿児島県',
  沖縄県: '沖縄県',
} as const;
export const CustomerAreaTypeLabel = {
  北海道: '北海道',
  青森県: '青森県',
  岩手県: '岩手県',
  宮城県: '宮城県',
  秋田県: '秋田県',
  山形県: '山形県',
  福島県: '福島県',
  茨城県: '茨城県',
  栃木県: '栃木県',
  群馬県: '群馬県',
  埼玉県: '埼玉県',
  千葉県: '千葉県',
  東京都: '東京都',
  神奈川県: '神奈川県',
  新潟県: '新潟県',
  富山県: '富山県',
  石川県: '石川県',
  福井県: '福井県',
  山梨県: '山梨県',
  長野県: '長野県',
  岐阜県: '岐阜県',
  静岡県: '静岡県',
  愛知県: '愛知県',
  三重県: '三重県',
  滋賀県: '滋賀県',
  京都府: '京都府',
  大阪府: '大阪府',
  兵庫県: '兵庫県',
  奈良県: '奈良県',
  和歌山県: '和歌山県',
  鳥取県: '鳥取県',
  島根県: '島根県',
  岡山県: '岡山県',
  広島県: '広島県',
  山口県: '山口県',
  徳島県: '徳島県',
  香川県: '香川県',
  愛媛県: '愛媛県',
  高知県: '高知県',
  福岡県: '福岡県',
  佐賀県: '佐賀県',
  長崎県: '長崎県',
  熊本県: '熊本県',
  大分県: '大分県',
  宮崎県: '宮崎県',
  鹿児島県: '鹿児島県',
  沖縄県: '沖縄県',
} as const;
export type CustomerAreaType = typeof CustomerAreaTypeValue[keyof typeof CustomerAreaTypeValue];

export const EffectTypeValue = {
  達成率: '達成率',
  目標達成: '目標達成',
  出勤時間: '出勤時間', // 時間数(72, 84)
  同伴件数: '同伴件数', // 同伴件数(5, 8, 12, 20)
  指名本数: '指名本数', // 指名本数(5, 15, 25, 50, 75, 100)
  ポイントランキング_店舗_年間: 'ポイントランキング_店舗_年間', // 順位(1-10)
  ポイントランキング_店舗_月間: 'ポイントランキング_店舗_月間', // 順位(1-10)
  ポイントランキング_グループ_年間: 'ポイントランキング_グループ_年間', // 順位(1-30)
  ポイントランキング_グループ_月間: 'ポイントランキング_グループ_月間', // 順位(1-30)
  同伴ランキング_グループ_年間: '同伴ランキング_グループ_年間', // 順位(1-30)
  同伴ランキング_グループ_月間: '同伴ランキング_グループ_月間', // 順位(1-30)
  指名ランキング_グループ_年間: '指名ランキング_グループ_年間', // 順位(1-30)
  指名ランキング_グループ_月間: '指名ランキング_グループ_月間', // 順位(1-30)
  個別レース: '個別レース', // 第一段階区切り順位:1, 第二段階区切り順位:2, 表示最低順位:3
  ポイントベスト更新_年間: 'ポイントベスト更新_年間',
  ポイントベスト更新_月間: 'ポイントベスト更新_月間',
  同伴数ベスト更新_年間: '同伴数ベスト更新_年間',
  同伴数ベスト更新_月間: '同伴数ベスト更新_月間',
  指名数ベスト更新_年間: '指名数ベスト更新_年間',
  指名数ベスト更新_月間: '指名数ベスト更新_月間',
  ポイントレコード_店舗_年間: 'ポイントレコード_店舗_年間',
  ポイントレコード_店舗_月間: 'ポイントレコード_店舗_月間',
  ポイントレコード_グループ_年間: 'ポイントレコード_グループ_年間',
  ポイントレコード_グループ_月間: 'ポイントレコード_グループ_月間',
  同伴数レコード_店舗_年間: '同伴数レコード_店舗_年間',
  同伴数レコード_店舗_月間: '同伴数レコード_店舗_月間',
  同伴数レコード_グループ_年間: '同伴数レコード_グループ_年間',
  同伴数レコード_グループ_月間: '同伴数レコード_グループ_月間',
  指名数レコード_店舗_年間: '指名数レコード_店舗_年間',
  指名数レコード_店舗_月間: '指名数レコード_店舗_月間',
  指名数レコード_グループ_年間: '指名数レコード_グループ_年間',
  指名数レコード_グループ_月間: '指名数レコード_グループ_月間',
  在籍日数: '在籍日数', // 在籍月数(3, 6, 12, 24...)
  お誕生日: 'お誕生日',
  指名があった日: '指名があった日',
  指名連日獲得: '指名連日獲得', // 日数(3-)
  Instagram初指名: 'Instagram初指名',
  ナイツ初指名: 'ナイツ初指名',
  ポケパラ初指名: 'ポケパラ初指名',
  同伴があった日: '同伴があった日',
  初アルマンド: '初アルマンド',
  初ソウメイ: '初ソウメイ',
  ランダム: 'ランダム', // タイプ(1-6)
} as const;
export const EffectTypeLabel = {
  達成率: '達成率',
  目標達成: '目標達成',
  出勤時間: '出勤時間',
  同伴件数: '同伴件数',
  指名本数: '指名本数',
  ポイントランキング_店舗_年間: 'ポイントランキング_店舗_年間',
  ポイントランキング_店舗_月間: 'ポイントランキング_店舗_月間',
  ポイントランキング_グループ_年間: 'ポイントランキング_グループ_年間',
  ポイントランキング_グループ_月間: 'ポイントランキング_グループ_月間',
  同伴ランキング_グループ_年間: '同伴ランキング_グループ_年間',
  同伴ランキング_グループ_月間: '同伴ランキング_グループ_月間',
  指名ランキング_グループ_年間: '指名ランキング_グループ_年間',
  指名ランキング_グループ_月間: '指名ランキング_グループ_月間',
  個別レース: '個別レース',
  ポイントベスト更新_年間: 'ポイントベスト更新_年間',
  ポイントベスト更新_月間: 'ポイントベスト更新_月間',
  同伴数ベスト更新_年間: '同伴数ベスト更新_年間',
  同伴数ベスト更新_月間: '同伴数ベスト更新_月間',
  指名数ベスト更新_年間: '指名数ベスト更新_年間',
  指名数ベスト更新_月間: '指名数ベスト更新_月間',
  ポイントレコード_店舗_年間: 'ポイントレコード_店舗_年間',
  ポイントレコード_店舗_月間: 'ポイントレコード_店舗_月間',
  ポイントレコード_グループ_年間: 'ポイントレコード_グループ_年間',
  ポイントレコード_グループ_月間: 'ポイントレコード_グループ_月間',
  同伴数レコード_店舗_年間: '同伴数レコード_店舗_年間',
  同伴数レコード_店舗_月間: '同伴数レコード_店舗_月間',
  同伴数レコード_グループ_年間: '同伴数レコード_グループ_年間',
  同伴数レコード_グループ_月間: '同伴数レコード_グループ_月間',
  指名数レコード_店舗_年間: '指名数レコード_店舗_年間',
  指名数レコード_店舗_月間: '指名数レコード_店舗_月間',
  指名数レコード_グループ_年間: '指名数レコード_グループ_年間',
  指名数レコード_グループ_月間: '指名数レコード_グループ_月間',
  在籍日数: '在籍日数',
  お誕生日: 'お誕生日',
  指名があった日: '指名があった日',
  指名連日獲得: '指名連日獲得',
  Instagram初指名: 'Instagram初指名',
  ナイツ初指名: 'ナイツ初指名',
  ポケパラ初指名: 'ポケパラ初指名',
  同伴があった日: '同伴があった日',
  初アルマンド: '初アルマンド',
  初ソウメイ: '初ソウメイ',
  ランダム: 'ランダム',
} as const;
export type EffectType = typeof EffectTypeValue[keyof typeof EffectTypeValue];

// ============================================================================
// types
// ============================================================================
export type SampleClass = {
  intValue: number;
  floatValue: number;
  textValue: string;
  classValue?: SampleClass;
  あああ: string;
}
export const DefaultSampleClass: SampleClass = {
  intValue: 0,
  floatValue: 0,
  textValue: '',
  classValue: undefined,
  あああ: '',
}

export type ActorBadge = {
  category: ActorBadgeCategory;
  badgeType: ActorBadgeType;
  value: number; // 表示値
  period: number; // *月期
  monthName: string; // GA4用月名
  endMonthName: string; // GA4用月名
  tenpoName: string; // バッジ獲得時点の店舗名
  ポイント単位種別: TenpoPointUnitType;
}
export const DefaultActorBadge: ActorBadge = {
  category: ActorBadgeCategoryValue.店舗ポイントランキング,
  badgeType: ActorBadgeTypeValue.なし,
  value: 0,
  period: 0,
  monthName: '',
  endMonthName: '',
  tenpoName: '',
  ポイント単位種別: TenpoPointUnitTypeValue.ポイント,
}

export type ActorBadgeSummary = {
  category: ActorBadgeCategory;
  badgeType: ActorBadgeType;
}
export const DefaultActorBadgeSummary: ActorBadgeSummary = {
  category: ActorBadgeCategoryValue.店舗ポイントランキング,
  badgeType: ActorBadgeTypeValue.なし,
}

export type RankingEntry = {
  rank: number; // 順位
  name: string; // キャスト名
  imageUrl: string; // キャスト画像URL
  value: number; // 評価値
  suffix: string; // 評価値の単位表示(円、Pなど)
  diff: number; // 前期との比較値
  actorId: string; // グループランキング取得用
  badges: ActorBadgeSummary[]; // ランキング表示バッジ
  tenpoCode:string;
  subValue: number; // 第二の評価値
  subSuffix: string; // 第二の評価値の単位表示(円、Pなど)
  customerDetail?: CustomerDetailForRankingEntry; // 顧客ランキングの分析データ
}
export const DefaultRankingEntry: RankingEntry = {
  rank: 0,
  name: '',
  imageUrl: '',
  value: 0,
  suffix: '',
  diff: 0,
  actorId: '',
  badges: [],
  tenpoCode:'',
  subValue: 0,
  subSuffix: '',
  customerDetail: undefined,
}

export type CustomerDetailForRankingEntry = {
  age: CustomerAgeType; // 年代
  area: CustomerAreaType; // 住所
  profession: CustomerProfessionType; // 職業
}
export const DefaultCustomerDetailForRankingEntry: CustomerDetailForRankingEntry = {
  age: CustomerAgeTypeValue.二十代,
  area: CustomerAreaTypeValue.北海道,
  profession: CustomerProfessionTypeValue.経営者個人事業主,
}

export type CustomRanking = {
  rankingId: string; // 各種ランキングID
  name: string; // 各種ランキング名
  latestPeriodIndex: number; // 最終の集計期間番号
  periodType: ActorCustomRankingPeriodType;
}
export const DefaultCustomRanking: CustomRanking = {
  rankingId: '',
  name: '',
  latestPeriodIndex: 0,
  periodType: ActorCustomRankingPeriodTypeValue.任意期間,
}

export type GraphDataEntry = {
  date: string; // YYYYMMDD ex.20210315
  無効: boolean; // グラフに表示しない場合はtrue
  成績: number; // ポイント or 円
  支給総額: number;
  店舗内ランキング: number;
  グループ内ランキング: number;
  指名本数: number;
  同伴本数: number;
  フリー件数: number;
  場内指名件数: number;
  出勤時間: number; // 分
  店内有効キャスト数: number; // 店舗ランキング最大値
  グループ内有効キャスト数: number; // グループランキング最大値
}
export const DefaultGraphDataEntry: GraphDataEntry = {
  date: '',
  無効: false,
  成績: 0,
  支給総額: 0,
  店舗内ランキング: 0,
  グループ内ランキング: 0,
  指名本数: 0,
  同伴本数: 0,
  フリー件数: 0,
  場内指名件数: 0,
  出勤時間: 0,
  店内有効キャスト数: 0,
  グループ内有効キャスト数: 0,
}

export type GraphGoalsDto = {
  成績: number; // ポイント or 円
  支給総額: number;
  店舗内ランキング: number;
  グループ内ランキング: number;
  指名本数: number;
  同伴本数: number;
  出勤時間: number; // 分
}
export const DefaultGraphGoalsDto: GraphGoalsDto = {
  成績: 0,
  支給総額: 0,
  店舗内ランキング: 0,
  グループ内ランキング: 0,
  指名本数: 0,
  同伴本数: 0,
  出勤時間: 0,
}

export type PercentageInGroup = {
  yearMonth: number; // yyyymm
  value: number; // 自分のスコア以上の人数/全体の人数
}
export const DefaultPercentageInGroup: PercentageInGroup = {
  yearMonth: 0,
  value: 0,
}

export type CalendarDate = {
  date: string; // YYYYMMDD ex.20210315
  isHoliday: boolean; // 祝日フラグ
  done: boolean; // 締め済営業日フラグ
  birthdayCustomerNames: string[]; // 誕生日のお客様名リスト
  scores: CalendarDailyScore[];
}
export const DefaultCalendarDate: CalendarDate = {
  date: '',
  isHoliday: false,
  done: false,
  birthdayCustomerNames: [],
  scores: [],
}

export type CalendarDailyScore = {
  name: string; // キャスト名
  value: number; // 獲得ポイント|円
  type: CalendarDailyScoreType; // 同伴 or 指名 or その他
}
export const DefaultCalendarDailyScore: CalendarDailyScore = {
  name: '',
  value: 0,
  type: CalendarDailyScoreTypeValue.同伴,
}

export type DailyPointInfo = {
  name: string; // ex. "場内　ゲスト"
  value: number; // 獲得ポイント数 or 円
}
export const DefaultDailyPointInfo: DailyPointInfo = {
  name: '',
  value: 0,
}

export type ActorVisitor = {
  id: string;
  date: string; // 年月日 ex.20200731
  title: string; // お客様名
  douhan: boolean; // 同伴フラグ
  vip: string; // VIPルームID
}
export const DefaultActorVisitor: ActorVisitor = {
  id: '',
  date: '',
  title: '',
  douhan: false,
  vip: '',
}

export type VipRoom = {
  id: string;
  name: string;
  price: number; // VIPルーム料金(円)
  enable: boolean; // 選択可能ならtrue
}
export const DefaultVipRoom: VipRoom = {
  id: '',
  name: '',
  price: 0,
  enable: false,
}

export type ActorSchedule = {
  date: string; // 年月日 ex.20200731
  status: シフト確定状態; // 確定状態を表す
  offer: シフト依頼状態; // 申請状態を表す
  staffName: string; // 操作を行ったスタッフ名
  rireki: ActorScheduleRireki[]; // 履歴一覧
}
export const DefaultActorSchedule: ActorSchedule = {
  date: '',
  status: シフト確定状態Value.フラット,
  offer: シフト依頼状態Value.フラット,
  staffName: '',
  rireki: [],
}

export type ActorScheduleRireki = {
  staffName: string; // 操作を行ったスタッフ名
  createdAt: number; // 作成日時
  label: string; // 履歴表示用テキスト
}
export const DefaultActorScheduleRireki: ActorScheduleRireki = {
  staffName: '',
  createdAt: 0,
  label: '',
}

export type ActorEventSchedule = {
  start: number; // 開始日時
  title: string; // イベントタイトル
}
export const DefaultActorEventSchedule: ActorEventSchedule = {
  start: 0,
  title: '',
}

export type ActorScheduleOffer = {
  date: string; // 年月日 ex.20200731
  castOfferType: CastOfferType; // キャストからの申請種別
  toDate: string; // 移動先年月日 ex.20200731
}
export const DefaultActorScheduleOffer: ActorScheduleOffer = {
  date: '',
  castOfferType: CastOfferTypeValue.出勤,
  toDate: '',
}

export type NotificationMessage = {
  id: string; // 通知ID(actor_notification_message_target.id)
  object_type: ActorNotificationMessageType; // 通知種別(actor_notification_message.summary)
  summary: string; // 一覧画面タイトル(actor_notification_message.summary)
  enabled: boolean; // 有効無効(actor_notification_message.enabled)
  title: string; // 詳細画面タイトル(actor_notification_message.title)
  message: string; // 詳細画面本文(actor_notification_message.message)
  seen: boolean; // 既読(actor_notification_message_target.seen)
  completed: boolean; // 完了(actor_notification_message_target.completed)
  createdAt: number; // 作成日時
  reply_limit: number; // 回答期限
}
export const DefaultNotificationMessage: NotificationMessage = {
  id: '',
  object_type: ActorNotificationMessageTypeValue.シフト許可,
  summary: '',
  enabled: false,
  title: '',
  message: '',
  seen: false,
  completed: false,
  createdAt: 0,
  reply_limit: 0,
}

export type GachaFirstResult = {
  予告: 予告Type;
  ドレス: ドレスType;
  お客様: お客様Type;
  シャンパン: シャンパンType;
  魚群: 魚群Type;
  排出: 排出Type;
}
export const DefaultGachaFirstResult: GachaFirstResult = {
  予告: 予告TypeValue.N,
  ドレス: ドレスTypeValue.N,
  お客様: お客様TypeValue.N,
  シャンパン: シャンパンTypeValue.N,
  魚群: 魚群TypeValue.N,
  排出: 排出TypeValue.F,
}

export type GachaRevivalResult = {
  ドレス: ドレスType;
  シャンパン: シャンパンType;
  排出: 排出Type;
}
export const DefaultGachaRevivalResult: GachaRevivalResult = {
  ドレス: ドレスTypeValue.N,
  シャンパン: シャンパンTypeValue.N,
  排出: 排出TypeValue.F,
}

export type GachaInfo = {
  美容ポイント: number;
  ハピネスガチャチケット: number;
}
export const DefaultGachaInfo: GachaInfo = {
  美容ポイント: 0,
  ハピネスガチャチケット: 0,
}

export type GachaLotteryItemDto = {
  等級: number;
  景品名: string;
  景品名2行目: string;
  画像id: string;
  メッセージカード: メッセージカードType;
}
export const DefaultGachaLotteryItemDto: GachaLotteryItemDto = {
  等級: 0,
  景品名: '',
  景品名2行目: '',
  画像id: '',
  メッセージカード: メッセージカードTypeValue.N,
}

export type GachaExchangeItemDto = {
  id: string;
  交換物名: string;
  画像id: string;
  説明見出し: string;
  説明詳細: string;
  説明詳細url: string;
  必要ポイント: number;
}
export const DefaultGachaExchangeItemDto: GachaExchangeItemDto = {
  id: '',
  交換物名: '',
  画像id: '',
  説明見出し: '',
  説明詳細: '',
  説明詳細url: '',
  必要ポイント: 0,
}

export type GachaExchangeHistoryDto = {
  id: string;
  createdAt: number;
  ステータス: string;
  個数: number;
  消費ポイント: number;
  交換物名: string;
  交換物名2行目: string;
  キャンセル済: boolean;
}
export const DefaultGachaExchangeHistoryDto: GachaExchangeHistoryDto = {
  id: '',
  createdAt: 0,
  ステータス: '',
  個数: 0,
  消費ポイント: 0,
  交換物名: '',
  交換物名2行目: '',
  キャンセル済: false,
}

export type GachaLotteryHistoryDto = {
  id: string;
  createdAt: number;
  ステータス: string;
  個数: number;
  等級: number;
  景品名: string;
  景品名2行目: string;
  キャンセル済: boolean;
}
export const DefaultGachaLotteryHistoryDto: GachaLotteryHistoryDto = {
  id: '',
  createdAt: 0,
  ステータス: '',
  個数: 0,
  等級: 0,
  景品名: '',
  景品名2行目: '',
  キャンセル済: false,
}

export type SwitchableActorInfo = {
  id: string;
  name: string;
  tenpoName: string;
  tenpoShortName: string;
  groupRanking: number;
  searchWords: string;
  selected: boolean;
}
export const DefaultSwitchableActorInfo: SwitchableActorInfo = {
  id: '',
  name: '',
  tenpoName: '',
  tenpoShortName: '',
  groupRanking: 0,
  searchWords: '',
  selected: false,
}

export type EffectDto = {
  id: string;
  effectType: EffectType;
  typeValue: number;
  param1: string;
  param2: string;
  param3: string;
  param4: string;
  tenpoName: string;
  ポイント単位種別: TenpoPointUnitType;
}
export const DefaultEffectDto: EffectDto = {
  id: '',
  effectType: EffectTypeValue.達成率,
  typeValue: 0,
  param1: '',
  param2: '',
  param3: '',
  param4: '',
  tenpoName: '',
  ポイント単位種別: TenpoPointUnitTypeValue.ポイント,
}


// ============================================================================
// actions
// ============================================================================
async function send<REQUEST, RESPONSE>(
  path: string,
  request: REQUEST,
  emptyResponse: RESPONSE,
  callback?: (res: RESPONSE) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<RESPONSE> {
  try {
    const response = await formClient.post(path, toQueryString(request));
    callNativeApiCallDone();
    if (callback) callback(response.data);
    return response.data;
  } catch (error) {
    if(error.response && error.response.status === 401){
      window.location.href = window.location.origin + process.env.PUBLIC_URL + '/login'
    } else {
      if (errorCallback) {
        errorCallback(error);
      } else {
        store.dispatch(showErrorAlert(`API Error\nPath: ${path}\nRequest: ${JSON.stringify(request)}\nResponse:\n\n${error}`));
//        alert(`API Error\nPath: ${path}\nRequest: ${JSON.stringify(request)}\nResponse:\n\n${error}`);
        throw error;
      }
    }
  }
  return emptyResponse;
}

export type SampleRequest = {
  intValue: number;
  floatValue: number;
  textValue: string;
  classValue?: SampleClass;
  あああ: string;
  textList: string[];
  classList: SampleClass[];
}

export type SampleResponse = {
  intValue: number;
  floatValue: number;
  textValue: string;
  classValue?: SampleClass;
  あああ: string;
  textList: string[];
  classList: SampleClass[];
}

export const EmptySampleResponse: SampleResponse = {
  intValue: 0,
  floatValue: 0,
  textValue: '',
  classValue: undefined,
  あああ: '',
  textList: [],
  classList: [],
}

export async function sendSample(
  request: SampleRequest,
  callback?: (res: SampleResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<SampleResponse> {
  return send('sample/', request, EmptySampleResponse, callback, errorCallback)
}

export const fetchSample = createAsyncThunk(
  'apiThunk/fetchSample',
  async (request:SampleRequest, thunkAPI) => {
    const response = await sendSample(request);
    return {request, response}
  }
)

export type fetchSampleAction = PayloadAction<{request: SampleRequest, response: SampleResponse}>

export type EndpointRequest = {
  appVersion: string;
  os: OSType;
}

export type EndpointResponse = {
  front: string;
  api: string;
  message: string;
}

export const EmptyEndpointResponse: EndpointResponse = {
  front: '',
  api: '',
  message: '',
}

export async function sendEndpoint(
  request: EndpointRequest,
  callback?: (res: EndpointResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<EndpointResponse> {
  return send('endpoint/', request, EmptyEndpointResponse, callback, errorCallback)
}

export const fetchEndpoint = createAsyncThunk(
  'apiThunk/fetchEndpoint',
  async (request:EndpointRequest, thunkAPI) => {
    const response = await sendEndpoint(request);
    return {request, response}
  }
)

export type fetchEndpointAction = PayloadAction<{request: EndpointRequest, response: EndpointResponse}>

export type DebugRequest = {
  type: number;
  value: string;
}

export type DebugResponse = {
}

export const EmptyDebugResponse: DebugResponse = {
}

export async function sendDebug(
  request: DebugRequest,
  callback?: (res: DebugResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<DebugResponse> {
  return send('debug/', request, EmptyDebugResponse, callback, errorCallback)
}

export const fetchDebug = createAsyncThunk(
  'apiThunk/fetchDebug',
  async (request:DebugRequest, thunkAPI) => {
    const response = await sendDebug(request);
    return {request, response}
  }
)

export type fetchDebugAction = PayloadAction<{request: DebugRequest, response: DebugResponse}>

export type ActorRegistTokenRequest = {
  token: string;
  version: string;
  clientId: string;
}

export type ActorRegistTokenResponse = {
  result: string; // OK or NG
}

export const EmptyActorRegistTokenResponse: ActorRegistTokenResponse = {
  result: '',
}

export async function sendActorRegistToken(
  request: ActorRegistTokenRequest,
  callback?: (res: ActorRegistTokenResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorRegistTokenResponse> {
  return send('actorRegistToken/', request, EmptyActorRegistTokenResponse, callback, errorCallback)
}

export const fetchActorRegistToken = createAsyncThunk(
  'apiThunk/fetchActorRegistToken',
  async (request:ActorRegistTokenRequest, thunkAPI) => {
    const response = await sendActorRegistToken(request);
    return {request, response}
  }
)

export type fetchActorRegistTokenAction = PayloadAction<{request: ActorRegistTokenRequest, response: ActorRegistTokenResponse}>

export type ActorLoginRequest = {
  username: string;
  password: string;
  clientId: string;
}

export type ActorLoginResponse = {
  result: string; // OK or NG
}

export const EmptyActorLoginResponse: ActorLoginResponse = {
  result: '',
}

export async function sendActorLogin(
  request: ActorLoginRequest,
  callback?: (res: ActorLoginResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorLoginResponse> {
  return send('actorLogin/', request, EmptyActorLoginResponse, callback, errorCallback)
}

export const fetchActorLogin = createAsyncThunk(
  'apiThunk/fetchActorLogin',
  async (request:ActorLoginRequest, thunkAPI) => {
    const response = await sendActorLogin(request);
    return {request, response}
  }
)

export type fetchActorLoginAction = PayloadAction<{request: ActorLoginRequest, response: ActorLoginResponse}>

export type ActorInfoRequest = {
}

export type ActorInfoResponse = {
  大目標: string;
}

export const EmptyActorInfoResponse: ActorInfoResponse = {
  大目標: '',
}

export async function sendActorInfo(
  request: ActorInfoRequest,
  callback?: (res: ActorInfoResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorInfoResponse> {
  return send('actorInfo/', request, EmptyActorInfoResponse, callback, errorCallback)
}

export const fetchActorInfo = createAsyncThunk(
  'apiThunk/fetchActorInfo',
  async (request:ActorInfoRequest, thunkAPI) => {
    const response = await sendActorInfo(request);
    return {request, response}
  }
)

export type fetchActorInfoAction = PayloadAction<{request: ActorInfoRequest, response: ActorInfoResponse}>

export type ActorLogoutRequest = {
}

export type ActorLogoutResponse = {
  result: string; // OK or NG
}

export const EmptyActorLogoutResponse: ActorLogoutResponse = {
  result: '',
}

export async function sendActorLogout(
  request: ActorLogoutRequest,
  callback?: (res: ActorLogoutResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorLogoutResponse> {
  return send('actorLogout/', request, EmptyActorLogoutResponse, callback, errorCallback)
}

export const fetchActorLogout = createAsyncThunk(
  'apiThunk/fetchActorLogout',
  async (request:ActorLogoutRequest, thunkAPI) => {
    const response = await sendActorLogout(request);
    return {request, response}
  }
)

export type fetchActorLogoutAction = PayloadAction<{request: ActorLogoutRequest, response: ActorLogoutResponse}>

export type ActorSwitchRequest = {
  actorId: string;
}

export type ActorSwitchResponse = {
  result: string; // OK or NG
}

export const EmptyActorSwitchResponse: ActorSwitchResponse = {
  result: '',
}

export async function sendActorSwitch(
  request: ActorSwitchRequest,
  callback?: (res: ActorSwitchResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorSwitchResponse> {
  return send('actorSwitch/', request, EmptyActorSwitchResponse, callback, errorCallback)
}

export const fetchActorSwitch = createAsyncThunk(
  'apiThunk/fetchActorSwitch',
  async (request:ActorSwitchRequest, thunkAPI) => {
    const response = await sendActorSwitch(request);
    return {request, response}
  }
)

export type fetchActorSwitchAction = PayloadAction<{request: ActorSwitchRequest, response: ActorSwitchResponse}>

export type DataVersionRequest = {
}

export type DataVersionResponse = {
  v: string[]; // バージョン値のリスト　indexはClientCacheSegmentTypeのordinal
}

export const EmptyDataVersionResponse: DataVersionResponse = {
  v: [],
}

export async function sendDataVersion(
  request: DataVersionRequest,
  callback?: (res: DataVersionResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<DataVersionResponse> {
  return send('dataVersion/', request, EmptyDataVersionResponse, callback, errorCallback)
}

export const fetchDataVersion = createAsyncThunk(
  'apiThunk/fetchDataVersion',
  async (request:DataVersionRequest, thunkAPI) => {
    const response = await sendDataVersion(request);
    return {request, response}
  }
)

export type fetchDataVersionAction = PayloadAction<{request: DataVersionRequest, response: DataVersionResponse}>

export type HomeRequest = {
  now: string;
}

export type HomeResponse = {
  目標給与額: number; // 円
  前日までの想定給与額: number; // 円
  現在獲得ポイント: number;
  ポイント単位種別: TenpoPointUnitType; // ポイント or 小計型の表示用
  現在勤務時間: number; // 時間
  店舗ランキング: number; // 順位
  前期店舗ランキング: number; // 順位
  グループランキング: number; // 順位
  前期グループランキング: number; // 順位
  同伴数: number; // 本
  指名数: number; // 本
  フリー件数: number; // 本
  フリー場内率: number; // %
  グループ内位置リスト: PercentageInGroup[]; // ピラミッド画面用の最大3ヶ月分のデータ
  actorFacePhotoId: string;
  actorName: string;
  tenpoName: string;
  ノルマ経過日数割合: number; // 該当月の締め済み営業日数/営業日数、1日時点と2日時点で該当付きが異なる
}

export const EmptyHomeResponse: HomeResponse = {
  目標給与額: 0,
  前日までの想定給与額: 0,
  現在獲得ポイント: 0,
  ポイント単位種別: TenpoPointUnitTypeValue.ポイント,
  現在勤務時間: 0,
  店舗ランキング: 0,
  前期店舗ランキング: 0,
  グループランキング: 0,
  前期グループランキング: 0,
  同伴数: 0,
  指名数: 0,
  フリー件数: 0,
  フリー場内率: 0,
  グループ内位置リスト: [],
  actorFacePhotoId: '',
  actorName: '',
  tenpoName: '',
  ノルマ経過日数割合: 0,
}

export async function sendHome(
  request: HomeRequest,
  callback?: (res: HomeResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<HomeResponse> {
  return send('home/', request, EmptyHomeResponse, callback, errorCallback)
}

export const fetchHome = createAsyncThunk(
  'apiThunk/fetchHome',
  async (request:HomeRequest, thunkAPI) => {
    const response = await sendHome(request);
    return {request, response}
  }
)

export type fetchHomeAction = PayloadAction<{request: HomeRequest, response: HomeResponse}>

export type SwitchableActorRequest = {
}

export type SwitchableActorResponse = {
  switchableActorInfos: SwitchableActorInfo[]; // (メンター用)切り替え可能なキャストリスト
}

export const EmptySwitchableActorResponse: SwitchableActorResponse = {
  switchableActorInfos: [],
}

export async function sendSwitchableActor(
  request: SwitchableActorRequest,
  callback?: (res: SwitchableActorResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<SwitchableActorResponse> {
  return send('switchableActor/', request, EmptySwitchableActorResponse, callback, errorCallback)
}

export const fetchSwitchableActor = createAsyncThunk(
  'apiThunk/fetchSwitchableActor',
  async (request:SwitchableActorRequest, thunkAPI) => {
    const response = await sendSwitchableActor(request);
    return {request, response}
  }
)

export type fetchSwitchableActorAction = PayloadAction<{request: SwitchableActorRequest, response: SwitchableActorResponse}>

export type BadgeRequest = {
  now: string;
}

export type BadgeResponse = {
  badgeList: ActorBadge[];
}

export const EmptyBadgeResponse: BadgeResponse = {
  badgeList: [],
}

export async function sendBadge(
  request: BadgeRequest,
  callback?: (res: BadgeResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<BadgeResponse> {
  return send('badge/', request, EmptyBadgeResponse, callback, errorCallback)
}

export const fetchBadge = createAsyncThunk(
  'apiThunk/fetchBadge',
  async (request:BadgeRequest, thunkAPI) => {
    const response = await sendBadge(request);
    return {request, response}
  }
)

export type fetchBadgeAction = PayloadAction<{request: BadgeRequest, response: BadgeResponse}>

export type RankingRequest = {
}

export type RankingResponse = {
  latestCustomRankings: CustomRanking[]; // 直近の各種ランキング定義
}

export const EmptyRankingResponse: RankingResponse = {
  latestCustomRankings: [],
}

export async function sendRanking(
  request: RankingRequest,
  callback?: (res: RankingResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<RankingResponse> {
  return send('ranking/', request, EmptyRankingResponse, callback, errorCallback)
}

export const fetchRanking = createAsyncThunk(
  'apiThunk/fetchRanking',
  async (request:RankingRequest, thunkAPI) => {
    const response = await sendRanking(request);
    return {request, response}
  }
)

export type fetchRankingAction = PayloadAction<{request: RankingRequest, response: RankingResponse}>

export type RegularRankingSummaryRequest = {
  rankingType: ActorRegularRankingType; // 集計種別
  periodType: ActorRegularRankingPeriodType; // 集計期間種別
  periodIndex: number; // 相対集計番号(0:今期、-1:前期...)
}

export type RegularRankingSummaryResponse = {
  selfRank: number; // ログインユーザの順位 存在しなければ0
  periodTitle: string; // 集計期間表示
  count: number; // 総件数
  totalPoint?: number; // 総ポイント(お客様ランキングで必要) 
}

export const EmptyRegularRankingSummaryResponse: RegularRankingSummaryResponse = {
  selfRank: 0,
  periodTitle: '',
  count: 0,
}

export async function sendRegularRankingSummary(
  request: RegularRankingSummaryRequest,
  callback?: (res: RegularRankingSummaryResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<RegularRankingSummaryResponse> {
  return send('regularRankingSummary/', request, EmptyRegularRankingSummaryResponse, callback, errorCallback)
}

export const fetchRegularRankingSummary = createAsyncThunk(
  'apiThunk/fetchRegularRankingSummary',
  async (request:RegularRankingSummaryRequest, thunkAPI) => {
    const response = await sendRegularRankingSummary(request);
    return {request, response}
  }
)

export type fetchRegularRankingSummaryAction = PayloadAction<{request: RegularRankingSummaryRequest, response: RegularRankingSummaryResponse}>

export type RegularRankingEntriesRequest = {
  rankingType: ActorRegularRankingType; // 集計種別
  periodType: ActorRegularRankingPeriodType; // 集計期間種別
  periodIndex: number; // 相対集計番号(0:今期、-1:前期...)
  limit: number; // 取得件数
  offset: number; // 取得オフセット件数
}

export type RegularRankingEntriesResponse = {
  entries: RankingEntry[];
}

export const EmptyRegularRankingEntriesResponse: RegularRankingEntriesResponse = {
  entries: [],
}

export async function sendRegularRankingEntries(
  request: RegularRankingEntriesRequest,
  callback?: (res: RegularRankingEntriesResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<RegularRankingEntriesResponse> {
  return send('regularRankingEntries/', request, EmptyRegularRankingEntriesResponse, callback, errorCallback)
}

export const fetchRegularRankingEntries = createAsyncThunk(
  'apiThunk/fetchRegularRankingEntries',
  async (request:RegularRankingEntriesRequest, thunkAPI) => {
    const response = await sendRegularRankingEntries(request);
    return {request, response}
  }
)

export type fetchRegularRankingEntriesAction = PayloadAction<{request: RegularRankingEntriesRequest, response: RegularRankingEntriesResponse}>

export type CustomRankingSummaryRequest = {
  rankingId: string; // 各種ランキングID
  periodIndex: number; // 集計番号
}

export type CustomRankingSummaryResponse = {
  selfRank: number; // ログインユーザの順位 存在しなければ0
  periodTitle: string; // 集計期間表示
  count: number; // 総件数
  totalPoint?: number; // 総ポイント(お客様ランキングで必要) 
}

export const EmptyCustomRankingSummaryResponse: CustomRankingSummaryResponse = {
  selfRank: 0,
  periodTitle: '',
  count: 0,
}

export async function sendCustomRankingSummary(
  request: CustomRankingSummaryRequest,
  callback?: (res: CustomRankingSummaryResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<CustomRankingSummaryResponse> {
  return send('customRankingSummary/', request, EmptyCustomRankingSummaryResponse, callback, errorCallback)
}

export const fetchCustomRankingSummary = createAsyncThunk(
  'apiThunk/fetchCustomRankingSummary',
  async (request:CustomRankingSummaryRequest, thunkAPI) => {
    const response = await sendCustomRankingSummary(request);
    return {request, response}
  }
)

export type fetchCustomRankingSummaryAction = PayloadAction<{request: CustomRankingSummaryRequest, response: CustomRankingSummaryResponse}>

export type CustomRankingEntriesRequest = {
  rankingId: string; // 各種ランキングID
  periodIndex: number; // 集計番号
  limit: number; // 取得件数
  offset: number; // 取得オフセット件数
}

export type CustomRankingEntriesResponse = {
  entries: RankingEntry[];
}

export const EmptyCustomRankingEntriesResponse: CustomRankingEntriesResponse = {
  entries: [],
}

export async function sendCustomRankingEntries(
  request: CustomRankingEntriesRequest,
  callback?: (res: CustomRankingEntriesResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<CustomRankingEntriesResponse> {
  return send('customRankingEntries/', request, EmptyCustomRankingEntriesResponse, callback, errorCallback)
}

export const fetchCustomRankingEntries = createAsyncThunk(
  'apiThunk/fetchCustomRankingEntries',
  async (request:CustomRankingEntriesRequest, thunkAPI) => {
    const response = await sendCustomRankingEntries(request);
    return {request, response}
  }
)

export type fetchCustomRankingEntriesAction = PayloadAction<{request: CustomRankingEntriesRequest, response: CustomRankingEntriesResponse}>

export type GraphGoalsRequest = {
}

export type GraphGoalsResponse = {
  グラフ目標?: GraphGoalsDto;
}

export const EmptyGraphGoalsResponse: GraphGoalsResponse = {
  グラフ目標: undefined,
}

export async function sendGraphGoals(
  request: GraphGoalsRequest,
  callback?: (res: GraphGoalsResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GraphGoalsResponse> {
  return send('graphGoals/', request, EmptyGraphGoalsResponse, callback, errorCallback)
}

export const fetchGraphGoals = createAsyncThunk(
  'apiThunk/fetchGraphGoals',
  async (request:GraphGoalsRequest, thunkAPI) => {
    const response = await sendGraphGoals(request);
    return {request, response}
  }
)

export type fetchGraphGoalsAction = PayloadAction<{request: GraphGoalsRequest, response: GraphGoalsResponse}>

export type GraphDataRequest = {
  periodType: GraphPeriodType;
}

export type GraphDataResponse = {
  entries: GraphDataEntry[];
}

export const EmptyGraphDataResponse: GraphDataResponse = {
  entries: [],
}

export async function sendGraphData(
  request: GraphDataRequest,
  callback?: (res: GraphDataResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GraphDataResponse> {
  return send('graphData/', request, EmptyGraphDataResponse, callback, errorCallback)
}

export const fetchGraphData = createAsyncThunk(
  'apiThunk/fetchGraphData',
  async (request:GraphDataRequest, thunkAPI) => {
    const response = await sendGraphData(request);
    return {request, response}
  }
)

export type fetchGraphDataAction = PayloadAction<{request: GraphDataRequest, response: GraphDataResponse}>

export type CalendarRequest = {
  year: number; // 年
  month: number; // 月 1~12
}

export type CalendarResponse = {
  result: string; // OK or NG
  dates: CalendarDate[];
}

export const EmptyCalendarResponse: CalendarResponse = {
  result: '',
  dates: [],
}

export async function sendCalendar(
  request: CalendarRequest,
  callback?: (res: CalendarResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<CalendarResponse> {
  return send('calendar/', request, EmptyCalendarResponse, callback, errorCallback)
}

export const fetchCalendar = createAsyncThunk(
  'apiThunk/fetchCalendar',
  async (request:CalendarRequest, thunkAPI) => {
    const response = await sendCalendar(request);
    return {request, response}
  }
)

export type fetchCalendarAction = PayloadAction<{request: CalendarRequest, response: CalendarResponse}>

export type ScoreDetailMonthlyRequest = {
  year: number; // 年
  month: number; // 月 1~12
}

export type ScoreDetailMonthlyResponse = {
  type: string,
  出勤日数: number;
  出勤時間: number;
  保証給: number;
  実績給: number;
  指名バック: number;
  同伴バック: number;
  減給: number;
  損益金: number;
  源泉徴収: number;
  厚生費: number;
  備品代: number;
  前払金: number;
  差引支給額: number;
  月次獲得ポイント総計: number;
  達成率: number;
  指名本数: number;
  同伴本数: number;
  フリー場内指名本数: number;
  その他場内指名本数: number;
  フリー件数: number;
  場内率: number;
  店内ポイントランキング順位: number;
  グループポイントランキング順位: number;
  店内同伴ランキング順位: number;
  グループ同伴ランキング順位: number;
  店舗指名ランキング順位: number;
  グループ指名ランキング順位: number;
  名前: string;
	基本給: number;
	褒章手当: number;
  平均時給: number;
	場内バック: number;
	調整金: number;
	総支給額: number;
	クリーニング: number;
	名刺代: number;
	送迎代: number;
	地代家賃: number;
	貸付返済: number;
	その他: number;
	振込手数料: number;
	備考: string;
	姓: string; // これAPIにもapi_specにもなくない？
	名: string; // これAPIにもapi_specにもなくない？
  計算時給: number;
  消費税不支給分: number;
  税額特例分: number;
  源泉徴収等: number;
}

export const EmptyScoreDetailMonthlyResponse: ScoreDetailMonthlyResponse = {
  type: "",
  出勤日数: 0,
  出勤時間: 0,
  保証給: 0,
  実績給: 0,
  指名バック: 0,
  同伴バック: 0,
  減給: 0,
  損益金: 0,
  源泉徴収: 0,
  厚生費: 0,
  備品代: 0,
  前払金: 0,
  差引支給額: 0,
  月次獲得ポイント総計: 0,
  達成率: 0,
  指名本数: 0,
  同伴本数: 0,
  フリー場内指名本数: 0,
  その他場内指名本数: 0,
  フリー件数: 0,
  場内率: 0,
  店内ポイントランキング順位: 0,
  グループポイントランキング順位: 0,
  店内同伴ランキング順位: 0,
  グループ同伴ランキング順位: 0,
  店舗指名ランキング順位: 0,
  グループ指名ランキング順位: 0,
  名前: "",
	基本給: 0,
	褒章手当: 0,
  平均時給: 0,
	場内バック: 0,
	調整金: 0,
	総支給額: 0,
	クリーニング: 0,
	名刺代: 0,
	送迎代: 0,
	地代家賃: 0,
	貸付返済: 0,
	その他: 0,
	振込手数料: 0,
	備考: "",
  姓: "",
  名: "",
  計算時給: 0,
  消費税不支給分: 0,
  税額特例分: 0,
  源泉徴収等: 0,
}

export async function sendScoreDetailMonthly(
  request: ScoreDetailMonthlyRequest,
  callback?: (res: ScoreDetailMonthlyResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ScoreDetailMonthlyResponse> {
  return send('scoreDetailMonthly/', request, EmptyScoreDetailMonthlyResponse, callback, errorCallback)
}

export const fetchScoreDetailMonthly = createAsyncThunk(
  'apiThunk/fetchScoreDetailMonthly',
  async (request:ScoreDetailMonthlyRequest, thunkAPI) => {
    const response = await sendScoreDetailMonthly(request);
    return {request, response}
  }
)

export type fetchScoreDetailMonthlyAction = PayloadAction<{request: ScoreDetailMonthlyRequest, response: ScoreDetailMonthlyResponse}>

export type ScoreDetailDailyRequest = {
  year: number; // 年
  month: number; // 月 1~12
  day: number; // 日
}

export type ScoreDetailDailyResponse = {
  出退勤時刻: string; // ex. 20:00 ~ 01:00
  出勤形態: string;
  当日獲得ポイント総計: number;
  当日獲得ポイント個別レコードリスト: DailyPointInfo[];
  指名本数: number;
  同伴本数: number;
  フリー場内指名本数: number;
  その他場内指名本数: number;
  フリー件数: number;
  備品代: number;
  前払金: number;
  減給: number;
  損益金: number;
  実績給: number; // 前日締め時点の該当月の実績給
}

export const EmptyScoreDetailDailyResponse: ScoreDetailDailyResponse = {
  出退勤時刻: '',
  出勤形態: '',
  当日獲得ポイント総計: 0,
  当日獲得ポイント個別レコードリスト: [],
  指名本数: 0,
  同伴本数: 0,
  フリー場内指名本数: 0,
  その他場内指名本数: 0,
  フリー件数: 0,
  備品代: 0,
  前払金: 0,
  減給: 0,
  損益金: 0,
  実績給: 0,
}

export async function sendScoreDetailDaily(
  request: ScoreDetailDailyRequest,
  callback?: (res: ScoreDetailDailyResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ScoreDetailDailyResponse> {
  return send('scoreDetailDaily/', request, EmptyScoreDetailDailyResponse, callback, errorCallback)
}

export const fetchScoreDetailDaily = createAsyncThunk(
  'apiThunk/fetchScoreDetailDaily',
  async (request:ScoreDetailDailyRequest, thunkAPI) => {
    const response = await sendScoreDetailDaily(request);
    return {request, response}
  }
)

export type fetchScoreDetailDailyAction = PayloadAction<{request: ScoreDetailDailyRequest, response: ScoreDetailDailyResponse}>

export type ShiftCalendarRequest = {
  year: number; // 年
  month: number; // 月 1~12
  day: number; // 日(0ならば当月と次月内全て返却)
}

export type ShiftCalendarResponse = {
  result: string; // OK or NG
  visitorList: ActorVisitor[]; // お客様予定リスト
  scheduleList: ActorSchedule[]; // 勤務予定リスト
  eventScheduleList: ActorEventSchedule[]; // キャストイベントリスト
}

export const EmptyShiftCalendarResponse: ShiftCalendarResponse = {
  result: '',
  visitorList: [],
  scheduleList: [],
  eventScheduleList: [],
}

export async function sendShiftCalendar(
  request: ShiftCalendarRequest,
  callback?: (res: ShiftCalendarResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ShiftCalendarResponse> {
  return send('shiftCalendar/', request, EmptyShiftCalendarResponse, callback, errorCallback)
}

export const fetchShiftCalendar = createAsyncThunk(
  'apiThunk/fetchShiftCalendar',
  async (request:ShiftCalendarRequest, thunkAPI) => {
    const response = await sendShiftCalendar(request);
    return {request, response}
  }
)

export type fetchShiftCalendarAction = PayloadAction<{request: ShiftCalendarRequest, response: ShiftCalendarResponse}>

export type VipRoomRequest = {
  year: number; // 年
  month: number; // 月 1~12
  day: number; // 日
}

export type VipRoomResponse = {
  vipRoomList: VipRoom[]; // 空きVIPルームリスト
}

export const EmptyVipRoomResponse: VipRoomResponse = {
  vipRoomList: [],
}

export async function sendVipRoom(
  request: VipRoomRequest,
  callback?: (res: VipRoomResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<VipRoomResponse> {
  return send('vipRoom/', request, EmptyVipRoomResponse, callback, errorCallback)
}

export const fetchVipRoom = createAsyncThunk(
  'apiThunk/fetchVipRoom',
  async (request:VipRoomRequest, thunkAPI) => {
    const response = await sendVipRoom(request);
    return {request, response}
  }
)

export type fetchVipRoomAction = PayloadAction<{request: VipRoomRequest, response: VipRoomResponse}>

export type ActorVisitorCreateRequest = {
  date: string; // 年月日 ex.20200731
  title: string; // お客様名
  douhan: boolean; // 同伴フラグ
  vip: string; // VIPルームID
}

export type ActorVisitorCreateResponse = {
  result: string; // OK or NG
  id: string; // ActorVisitor.id
}

export const EmptyActorVisitorCreateResponse: ActorVisitorCreateResponse = {
  result: '',
  id: '',
}

export async function sendActorVisitorCreate(
  request: ActorVisitorCreateRequest,
  callback?: (res: ActorVisitorCreateResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorVisitorCreateResponse> {
  return send('actorVisitorCreate/', request, EmptyActorVisitorCreateResponse, callback, errorCallback)
}

export const fetchActorVisitorCreate = createAsyncThunk(
  'apiThunk/fetchActorVisitorCreate',
  async (request:ActorVisitorCreateRequest, thunkAPI) => {
    const response = await sendActorVisitorCreate(request);
    return {request, response}
  }
)

export type fetchActorVisitorCreateAction = PayloadAction<{request: ActorVisitorCreateRequest, response: ActorVisitorCreateResponse}>

export type ActorVisitorUpdateRequest = {
  id: string; // ActorVisitor.id
  title: string; // お客様名
  douhan: boolean; // 同伴フラグ
  vip: string; // VIPルームID
}

export type ActorVisitorUpdateResponse = {
  result: string; // OK or NG
}

export const EmptyActorVisitorUpdateResponse: ActorVisitorUpdateResponse = {
  result: '',
}

export async function sendActorVisitorUpdate(
  request: ActorVisitorUpdateRequest,
  callback?: (res: ActorVisitorUpdateResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorVisitorUpdateResponse> {
  return send('actorVisitorUpdate/', request, EmptyActorVisitorUpdateResponse, callback, errorCallback)
}

export const fetchActorVisitorUpdate = createAsyncThunk(
  'apiThunk/fetchActorVisitorUpdate',
  async (request:ActorVisitorUpdateRequest, thunkAPI) => {
    const response = await sendActorVisitorUpdate(request);
    return {request, response}
  }
)

export type fetchActorVisitorUpdateAction = PayloadAction<{request: ActorVisitorUpdateRequest, response: ActorVisitorUpdateResponse}>

export type ActorVisitorDeleteRequest = {
  id: string; // ActorVisitor.id
}

export type ActorVisitorDeleteResponse = {
  result: string; // OK or NG
}

export const EmptyActorVisitorDeleteResponse: ActorVisitorDeleteResponse = {
  result: '',
}

export async function sendActorVisitorDelete(
  request: ActorVisitorDeleteRequest,
  callback?: (res: ActorVisitorDeleteResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ActorVisitorDeleteResponse> {
  return send('actorVisitorDelete/', request, EmptyActorVisitorDeleteResponse, callback, errorCallback)
}

export const fetchActorVisitorDelete = createAsyncThunk(
  'apiThunk/fetchActorVisitorDelete',
  async (request:ActorVisitorDeleteRequest, thunkAPI) => {
    const response = await sendActorVisitorDelete(request);
    return {request, response}
  }
)

export type fetchActorVisitorDeleteAction = PayloadAction<{request: ActorVisitorDeleteRequest, response: ActorVisitorDeleteResponse}>

export type ShiftOfferRequest = {
  offerList: ActorScheduleOffer[]; // シフト申請リスト
}

export type ShiftOfferResponse = {
  result: string; // OK or NG
  updatedDates: string[]; // リロード対象年月日 ex.20200731
}

export const EmptyShiftOfferResponse: ShiftOfferResponse = {
  result: '',
  updatedDates: [],
}

export async function sendShiftOffer(
  request: ShiftOfferRequest,
  callback?: (res: ShiftOfferResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<ShiftOfferResponse> {
  return send('shiftOffer/', request, EmptyShiftOfferResponse, callback, errorCallback)
}

export const fetchShiftOffer = createAsyncThunk(
  'apiThunk/fetchShiftOffer',
  async (request:ShiftOfferRequest, thunkAPI) => {
    const response = await sendShiftOffer(request);
    return {request, response}
  }
)

export type fetchShiftOfferAction = PayloadAction<{request: ShiftOfferRequest, response: ShiftOfferResponse}>

export type NotificationListRequest = {
  limit: number;
  offset: number;
  cause: string;
}

export type NotificationListResponse = {
  list: NotificationMessage[];
  count: number; // 全通知数
  rest_count: number; // 未読数
}

export const EmptyNotificationListResponse: NotificationListResponse = {
  list: [],
  count: 0,
  rest_count: 0,
}

export async function sendNotificationList(
  request: NotificationListRequest,
  callback?: (res: NotificationListResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<NotificationListResponse> {
  return send('notificationList/', request, EmptyNotificationListResponse, callback, errorCallback)
}

export const fetchNotificationList = createAsyncThunk(
  'apiThunk/fetchNotificationList',
  async (request:NotificationListRequest, thunkAPI) => {
    const response = await sendNotificationList(request);
    return {request, response}
  }
)

export type fetchNotificationListAction = PayloadAction<{request: NotificationListRequest, response: NotificationListResponse}>

export type NotificationViewRequest = {
  id: string; // 通知ID(actor_notification_message_target.id)
}

export type NotificationViewResponse = {
  result: string; // OK or NG
  notification?: NotificationMessage; // 更新後データ
  rest_count: number; // 未読数
}

export const EmptyNotificationViewResponse: NotificationViewResponse = {
  result: '',
  notification: undefined,
  rest_count: 0,
}

export async function sendNotificationView(
  request: NotificationViewRequest,
  callback?: (res: NotificationViewResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<NotificationViewResponse> {
  return send('notificationView/', request, EmptyNotificationViewResponse, callback, errorCallback)
}

export const fetchNotificationView = createAsyncThunk(
  'apiThunk/fetchNotificationView',
  async (request:NotificationViewRequest, thunkAPI) => {
    const response = await sendNotificationView(request);
    return {request, response}
  }
)

export type fetchNotificationViewAction = PayloadAction<{request: NotificationViewRequest, response: NotificationViewResponse}>

export type NotificationConfirmRequest = {
  id: string; // 通知ID(actor_notification_message_target.id)
  apply: boolean; // ユーザからのアクション(はい・いいえ)
}

export type NotificationConfirmResponse = {
  result: string; // OK or NG
  notification?: NotificationMessage; // 更新後データ
  rest_count: number; // 未読数
}

export const EmptyNotificationConfirmResponse: NotificationConfirmResponse = {
  result: '',
  notification: undefined,
  rest_count: 0,
}

export async function sendNotificationConfirm(
  request: NotificationConfirmRequest,
  callback?: (res: NotificationConfirmResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<NotificationConfirmResponse> {
  return send('notificationConfirm/', request, EmptyNotificationConfirmResponse, callback, errorCallback)
}

export const fetchNotificationConfirm = createAsyncThunk(
  'apiThunk/fetchNotificationConfirm',
  async (request:NotificationConfirmRequest, thunkAPI) => {
    const response = await sendNotificationConfirm(request);
    return {request, response}
  }
)

export type fetchNotificationConfirmAction = PayloadAction<{request: NotificationConfirmRequest, response: NotificationConfirmResponse}>

export type MyPageRequest = {
}

export type MyPageResponse = {
  ランクID: number; // 現在のランクID(1~4)
  ランク名: string; // 現在のランク名
  ランク色: string; // 現在のランク色 RGB6桁(ex. FFFFFF)
  BP: number; // 現在のBP
  次のランクまでのBP: number; // 次のランクまでのBP
  次のランクまでの達成率: number; // 次のランクまでの達成率
  allBadgeList: ActorBadge[]; // 全獲得バッジ(古->新)
}

export const EmptyMyPageResponse: MyPageResponse = {
  ランクID: 0,
  ランク名: '',
  ランク色: '',
  BP: 0,
  次のランクまでのBP: 0,
  次のランクまでの達成率: 0,
  allBadgeList: [],
}

export async function sendMyPage(
  request: MyPageRequest,
  callback?: (res: MyPageResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<MyPageResponse> {
  return send('myPage/', request, EmptyMyPageResponse, callback, errorCallback)
}

export const fetchMyPage = createAsyncThunk(
  'apiThunk/fetchMyPage',
  async (request:MyPageRequest, thunkAPI) => {
    const response = await sendMyPage(request);
    return {request, response}
  }
)

export type fetchMyPageAction = PayloadAction<{request: MyPageRequest, response: MyPageResponse}>

export type GoalsConfigRequest = {
  year: number; // 年
  month: number; // 月 1~12
}

export type GoalsConfigResponse = {
  result: string; // OK or NG(データ取得失敗時)
  グラフ目標?: GraphGoalsDto;
  成績最低値: number; // ポイント or 円
}

export const EmptyGoalsConfigResponse: GoalsConfigResponse = {
  result: '',
  グラフ目標: undefined,
  成績最低値: 0,
}

export async function sendGoalsConfig(
  request: GoalsConfigRequest,
  callback?: (res: GoalsConfigResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GoalsConfigResponse> {
  return send('goalsConfig/', request, EmptyGoalsConfigResponse, callback, errorCallback)
}

export const fetchGoalsConfig = createAsyncThunk(
  'apiThunk/fetchGoalsConfig',
  async (request:GoalsConfigRequest, thunkAPI) => {
    const response = await sendGoalsConfig(request);
    return {request, response}
  }
)

export type fetchGoalsConfigAction = PayloadAction<{request: GoalsConfigRequest, response: GoalsConfigResponse}>

export type GoalsConfigUpdateRequest = {
  year: number; // 年
  month: number; // 月 1~12
  グラフ目標?: GraphGoalsDto;
  大目標: string;
}

export type GoalsConfigUpdateResponse = {
  result: string; // OK or NG
}

export const EmptyGoalsConfigUpdateResponse: GoalsConfigUpdateResponse = {
  result: '',
}

export async function sendGoalsConfigUpdate(
  request: GoalsConfigUpdateRequest,
  callback?: (res: GoalsConfigUpdateResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GoalsConfigUpdateResponse> {
  return send('goalsConfigUpdate/', request, EmptyGoalsConfigUpdateResponse, callback, errorCallback)
}

export const fetchGoalsConfigUpdate = createAsyncThunk(
  'apiThunk/fetchGoalsConfigUpdate',
  async (request:GoalsConfigUpdateRequest, thunkAPI) => {
    const response = await sendGoalsConfigUpdate(request);
    return {request, response}
  }
)

export type fetchGoalsConfigUpdateAction = PayloadAction<{request: GoalsConfigUpdateRequest, response: GoalsConfigUpdateResponse}>

export type GachaInfoRequest = {
}

export type GachaInfoResponse = {
  info?: GachaInfo;
}

export const EmptyGachaInfoResponse: GachaInfoResponse = {
  info: undefined,
}

export async function sendGachaInfo(
  request: GachaInfoRequest,
  callback?: (res: GachaInfoResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaInfoResponse> {
  return send('gachaInfo/', request, EmptyGachaInfoResponse, callback, errorCallback)
}

export const fetchGachaInfo = createAsyncThunk(
  'apiThunk/fetchGachaInfo',
  async (request:GachaInfoRequest, thunkAPI) => {
    const response = await sendGachaInfo(request);
    return {request, response}
  }
)

export type fetchGachaInfoAction = PayloadAction<{request: GachaInfoRequest, response: GachaInfoResponse}>

export type GachaLotteryNormalRequest = {
}

export type GachaLotteryNormalResponse = {
  result: string; // OK or NG
  info?: GachaInfo;
  初回抽選結果?: GachaFirstResult;
  復活抽選結果?: GachaRevivalResult;
  当選景品?: GachaLotteryItemDto;
}

export const EmptyGachaLotteryNormalResponse: GachaLotteryNormalResponse = {
  result: '',
  info: undefined,
  初回抽選結果: undefined,
  復活抽選結果: undefined,
  当選景品: undefined,
}

export async function sendGachaLotteryNormal(
  request: GachaLotteryNormalRequest,
  callback?: (res: GachaLotteryNormalResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaLotteryNormalResponse> {
  return send('gachaLotteryNormal/', request, EmptyGachaLotteryNormalResponse, callback, errorCallback)
}

export const fetchGachaLotteryNormal = createAsyncThunk(
  'apiThunk/fetchGachaLotteryNormal',
  async (request:GachaLotteryNormalRequest, thunkAPI) => {
    const response = await sendGachaLotteryNormal(request);
    return {request, response}
  }
)

export type fetchGachaLotteryNormalAction = PayloadAction<{request: GachaLotteryNormalRequest, response: GachaLotteryNormalResponse}>

export type GachaExchangeItemListRequest = {
  limit: number;
  offset: number;
}

export type GachaExchangeItemListResponse = {
  list: GachaExchangeItemDto[];
  count: number;
}

export const EmptyGachaExchangeItemListResponse: GachaExchangeItemListResponse = {
  list: [],
  count: 0,
}

export async function sendGachaExchangeItemList(
  request: GachaExchangeItemListRequest,
  callback?: (res: GachaExchangeItemListResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaExchangeItemListResponse> {
  return send('gachaExchangeItemList/', request, EmptyGachaExchangeItemListResponse, callback, errorCallback)
}

export const fetchGachaExchangeItemList = createAsyncThunk(
  'apiThunk/fetchGachaExchangeItemList',
  async (request:GachaExchangeItemListRequest, thunkAPI) => {
    const response = await sendGachaExchangeItemList(request);
    return {request, response}
  }
)

export type fetchGachaExchangeItemListAction = PayloadAction<{request: GachaExchangeItemListRequest, response: GachaExchangeItemListResponse}>

export type GachaExchangeRequest = {
  id: string;
  個数: number;
}

export type GachaExchangeResponse = {
  result: string; // OK or NG
  info?: GachaInfo;
}

export const EmptyGachaExchangeResponse: GachaExchangeResponse = {
  result: '',
  info: undefined,
}

export async function sendGachaExchange(
  request: GachaExchangeRequest,
  callback?: (res: GachaExchangeResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaExchangeResponse> {
  return send('gachaExchange/', request, EmptyGachaExchangeResponse, callback, errorCallback)
}

export const fetchGachaExchange = createAsyncThunk(
  'apiThunk/fetchGachaExchange',
  async (request:GachaExchangeRequest, thunkAPI) => {
    const response = await sendGachaExchange(request);
    return {request, response}
  }
)

export type fetchGachaExchangeAction = PayloadAction<{request: GachaExchangeRequest, response: GachaExchangeResponse}>

export type GachaExchangeHistoryListRequest = {
  limit: number;
  offset: number;
}

export type GachaExchangeHistoryListResponse = {
  list: GachaExchangeHistoryDto[];
  count: number;
}

export const EmptyGachaExchangeHistoryListResponse: GachaExchangeHistoryListResponse = {
  list: [],
  count: 0,
}

export async function sendGachaExchangeHistoryList(
  request: GachaExchangeHistoryListRequest,
  callback?: (res: GachaExchangeHistoryListResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaExchangeHistoryListResponse> {
  return send('gachaExchangeHistoryList/', request, EmptyGachaExchangeHistoryListResponse, callback, errorCallback)
}

export const fetchGachaExchangeHistoryList = createAsyncThunk(
  'apiThunk/fetchGachaExchangeHistoryList',
  async (request:GachaExchangeHistoryListRequest, thunkAPI) => {
    const response = await sendGachaExchangeHistoryList(request);
    return {request, response}
  }
)

export type fetchGachaExchangeHistoryListAction = PayloadAction<{request: GachaExchangeHistoryListRequest, response: GachaExchangeHistoryListResponse}>

export type GachaLotteryHistoryListRequest = {
  limit: number;
  offset: number;
}

export type GachaLotteryHistoryListResponse = {
  list: GachaLotteryHistoryDto[];
  count: number;
}

export const EmptyGachaLotteryHistoryListResponse: GachaLotteryHistoryListResponse = {
  list: [],
  count: 0,
}

export async function sendGachaLotteryHistoryList(
  request: GachaLotteryHistoryListRequest,
  callback?: (res: GachaLotteryHistoryListResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<GachaLotteryHistoryListResponse> {
  return send('gachaLotteryHistoryList/', request, EmptyGachaLotteryHistoryListResponse, callback, errorCallback)
}

export const fetchGachaLotteryHistoryList = createAsyncThunk(
  'apiThunk/fetchGachaLotteryHistoryList',
  async (request:GachaLotteryHistoryListRequest, thunkAPI) => {
    const response = await sendGachaLotteryHistoryList(request);
    return {request, response}
  }
)

export type fetchGachaLotteryHistoryListAction = PayloadAction<{request: GachaLotteryHistoryListRequest, response: GachaLotteryHistoryListResponse}>

export type EffectListRequest = {
}

export type EffectListResponse = {
  effects: EffectDto[];
}

export const EmptyEffectListResponse: EffectListResponse = {
  effects: [],
}

export async function sendEffectList(
  request: EffectListRequest,
  callback?: (res: EffectListResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<EffectListResponse> {
  return send('effectList/', request, EmptyEffectListResponse, callback, errorCallback)
}

export const fetchEffectList = createAsyncThunk(
  'apiThunk/fetchEffectList',
  async (request:EffectListRequest, thunkAPI) => {
    const response = await sendEffectList(request);
    return {request, response}
  }
)

export type fetchEffectListAction = PayloadAction<{request: EffectListRequest, response: EffectListResponse}>

export type EffectViewRequest = {
  ids: string[];
}

export type EffectViewResponse = {
}

export const EmptyEffectViewResponse: EffectViewResponse = {
}

export async function sendEffectView(
  request: EffectViewRequest,
  callback?: (res: EffectViewResponse) => void,
  errorCallback?: (error: AxiosError) => void,
): Promise<EffectViewResponse> {
  return send('effectView/', request, EmptyEffectViewResponse, callback, errorCallback)
}

export const fetchEffectView = createAsyncThunk(
  'apiThunk/fetchEffectView',
  async (request:EffectViewRequest, thunkAPI) => {
    const response = await sendEffectView(request);
    return {request, response}
  }
)

export type fetchEffectViewAction = PayloadAction<{request: EffectViewRequest, response: EffectViewResponse}>
