import { ThemeOptions } from '@material-ui/core/styles/createMuiTheme';

import { consts } from 'consts';

export const theme: ThemeOptions = {
  palette: {
    // type: 'dark', // ダークテーマ
    // primary: green,
    primary: {
      main: '#dc3a70', // ヘッダー背景色
      light: '#E8447D', // テキスト強調色
    },
    background: {
      default: '#fff',
    },
    text: {
      primary: '#4D4E4C', // メインテキスト色
      hint: '#898A89', // 中間色
      secondary: '#9FA0A0', // 薄字テキスト色
      disabled: '#AFB0B0', // 最薄
    },
  },
  typography: {
    fontFamily: [
      // "Helvetica Neue",
      // 'HelveticaNeue',
      // 'Questrial',
      // 'Muli',
      // 'Century Gothic',
      // 'Avenir-MediumOblique',
      // 'Avenir-Light',
      'Roboto',
      // 'BlinkMacSystemFont',
      // "Segoe UI",
      // 'Verdana',
      // 'Meiryo',
      'sans-serif',
    ].join(','),
    fontSize: 12,
    h1: {
      fontSize: '1.75rem',
    },
    h2: {
      fontSize: '1.5rem',
    },
    h3: {
      fontSize: '1.25rem',
    },
    h4: {
      fontSize: '1.125rem',
    },
    h5: {
      fontSize: '1rem',
    },
    h6: {
      fontSize: '1rem',
    },
  },
  overrides: {
    MuiToolbar: {
      dense: {
        height: consts.headerSize,
      },
      gutters: {
        marginTop: 0,
      },
    },
    MuiBottomNavigation: {
      root: {
        height: consts.footerSize,
        width: '100%',
        position: 'fixed',
        bottom: 0,
        backgroundColor: '#eff1f2',
        paddingLeft: 12,
        paddingRight: 12,
        paddingBottom: 0,
      },
    },
    MuiBottomNavigationAction: {
      root: {
        minWidth: 60,
        padding: '14px 0px 8px',
        '&$selected': {
          paddingTop: 14,
        },
      },
      label: {
        fontSize: 10,
        fontWeight: 'bold',
        paddingTop: 3,
        '&$selected': {
          fontSize: 10,
        },
      },
    },
    MuiDialog: {
      paper: {
        marginTop: 45,
        marginLeft: 0,
        marginRight: 0,
        marginBottom: 0,
      },
      paperWidthFalse: {
        // maxWidth: '100%',
        maxWidth: 345,
      },
    },

    MuiInput: {
      // Name of the component ⚛️ / style sheet
      // root: { // Name of the rule
      //   color: "orange",
      //   "&$focused": { // increase the specificity for the pseudo class
      //     color: "blue",
      //   }
      // },
      underline: {
        '&:before': {
          transition: 'none',
        },
        '&:hover:not($disabled):before': {
          transition: 'none',
        },
        '&:after': {
          transition: 'none',
        },
        '&:hover:not($disabled):after': {
          transition: 'none',
        },
      },
    },
  },
};

export type ScreenInfo = {
  supportedFuncName: string;
  viewportScale: number;
  viewportWidth: number;
  top: number;
  bottom: number;
  left: number;
  right: number;
};

// Header, Footerにviewportでのscale量を適用 safe-areaはscale前のpx値を設定
export function generateAdjustedThemeChunk(screenInfo: ScreenInfo) {
  return {
    overrides: {
      MuiToolbar: {
        gutters: {
          marginTop: getTopSafeAreaStyle(screenInfo)
        },
      },
      MuiBottomNavigation: {
        root: {
          height:
            screenInfo.supportedFuncName === ''
              ? consts.footerSize + screenInfo.top / screenInfo.viewportScale
              : `calc(${consts.footerSize}px + ${screenInfo.supportedFuncName}(safe-area-inset-bottom) / ${screenInfo.viewportScale})`,
          paddingBottom:
            screenInfo.supportedFuncName === ''
              ? screenInfo.bottom / screenInfo.viewportScale
              : `calc(${screenInfo.supportedFuncName}(safe-area-inset-bottom) / ${screenInfo.viewportScale})`,
        },
      },
    },
  };
}

function getTopSafeAreaStyle(screenInfo: ScreenInfo) : string|number {
  return screenInfo.supportedFuncName === ''
    ? screenInfo.top / screenInfo.viewportScale
    : `calc(${screenInfo.supportedFuncName}(safe-area-inset-top) / ${screenInfo.viewportScale})`;
}

export function getHeaderHeightStyle(screenInfo: ScreenInfo) : string|number {
  return screenInfo.supportedFuncName === ''
    ? screenInfo.top / screenInfo.viewportScale + consts.headerSize
    : `calc(${screenInfo.supportedFuncName}(safe-area-inset-top) / ${screenInfo.viewportScale} + ${consts.headerSize}px)`;
}
