import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

import { callNativePlayGachaMovies } from '../lib/nativeBridge';
import { StandardButton } from '../components/StandardButton';
import {BGM_VOL_BASE, BGM_VOL_MIN, GachaArg, getMovieUrl, getResourceUrl} from '../lib/gachaLib';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      width: 300,
      height: '100%',
      textAlign: 'center',
      margin: 'auto',
    },
  }),
);

// const arg:GachaArg = {
//   bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
//   // message: "",
//   message: "1等\nビューティーコイン30000枚",
//   items: [
//     // 予告
//     { url:getMovieUrl('c001_05'), loop:false, bgmVol:BGM_VOL_BASE},
//     // ドレス
//     { url:getMovieUrl('c002_03'), loop:false, bgmVol:BGM_VOL_BASE},
//     { url:getMovieUrl('c003_03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // お客様
//     { url:getMovieUrl('c004_b_03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // シャンパン
//     { url:getMovieUrl('c005_03_s03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // ループ
//     { url:getMovieUrl('c006_02_s03'), loop:true, bgmVol:BGM_VOL_BASE},
//     // 失敗
//     { url:getMovieUrl('c006_04_s03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // 復活・お客様
//     { url:getMovieUrl('c007_1b_03_s03'), loop:false, bgmVol:BGM_VOL_MIN},
//     // 復活・シャンパン
//     { url:getMovieUrl('c005b_03_s03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // ループ
//     { url:getMovieUrl('c006_02_s03'), loop:true, bgmVol:BGM_VOL_BASE},
//     // 成功
//     { url:getMovieUrl('c006_04_s03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // 成功結果
//     { url:getMovieUrl('c007_2_t03_s03'), loop:false, bgmVol:BGM_VOL_BASE},
//     // 宝箱
//     { url:getMovieUrl('c009_r03_03'), loop:false, bgmVol:BGM_VOL_BASE},
//   ],
// }

// 復活サンプル
const arg1: GachaArg = {
  bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
  title: '二等',
  message: 'ビューティーコイン\n10000枚',
  imageUrl: getResourceUrl('f.png'),
  items: [
    // 予告
    { url: getMovieUrl('c001_01'), loop: false, bgmVol: BGM_VOL_BASE },
    // ドレス + 振り返り
    { url: getMovieUrl('c002_c003_01_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
    // お客様
    { url: getMovieUrl('c004_b_01'), loop: false, bgmVol: BGM_VOL_BASE },
    // シャンパン
    { url: getMovieUrl('c005_01_s01'), loop: false, bgmVol: BGM_VOL_BASE },
    // ループ
    { url: getMovieUrl('c006_02_s01'), loop: true, bgmVol: BGM_VOL_BASE },
    // 失敗
    { url: getMovieUrl('c006_04_s01'), loop: false, bgmVol: BGM_VOL_BASE },
    // 復活・お客様
    { url: getMovieUrl('c007_1b_01_s01'), loop: false, bgmVol: BGM_VOL_MIN },
    // 復活・シャンパン
    { url: getMovieUrl('c005b_03_s01'), loop: false, bgmVol: BGM_VOL_BASE },
    // ループ
    { url: getMovieUrl('c006_02_s01'), loop: true, bgmVol: BGM_VOL_BASE },
    // 成功
    { url: getMovieUrl('c006_03_s01'), loop: false, bgmVol: BGM_VOL_BASE },
    // 成功結果
    { url: getMovieUrl('c007_2_t02_s02'), loop: false, bgmVol: BGM_VOL_BASE },
    // 宝箱
    { url: getMovieUrl('c009_r02_02'), loop: false, bgmVol: BGM_VOL_BASE },
  ],
};

// 熱いサンプル
const arg2: GachaArg = {
  bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
  title: '三等',
  message: 'ビューティーコイン\n5000枚',
  imageUrl: getResourceUrl('f.png'),
  items: [
    // 予告
    { url: getMovieUrl('c001_02'), loop: false, bgmVol: BGM_VOL_BASE },
    // ドレス + 振り返り
    { url: getMovieUrl('c002_c003_01_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
    // お客様
    { url: getMovieUrl('c004_a_01'), loop: false, bgmVol: BGM_VOL_BASE },
    // シャンパン
    { url: getMovieUrl('c005_01_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // ループ
    { url: getMovieUrl('c006_02_s03'), loop: true, bgmVol: BGM_VOL_BASE },
    // シャンパンあける
    { url: getMovieUrl('c006_03_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // 成功結果
    { url: getMovieUrl('c007_2_t03_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // 宝箱
    { url: getMovieUrl('c009_r03_03'), loop: false, bgmVol: BGM_VOL_BASE },
  ],
};

// はずれサンプル
const arg3: GachaArg = {
  bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
  title: '',
  message: '',
  imageUrl: '',
  items: [
    // ドレス + 振り返り
    { url: getMovieUrl('c002_c003_02_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
    // お客様
    { url: getMovieUrl('c004_b_02'), loop: false, bgmVol: BGM_VOL_BASE },
    // シャンパン（できれば魚群なしで　c005_02_s01_g0）
    // { url:getMovieUrl('c005_02_s01'), loop:false, bgmVol:BGM_VOL_BASE},
    { url: getMovieUrl('c005_02_s01_g0'), loop: false, bgmVol: BGM_VOL_BASE },
    // ループ
    { url: getMovieUrl('c006_02_s01'), loop: true, bgmVol: BGM_VOL_BASE },
    // シャンパンあける
    { url: getMovieUrl('c006_04_s01'), loop: false, bgmVol: BGM_VOL_BASE },
    // { url:getMovieUrl('c006_03_s03'), loop:false, bgmVol:BGM_VOL_BASE},
    // 成功結果
    { url: getMovieUrl('c007_1_02_s01'), loop: false, bgmVol: BGM_VOL_MIN },
  ],
};

// レインボー確定サンプル
const arg4: GachaArg = {
  bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
  title: '一等',
  message: 'ビューティーコイン\n30000枚',
  imageUrl: getResourceUrl('f.png'),
  items: [
    // 予告
    { url: getMovieUrl('c001_05'), loop: false, bgmVol: BGM_VOL_BASE },
    // ドレス + 振り返り
    { url: getMovieUrl('c002_c003_01_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
    // お客様
    { url: getMovieUrl('c004_b_03'), loop: false, bgmVol: BGM_VOL_BASE },
    // シャンパン
    { url: getMovieUrl('c005_03_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // ループ
    { url: getMovieUrl('c006_02_s03'), loop: true, bgmVol: BGM_VOL_BASE },
    // シャンパンあける
    { url: getMovieUrl('c006_03_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // 成功結果
    { url: getMovieUrl('c007_2_t03_s03'), loop: false, bgmVol: BGM_VOL_BASE },
    // 宝箱
    { url: getMovieUrl('c009_r03_03'), loop: false, bgmVol: BGM_VOL_BASE },
  ],
};

// // 結合動画比較
// const arg5: GachaArg = {
//   bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
//   title: '',
//   message: '',
//   imageUrl: '',
//   items: [
//     // ドレス + 振り返り
//     { url: getMovieUrl('c002_c003_02'), loop: false, bgmVol: BGM_VOL_BASE },
//     { url: getMovieUrl('c002_c003_01fc'), loop: false, bgmVol: BGM_VOL_BASE },
//     { url: getMovieUrl('c002_c003_01fc18'), loop: false, bgmVol: BGM_VOL_BASE },
//     { url: getMovieUrl('c002_c003_01_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
//
//
//     // お客様
//     { url: getMovieUrl('c004_b_02'), loop: false, bgmVol: BGM_VOL_BASE },
//   ],
// };

export const GachaMovieCheckPage: React.FC = () => {
  const classes = useStyles();
  // useEffect(()=>{callNativePlayGachaMovies({
  //   // debug: true,
  //   bgm: getResourceUrl('baruserona-gacha_BGM_Master.mp3'),
  //   title: '二等',
  //   message: 'ハーブティーセレクション\nGRAN PRO.',
  //   // message: '美容ポイント美容ポイント美容ポイント美容ポイント美容ポイント\n30000Pt',
  //   // message: '美容ポイント\n30000Pt',
  //   // message: '美容ポイント',
  //   imageUrl: 'http://192.168.1.100:8080/abelia/api/castapp/photo/d6303637-aad7-40ef-b644-ca25fb4570cb',
  //   items: [
  //     { url: getMovieUrl('c002_c003_01_fc24'), loop: false, bgmVol: BGM_VOL_BASE },
  //     { url: getMovieUrl('c009_r03_03'), loop: false, bgmVol: BGM_VOL_BASE },
  //   ],
  // })}, [])
  return (
    <>
      <div className={classNames(classes.root, classes.verticalCenter)}>
        <StandardButton size='large' onClick={() => callNativePlayGachaMovies(arg2)}>
          熱いサンプル
        </StandardButton>
        <br />
        <br />
        <StandardButton size='large' onClick={() => callNativePlayGachaMovies(arg4)}>
          レインボー確定
        </StandardButton>
        <br />
        <br />
        <StandardButton size='large' onClick={() => callNativePlayGachaMovies(arg3)}>
          はずれサンプル
        </StandardButton>
        <br />
        <br />
        <StandardButton size='large' onClick={() => callNativePlayGachaMovies(arg1)}>
          復活サンプル
        </StandardButton>
        {/*<br />*/}
        {/*<br />*/}
        {/*<StandardButton size='large' onClick={() => callNativePlayGachaMovies(arg5)}>*/}
        {/*  結合動画比較(old, fc, fc18, _fc24)*/}
        {/*</StandardButton>*/}
      </div>
    </>
  );
};
