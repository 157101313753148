import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const smallSize = 27;
const mediumSize = 32;
const largeSize = 36;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    common: {
      display: 'inline-block',
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      backgroundColor: theme.palette.primary.main,
      textAlign: 'center',
      whiteSpace: 'nowrap',
    },
    small: {
      // fontWeight: theme.typography.fontWeightRegular,
      height: smallSize,
      borderRadius: smallSize / 2,
      paddingLeft: smallSize / 2,
      paddingRight: smallSize / 2,
      fontSize: 11,
    },
    medium: {
      height: mediumSize,
      borderRadius: mediumSize / 2,
      paddingLeft: mediumSize / 2,
      paddingRight: mediumSize / 2,
      fontSize: 14,
    },
    large: {
      height: largeSize,
      borderRadius: largeSize / 2,
      paddingLeft: largeSize / 2,
      paddingRight: largeSize / 2,
      paddingTop: 1,
      fontSize: 16,
    },
    fullWidth: {
      display: 'block',
      width: '100%',
    },
    disabled: {
      opacity: 0.26,
    },
    outlined: {
      color: theme.palette.primary.main,
      backgroundColor: 'white',
      border: 'solid 1px ' + theme.palette.primary.main,
    },
    grey: {
      backgroundColor: '#b4b4b4',
    },
    transparent: {
      backgroundColor: 'transparent',
      border: 'solid 1px white',
    },
  }),
);
interface Props extends React.HTMLAttributes<HTMLDivElement> {
  children?: React.ReactNode;
  onClick?: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void;
  fullWidth?: boolean;
  size?: 'small' | 'medium' | 'large';
  disabled?: boolean;
  appearance?: 'default' | 'outlined' | 'grey' | 'transparent';
}

export const StandardButton = (props: Props) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(
        classes.common,
        { [classes.small]: props.size && props.size === 'small' },
        { [classes.medium]: !props.size || props.size === 'medium' },
        { [classes.large]: props.size && props.size === 'large' },
        { [classes.outlined]: props.appearance && props.appearance === 'outlined' },
        { [classes.grey]: props.appearance && props.appearance === 'grey' },
        { [classes.transparent]: props.appearance && props.appearance === 'transparent' },
        { [classes.fullWidth]: props.fullWidth },
        { [classes.disabled]: props.disabled },
        props.className,
      )}
      style={props.style}
      onClick={(e) => {
        if (props.onClick && !props.disabled) props.onClick(e);
      }}
    >
      <div className={classes.verticalCenter}>
        <span>{props.children}</span>
      </div>
    </div>
  );
};
