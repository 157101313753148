import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { fetchHome, fetchHomeAction } from '../lib/api';

import { RootState } from './store';

interface AuthState {
  isAuthenticated: boolean;
}

const initialState: AuthState = {
  isAuthenticated: false,
};

export const homeSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setIsAuthenticated: (state, action: PayloadAction<boolean>) => {
      state.isAuthenticated = action.payload;
    },
  },
  extraReducers: {
    [fetchHome.fulfilled.toString()]: (state, action: fetchHomeAction) => {
      state.isAuthenticated = true;
    },
    // [fetchHome.rejected.toString()]: (state, action: fetchHomeAction) => {
    //   error.response.status === 401
    //   state.isAuthenticated = false;
    // },
  },
});

export const { setIsAuthenticated } = homeSlice.actions;
export const selectAuth = (state: RootState) => state.auth;
export default homeSlice.reducer;
