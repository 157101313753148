import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { EffectDetail, effectImageHeight, getNumBy共通小計Str, useTenpoPointUnitTypeLocal } from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';
import {EffectType, EffectTypeValue} from "../../lib/api";

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  shopImage:{
    objectFit: 'contain',
    height: effectImageHeight(74),
    top: effectImageHeight(600),
  },
  monthImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(376),
    height: effectImageHeight(87),
    textAlign: 'center',
  },
  yearImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(386),
    height: effectImageHeight(73),
    textAlign: 'center',
  },
  smallValueImageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(986),
    height: effectImageHeight(68),
    left: 7.5,
  },
  middleValueImageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(1129),
    height: effectImageHeight(124),
    left: 12,
  },
  bigValueImageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(1120),
    height: effectImageHeight(142),
    left: 12,
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
  },
  middleImageList:{
    marginLeft: -3.5,
  },
  bigImageList:{
    marginLeft: -4,
  },
}));

export function getEffectSheetType6PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}
const numberFormat = new Intl.NumberFormat();

function getCalendarPathes(monthly: boolean, param: string): string[] {
  if(monthly) return [assetPath(`img/effect/barcelona_record/month/zetaisu_${param}.png`)]
  let pathes: string[] = param.split('').map((s) => assetPath(`img/effect/barcelona_record/year/ba_pt_year_${s}.png`));
  pathes.push(assetPath(`img/effect/barcelona_record/year/ba_pt_year.png`))
  return pathes;
}
function getUnitPath(pointUnit: string, sheetType: EffectType): string {
  if(sheetType === EffectTypeValue.同伴数レコード_店舗_年間 ||
    sheetType === EffectTypeValue.同伴数レコード_店舗_月間 ||
    sheetType === EffectTypeValue.同伴数レコード_グループ_年間 ||
    sheetType === EffectTypeValue.同伴数レコード_グループ_月間
  ){
    return assetPath(`img/effect/barcelona_record/point/zi_ken.png`)
  }
  if(sheetType === EffectTypeValue.指名数レコード_店舗_年間 ||
    sheetType === EffectTypeValue.指名数レコード_店舗_月間 ||
    sheetType === EffectTypeValue.指名数レコード_グループ_年間 ||
    sheetType === EffectTypeValue.指名数レコード_グループ_月間
  ){
    return assetPath(`img/effect/barcelona_record/point/zi_hon.png`)
  }
  return assetPath(`img/effect/barcelona_record/point/zi_${pointUnit}.png`)
}
function getSmallUnitPath(pointUnit: string, sheetType: EffectType): string {
  if(sheetType === EffectTypeValue.同伴数レコード_店舗_年間 ||
    sheetType === EffectTypeValue.同伴数レコード_店舗_月間 ||
    sheetType === EffectTypeValue.同伴数レコード_グループ_年間 ||
    sheetType === EffectTypeValue.同伴数レコード_グループ_月間
  ){
    return assetPath(`img/effect/barcelona_record/point_small/zi_small_ken.png`)
  }
  if(sheetType === EffectTypeValue.指名数レコード_店舗_年間 ||
    sheetType === EffectTypeValue.指名数レコード_店舗_月間 ||
    sheetType === EffectTypeValue.指名数レコード_グループ_年間 ||
    sheetType === EffectTypeValue.指名数レコード_グループ_月間
  ){
    return assetPath(`img/effect/barcelona_record/point_small/zi_small_hon.png`)
  }
  return assetPath(`img/effect/barcelona_record/point_small/zi_small_${pointUnit}.png`)
}

function isNumberFormat(sheetType: EffectType): boolean {
  if(sheetType === EffectTypeValue.ポイントレコード_店舗_年間 ||
    sheetType === EffectTypeValue.ポイントレコード_店舗_月間 ||
    sheetType === EffectTypeValue.ポイントレコード_グループ_年間 ||
    sheetType === EffectTypeValue.ポイントレコード_グループ_月間
  ){
    return true
  }
  return false
}

// SheetLayoutType.バルセロナレコード
export function EffectSheetType6(props: Props) {
  const classes = useStyles();
  const t = useTenpoPointUnitTypeLocal(props.sheet.dto.ポイント単位種別, 'pt', 'yen', '');
  const is小計 = t === 'yen';
  const f = isNumberFormat(props.sheet.dto.effectType);

  const oldValueString = f
    ? String(numberFormat.format(getNumBy共通小計Str(props.sheet.dto.param2, is小計)))
    : props.sheet.dto.param2;
  let oldValuePathes = oldValueString.split('').map((s) => assetPath(`img/effect/barcelona_record/point_small/zi_small_${s === ',' ? 'comma' : s}.png`));
  oldValuePathes.push(getSmallUnitPath(t, props.sheet.dto.effectType))

  const newValueString = f
    ? String(numberFormat.format(getNumBy共通小計Str(props.sheet.dto.param3, is小計)))
    : props.sheet.dto.param3;
  let newValuePathes = newValueString.split('').map((s) => assetPath(`img/effect/barcelona_record/point/zi_${s === ',' ? 'comma' : s}.png`));
  newValuePathes.push(getUnitPath(t, props.sheet.dto.effectType))

  const monthly = Number(props.sheet.dto.param1) <= 12

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <div className={classNames(
      {[classes.monthImageListContainer]: monthly},
      {[classes.yearImageListContainer]: !monthly},
    )}>
      {getCalendarPathes(monthly, props.sheet.dto.param1).map((p, i) =>
        <img key={i} className={classNames(classes.imageList)} src={p} alt="month"/>
      )}
    </div>
    {props.sheet.dto.param4 && <img
      className={classNames(classes.image, classes.horizontalCenter, classes.shopImage)}
      src={assetPath(`img/effect/common/shop/ba_pt_${props.sheet.dto.param4}.png`)}
      alt="shop"
    />}
    <div className={classes.smallValueImageListContainer}>
      {oldValuePathes.map((p, i) =>
        <img key={i} className={classNames(classes.imageList)} src={p} alt="old value"/>
      )}
    </div>
    {f &&
      <div className={classes.middleValueImageListContainer}>
        {newValuePathes.map((p, i) =>
          <img key={i} className={classNames(classes.imageList, classes.middleImageList)} src={p} alt="new value" />
        )}
      </div>
    }
    {!f &&
      <div className={classes.bigValueImageListContainer}>
        {newValuePathes.map((p, i) =>
          <img key={i} className={classNames(classes.imageList, classes.bigImageList)} src={p} alt="new value big" />
        )}
      </div>
    }
  </div>;
}
