import React, { useEffect } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import 'swiper/swiper.scss';
import { useDispatch, useSelector } from 'react-redux';

import { selectHome } from '../../app/homeSlice';
import { getBadgeImage, getBadgeImageOff, orderedBadgeCategories } from '../../lib/dataFunctions';

import { consts } from 'consts';
import { imagePath } from 'lib/pathes';
import { fetchBadge } from 'lib/api';
import { setBadgeDialogCategory } from 'app/applicationSlice';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    badgeGroup: {
      paddingTop: 4,
    },
    badge: {
      paddingLeft: 1,
      paddingRight: 1,
      width: consts.badgeSize,
      height: consts.badgeSize,
    },
  }),
);

export const BadgeSwiper: React.FC = () => {
  const dispatch = useDispatch();
  const { badgeList, now } = useSelector(selectHome);
  const classes = useStyles();

  useEffect(() => {
    dispatch(fetchBadge({now: now}));
  }, [now]);

  const badges = orderedBadgeCategories.map((category, index) => {
    const badge = badgeList.find((b) => b.category === category);
    if (badge) {
      return (
        <img
          key={index}
          onClick={() => {
            dispatch(setBadgeDialogCategory({ category }));
            // ッターン！！！
            console.log("setBadgeDialogCategory", badge, index)
          }}
          src={imagePath(getBadgeImage(category, badge.badgeType))}
          className={classes.badge}
          alt="獲得バッヂ"
        />
      );
    } else {
      return <img key={index} src={imagePath(getBadgeImageOff(category))} className={classes.badge} alt="未獲得バッヂ" />;
    }
  });

  return (
    <>
      <Swiper>
        <SwiperSlide>
          <div className={classes.badgeGroup}>{badges}</div>
        </SwiperSlide>
      </Swiper>
    </>
  );
};
