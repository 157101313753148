import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { callNativeSyncRestCount } from '../lib/nativeBridge';

import { RootState, createInnerAction } from './store';

import {
  NotificationMessage,
  fetchNotificationConfirm,
  fetchNotificationConfirmAction,
  fetchNotificationList,
  fetchNotificationListAction,
  fetchNotificationView,
  fetchNotificationViewAction,
} from 'lib/api';

interface NotificationState {
  count: number;
  restCount: number;
  list: NotificationMessage[];
  loading: boolean;
  detail: NotificationMessage | null | undefined;
}

const initialState: NotificationState = {
  count: -1,
  restCount: 0,
  list: [],
  loading: false,
  detail: null,
};
export const notificationSlice = createSlice({
  name: 'notification',
  initialState,
  reducers: {
    setLoadingNotification: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    clearNotificationDetail: (state) => {
      state.detail = null;
    },
    clearNotificationList: (state) => {
      state.count = 0;
      state.list = [];
    },
    setRestCount: (state, action: PayloadAction<number>) => {
      const [restCount] = [action.payload];
      if (restCount !== -1) {
        state.restCount = restCount;
        // native側に通知
        callNativeSyncRestCount(restCount);
      }
    },
  },
  extraReducers: {
    [fetchNotificationList.fulfilled.toString()]: (state, action: fetchNotificationListAction) => {
      const [offset, limit, count, restCount, list] = [
        action.payload.request.offset,
        action.payload.request.limit,
        action.payload.response.count,
        action.payload.response.rest_count,
        action.payload.response.list,
      ];
      const prevCount = state.count;
      state.count = count;
      if (prevCount !== count) {
        state.list = [];
      }
      if (limit !== 0) {
        for (let i = 0; i < list.length; i++) {
          state.list[offset + i] = list[i];
        }
      }
      notificationSlice.caseReducers.setRestCount(state, createInnerAction(restCount));
    },

    [fetchNotificationView.fulfilled.toString()]: (state, action: fetchNotificationViewAction) => {
      const [result, restCount, notification] = [
        action.payload.response.result,
        action.payload.response.rest_count,
        action.payload.response.notification,
      ];
      if (result.toUpperCase() !== 'OK') {
        throw new Error();
      }
      state.detail = notification;
      // 更新後データをリストに反映
      state.list = state.list.map((v) => (!!notification && notification.id === v.id ? notification : v));
      notificationSlice.caseReducers.setRestCount(state, createInnerAction(restCount));
    },
    [fetchNotificationConfirm.fulfilled.toString()]: (state, action: fetchNotificationConfirmAction) => {
      const [result, restCount, notification] = [
        action.payload.response.result,
        action.payload.response.rest_count,
        action.payload.response.notification,
      ];
      if (result.toUpperCase() !== 'OK') {
        throw new Error();
      }
      state.detail = notification;
      // 更新後データをリストに反映
      state.list = state.list.map((v) => (!!notification && notification.id === v.id ? notification : v));
      notificationSlice.caseReducers.setRestCount(state, createInnerAction(restCount));
    },
  },
});

export const { setLoadingNotification, clearNotificationList, clearNotificationDetail } = notificationSlice.actions;

export const selectNotification = (state: RootState) => {
  return state.notification;
};
export default notificationSlice.reducer;
