import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';

import { StandardButton } from '../components/StandardButton';
import { Path } from '../routes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      width: 300,
      height: '100%',
      textAlign: 'center',
      margin: 'auto',
    },
  }),
);

export const GachaDummyPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  return (
    <>
      <div className={classNames(classes.root, classes.verticalCenter)}>
        <StandardButton size='large' onClick={() => history.push(Path.gacha)}>
          ガチャTOP
        </StandardButton>
        <br />
        <br />
        <StandardButton size='large' onClick={() => history.push(Path.gachaMovieCheck)}>
          ガチャ演出確認
        </StandardButton>
      </div>
    </>
  );
};
