import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getInnerContentHeight } from '../consts';
import { clearLoadingState, selectSchedule } from '../app/scheduleSlice';
import { fetchScoreDetailDaily } from '../lib/api';
import { selectApplication, setDailyScoreDetailDialogParams } from '../app/applicationSlice';
import { useTenpoPointUnitType } from '../hooks/useTenpoPointUnitType';

import { LoadingOverlay } from './LoadingOverlay';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";
import moment from 'moment';

// export const useDailyScoreDetailDialog = (year: number, month: number)=>{
//   const dispatch = useDispatch();
//   dispatch(setDailyScoreDetailDialogParams({year: year, month: month}))
// }

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20; // footer分
const r1Height = 100;
const r3Height = 20;
const r2Height = containerHeight - r1Height - r3Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 13,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r2: {
      height: r2Height,
    },
    r2Table: {},
    r3: {
      height: r3Height,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 42,
      paddingBottom: 0,
    },
    noBorder: {
      border: 'none',
    },
    tdValue: {
      minWidth: 116,
      paddingRight: 0,
    },
    tdDouhan: {
      color: '#dc3a70',
    },
    tdShimei: {
      color: '#00874C',
    },
  }),
);

export const DailyScoreDetailDialog: React.FC = () => {
  const classes = useStyles();
  const { dailyScoreDetailDialogParams } = useSelector(selectApplication);
  const { scoreDetailDailyResponse, scoreDetailDailyLoaded } = useSelector(selectSchedule);

  const dispatch = useDispatch();
  useEffect(() => {
    console.log('useEffect');
    if (dailyScoreDetailDialogParams) {
      dispatch(
        fetchScoreDetailDaily({
          year: dailyScoreDetailDialogParams.year,
          month: dailyScoreDetailDialogParams.month + 1,
          day: dailyScoreDetailDialogParams.day,
        }),
      );

      const day = moment([dailyScoreDetailDialogParams.year, dailyScoreDetailDialogParams.month, dailyScoreDetailDialogParams.day]).toDate()
      reportGA(GAEventValue.CALENDAR_SEISEKI, day);
    }
  }, [dailyScoreDetailDialogParams]);

  const unit = useTenpoPointUnitType('pt', '円', '');

  // 当日獲得ポイント個別レコードリスト
  const rows = [];
  rows.push({ title: '出退勤時刻', value: scoreDetailDailyResponse.出退勤時刻 });
  rows.push({
    key: '出勤形態',
    title: scoreDetailDailyResponse.出勤形態,
    value: scoreDetailDailyResponse.当日獲得ポイント総計.toLocaleString() + unit,
  });

  if (scoreDetailDailyResponse.当日獲得ポイント個別レコードリスト) {
    for (let i = 0; i < scoreDetailDailyResponse.当日獲得ポイント個別レコードリスト.length; i++) {
      const r = scoreDetailDailyResponse.当日獲得ポイント個別レコードリスト[i];
      rows.push({
        key: '当日獲得ポイント個別レコード' + i,
        title: r.name,
        value: (Math.floor(r.value * 10) / 10).toLocaleString() + unit,
      });
    }
  }

  rows.push({ key: 'spacer1', title: '', value: '' });

  //  rows.push({ title: '同伴', value: scoreDetailDailyResponse.同伴本数.toLocaleString() + '件' });
  //  rows.push({ title: '指名', value: scoreDetailDailyResponse.指名本数.toLocaleString() + '本' });
  rows.push({ title: 'フリー場内', value: scoreDetailDailyResponse.フリー場内指名本数.toLocaleString() + '本' });
  rows.push({ title: 'その他場内', value: scoreDetailDailyResponse.その他場内指名本数.toLocaleString() + '本' });
  rows.push({ title: 'フリー件数', value: scoreDetailDailyResponse.フリー件数.toLocaleString() + '件' });

  rows.push({ key: 'spacer2', title: '', value: '' });

  if (scoreDetailDailyResponse.備品代)
    rows.push({ title: '備品代', value: scoreDetailDailyResponse.備品代.toLocaleString() + '円' }); // 0は非表示
  if (scoreDetailDailyResponse.前払金)
    rows.push({ title: '前払金', value: scoreDetailDailyResponse.前払金.toLocaleString() + '円' }); // 0は非表示
  if (scoreDetailDailyResponse.減給)
    rows.push({ title: '減給', value: scoreDetailDailyResponse.減給.toLocaleString() + '円' }); // 0は非表示
  rows.push({ title: '損益金', value: scoreDetailDailyResponse.損益金.toLocaleString() + '円' });
  //  rows.push({ title: '実績給', value: scoreDetailDailyResponse.実績給.toLocaleString() + '円' });

  const onClose = () => {
    dispatch(setDailyScoreDetailDialogParams(null));
    dispatch(clearLoadingState());
  };

  const formatTitle = (s: string) => {
    if (s.indexOf('同伴') > 0)
      return (
        <span>
          <span className={classes.tdDouhan}>{s.replace('同伴', '')}</span> 同伴
        </span>
      );
    if (s.indexOf('指名') > 0)
      return (
        <span>
          <span className={classes.tdShimei}>{s.replace('指名', '')}</span> 指名
        </span>
      );
    return s;
  };

  return (
    <Dialog open={dailyScoreDetailDialogParams !== null} onClose={() => onClose()} maxWidth={false}>
      {!!dailyScoreDetailDialogParams && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
              <Grid item xs={8} className={classNames(classes.r1C2)}>
                <div>
                  <div className={classes.title}>
                    {dailyScoreDetailDialogParams.year}年{dailyScoreDetailDialogParams.month + 1}月
                    {dailyScoreDetailDialogParams.day}日
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} className={classNames(classes.r1C3)}>
                <CloseIcon onClick={() => onClose()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            {!scoreDetailDailyLoaded ? (
              <LoadingOverlay />
            ) : (
              <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
                <Table size='small' aria-label='a dense table'>
                  <TableBody>
                    {rows.map((row) => (
                      <TableRow key={!row.key ? row.title : row.key}>
                        <TableCell
                          component='th'
                          scope='row'
                          className={classes.noBorder}
                          style={{ paddingLeft: 10, paddingRight: 4 }}
                        >
                          {formatTitle(row.title)}
                        </TableCell>
                        <TableCell
                          align='right'
                          className={classNames(classes.noBorder, classes.tdValue)}
                          style={{ paddingLeft: 4 }}
                        >
                          {row.value}
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={12} className={classes.r3}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
