import React, { useEffect } from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { Paper, Table, TableBody, TableCell, TableContainer, TableRow } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

import { getInnerContentHeight } from '../consts';
import { clearLoadingState, selectSchedule } from '../app/scheduleSlice';
import { fetchScoreDetailMonthly } from '../lib/api';
import { selectApplication, setMonthlyScoreDetailDialogParams } from '../app/applicationSlice';
import { useTenpoPointUnitType } from '../hooks/useTenpoPointUnitType';

import { LoadingOverlay } from './LoadingOverlay';
import { imagePath } from '../lib/pathes';

// export const useMonthlyScoreDetailDialog = (year: number, month: number)=>{
//   const dispatch = useDispatch();
//   dispatch(setMonthlyScoreDetailDialogParams({year: year, month: month}))
// }

const containerHeight = getInnerContentHeight();
const r1Height = 290;
const r3Height = 45;
const r2Height = containerHeight - r1Height - r3Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      // backgroundColor:'red',
      position: 'absolute',
      top: 46,
      Left: 26,
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green',
      position: 'absolute',
      top: 10,
      left: 50,
    },
    r1C3: {
      // backgroundColor:'red',
      position: 'absolute',
      top: 35,
      right: 35,
    },
    r2: {
      height: r2Height,
    },
    r2Table: {
      width: 250,
      margin: 'auto',
      // backgroundColor:'red',
    },
    r3: {
      height: r3Height,
      // backgroundColor:'green'
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 21,
      color: '#ae9272',
      letterSpacing: 0.6,
      lineHeight: 0,
      paddingTop: 48,
      width: 250,
      height: 280,
      // backgroundColor:'red',
    },
    title2: {
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
      fontSize: 17,
      // backgroundColor:'red',
    },
    title3: {
      fontWeight: theme.typography.fontWeightBold,
      lineHeight: 1,
      fontSize: 19,
      // backgroundColor:'red',
    },
    title4: {
      lineHeight: 1.5,
      fontSize: 12,
      // backgroundColor:'green',
    },
    title5: {
      width: 120,
      lineHeight: 1.7,
      fontSize: 12,
      paddingLeft: 20,
      textAlign: 'left',      
      // border: "1px solid #000",
    },
    title6: {
      width: 120,
      lineHeight: 1.7,
      fontSize: 12,
      paddingRight: 18,
      paddingTop: 0,
      textAlign: 'right',
      // border: "1px solid #000",
    },
    title7: {
      fontSize: 13,
      paddingUnder: 0,
      // backgroundColor:'red',
    },
    title8: {
      lineHeight: 1,
      cellpadding: 10,
    },
    meisaiback: {
      width: 345,
      height: 630,
      display:"block",
    },
    imagecontainer: {
      position: 'relative',
    },
    noBorder: {
      lineHeight: 1,
      fontSize: 11,
      width: 130,
      paddingRight: 10,
      paddingLeft: 25,
      border: 'none',
      // border: "1px solid #000000",
    },
    noBorder2: {
      lineHeight: 1,
      fontSize: 11,
      width: 150,
      paddingRight: 50,
      paddingLeft: 0,
      border: 'none',
      textAlign: 'right', 
      // border: "1px solid #000000",
    },
    noBorder3: {
      marginTop: 12,
      // border: "1px solid #000000",
    },
    noBorderComment: {
      lineHeight: 1.2,
      fontSize: 11,
      width: 280,
      paddingLeft: 25,
      border: 'none',
    },
  }),
);

export const MonthlyScoreDetailGenericDialog: React.FC = () => {
  const classes = useStyles();
  const { monthlyScoreDetailDialogParams } = useSelector(selectApplication);
  const { scoreDetailMonthlyResponse, scoreDetailMonthlyLoaded } = useSelector(selectSchedule);

  const dispatch = useDispatch();

  const rows = [];
  rows.push({ title: '総支給額', value: '￥' + scoreDetailMonthlyResponse.総支給額.toLocaleString() })
  rows.push({
    title: useTenpoPointUnitType('基本給', '計算時給', ''),
    value: '￥' + scoreDetailMonthlyResponse.基本給.toLocaleString()
  });
  rows.push({ title: '褒章手当', value: '￥' + scoreDetailMonthlyResponse.褒章手当.toLocaleString() });
  rows.push({
    title: useTenpoPointUnitType('', '場内指名バック', ''),
    value: useTenpoPointUnitType('', '￥' + scoreDetailMonthlyResponse.場内バック.toLocaleString(), '')
  });
  rows.push({ title: '指名バック', value: '￥' + scoreDetailMonthlyResponse.指名バック.toLocaleString() });
  rows.push({ title: '同伴バック', value: '￥' + scoreDetailMonthlyResponse.同伴バック.toLocaleString() });
  rows.push({ title: '減給', value: '￥' + scoreDetailMonthlyResponse.減給.toLocaleString() });
  rows.push({ title: '損益金', value: '￥' + scoreDetailMonthlyResponse.損益金.toLocaleString() });
  rows.push({ title: '調整金', value: '￥' + scoreDetailMonthlyResponse.調整金.toLocaleString() });
  const 控除合計 = scoreDetailMonthlyResponse.源泉徴収 + scoreDetailMonthlyResponse.厚生費 + scoreDetailMonthlyResponse.前払金
    + scoreDetailMonthlyResponse.備品代 + scoreDetailMonthlyResponse.クリーニング + scoreDetailMonthlyResponse.名刺代
    + scoreDetailMonthlyResponse.送迎代 + scoreDetailMonthlyResponse.地代家賃 + scoreDetailMonthlyResponse.貸付返済
    + scoreDetailMonthlyResponse.その他 + scoreDetailMonthlyResponse.振込手数料
  rows.push({ title: '控除合計', value: '￥' + 控除合計.toLocaleString() });
  rows.push({ title: '源泉徴収等', value: '￥' + (scoreDetailMonthlyResponse.源泉徴収 + scoreDetailMonthlyResponse.厚生費).toLocaleString() });
  rows.push({ title: '前払金', value: '￥' + scoreDetailMonthlyResponse.前払金.toLocaleString() });
  rows.push({ title: '備品代', value: '￥' + scoreDetailMonthlyResponse.備品代.toLocaleString() });
  rows.push({ title: 'クリーニング', value: '￥' + scoreDetailMonthlyResponse.クリーニング.toLocaleString() });
  rows.push({ title: '名刺代', value: '￥' + scoreDetailMonthlyResponse.名刺代.toLocaleString() });
  rows.push({ title: '送迎代', value: '￥' + scoreDetailMonthlyResponse.送迎代.toLocaleString() });
  rows.push({ title: '地代家賃', value: '￥' + scoreDetailMonthlyResponse.地代家賃.toLocaleString() });
  rows.push({ title: '貸付返済', value: '￥' + scoreDetailMonthlyResponse.貸付返済.toLocaleString() });
  rows.push({ title: 'その他', value: '￥' + scoreDetailMonthlyResponse.その他.toLocaleString() });
  rows.push({ title: 'インボイス', value: '￥' + scoreDetailMonthlyResponse.振込手数料.toLocaleString() });
  if (scoreDetailMonthlyResponse.備考 !== "") rows.push({ title: '備考', value: '' });

  const 固定表示RowTitles = ['総支給額', '控除合計', '備考'];

  const onClose = () => {
    dispatch(setMonthlyScoreDetailDialogParams(null));
    dispatch(clearLoadingState());
  };
  return (
    <Dialog open={monthlyScoreDetailDialogParams !== null} onClose={() => onClose()} maxWidth={false}>
      {!!monthlyScoreDetailDialogParams && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <div>
                <img
                  className={classes.meisaiback}
                  src={imagePath('housyu_meisai.png')}
                  alt="報酬明細画像"
                />
                <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
                <Grid item xs={8} className={classNames(classes.r1C2)}>
                  <div className={classes.imagecontainer}>
                    <div className={classes.title}>
                      <p>報酬明細書<br/></p>
                      <p className={classes.title2}>
                        {monthlyScoreDetailDialogParams.year}年{monthlyScoreDetailDialogParams.month + 1}月 {scoreDetailMonthlyResponse.名前.replace(/\s+/g, "")}様<br/>
                      </p>
                      <p className={classes.title3}>  
                        支給額　{scoreDetailMonthlyResponse.差引支給額.toLocaleString()}円<br/>
                      </p>
                      <p className={classes.title4}>バック達成率：{scoreDetailMonthlyResponse.達成率.toLocaleString()}％</p>
                      <p className={classes.title7}>出勤日数：{scoreDetailMonthlyResponse.出勤日数.toLocaleString()}日&emsp;出勤時間：{(Math.floor((scoreDetailMonthlyResponse.出勤時間 * 10) / 60) / 10).toLocaleString()}時間</p>
                      <table className={classes.title8}>
	                        <tr>
	                          <td className={classes.title5}>
	                            保証給<br/>
	                            実績給<br/>
	                            平均時給
	                          </td>
                            <td className={classes.title6}>
                              {scoreDetailMonthlyResponse.保証給.toLocaleString()}円<br/>
                              {scoreDetailMonthlyResponse.実績給.toLocaleString()}円<br/>
                              {scoreDetailMonthlyResponse.平均時給.toLocaleString()}円<br/>
                            </td>
	                        </tr>
                      </table>
                    </div>
                  </div>
                </Grid>
                <Grid item xs={2} className={classNames(classes.r1C3)}>
                  <CloseIcon onClick={() => onClose()} />
                </Grid>
              </div>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            {!scoreDetailMonthlyLoaded ? (
              <LoadingOverlay />
            ) : (
              <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
                  <Table size='small' aria-label='a dense table' className={classes.noBorder3}>
                  <TableBody>
                      {rows.filter(row => (row.title !== '' && row.value !== '￥0') || 固定表示RowTitles.includes(row.title)).map((row) => (
                        <>
                          {/* 控除合計、備考の直上に文字列１行の程度のマージンを設ける */}
                          {['控除合計', '備考'].includes(row.title) &&
                            <TableRow>
                              <TableCell component='th' scope='row' className={classes.noBorder} colSpan={2} />
                            </TableRow>
                          }
                          <TableRow key={row.title}>
                            <TableCell component='th' scope='row' className={classes.noBorder} style={固定表示RowTitles.includes(row.title) ? { paddingLeft: 10 } : {}}>
                              {row.title}
                            </TableCell>
                            <TableCell className={classes.noBorder2}>
                              {row.value}
                            </TableCell>
                          </TableRow>
                        </>
                    ))}
                      {scoreDetailMonthlyResponse.備考 !== "" &&
                        <TableRow key={'備考中身'}>
                          <TableCell component='th' scope='row' className={classes.noBorderComment} colSpan={2}
                            dangerouslySetInnerHTML={{ __html: scoreDetailMonthlyResponse.備考.replace(/\n/g, '<br/>') }}
                          />
                        </TableRow>
                      }
                  </TableBody>
                </Table>
              </TableContainer>
            )}
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={12} className={classes.r3}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
