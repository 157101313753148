import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import SwiperClass from "swiper/types/swiper-class";
import { useSelector } from "react-redux";

import { selectApplication } from "../../app/applicationSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      marginLeft: "auto",
      marginRight: "auto",
      paddingTop: 14,
      display: "flex",
      justifyContent: "space-around",
    },
    dot: {
      width: 6,
      height: 6,
      background: "#fff",
      borderRadius: 3,
    },
  })
);

type Props = {
  swiper?: SwiperClass;
  count: number;
};

export function GraphDots(props: Props) {
  const { graphSwiperIndex } = useSelector(selectApplication);

  const classes = useStyles();
  const dots = [];
  for (let i = 0; i < props.count; i++) {
    dots.push(
      <div
        key={i}
        className={classes.dot}
        style={{
          opacity: i === graphSwiperIndex ? 1 : 0.35,
        }}
        onClick={(e) => props.swiper?.slideTo(i + 1)}
      ></div>
    );
  }

  return (
    <>
      <div className={classes.container} style={{ width: 16 * props.count }}>
        {dots}
      </div>
    </>
  );
}
