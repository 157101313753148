import { PayloadAction, createSlice } from '@reduxjs/toolkit';

import { RootState } from './store';

import {
  DefaultGachaInfo,
  GachaExchangeHistoryDto,
  GachaExchangeItemDto,
  GachaInfo,
  GachaLotteryHistoryDto,
  fetchGachaExchange,
  fetchGachaExchangeAction,
  fetchGachaExchangeHistoryList,
  fetchGachaExchangeHistoryListAction,
  fetchGachaExchangeItemList,
  fetchGachaExchangeItemListAction,
  fetchGachaInfo,
  fetchGachaInfoAction,
  fetchGachaLotteryHistoryList,
  fetchGachaLotteryHistoryListAction,
  fetchGachaLotteryNormal,
  fetchGachaLotteryNormalAction,
} from 'lib/api';

interface GachaSliceState {
  loading: boolean;

  loaded: boolean;
  info: GachaInfo;

  exchangeItemList: GachaExchangeItemDto[];
  exchangeItemCount: number;

  exchangeHistoryList: GachaExchangeHistoryDto[];
  exchangeHistoryCount: number;

  lotteryHistoryList: GachaLotteryHistoryDto[];
  lotteryHistoryCount: number;
}

const initialState: GachaSliceState = {
  loading: false,

  loaded: false,
  info: DefaultGachaInfo,

  exchangeItemList: [],
  exchangeItemCount: -1,

  exchangeHistoryList: [],
  exchangeHistoryCount: -1,

  lotteryHistoryList: [],
  lotteryHistoryCount: -1,
};

export const gachaSlice = createSlice({
  name: 'gacha',
  initialState,
  reducers: {
    setGachaLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
  },
  extraReducers: {
    [fetchGachaInfo.fulfilled.toString()]: (state, action: fetchGachaInfoAction) => {
      if (action.payload.response.info) {
        state.info = action.payload.response.info;
        state.loaded = true;
      }
    },

    [fetchGachaExchangeItemList.fulfilled.toString()]: (state, action: fetchGachaExchangeItemListAction) => {
      const [offset, limit, count, list] = [
        action.payload.request.offset,
        action.payload.request.limit,
        action.payload.response.count,
        action.payload.response.list,
      ];
      const prevCount = state.exchangeItemCount;
      state.exchangeItemCount = count;
      if (prevCount !== count) {
        state.exchangeItemList = [];
      }
      if (limit !== 0) {
        for (let i = 0; i < list.length; i++) {
          state.exchangeItemList[offset + i] = list[i];
        }
      }
    },

    [fetchGachaExchangeHistoryList.fulfilled.toString()]: (state, action: fetchGachaExchangeHistoryListAction) => {
      const [offset, limit, count, list] = [
        action.payload.request.offset,
        action.payload.request.limit,
        action.payload.response.count,
        action.payload.response.list,
      ];
      const prevCount = state.exchangeHistoryCount;
      state.exchangeHistoryCount = count;
      if (prevCount !== count) {
        state.exchangeHistoryList = [];
      }
      if (limit !== 0) {
        for (let i = 0; i < list.length; i++) {
          state.exchangeHistoryList[offset + i] = list[i];
        }
      }
    },

    [fetchGachaLotteryHistoryList.fulfilled.toString()]: (state, action: fetchGachaLotteryHistoryListAction) => {
      const [offset, limit, count, list] = [
        action.payload.request.offset,
        action.payload.request.limit,
        action.payload.response.count,
        action.payload.response.list,
      ];
      const prevCount = state.lotteryHistoryCount;
      state.lotteryHistoryCount = count;
      if (prevCount !== count) {
        state.lotteryHistoryList = [];
      }
      if (limit !== 0) {
        for (let i = 0; i < list.length; i++) {
          state.lotteryHistoryList[offset + i] = list[i];
        }
      }
    },

    [fetchGachaLotteryNormal.fulfilled.toString()]: (state, action: fetchGachaLotteryNormalAction) => {
      const [info] = [action.payload.response.info];
      if (info) {
        state.info = info;
      }
    },

    [fetchGachaExchange.fulfilled.toString()]: (state, action: fetchGachaExchangeAction) => {
      const [info] = [action.payload.response.info];
      if (info) {
        state.info = info;
      }
    },
  },
});

export const { setGachaLoading } = gachaSlice.actions;

export const selectGacha = (state: RootState) => state.gacha;

export default gachaSlice.reducer;
