import { Card, Theme, createStyles, makeStyles } from '@material-ui/core';
import { CustomerAgeTypeValue, CustomerAreaTypeValue, CustomerDetailForRankingEntry, CustomerProfessionTypeValue, RankingEntry } from 'lib/api';
import React, { VFC } from 'react';
import ReactApexChart from 'react-apexcharts';
import './ApexChartStyles.css';

const AgePercentage = ['20代', '30代', '40代', '50代', '60代以上'];
const Occupation = ['経営者・個人事業主', '会社役員', '会社員・公務員', '投資家', '医療', '士業', '農業・漁業', '神職', 'プロスポーツ選手', 'その他'];
const Occupationlabel = ['経営者', '会社役員', '会社員・公務員', '投資家', '医療', '士業(弁護士等)', '農業・漁業', '神職(神社等)', 'スポーツ選手', 'その他'];
const Region = Object.values(CustomerAreaTypeValue);

type Props = {
  titleText: string;
  label: 'AgePercentage' | 'Occupation' | 'Region';
  entries: RankingEntry[];
};

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textAlign: 'start',
    },
    title: {
      fontWeight: 'bold',
      fontSize: 14,
      margin: 0,
    },
    noData: {
      width: 300,
      height: 200,
      padding: 10
    }
  }),
);

export const PieChart: VFC<Props> = ({ titleText, label, entries }) => {
  const classes = useStyles();

  const colors = [
    '#008FFB', '#00E396', '#FEB019', '#FF4560', '#775DD0', '#546E7A', '#26a69a', '#D10CE8',
    '#DC143C', '#556B2F', '#FF6347', '#4682B4', '#40E0D0', '#FFD700', '#D2691E', '#6495ED',
    '#8B4513', '#2F4F4F', '#008B8B', '#B8860B', '#483D8B', '#8B008B', '#006400', '#8B0000',
    '#4682B4', '#A52A2A', '#5F9EA0', '#DAA520', '#808080', '#6B8E23', '#DB7093', '#CD5C5C',
    '#008080', '#4682B4', '#2F4F4F', '#708090', '#FFFF00', '#00FF00', '#32CD32', '#FAF0E6',
    '#FF00FF', '#800000', '#66CDAA', '#0000CD', '#BA55D3', '#9370DB', '#3CB371'
  ];


  const makeLabelSeries = (): (string | number)[][] => {
    const r = [];
    const series = makeSeries();
    switch (label) {
      case 'AgePercentage':
        for (let i = 0; i < AgePercentage.length; i++) {
          r.push([AgePercentage[i], AgePercentage[i], series[i], colors[i]]);
        }
        r.sort((a, b) => {
          if (a[2] < b[2]) return 1;
          if (a[2] > b[2]) return -1;
          return 0;
        });
        return r;
      case 'Occupation':
        for (let i = 0; i < Occupation.length; i++) {
          if (Occupation[i] !== 'その他') {
            r.push([Occupation[i], Occupationlabel[i], series[i], colors[i]]);
          }
        }
        r.sort((a, b) => {
          if (a[2] < b[2]) return 1;
          if (a[2] > b[2]) return -1;
          return 0;
        });
        r.push(["その他", "その他", series[9], colors[9]]);
        return r;
      case 'Region':
        for (let i = 0; i < Region.length; i++) {
          r.push([Region[i], Region[i], series[i], colors[i]]);
        }
        r.sort((a, b) => {
          if (a[2] < b[2]) return 1;
          if (a[2] > b[2]) return -1;
          return 0;
        });
        // 都道府県の人数が多い順の上位七件を取得それ以外はその他に入れる
        const rsponse = [];
        let sum = 0;
        for (let num of series) {
          sum += num;
        }
        for (let i = 0; i < 8; i++) {
          if (r[i][2] !== 0) {
            if (i < 7) {
              sum -= Number(r[i][2]);
              rsponse.push(r[i]);
            } else {
              rsponse.push(["その他", "その他", sum, colors[i]]);
              break;
            }
          }
        }
        return rsponse;
    }
  };

  const getColor = (): string[] => {
    return makeLabelSeries().map((v) => String(v[3]));
  }
  const getLabelsLeft = (): string[] => {
    return makeLabelSeries().map((v) => String(v[1]));
  }
  const getLabels = (): string[] => {
    return makeLabelSeries().map((v) => String(v[0]));
  }
  const getSeries = (): number[] => {
    return makeLabelSeries().map((v) => Number(v[2]));
  }
  const makeSeries = (): number[] => {
    const series = label === 'Occupation' ? [0, 0, 0, 0, 0, 0, 0, 0, 0, 0] : 'Region' ? new Array(47).fill(0) : [0, 0, 0, 0, 0, 0];
    switch (label) {
      case 'AgePercentage':
        entries.forEach((v) => {
          for (let i = 0; i < Object.keys(CustomerAgeTypeValue).length; i++) {
            if (v.customerDetail?.age === Object.values(CustomerAgeTypeValue)[i]) {
              series[i] += 1;
              break;
            }
          }
        })
        return series;
      case 'Occupation':
        entries.forEach((v) => {
          for (let i = 0; i < Object.keys(CustomerProfessionTypeValue).length; i++) {
            if (v.customerDetail?.profession === Object.values(CustomerProfessionTypeValue)[i]) {
              series[i] += 1;
              break;
            }
          }
        })
        return series;
      case 'Region':
        entries.forEach((v) => {
          for (let i = 0; i < Region.length; i++) {
            if (v.customerDetail?.area === Region[i]) {
              series[i] += 1;
              break;
            }
          }
        })
        return series;
    }
  }

  return (
    <Card className={classes.root} style={{ height: label === 'Occupation' ? '240px' : '200px' }}>
      <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'stretch', margin: '2%' }}>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '164px' }}>
          <div style={{ width: '10px', height: '20px' }}></div>
          {getLabelsLeft().map((label, index) => (
            <div style={{ display: 'flex', flexDirection: 'row-reverse', alignItems: 'center', margin: '1%' }}>
              <div key={index} style={{ whiteSpace: 'pre-line' }}>{label}</div>
              <div style={{ width: '10px', height: '10px', borderRadius: '50%', backgroundColor: getColor()[index], margin: '2px' }}></div>
            </div>
          ))}
        </div>
        <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start', width: '100%' }}>
          < ReactApexChart
            options={{
              title: {
                text: titleText,
              },
              chart: {
                type: 'pie',
                sparkline: {
                  enabled: true
                }
              },
              dataLabels: {
                enabled: true,
                offsetX: 0,
                offsetY: 0,
                formatter: function (val) {
                  return Math.round(Number(val) * 10) / 10 + "%"
                },
                style: {
                  fontSize: '12px',
                  colors: ['#ffffff'],
                },
              },
              labels: getLabels(),
              colors: getColor(),
            }}
            series={getSeries()}
            type='donut'
            height="170px"
          />
        </div>
      </div>
    </Card >
  );
};
