import { useDispatch, useSelector } from "react-redux";

import { selectHome } from "../../app/homeSlice";
import {
  GraphPeriodTypeValue,
  TenpoPointUnitTypeValue,
  fetchGraphData,
  fetchGraphGoals,
} from "../../lib/api";
import { useTenpoPointUnitType } from "../../hooks/useTenpoPointUnitType";
import { GraphValue, selectGraph } from "../../app/graphSlice";

import { GraphType } from "./graphDefine";

export function useTitle(type: GraphType) {
  const scoreValue = useTenpoPointUnitType("ポイント", "小計", "");
  switch (type) {
    case GraphType.成績:
      return scoreValue;
    case GraphType.給与総支給額:
      return "給与総支給額";
    case GraphType.店舗ポイントランキング:
      return "店舗Ptランキング";
    case GraphType.グループポイントランキング:
      return "グループPtランキング";
    case GraphType.同伴数:
      return "同伴数";
    case GraphType.フリー件数:
      return "フリー場内率";
    case GraphType.指名本数:
      return "指名本数";
    case GraphType.出勤時間:
      return "勤務時間";
  }
}

export function useUnit(type: GraphType) {
  const scoreValue = useTenpoPointUnitType("pt", "万円", "");
  switch (type) {
    case GraphType.成績:
      return scoreValue;
    case GraphType.給与総支給額:
      return "万円";
    case GraphType.店舗ポイントランキング:
    case GraphType.グループポイントランキング:
      return "順位";
    case GraphType.同伴数:
      return "件数";
    case GraphType.フリー件数:
      return "％";
    case GraphType.指名本数:
      return "本数";
    case GraphType.出勤時間:
      return "時間";
    default:
      return "-";
  }
}
export function filterNonNUll(values: GraphValue[]): number[] {
  const res: number[] = [];
  for (let i = 0; i < values.length; i++) {
    const v = values[i];
    if (v !== null) {
      res.push(v);
    }
  }
  return res;
}

// return [yMin, yMax, goal]
export function useRange(
  type: GraphType,
  nullableValues: GraphValue[],
  rankingCounts: number[]
): number[] {
  const goal = useGraphGoal(type);
  const values = filterNonNUll(nullableValues);

  // const rate = 1.1;
  // const rate = 1.02;
  const rate = 1;
  switch (type) {
    case GraphType.成績:
    case GraphType.給与総支給額:
    case GraphType.同伴数:
    case GraphType.フリー件数:
    case GraphType.指名本数:
    case GraphType.出勤時間:
      return [
        0,
        Math.max(...values.filter((v) => v !== null), goal) * rate,
        goal,
      ];
    case GraphType.店舗ポイントランキング:
    case GraphType.グループポイントランキング: {
      // ランキングのvaluesは負値で来るため、正値化して最大値を取得
      const maxValue = Math.min(...values) * -1;
      // 全体の人数(rankingCounts)だとグラフ幅が広くなりすぎるため、値の最大値を取る
      // let yMax = Math.max(...rankingCounts, goal, 10) * rate;
      let yMax = Math.max(maxValue, goal, 7) * rate;
      // ランキング系は1を最大値に表示するためforceNiceScalea:falseにしている。y軸を整数にするため、4n+1に丸める
      yMax = Math.ceil(yMax / 4) * 4 + 1;
      // 上下反転グラフにするためマイナス値にする
      return [yMax * -1, -1, goal * -1];
    }
    default:
      return [0, 1000];
  }
}

export function useGraphGoal(type: GraphType): number {
  const { goals } = useSelector(selectGraph);
  switch (type) {
    case GraphType.成績:
      return goals.成績;
    case GraphType.給与総支給額:
      return goals.支給総額;
    case GraphType.同伴数:
      return goals.同伴本数;
    case GraphType.フリー件数:
      return 0;
    case GraphType.指名本数:
      return goals.指名本数;
    case GraphType.出勤時間:
      return goals.出勤時間;
    case GraphType.店舗ポイントランキング:
      return goals.店舗内ランキング;
    case GraphType.グループポイントランキング:
      return goals.グループ内ランキング;
  }
}

export function useGraphEntries() {
  const { periodType, graphDataResponses } = useSelector(selectGraph);
  const entries = graphDataResponses[periodType];
  return !entries ? [] : entries;
}

export function useSelectGraphData(graphValueType: string) {
  const { periodType, graphData } = useSelector(selectGraph);
  if (!graphData[periodType] || !graphData[periodType][graphValueType]) {
    return {
      labels: [],
      values: [],
      rankingCounts: [],
    };
  }
  return graphData[periodType][graphValueType];
}

const numberFormat = new Intl.NumberFormat();
export type YAxisFormatter = (val: number) => string | string[];
export type ValueFormatter = (val: GraphValue) => string;
const simpleValueFormatter: ValueFormatter = (val: GraphValue): string => {
  if (val === null) return "-";
  return String(numberFormat.format(val));
};
const zeroValueFormatter: ValueFormatter = (val: GraphValue): string => {
  return String(0);
};
const tenpoPointValueFormatter: ValueFormatter = (val: GraphValue): string => {
  return "pt";
};
const rinPointValueFormatter: ValueFormatter = (val: GraphValue): string => {
  return "円";
};
const yenValueFormatter: ValueFormatter = (val: GraphValue): string => {
  if (val === null) return "-";
  return String(numberFormat.format(Math.round(val / 10000)));
};
const minutesValueFormatter: ValueFormatter = (val: GraphValue): string => {
  if (val === null) return "-";
  return String(numberFormat.format(Math.ceil(val / 60)));
};
const rankingYAxisValueFormatter: ValueFormatter = (
  val: GraphValue
): string => {
  if (val === null) return "-";
  if (val === 0) return "1";
  return String(numberFormat.format(Math.round(val) * -1));
};
const rankingValueFormatter: ValueFormatter = (val: GraphValue): string => {
  if (val === null || val === 0) return "-";
  return String(numberFormat.format(Math.round(val) * -1));
};
export const 場内率ValueFormatter: ValueFormatter = (
  val: GraphValue
): string => {
  if (val === null) return "-";
  return numberFormat.format(val);
};

export function useYAxisFormatter(type: GraphType): YAxisFormatter {
  const home = useSelector(selectHome);

  let valueFormatter: ValueFormatter = simpleValueFormatter;
  switch (type) {
    case GraphType.成績:
      if (!home.loaded) {
        valueFormatter = zeroValueFormatter;
      } else if (
        home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.小計
      ) {
        valueFormatter = yenValueFormatter;
      }
      break;
    case GraphType.給与総支給額:
      valueFormatter = yenValueFormatter;
      break;
    case GraphType.出勤時間:
      valueFormatter = minutesValueFormatter;
      break;
    case GraphType.店舗ポイントランキング:
    case GraphType.グループポイントランキング:
      valueFormatter = rankingYAxisValueFormatter;
      break;
    case GraphType.フリー件数:
      valueFormatter = 場内率ValueFormatter;
      break;
  }
  return (val: number): string | string[] => {
    return valueFormatter(
      val < 5 ? Math.round(val * 10) / 10 : Math.round(val)
    );
  };
}

const yenDetailValueFormatter: ValueFormatter = (val: GraphValue): string => {
  if (val === null) return "-";
  return "¥" + numberFormat.format(val);
};
const minutesDetailValueFormatter: ValueFormatter = (
  val: GraphValue
): string => {
  if (val === null) return "-";
  return String(numberFormat.format(Math.ceil((val / 60) * 10) / 10));
};
export function useValueFormatter(type: GraphType): ValueFormatter {
  const home = useSelector(selectHome);
  let valueFormatter = simpleValueFormatter;
  switch (type) {
    case GraphType.成績:
      if (!home.loaded) {
        valueFormatter = tenpoPointValueFormatter;
      } else if (
        home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.小計
      ) {
        valueFormatter = rinPointValueFormatter;
      }
      break;
    case GraphType.給与総支給額:
      valueFormatter = yenDetailValueFormatter;
      break;
    case GraphType.出勤時間:
      valueFormatter = minutesDetailValueFormatter;
      break;
    case GraphType.店舗ポイントランキング:
    case GraphType.グループポイントランキング:
      valueFormatter = rankingValueFormatter;
      break;
  }
  return valueFormatter;
}

export function 累積化(values: GraphValue[]): GraphValue[] {
  let sum = 0;
  for (let i = 0; i < values.length; i++) {
    const v = values[i];
    if (v === null) {
      values[i] = null;
    } else {
      sum += v;
      values[i] = sum;
    }
  }
  return values;
}

export function useDateFormat(): string {
  const { periodType } = useSelector(selectGraph);
  return periodType === GraphPeriodTypeValue.MONTH ? "MM/DD" : "YY/MM";
}

export function useFetchGraphData() {
  const dispatch = useDispatch();
  const entries = useGraphEntries();
  var { periodType } = useSelector(selectGraph);

  if (periodType === GraphPeriodTypeValue.ONEYEAR) {
    periodType = GraphPeriodTypeValue.ALL;
  }

  return () => {
    // TODO ローカルキャッシュ考慮: state永続化と再読み込み対応
    if (entries.length === 0) {
      dispatch(fetchGraphData({ periodType }));
      dispatch(fetchGraphGoals({}));
    }
  };
}

export function clearOddIndexedElements(labels: string[]) {
  var clearOdd = labels.slice();
  if (clearOdd.length >= 7) {
    for (let i = 1; i < clearOdd.length; i += 2) {
      clearOdd[i] = "";
    }
    return clearOdd;
  } else {
    return clearOdd;
  }
}

export function roundToNearestGoodNumber(
  type: GraphType,
  maxNumber: number
): number {
  var retValue = 0;
  var 給与万円Flag = false;
  if (maxNumber === 0) {
    return 0;
  }
  if (type === GraphType.出勤時間) {
    maxNumber = maxNumber / 60;
  } else if (type === GraphType.給与総支給額) {
    if (maxNumber >= 10000) {
      給与万円Flag = true;
      maxNumber = maxNumber / 10000;
    }
  }
  if (maxNumber >= 1 && maxNumber <= 10) {
    retValue = Math.ceil(maxNumber / 4);
  } else if (maxNumber >= 10 && maxNumber <= 20) {
    retValue = 5;
  } else if (maxNumber >= 20 && maxNumber <= 30) {
    retValue = 10;
  } else if (maxNumber >= 30 && maxNumber <= 50) {
    retValue = 15;
  } else if (maxNumber >= 50 && maxNumber <= 100) {
    retValue = 25;
  } else if (maxNumber >= 100 && maxNumber <= 200) {
    retValue = 50;
  } else if (maxNumber >= 200 && maxNumber <= 300) {
    retValue = 100;
  } else if (maxNumber >= 300 && maxNumber <= 500) {
    retValue = 150;
  } else if (maxNumber >= 500 && maxNumber <= 1000) {
    retValue = 250;
  } else if (maxNumber >= 1000 && maxNumber <= 2000) {
    retValue = 500;
  } else if (maxNumber >= 2000 && maxNumber <= 4000) {
    retValue = 1000;
  } else if (maxNumber >= 4000 && maxNumber <= 6000) {
    retValue = 1500;
  } else if (maxNumber >= 6000 && maxNumber <= 8000) {
    retValue = 2000;
  } else if (maxNumber >= 8000 && maxNumber <= 10000) {
    retValue = 2500;
  } else {
    var ifMaxNum = 12000;
    retValue = 3000;
    var addMaxValue = 2000;
    var addRetValue = 500;
    while (maxNumber > ifMaxNum) {
      var past10beki = `${retValue}`.length;
      ifMaxNum += addMaxValue;
      retValue += addRetValue;
      var latest10beki = `${retValue}`.length;
      if (latest10beki !== past10beki) {
        addRetValue = (10 ** past10beki * 5) / 10;
        addMaxValue = addRetValue * 4;
      }
    }
    if (`${retValue}`.length !== `${retValue * 4}`.length) {
      if (retValue % 10 ** (`${retValue}`.length - 1)) {
        retValue += addRetValue;
      }
    }
  }
  if (給与万円Flag) {
    return retValue * 10000;
  }
  if (type === GraphType.出勤時間) {
    return retValue * 60;
  } else {
    return retValue;
  }
}

export function rankingRoundToNearestGoodNumber(maxNumber: number): number {
  if (maxNumber === 0) {
    return 0;
  }
  if (maxNumber >= 1 && maxNumber <= 10) {
    return -1 * Math.ceil(maxNumber / 4);
  } else if (maxNumber >= 10 && maxNumber <= 20) {
    return -5;
  } else if (maxNumber >= 20 && maxNumber <= 30) {
    return -10;
  } else if (maxNumber >= 30 && maxNumber <= 50) {
    return -15;
  } else if (maxNumber >= 50 && maxNumber <= 100) {
    return -25;
  } else if (maxNumber >= 100 && maxNumber <= 200) {
    return -50;
  } else if (maxNumber >= 200 && maxNumber <= 300) {
    return -100;
  } else if (maxNumber >= 300 && maxNumber <= 500) {
    return -150;
  } else if (maxNumber >= 500 && maxNumber <= 1000) {
    return -250;
  } else if (maxNumber >= 1000 && maxNumber <= 2000) {
    return -500;
  } else if (maxNumber >= 2000 && maxNumber <= 4000) {
    return -1000;
  } else if (maxNumber >= 4000 && maxNumber <= 6000) {
    return -1500;
  } else if (maxNumber >= 6000 && maxNumber <= 8000) {
    return -2000;
  } else if (maxNumber >= 8000 && maxNumber <= 10000) {
    return -2500;
  } else {
    var base = Math.ceil(maxNumber / 4);
    var base10beki = 10 ** (`${base}`.length - 1);
    base = base10beki * Math.ceil(base / base10beki);
    return -1 * base;
  }
}
