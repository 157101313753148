import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { EffectDetail, effectImageHeight, getNumBy共通小計Str, useTenpoPointUnitTypeLocal } from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';
import {EffectType, EffectTypeValue} from "../../lib/api";

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  bigYearImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(415),
    height: effectImageHeight(73),
    textAlign: 'center',
  },
  smallYearImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(382),
    height: effectImageHeight(54),
    textAlign: 'center',
  },
  monthDayImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(444),
    height: effectImageHeight(79),
    textAlign: 'center',
  },
  middleValueImageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(1178),
    height: effectImageHeight(124),
    left: 12,
  },
  bigValueImageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(1169),
    height: effectImageHeight(142),
    left: 12,
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
  },
  middleImageList:{
    marginLeft: -3.5,
  },
  bigImageList:{
    marginLeft: -4,
  },
}));

export function getEffectSheetType5PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}
const numberFormat = new Intl.NumberFormat();

function getUnitPath(pointUnit: string, sheetType: EffectType): string {
  if(sheetType === EffectTypeValue.同伴数ベスト更新_年間 || sheetType === EffectTypeValue.同伴数ベスト更新_月間){
    return assetPath(`img/effect/ziko_best/point/zi_ken.png`)
  }
  if(sheetType === EffectTypeValue.指名数ベスト更新_年間 || sheetType === EffectTypeValue.指名数ベスト更新_月間){
    return assetPath(`img/effect/ziko_best/point/zi_hon.png`)
  }
  return assetPath(`img/effect/ziko_best/point/zi_${pointUnit}.png`)
}
function isNumberFormat(sheetType: EffectType): boolean {
  if(sheetType === EffectTypeValue.ポイントベスト更新_年間 || sheetType === EffectTypeValue.ポイントベスト更新_月間){
    return true
  }
  return false
}

// SheetLayoutType.自己ベスト
export function EffectSheetType5(props: Props) {
  const classes = useStyles();
  const t = useTenpoPointUnitTypeLocal(props.sheet.dto.ポイント単位種別, 'pt', 'yen', '');
  const is小計 = t === 'yen';
  const f = isNumberFormat(props.sheet.dto.effectType);
  const numString = f
    ? String(numberFormat.format(getNumBy共通小計Str(props.sheet.dto.param2, is小計)))
    : props.sheet.dto.param2;
  let pathes = numString.split('').map((s) =>
    assetPath(`img/effect/ziko_best/point/zi_${s === ',' ? 'comma' : s}.png`)
  );
  pathes.push(getUnitPath(t, props.sheet.dto.effectType))

  const calendarChunk = props.sheet.dto.param1.split('');
  const monthly = calendarChunk.length > 4

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    {monthly &&
      <>
        <div className={classes.smallYearImageListContainer}>
          {calendarChunk.slice(0, 4).map((c, i) =>
            <img key={i} className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/Year_small/zi_Year_small_${c}.png`)} alt="year small"/>
          )}
          <img className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/Year_small/zi_Year_small.png`)} alt="year label"/>
        </div>
        <div className={classes.monthDayImageListContainer}>
          <img className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/month/zi_mo${Number(calendarChunk.slice(4, 6).join(''))}.png`)} alt="calendar month" />
          {calendarChunk.slice(6, 8).map((c, i) =>
            <img key={i} className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/day/zi_day0${c}.png`)} alt="calendar chunk"/>
          )}
          <img className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/day/zi_day.png`)} alt="calendar day"/>
        </div>
      </>
    }
    {!monthly &&
      <div className={classes.bigYearImageListContainer}>
        {calendarChunk.map((c, i) =>
          <img key={i} className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/Year_big/zi_Year_big_${c}.png`)} alt="calendar year chunk" />
        )}
        <img className={classNames(classes.imageList)} src={assetPath(`img/effect/ziko_best/Year_big/zi_Year_big.png`)} alt="year big"/>
      </div>
    }
    {f &&
      <div className={classNames(classes.middleValueImageListContainer)}>
        {pathes.map((p, i) =>
          <img key={i} className={classNames(classes.imageList, classes.middleImageList)} src={p} alt="middle value"/>
        )}
      </div>
    }
    {!f &&
      <div className={classNames(classes.bigValueImageListContainer)}>
        {pathes.map((p, i) =>
          <img key={i} className={classNames(classes.imageList, classes.bigImageList)} src={p} alt="big value"/>
        )}
      </div>
    }
  </div>;
}
