import React, { useEffect, useState } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperClass from "swiper/types/swiper-class";
import { useDispatch, useSelector } from "react-redux";
import { RouteComponentProps } from "react-router-dom";

import { getInnerContentHeight } from "../consts";
import { GraphType } from "../components/Graph/graphDefine";
import { setGraphSwiperIndex } from "../app/applicationSlice";
import { GraphButtons } from "../components/Graph/GraphButtons";
import { GraphDots } from "../components/Graph/GraphDots";

import { Graph } from "components/Graph/Graph";

import { reportGA, GAEventValue } from "../lib/googleAnalytics";
import { useSelectGraphData } from "components/Graph/graphDataLib";
import moment from "moment";
import { selectGraph } from "app/graphSlice";
import { GraphPeriodTypeValue } from "lib/api";

const contentHeight = getInnerContentHeight();
const separatorAreaHeight = 1;
const buttonAreaHeight = 29 + 35;
const paginationAreaHeight = 55;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: "100%",
      backgroundColor: "#dc3a70",
    },
    separator: {
      height: separatorAreaHeight,
      backgroundColor: "white",
    },
    buttons: {
      height: buttonAreaHeight,
      paddingTop: 35,
    },
    swiper: {
      height:
        contentHeight -
        separatorAreaHeight -
        buttonAreaHeight -
        paginationAreaHeight,
    },
    pagination: {
      height: paginationAreaHeight,
    },
  })
);

function useDate(type: GraphType): Date | undefined {
  const data = useSelectGraphData(type.toString());
  // console.log("ga useDate", data.labels[0])
  if (data.values.length === 0) {
    return undefined;
  }
  const dDate = moment(data.labels[0]).toDate();
  return dDate;
}

type Props = RouteComponentProps<{}>;

export const GraphPage = (props: Props) => {
  // console.log('GraphPage render', a++);
  const dispatch = useDispatch();
  const [swiper, setSwiper] = useState<SwiperClass | undefined>(undefined);
  const urlParams = new URLSearchParams(props.location.search);
  const swiperIndex = urlParams.get("si")
    ? parseInt(urlParams.get("si") as string)
    : 1;

  const graphTypes = [
    GraphType.成績,
    GraphType.給与総支給額,
    GraphType.店舗ポイントランキング,
    GraphType.グループポイントランキング,
    GraphType.フリー件数,
    GraphType.出勤時間,
    GraphType.同伴数,
    GraphType.指名本数,
  ];

  const gaGraphType = [
    GraphType.成績,
    GraphType.店舗ポイントランキング,
    GraphType.同伴数,
    GraphType.フリー件数,
  ];

  const gaGraphMonth = [
    GAEventValue.GRAPH_SOUSHI_MONTH, // GraphType.成績
    GAEventValue.GRAPH_TENPO_MONTH, // GraphType.店舗ポイントランキング
    GAEventValue.GRAPH_DOUHAN_MONTH, // GraphType.同伴数
    GAEventValue.GRAPH_FREE_MONTH, // GraphType.フリー件数
  ];

  const gaGraphYear = [
    GAEventValue.GRAPH_SOUSHI_YEAR, // GraphType.成績
    GAEventValue.GRAPH_TENPO_YEAR, // GraphType.店舗ポイントランキング
    GAEventValue.GRAPH_DOUHAN_YEAR, // GraphType.同伴数
    GAEventValue.GRAPH_FREE_YEAR, // GraphType.フリー件数
  ];

  const gaGraphAll = [
    GAEventValue.GRAPH_SOUSHI_ALL, // GraphType.成績
    GAEventValue.GRAPH_TENPO_ALL, // GraphType.店舗ポイントランキング
    GAEventValue.GRAPH_DOUHAN_ALL, // GraphType.同伴数
    GAEventValue.GRAPH_FREE_ALL, // GraphType.フリー件数
  ];

  const graphSwipers = [];
  for (let i = 0; i < graphTypes.length; i += 2) {
    const graphs = [];
    for (let n = 0; n < 2; n++) {
      const graphIndex = i + n;
      if (graphTypes[graphIndex] !== undefined) {
        graphs.push(
          <Graph key={graphIndex} type={graphTypes[graphIndex]}></Graph>
        );
      }
    }

    graphSwipers.push(
      <SwiperSlide key={i}>
        <div>{graphs}</div>
      </SwiperSlide>
    );
  }

  const { periodType } = useSelector(selectGraph);
  const [type, setType] = useState(GraphType.出勤時間);
  const [index, setIndex] = useState(-1);
  const dDate = useDate(type);
  useEffect(() => {
    // console.log("ga effect", graphSwiperIndex, dDate)
    if (!dDate || index === -1) return;
    switch (periodType) {
      case GraphPeriodTypeValue.MONTH:
        reportGA(gaGraphMonth[index], dDate);
        break;
      case GraphPeriodTypeValue.YEAR:
        reportGA(gaGraphYear[index], dDate);
        break;
      case GraphPeriodTypeValue.ALL:
        reportGA(gaGraphAll[index], dDate);
        break;
      // case GraphPeriodTypeValue.ONEYEAR:
      //   reportGA(gaGraphYear[index], dDate)
      //   break;
    }
  }, [index, periodType, dDate?.getTime()]);

  // useEffect(() => {
  //   return () => {
  //     console.log("ga umount")
  //     dispatch(setGraphSwiperIndex({ index: 0 }));
  //   }
  // }, []);

  const classes = useStyles();
  return (
    <>
      <div className={classes.container}>
        <div className={classes.separator}></div>
        <div className={classes.buttons}>
          <GraphButtons />
        </div>
        <div className={classes.swiper}>
          <Swiper
            initialSlide={swiperIndex - 1}
            onSwiper={setSwiper}
            loop={true}
            onTransitionEnd={(e) => {
              const i = (e.activeIndex + 4 - 1) % 4;
              dispatch(setGraphSwiperIndex({ index: i }));
              setIndex(i);
              setType(gaGraphType[i]);
            }}
          >
            {graphSwipers}
          </Swiper>
        </div>
        <div className={classes.pagination}>
          <GraphDots swiper={swiper} count={4}></GraphDots>
        </div>
      </div>
    </>
  );
};
