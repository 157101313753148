import {
  Action,
  PayloadAction,
  ThunkAction,
  combineReducers,
  configureStore,
  getDefaultMiddleware,
} from '@reduxjs/toolkit';
import { FLUSH, PAUSE, PERSIST, PURGE, REGISTER, REHYDRATE, persistReducer, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

// import createEncryptor from 'redux-persist-transform-encrypt'
import counterReducer from '../features/counter/counterSlice';

import authReducer from './authSlice';
import applicationReducer from './applicationSlice';
import homeReducer from './homeSlice';
import rankingReducer from './rankingSlice';
import scheduleReducer from './scheduleSlice';
import graphReducer from './graphSlice';
import notificationReducer from './notificationSlice';
import myPageReducer from './myPageSlice';
import actorReducer from './actorSlice';
import gachaReducer from './gachaSlice';
import effectReducer from './effectSlice';

const appReducer = combineReducers({
  application: applicationReducer,
  counter: counterReducer,
  auth: authReducer,
  home: homeReducer,
  ranking: rankingReducer,
  schedule: scheduleReducer,
  graph: graphReducer,
  notification: notificationReducer,
  myPage: myPageReducer,
  actor: actorReducer,
  gacha: gachaReducer,
  effect: effectReducer,
});

// TODO TS型指定
// @ts-ignore
const rootReducer = (state, action) => {
  if (action.type === 'CLEAR_STATE') {
    // TODO stateは初期化されるがexceptionが出る
    state = undefined;
  }
  // @ts-ignore
  return appReducer(state, action);
};

// const encryptor = createEncryptor({
//   // TODO キー生成、保管
//   secretKey: 'my-super-secret-key',
//   onError: function(error) {
//     console.error(error)
//   }
// })

const persistConfig = {
  key: 'root',
  version: 1,
  storage,
  // TODO 全体のpersist検討(キャッシュ設計、API再整理等)
  whitelist: ['auth'],
  // TODO redux-persist-transform-encryptの型関連が怪しいので自作検討
  // transforms: [encryptor]
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
  reducer: persistedReducer,
  middleware: getDefaultMiddleware({
    serializableCheck: {
      ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
    },
  }),
});

export const persistor = persistStore(store);

// キャッシュ無効化
// persistor.purge()
// persistor.flush()

export type RootState = ReturnType<typeof store.getState>;
export type AppThunk<ReturnType = void> = ThunkAction<ReturnType, RootState, unknown, Action<string>>;
export type AppDispatch = typeof store.dispatch;
export function createInnerAction<T>(v: T): PayloadAction<T> {
  return {
    payload: v,
    type: '',
  };
}
