function e(v) {
  if (v === null) {
    return '';
  } else {
    // return v
    return encodeURIComponent(v);
  }
}

function toQueryArray(v, keys) {
  if (!keys) keys = [];
  let result = [];
  if (v instanceof Array) {
    const ancestorKeys = keys.concat();
    const parentKey = ancestorKeys.pop() || '';
    for (let i = 0; i < v.length; i++) {
      result = result.concat(toQueryArray(v[i], ancestorKeys.concat([`${parentKey}[${i}]`])));
    }
  } else if (v instanceof Object) {
    for (const key in v) {
      if (!Object.prototype.hasOwnProperty.call(v, key)) continue;
      if (v[key] === null) continue;
      result = result.concat(toQueryArray(v[key], keys.concat([key])));
    }
  } else {
    result.push([keys, v]);
  }
  return result;
}

export function toQueryString(v) {
  return toQueryArray(v)
    .map((kv) => [kv[0].map((k) => e(k)).join('.'), e(kv[1])].join('='))
    .join('&');
}
