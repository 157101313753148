import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {
  EffectDetail,
  effectImageHeight,
  effectImageWidth,
  getNumBy共通小計Str,
  useTenpoPointUnitTypeLocal,
} from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  monthImage:{
    width: effectImageWidth(184),
    height: effectImageHeight(87),
    top: effectImageHeight(407),
  },
  imageListContainer: {
    position: 'absolute',
    width: '100%',
    textAlign: 'center',
    top: effectImageHeight(874),
    height: effectImageHeight(110),
    // left: 12,
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
    marginLeft: -1,
  },
}));

export function getEffectSheetType2PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}

const numberFormat = new Intl.NumberFormat();

// SheetLayoutType.目標達成
export function EffectSheetType2(props: Props) {
  const classes = useStyles();
  const t = useTenpoPointUnitTypeLocal(props.sheet.dto.ポイント単位種別, 'pt', 'yen', '');
  const numString = String(numberFormat.format(getNumBy共通小計Str(props.sheet.dto.param2, t === 'yen')));
  let pathes = numString.split('').map((s) => assetPath(`img/effect/zetaisu/zetaisu/mokuhyou/${s}.png`));
  pathes.push(assetPath(`img/effect/zetaisu/zetaisu/mokuhyou/${t}.png`))

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <img
      className={classNames(classes.image, classes.horizontalCenter, classes.monthImage)}
      src={assetPath(`img/effect/zetaisu/zetaisu/zetaisu_${props.sheet.dto.param1}.png`)}
      alt="month"
    />
    <div className={classes.imageListContainer}>
      {pathes.map((p, i) =>
        <img key={i} className={classNames(classes.imageList)} src={p} alt="mouhyou" />
      )}
    </div>
  </div>;
}
