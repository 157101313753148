import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Grid, Paper } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FormattedNumber } from 'react-intl';
import classNames from 'classnames';

import { useTenpoPointUnitType } from '../hooks/useTenpoPointUnitType';
import { BadgeSwiper } from '../components/Badge/BadgeSwiper';
import { BadgeDetailDialog } from '../components/Badge/BadgeDetailDialog';

import { getInnerContentHeight } from 'consts';
import { GradientCircle, HomeRankingListItem } from 'components';
import { Path } from 'routes';
import { selectHome } from 'app/homeSlice';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";
import {fetchEffectList} from "../lib/api";
import {selectAuth} from "../app/authSlice";

// iphone Xで縦スクロールが出ない高さ
const contentHeight = getInnerContentHeight();
const heightR1 = 53;
const heightR2 = 188;
const heightR3 = 102;
const heightR4 = contentHeight - (heightR1 + heightR2 + heightR3);
const heightR4ListItem = Math.floor(heightR4 / 5);

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      padding: theme.spacing(0),
      textAlign: 'center',
      color: theme.palette.text.primary,
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    r1: {
      height: heightR1,
      paddingTop: '12px',
    },
    r2: {
      height: heightR2,
      // paddingTop: '12px',
      lineHeight: '1.5rem',
    },
    circleContainer: {
      position: 'relative',
    },
    r3: {
      // height: 467,
      height: heightR3,
    },
    r31: {
      // width: '100%',
      backgroundColor: 'black',
    },
    r4ListItem: {
      height: heightR4ListItem,
    },
    r202: {
      width: 188,
      height: 188,
      backgroundColor: 'red',
    },
    t1: {
      fontSize: 22,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },
    t2: {
      fontSize: 14,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },
    t3: {
      fontSize: 15,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
    },
    t4: {
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },
    t5: {
      fontSize: 12,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.secondary,
      lineHeight: '1rem',
    },
    t6: {
      fontSize: 21,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.primary,
      paddingTop: 10,
      paddingBottom: 10,
    },
    t7: {
      fontSize: 29,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
      lineHeight: '2.2rem',
    },
    t8: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.text.primary,
      lineHeight: '2rem',
    },
  }),
);

export const HomePage: React.FC = () => {
  const classes = useStyles();
  const { homeResponse, loaded } = useSelector(selectHome);
  const dispatch = useDispatch();
  const { isAuthenticated } = useSelector(selectAuth);

  useEffect(() => {
    reportGA(GAEventValue.HOME);
  }, []);

  useEffect(() => {
    if (!loaded || !isAuthenticated) return;
    dispatch(fetchEffectList({}))
  }, [loaded, isAuthenticated]);

  const 給与額目標達成率 = !homeResponse.目標給与額 ? 0 : homeResponse.前日までの想定給与額 / homeResponse.目標給与額;

  function compare(a: number, b: number): boolean | undefined {
    if (!a) return undefined;
    if (a === b) return undefined;
    return a > b;
  }

  return (
    <>
      <Grid container>
        <Grid item xs={12} className={classes.r1}>
          <Paper elevation={0} className={classes.paper}>
            <div>
              {/* 達成率がマイナスの場合は 0% と表示する */}
              <span className={classes.t1}>
                {0 === 給与額目標達成率 || 10 <= 給与額目標達成率
                  ? 'ー'
                  : Math.round(Math.max(0, 給与額目標達成率) * 100)}
              </span>
              <span className={classes.t2}>{0 === 給与額目標達成率 || 10 <= 給与額目標達成率 ? '' : '%'}</span>
            </div>
          </Paper>
        </Grid>

        <Grid item xs={3} className={classes.r2}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.t3}>{useTenpoPointUnitType('ポイント', '小計', '')}</div>
            <div className={classes.t4}>
              <FormattedNumber value={homeResponse.現在獲得ポイント} />
            </div>
            <div className={classes.t5}>{useTenpoPointUnitType('pt', '円', '')}</div>
          </Paper>
        </Grid>
        <Grid item xs={6} className={classNames(classes.r2, classes.circleContainer)}>
          <GradientCircle size={187} value={給与額目標達成率} />
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.t6}>給 与</div>
            <div className={classes.t7}>
              <FormattedNumber value={homeResponse.前日までの想定給与額} />
            </div>
            <div className={classes.t8}>円</div>
          </Paper>
        </Grid>
        <Grid item xs={3} className={classes.r2}>
          <Paper elevation={0} className={classes.paper}>
            <div className={classes.t3}>勤務時間</div>
            <div className={classes.t4}>{Math.floor((homeResponse.現在勤務時間 * 10) / 60) / 10}</div>
            <div className={classes.t5}>時間</div>
          </Paper>
        </Grid>

        <Grid item xs={12} className={classes.r3}>
          <BadgeSwiper />
        </Grid>

        <Grid item xs={12} className={classes.r4ListItem}>
          <HomeRankingListItem
            type='shop'
            value1={homeResponse.店舗ランキング}
            isUp={compare(homeResponse.前期店舗ランキング, homeResponse.店舗ランキング)}
            url={Path.graph + '?si=2'}
            ノルマ日数割合={homeResponse.ノルマ経過日数割合}
          />
        </Grid>
        <Grid item xs={12} className={classes.r4ListItem}>
          <HomeRankingListItem
            type='group'
            value1={homeResponse.グループランキング}
            isUp={compare(homeResponse.前期グループランキング, homeResponse.グループランキング)}
            url={Path.pyramid}
            ノルマ日数割合={homeResponse.ノルマ経過日数割合}
          />
        </Grid>
        <Grid item xs={12} className={classes.r4ListItem}>
          <HomeRankingListItem type='douhan' value1={homeResponse.同伴数} url={Path.graph + '?si=4'} ノルマ日数割合={homeResponse.ノルマ経過日数割合} />
        </Grid>
        <Grid item xs={12} className={classes.r4ListItem}>
          <HomeRankingListItem type='shimei' value1={homeResponse.指名数} url={Path.graph + '?si=4'} ノルマ日数割合={homeResponse.ノルマ経過日数割合} />
        </Grid>
        <Grid item xs={12} className={classes.r4ListItem}>
          <HomeRankingListItem
            type='free'
            value1={homeResponse.フリー件数}
            value2={Math.round(homeResponse.フリー場内率)}
            url={Path.graph + '?si=3'}
            ノルマ日数割合={homeResponse.ノルマ経過日数割合}
            isLast
          />
        </Grid>
      </Grid>
      <BadgeDetailDialog />
    </>
  );
};
