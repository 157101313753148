import React, { useEffect, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
    },
  }),
);

const countChar = function (str: string): number {
  let len = 0;
  str = escape(str);
  for (let i = 0; i < str.length; i++, len++) {
    if (str.charAt(i) === '%') {
      if (str.charAt(++i) === 'u') {
        i += 3;
        len++;
      }
      i++;
    }
  }
  return len;
};
type Props = {
  content: string;
  size: number;
};

export function SloppyScalableText(props: Props) {
  const classes = useStyles();
  const getScale = (s: string): number => {
    const b = countChar(s);
    if (b > props.size) {
      return props.size / b;
    }
    return 1;
  };

  const [scale, setScale] = useState<number>(getScale(props.content));
  useEffect(() => {
    setScale(getScale(props.content));
  }, [props.content]);
  const style = {
    fontSize: scale * 100 + '%',
  };
  return (
    <span className={classes.root} style={style}>
      {props.content}
    </span>
  );
}
