import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { Divider, Grid } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { imagePath } from 'lib/pathes';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    listContainer: {
      height: '100%',
      paddingLeft: 10,
      paddingRight: 5,
    },
    listItem: {
      // backgroundColor: 'black',
      textAlign: 'center',
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',

      // border: 'solid 1px black',
    },
    listItem1: {},
    listItem2: {
      textAlign: 'left',
      fontSize: 13.5,
      lineHeight: '1.2em',
      fontWeight: 'bold',
    },
    listItem5: {
      // textAlign: 'right',
    },
    listIcon1: {
      width: 117 / 3,
      height: 117 / 3,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    listIcon2: {
      width: 143 / 3,
      height: 141 / 3,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    listIconLink: {
      width: 81 / 3,
      height: 80 / 3,
      marginTop: 6,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    listIconArrow: {
      width: 34 / 3.4,
      height: 49 / 3.4,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    divider: {
      marginLeft: 16,
      marginRight: 16,
    },
    value: {
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },
    unit: {
      fontSize: 15,
      fontWeight: theme.typography.fontWeightLight,
      color: theme.palette.primary.light,
    },
    unitEmpty: {
      color: 'transparent',
    },
    valueBG: {
      backgroundSize: `${143 / 3}px ${141 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
    },
    douhanValue: {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      // color: 'black',
      // paddingLeft:0,
      paddingBottom: 9,
    },
    shimeiValue: {
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      // color: 'black',
      // paddingLeft: 1,
      paddingBottom: 10,
    },
    freeValueBG: {
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('icon_home_count_free.png')}')`,
      backgroundSize: 'contain',
    },
    freeValue: {
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      // color: 'black',
      paddingBottom: 2,
      paddingLeft: 3,
      letterSpacing: 1.2,
    },
    freeValue1: {
      fontSize: 17,
    },
    freeValue2: {
      fontSize: 10,
    },
  }),
);

type Props = {
  type: string;
  value1: number;
  value2?: number;
  url: string;
  isUp?: boolean;
  isLast?: boolean;
  ノルマ日数割合: number;
};

// TODO: コンポーネント分割検討
export function HomeRankingListItem(props: Props) {
  const classes = useStyles();
  const history = useHistory();
  let title;
  let linkImg;
  let ArrowIcon;
  let DetailGridItems;

  if (props.value1 === 0) {
    ArrowIcon = null;
  } else if (props.isUp === true) {
    ArrowIcon = <img src={imagePath('icon_home_arrow_up.png')} className={classes.listIconArrow}  alt="前回比アップ" />;
  } else if (props.isUp === false) {
    ArrowIcon = <img src={imagePath('icon_home_arrow_down.png')} className={classes.listIconArrow} alt="前回比ダウン" />;
  } else {
    ArrowIcon = null;
  }

  if (props.type === 'shop') {
    title = '店舗ランキング';
    linkImg = 'icon_home_link_graph.png';
  } else if (props.type === 'group') {
    title = 'グループランキング';
    linkImg = 'icon_home_link_pyramid.png';
  } else if (props.type === 'douhan') {
    title = '同 伴';
    linkImg = 'icon_home_link_graph.png';
  } else if (props.type === 'shimei') {
    title = '指 名';
    linkImg = 'icon_home_link_graph.png';
  } else if (props.type === 'free') {
    title = 'フリー件数（場内率）';
    linkImg = 'icon_home_link_graph.png';
  } else {
    throw new Error('Unsupported type:' + props.type);
  }

  if (props.type === 'shop' || props.type === 'group') {
    // URLに debug.ranking=1 などして表示上のテストをする
    const urlParams = new URLSearchParams(window.location.search);
    const value = urlParams.has("debug.ranking") ? Number(urlParams.get("debug.ranking")) : props.value1;
    let ValueDisplay;
    if (props.type === 'shop' && 0 < value && value <= 10) {
      ValueDisplay = (
        <img
          src={imagePath('home/icon_home_ranking_' + props.type + '_' + String(value).padStart(2, '0') + '.png')}
          className={classes.listIcon2}
          alt={"ランキング"+ value +"位"}
        />
      );
    } else if (props.type === 'group' && 0 < value && value <= 30) {
      ValueDisplay = (
        <img
          src={imagePath('home/icon_home_ranking_' + props.type + '_' + String(value).padStart(2, '0') + '.png')}
          className={classes.listIcon2}
          alt={"ランキング"+ value +"位"}
        />
      );
    } else if (value) {
      ValueDisplay = (
        <div>
          <span className={classes.value}>{value}</span>
          <span className={classes.unit}>位</span>
        </div>
      );
    } else {
      ValueDisplay = (
        <div>
          <span className={classes.value}>−</span>
          <span className={classes.unit}>位</span>
        </div>
      );
    }
    DetailGridItems = (
      <>
        <Grid item xs={2} className={classes.listItem}>
          {ValueDisplay}
        </Grid>
        <Grid item xs={1} className={classes.listItem}>
          {ArrowIcon}
        </Grid>
      </>
    );
  } else if (props.type === 'douhan' || props.type === 'shimei') {
    // URLに debug.ranking=1 などして表示上のテストをする
    const urlParams = new URLSearchParams(window.location.search);
    const value = function (v) {
      if (props.type === 'douhan') {
        return urlParams.has("debug.douhan") ? Number(urlParams.get("debug.douhan")) : v;
      } else {
        return urlParams.has("debug.shimei") ? Number(urlParams.get("debug.shimei")) : v;
      }
    }(props.value1);

    let imageName;
    let unit;
    let valueClassName;

    if (props.type === 'douhan') {
      //同伴：30組以上で表示（背景画像１種を設置し、白抜きの文字列を上に表示し可変とする）
      unit = '件';
      valueClassName = classes.douhanValue;
      if (value === 0) {
        // ignore
      } else if (20*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_douhan_tier1.png';
      } else if (15*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_douhan_tier2.png';
      } else if (10*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_douhan_tier3.png';
      }
    } else if (props.type === 'shimei') {
      //指名：10本以上で表示（背景画像１種を設置し、白抜きの文字列を上に表示し可変とする）
      unit = '本';
      valueClassName = classes.shimeiValue;
      if (value === 0) {
        // ignore
      } else if (70*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_shimei_tier1.png';
      } else if (50*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_shimei_tier2.png';
      } else if (30*props.ノルマ日数割合 <= value) {
        imageName = 'icon_home_count_shimei_tier3.png';
      }
    }

    if (!imageName) {
      DetailGridItems = (
        <>
          <Grid item xs={2} className={classes.listItem}>
            <div>
              {/*<span className={classes.unit + ' ' + classes.unitEmpty}>{unit}</span>*/}
              <span className={classes.value}>{value ? value : '−'}</span>
              <span className={classes.unit}>{unit}</span>
            </div>
          </Grid>
          <Grid item xs={1} />
        </>
      );
    } else {
      DetailGridItems = (
        <>
          <Grid
            item
            xs={2}
            className={classes.listItem + ' ' + classes.valueBG}
            style={{
              backgroundImage: `url('${imagePath(imageName)}')`,
            }}
          >
            <span className={valueClassName}>{value}</span>
          </Grid>
          <Grid item xs={1} />
        </>
      );
    }
  } else if (props.type === 'free') {
    DetailGridItems = (
      <Grid item xs={3} className={classes.listItem + ' ' + classes.freeValueBG}>
        <div className={classes.freeValue}>
          <span className={classes.freeValue1}>{props.value1 ? props.value1 : '−'}</span>
          <span className={classes.freeValue2}>件({props.value2 ? props.value2 : '−'}%)</span>
        </div>
      </Grid>
    );
  }

  return (
    <>
      <Divider className={classes.divider} />
      <Grid container className={classes.listContainer}>
        <Grid item xs={2} className={classes.listItem + ' ' + classes.listItem1}>
          <img src={imagePath('icon_home_title_' + props.type + '.png')} className={classes.listIcon1} alt="カテゴリーシンボル" />
        </Grid>
        <Grid item xs={5} className={classes.listItem + ' ' + classes.listItem2}>
          {title}
        </Grid>
        {DetailGridItems}
        <Grid item xs={2} className={classes.listItem + ' ' + classes.listItem5}>
          <img
            src={imagePath(linkImg)}
            className={classes.listIconLink}
            onClick={() => {
              if (props.url) {
                history.push(props.url);
              }
            }}
            alt="詳細"
          />
        </Grid>
      </Grid>
      {props.isLast && <Divider className={classes.divider} />}
    </>
  );
}
