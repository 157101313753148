import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import {RootState} from './store';

import {
  EffectDto,
  fetchEffectList, fetchEffectListAction
} from 'lib/api';
import {buildEffectArg, EffectArg} from "../lib/effectLib";

interface ActorState {
  showEffect: boolean;
  effectArg: EffectArg | null;
  effects: EffectDto[] | null;
  effectListLoaded: boolean;

  maskColorR: string;
  maskColorG: string;
  maskColorB: string;
  maskOpacity: string;
  maskBlurPx: string;
}

const initialState: ActorState = {
  showEffect: false,
  effectArg: null,
  effects: null,
  effectListLoaded: false,

  maskColorR: '0',
  maskColorG: '0',
  maskColorB: '0',
  maskOpacity: '0.4',
  maskBlurPx: '3',
};

export const effectSlice = createSlice({
  name: 'effect',
  initialState,
  reducers: {
    setShowEffect: (state, action: PayloadAction<boolean>) => {
      state.showEffect = action.payload;
    },
    setEffectArg: (state, action: PayloadAction<EffectArg | null>) => {
      state.effectArg = action.payload;
      if(!action.payload){
        state.showEffect = false;
        state.effects = null;
      }
    },
    setMaskColorR: (state, action: PayloadAction<string>) => {
      state.maskColorR = action.payload;
    },
    setMaskColorG: (state, action: PayloadAction<string>) => {
      state.maskColorG = action.payload;
    },
    setMaskColorB: (state, action: PayloadAction<string>) => {
      state.maskColorB = action.payload;
    },
    setMaskOpacity: (state, action: PayloadAction<string>) => {
      state.maskOpacity = action.payload;
    },
    setMaskBlurPx: (state, action: PayloadAction<string>) => {
      state.maskBlurPx = action.payload;
    },
  },
  extraReducers: {
    [fetchEffectList.fulfilled.toString()]: (state, action: fetchEffectListAction) => {
      state.effectListLoaded = true;
      state.effects = action.payload.response.effects;
      if(!!state.effectArg) return;
      state.effectArg = buildEffectArg(action.payload.response.effects);
    },
  },
});

export const {
  setShowEffect,
  setEffectArg,
  setMaskColorR,
  setMaskColorG,
  setMaskColorB,
  setMaskOpacity,
  setMaskBlurPx,
} = effectSlice.actions;

export const selectEffect = (state: RootState) => state.effect;

export default effectSlice.reducer;
