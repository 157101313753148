import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import {
  FormControl,
  FormControlLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  TextField,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import moment from 'moment';

import { getInnerContentHeight } from '../consts';
import { selectSchedule } from '../app/scheduleSlice';
import { fetchActorVisitorCreate, fetchActorVisitorUpdate, fetchShiftCalendar } from '../lib/api';
import { selectApplication, setVisitorScheduleFormDialogParams, showSuccessAlert } from '../app/applicationSlice';

import { StandardButton } from './StandardButton';

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20; // footer分
const r1Height = 100;
const r3Height = 20;
const r2Height = containerHeight - r1Height - r3Height;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 13,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 0,
      paddingTop: 43,
      // backgroundColor:'red'
    },
    r2: {
      height: r2Height,
    },
    r2Table: {},
    r3: {
      height: r3Height,
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 42,
      paddingBottom: 0,
    },
    noBorder: {
      border: 'none',
    },
    tdValue: {
      minWidth: 116,
      paddingRight: 10,
    },
    button: {
      minWidth: 100,
    },
  }),
);
const padding = '　';
export const VisitorScheduleFormDialog: React.FC = () => {
  const classes = useStyles();
  const { visitorScheduleFormDialogParams, visitorScheduleFormDialogParamsOrg } = useSelector(selectApplication);
  const { vipRoomList } = useSelector(selectSchedule);

  const dispatch = useDispatch();

  // const { control, handleSubmit } = useForm({
  //   // defaultValues: { ...visitorScheduleFormDialogParams }
  //   defaultValues: {
  //     title: visitorScheduleFormDialogParams ? visitorScheduleFormDialogParams.title : '',
  //     // textBox: "",
  //     // pullDown: "",
  //   },
  // });

  const onClose = () => {
    dispatch(setVisitorScheduleFormDialogParams(null));
  };

  const onSubmit = async () => {
    if (visitorScheduleFormDialogParams) {
      onClose();
      if (visitorScheduleFormDialogParams.id) {
        await dispatch(
          fetchActorVisitorUpdate({
            id: visitorScheduleFormDialogParams.id,
            title: visitorScheduleFormDialogParams.title,
            douhan: visitorScheduleFormDialogParams.douhan,
            vip: !visitorScheduleFormDialogParams.vip ? '' : visitorScheduleFormDialogParams.vip,
          }),
        );
        dispatch(showSuccessAlert('お客様予定を登録しました'));
      } else {
        await dispatch(
          fetchActorVisitorCreate({
            date: visitorScheduleFormDialogParams.date,
            title: visitorScheduleFormDialogParams.title,
            douhan: visitorScheduleFormDialogParams.douhan,
            vip: !visitorScheduleFormDialogParams.vip ? '' : visitorScheduleFormDialogParams.vip,
          }),
        );
        dispatch(showSuccessAlert('お客様予定を更新しました'));
      }
      const d = moment(visitorScheduleFormDialogParams.date);
      dispatch(fetchShiftCalendar({ year: d.year(), month: d.month() + 1, day: d.date() }));
    }
  };

  // const handleTitleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
  //   if(visitorScheduleFormDialogParams){
  //     dispatch(setVisitorScheduleFormDialogParams({...visitorScheduleFormDialogParams, title:event.target.value}));
  //   }
  // };

  const rows = [];
  if (visitorScheduleFormDialogParams) {
    rows.push({ key: null, title: 'お客様予定', value: '' });
    // rows.push({ key: null, title: padding + '名前', value: (
    //     <Controller
    //       control={control}
    //       name="title"
    //       render={({ field }) => (
    //         <TextField
    //           {...field}
    //           label="テキストフィールド"
    //           fullWidth
    //           margin="normal"
    //           placeholder="プレースホルダー"
    //         />
    //       )}
    //     />
    //   ) });
    // TODO フォームライブラリ導入検討
    rows.push({
      key: null,
      title: padding + '名前',
      value: (
        <TextField
          fullWidth
          margin='normal'
          defaultValue={visitorScheduleFormDialogParams.title}
          onChange={(e) =>
            dispatch(setVisitorScheduleFormDialogParams({ ...visitorScheduleFormDialogParams, title: e.target.value }))
          }
        />
      ),
    });
    rows.push({
      key: null,
      title: padding + '同伴',
      value: (
        <FormControl component='fieldset'>
          <RadioGroup
            row
            defaultValue={visitorScheduleFormDialogParams.douhan ? 'true' : 'false'}
            onChange={(e) =>
              dispatch(
                setVisitorScheduleFormDialogParams({
                  ...visitorScheduleFormDialogParams,
                  douhan: e.target.value === 'true',
                }),
              )
            }
          >
            <FormControlLabel value='true' control={<Radio color='primary' />} label='有' labelPlacement='start' />
            <FormControlLabel value='false' control={<Radio color='primary' />} label='無' labelPlacement='start' />
          </RadioGroup>
        </FormControl>
      ),
    });
    rows.push({
      key: null,
      title: padding + 'VIP',
      value: (
        <FormControl>
          <Select
            defaultValue={!visitorScheduleFormDialogParams.vip ? '' : visitorScheduleFormDialogParams.vip}
            onChange={(e) =>
              dispatch(
                setVisitorScheduleFormDialogParams({ ...visitorScheduleFormDialogParams, vip: e.target.value + '' }),
              )
            }
            displayEmpty
          >
            <MenuItem value={''}>
              <em>未選択</em>
            </MenuItem>
            {vipRoomList.map((vipRoom) => (
              <MenuItem
                key={vipRoom.id}
                value={vipRoom.id}
                disabled={
                  !vipRoom.enable &&
                  !(!!visitorScheduleFormDialogParamsOrg && visitorScheduleFormDialogParamsOrg.vip === vipRoom.id)
                }
              >
                {vipRoom.name}（{vipRoom.price}円）
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      ),
    });
  }

  return (
    <Dialog open={visitorScheduleFormDialogParams !== null} onClose={() => onClose()} maxWidth={false}>
      {!!visitorScheduleFormDialogParams && (
        <Grid container className={classes.container}>
          <Grid item xs={12}>
            <Grid container className={classNames(classes.r1)}>
              <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
              <Grid item xs={8} className={classNames(classes.r1C2)}>
                <div>
                  <div className={classes.title}>
                    {moment(visitorScheduleFormDialogParams.date).format('YYYY年M月D日')}
                  </div>
                </div>
              </Grid>
              <Grid item xs={2} className={classNames(classes.r1C3)}>
                <CloseIcon onClick={() => onClose()} />
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={10} className={classes.r2}>
            {/*<form onSubmit={handleSubmit(onSubmit)}>*/}
            <TableContainer className={classNames(classes.r2, classes.r2Table)} component={Paper}>
              <Table>
                <TableBody>
                  {rows.map((row) => (
                    <TableRow key={!row.key ? row.title : row.key}>
                      <TableCell
                        component='th'
                        scope='row'
                        className={classes.noBorder}
                        style={{ paddingLeft: 10, paddingRight: 4 }}
                      >
                        {row.title}
                      </TableCell>
                      <TableCell
                        align='right'
                        className={classNames(classes.noBorder, classes.tdValue)}
                        style={{ paddingLeft: 4 }}
                      >
                        {row.value}
                      </TableCell>
                    </TableRow>
                  ))}
                  <TableRow>
                    <TableCell
                      component='td'
                      scope='row'
                      className={classes.noBorder}
                      style={{ paddingLeft: 10, paddingRight: 4 }}
                    >
                      <StandardButton onClick={onSubmit} className={classes.button}>
                        確定
                      </StandardButton>
                    </TableCell>
                    <TableCell
                      align='right'
                      className={classNames(classes.noBorder, classes.tdValue)}
                      style={{ paddingRight: 10 }}
                    >
                      <StandardButton onClick={onClose} className={classes.button}>
                        キャンセル
                      </StandardButton>
                    </TableCell>
                  </TableRow>
                </TableBody>
              </Table>
            </TableContainer>
            {/*</form>*/}
          </Grid>
          <Grid item xs={1} className={classes.r2}></Grid>
          <Grid item xs={12} className={classes.r3}></Grid>
        </Grid>
      )}
    </Dialog>
  );
};
