import React, { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import classNames from 'classnames';
import { VariableSizeList } from 'react-window';

import { Path } from '../routes';
import { getInnerContentHeight } from '../consts';
import { helpList } from '../lib/dataFunctions';
import { imagePath } from '../lib/pathes';
import { SloppyScalableText } from '../components';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

const contentHeight = getInnerContentHeight();
const verticalPadding = 8;
const horizontalPadding = 18;
const listHeight = 82;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: '100%',
    },
    listItemContainer: {
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    listItemFirst: {
      paddingTop: verticalPadding,
      borderTop: 'none',
    },
    listItemLast: {
      paddingBottom: verticalPadding,
    },
    grid: {
      borderTop: '1px #D5D6D6 solid',
    },

    gridCell1: {
      paddingTop: 19,
      paddingLeft: 1,
      paddingRight: 10,
    },
    gridCell2: {
      textAlign: 'center',
      paddingTop: 16,
      // paddingLeft: 2,
    },

    overflowableText: {
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
    },
    title: {
      fontSize: 16,
      fontWeight: theme.typography.fontWeightBold,
    },
    body: {
      fontSize: 13,
      paddingTop: 7,
      paddingLeft: 2,
      color: theme.palette.text.hint,
    },
    listImage: {
      width: 50,
    },
  }),
);

export const HelpPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const elm = useRef<HTMLDivElement>(null);
  const [listContainerHeight, setListContainerHeight] = useState(contentHeight);

  useLayoutEffect(() => {
    if (!!elm && !!elm.current) {
      const rect = elm.current.getBoundingClientRect();
      setListContainerHeight(rect.height);
    }
  }, [elm, elm.current]);

  useEffect(() => {
    reportGA(GAEventValue.MYPAGE_BPRANK)
  }, []);

  return (
    <>
      <div className={classes.root} ref={elm}>
        <VariableSizeList
          height={listContainerHeight}
          width={'100%'}
          itemSize={(index) =>
            index === 0 || index === helpList.length - 1 ? listHeight + verticalPadding : listHeight
          }
          itemCount={helpList.length}
        >
          {({ index, style }) => (
            <div
              style={style}
              className={classNames(
                classes.listItemContainer,
                { [classes.listItemFirst]: index === 0 },
                { [classes.listItemLast]: index === helpList.length - 1 },
              )}
              onClick={(e) => history.push(Path.help + '/' + index)}
            >
              <Grid container className={classNames({ [classes.grid]: index !== 0 })}>
                <Grid item xs={10} className={classes.gridCell1}>
                  <div className={classNames(classes.overflowableText, classes.title)}>
                    <SloppyScalableText content={helpList[index].summary} size={34} />
                  </div>
                  <div className={classNames(classes.overflowableText, classes.body)}>{helpList[index].body}</div>
                </Grid>

                <Grid item xs={2} className={classNames(classes.gridCell2)}>
                  {helpList[index].image && (
                    <img className={classes.listImage} src={imagePath(helpList[index].image)} alt="バッヂアイコンサンプル" />
                  )}
                </Grid>
              </Grid>
            </div>
          )}
        </VariableSizeList>
      </div>
    </>
  );
};
