import React from 'react';
import Dialog from '@material-ui/core/Dialog';
import { useDispatch, useSelector } from 'react-redux';

import { selectHome } from '../../app/homeSlice';

import { BadgeDetailContents } from './BadgeDetailContents';

import { selectApplication, setBadgeDialogCategory } from 'app/applicationSlice';
import { ActorBadgeCategoryValue } from 'lib/api';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

export const BadgeDetailDialog: React.FC = () => {
  const dispatch = useDispatch();
  const { badgeDialogCategory } = useSelector(selectApplication);
  const { badgeList } = useSelector(selectHome);

  const onClose = () => {
    dispatch(setBadgeDialogCategory({ category: null }));
  };
  const badge = badgeList.find((b) => b.category === badgeDialogCategory);

  const gaUrlKeys = {
    [ActorBadgeCategoryValue.店舗ポイントランキング]: GAEventValue.HOME_BADGE_TPOINT,
    [ActorBadgeCategoryValue.グループポイントランキング]: GAEventValue.HOME_BADGE_GPOINT,
    [ActorBadgeCategoryValue.グループ同伴ランキング]: GAEventValue.HOME_BADGE_DOUHAN,
    [ActorBadgeCategoryValue.ポイント]: GAEventValue.HOME_BADGE_SAIKOU,
    [ActorBadgeCategoryValue.サンクスコイン]: GAEventValue.HOME_BADGE_THANKS,
  };
  const localReportGA = () => {
    if (!badge) return;
    reportGA(gaUrlKeys[badge.category], badge.monthName);
  };

  return (
    <Dialog
      open={!!badge}
      onClose={() => onClose()}
      maxWidth={false}
      PaperProps={{
        style: {
          boxShadow: 'none',
          borderRadius: 0,
        },
      }}
      onEntered={() => {localReportGA()}}
    >
      <BadgeDetailContents badge={badge} handleOnClose={onClose} />
    </Dialog>
  );
};
