import React, { useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import Grid from '@material-ui/core/Grid';
import CloseIcon from '@material-ui/icons/Close';
import classNames from 'classnames';
import Slider from '@material-ui/core/Slider';

import { selectHome } from '../app/homeSlice';
import { PercentageInGroup } from '../lib/api';
import { Path } from '../routes';
import { getInnerContentHeight } from '../consts';
import { NoData } from '../components/NoData';
import { imagePath } from '../lib/pathes';
import { LoadingOverlay } from '../components/LoadingOverlay';

const contentHeight = getInnerContentHeight();
const r1Height = 140;
const r2Height = 332;
const r3Height = contentHeight - r1Height - r2Height;

const pyramidWidth = 297;
const indicatorSize = 2;
const indicatorAreaHeight = 22;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
    },
    container: {},

    r1: {
      height: r1Height,
    },
    r1C2: {
      textAlign: 'center',
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      paddingTop: 33,
    },
    r1C3: {
      paddingRight: 0,
      paddingLeft: 18,
      paddingTop: 20,
      color: theme.palette.text.hint,
    },
    title1: {
      fontSize: 30,
      letterSpacing: 1.2,
    },
    title2: {
      fontSize: 21,
    },

    r2: {
      height: r2Height,
      display: 'flex',
      justifyContent: 'space-between',
    },
    r2c1: {
      width: 39,
      textAlign: 'right',
      paddingTop: 117,
    },
    r2c2: {
      width: pyramidWidth,
      position: 'relative',
      backgroundImage: `url(${imagePath('5_pyramid/pyramid.png')})`,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
    },
    indicatorArea: {
      position: 'absolute',
      width: 118,
      height: indicatorAreaHeight,
      left: pyramidWidth / 2,
      display: 'flex',
      justifyContent: 'space-between',
    },
    indicatorAreaBar: {
      height: indicatorSize,
      width: 79,
      backgroundColor: theme.palette.primary.main,
    },
    indicatorAreaText: {
      paddingLeft: 6,
      color: theme.palette.primary.light,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
    },
    indicatorAreaTextUnit: {
      fontSize: 12,
    },
    innerIndicatorLine: {
      position: 'absolute',
      height: indicatorSize,
      width: '100%',
      backgroundColor: theme.palette.primary.main,
    },
    r2c3: {
      width: 39,
      paddingTop: 117,
    },
    linkImage: {
      width: 26,
    },

    r3: {
      height: r3Height,
    },
    hukidashi: {
      marginTop: 22.5,
      marginLeft: 'auto',
      marginRight: 'auto',
      width: 309.5,
      height: 104,
      backgroundSize: 'contain',
      backgroundRepeat: 'no-repeat',
      paddingTop: 28,
      paddingLeft: 7,
    },
    hukidashiTextArea: {
      width: 295.5,
      height: 70,
      color: theme.palette.primary.light,
      fontWeight: theme.typography.fontWeightBold,
      letterSpacing: 1.2,
    },
    hukidashiText1: {
      textAlign: 'center',
      fontSize: 14,
    },
    hukidashiText2: {
      textAlign: 'center',
      fontSize: 46,
    },
    hukidashiText3: {
      paddingLeft: 2,
      fontSize: 23,
    },
  }),
);

const pyramidImageInfo = {
  width: 890,
  height: 992,
  linePositions: {
    '2%': 50,
    '10%': 129,
    '30%': 318,
    '50%': 506,
  },
};

// ピラミッド画像のパーセンテージライン位置がずれているため、縦位置を補正する
function convVerticalRatio(v: number): number {
  if (0 <= v && v <= 0.02) {
    return (v * (pyramidImageInfo.linePositions['2%'] / pyramidImageInfo.height)) / 0.02;
  } else if (0.02 < v && v <= 0.1) {
    return (
      (v - 0.02) *
        ((pyramidImageInfo.linePositions['10%'] - pyramidImageInfo.linePositions['2%']) /
          pyramidImageInfo.height /
          (0.1 - 0.02)) +
      convVerticalRatio(0.02)
    );
  } else if (0.1 < v && v <= 0.3) {
    return (
      (v - 0.1) *
        ((pyramidImageInfo.linePositions['30%'] - pyramidImageInfo.linePositions['10%']) /
          pyramidImageInfo.height /
          (0.3 - 0.1)) +
      convVerticalRatio(0.1)
    );
  } else if (0.3 < v && v <= 0.5) {
    return (
      (v - 0.3) *
        ((pyramidImageInfo.linePositions['50%'] - pyramidImageInfo.linePositions['30%']) /
          pyramidImageInfo.height /
          (0.5 - 0.3)) +
      convVerticalRatio(0.3)
    );
  } else {
    return (
      (v - 0.5) *
        ((pyramidImageInfo.height - pyramidImageInfo.linePositions['50%']) / pyramidImageInfo.height / (1 - 0.5)) +
      convVerticalRatio(0.5)
    );
  }
}
function getHukidashiBG(v: number): string {
  if (0 <= v && v <= 0.02) {
    return imagePath('5_pyramid/pyramid_hukidashi_01.png');
  } else if (0.02 < v && v <= 0.1) {
    return imagePath('5_pyramid/pyramid_hukidashi_02.png');
  } else if (0.1 < v && v <= 0.3) {
    return imagePath('5_pyramid/pyramid_hukidashi_03.png');
  } else if (0.3 < v && v <= 0.5) {
    return imagePath('5_pyramid/pyramid_hukidashi_05.png');
  } else {
    return imagePath('5_pyramid/pyramid_hukidashi_06.png');
  }
}

const DEBUG = false;

type Props = {
  v: PercentageInGroup;
  toPrev?: () => void;
  toNext?: () => void;
};
export const PyramidContents = (props: Props) => {
  const classes = useStyles();
  const { loaded } = useSelector(selectHome);
  const history = useHistory();
  const [value, setValue] = useState(0.0);

  const percentageInGroup = props.v;

  const v = !DEBUG && !!percentageInGroup ? percentageInGroup.value : value / 100;
  const displayValue = convVerticalRatio(v);
  const innerIndicatorStyle = {
    width: pyramidWidth * displayValue,
    top: r2Height * displayValue - indicatorSize / 2,
    left: pyramidWidth / 2 - (pyramidWidth / 2) * displayValue,
  };
  const indicatorAreaStyle = {
    top: r2Height * displayValue - indicatorAreaHeight / 2,
  };

  const onClose = () => {
    history.push(Path.home);
  };
  let contents = null;
  if (percentageInGroup) {
    contents = (
      <Grid container className={classes.container}>
        <Grid item xs={2} className={classNames(classes.r1)}></Grid>
        <Grid item xs={8} className={classNames(classes.r1, classes.r1C2)}>
          <div className={classes.title1}>{(percentageInGroup.yearMonth / 100).toFixed(2)}</div>
          <div className={classes.title2}>グループランキング</div>
          {DEBUG && (
            <Slider
              value={value}
              onChange={(e, v) => {
                // @ts-ignore
                setValue(v);
              }}
            />
          )}
        </Grid>
        <Grid item xs={2} className={classNames(classes.r1, classes.r1C3)}>
          <CloseIcon onClick={onClose} />
        </Grid>

        <Grid item xs={12} className={classNames(classes.r2)}>
          <div
            className={classes.r2c1}
            onClick={() => {
              if (props.toPrev) props.toPrev();
            }}
          >
            {!!props.toPrev && <img src={imagePath('5_pyramid/pyramid_left_01.png')} className={classes.linkImage} alt="先月" />}
          </div>
          <div className={classes.r2c2}>
            <div className={classes.innerIndicatorLine} style={innerIndicatorStyle}></div>

            {v <= 0.5 && (
              <div className={classNames(classes.indicatorArea)} style={indicatorAreaStyle}>
                <div className={classes.verticalCenter}>
                  <div className={classNames(classes.indicatorAreaBar)}></div>
                </div>
                <div className={classes.verticalCenter}>
                  <div className={classes.indicatorAreaText}>
                    {(v * 100).toFixed(1)}
                    <span className={classes.indicatorAreaTextUnit}>％</span>
                  </div>
                </div>
              </div>
            )}
          </div>
          <div
            className={classes.r2c3}
            onClick={() => {
              if (props.toNext) props.toNext();
            }}
          >
            {!!props.toNext && <img src={imagePath('5_pyramid/pyramid_right_01.png')} className={classes.linkImage} alt="次月" />}
          </div>
        </Grid>

        <Grid item xs={12} className={classNames(classes.r3)}>
          <div className={classes.hukidashi} style={{ backgroundImage: `url(${getHukidashiBG(v)})` }}>
            <Grid container className={classes.hukidashiTextArea}>
              <Grid item xs={6} className={classNames(classes.hukidashiText1, classes.verticalCenter)}>
                あなたは{v <= 0.5 ? '上位' : '下位'}
              </Grid>
              <Grid item xs={5} className={classNames(classes.hukidashiText2, classes.verticalCenter)}>
                <div>
                  {(v * 100).toFixed(1)}
                  <span className={classes.hukidashiText3}>％</span>
                </div>
              </Grid>
            </Grid>
          </div>
        </Grid>
      </Grid>
    );
  } else {
    contents = (
      <Grid container className={classes.container}>
        <Grid item xs={10} className={classNames(classes.r1)}></Grid>
        <Grid item xs={2} className={classNames(classes.r1, classes.r1C3)}>
          <CloseIcon onClick={onClose} />
        </Grid>
        <Grid item xs={12}>
          {loaded ? <NoData /> : <LoadingOverlay />}
        </Grid>
      </Grid>
    );
  }

  return <>{contents}</>;
};
