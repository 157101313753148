import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Grid, Theme, createStyles, makeStyles } from '@material-ui/core';
import classNames from 'classnames';
import { FormattedNumber } from 'react-intl';
import { useHistory } from 'react-router-dom';

import { getFaceImagePath, imagePath } from '../lib/pathes';
import { GradientCircle, SloppyScalableText } from '../components';
import { selectMyPage } from '../app/myPageSlice';
import { selectHome } from '../app/homeSlice';
import { BadgeDetailSwiperDialog } from '../components/Badge/BadgeDetailSwiperDialog';
import { getBadgeImageDefault, getBadgeImageOff, orderedBadgeCategories } from '../lib/dataFunctions';
import { setBadgeDialogCategory } from '../app/applicationSlice';
import { ActorBadgeCategory, fetchActorLogout, fetchMyPage } from '../lib/api';
import { Path } from '../routes';

import { setIsAuthenticated } from 'app/authSlice';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      height: '100%',
      backgroundSize: 'cover',
      backgroundRepeat: 'no-repeat',
    },

    r1: {
      height: 53,
      paddingTop: 15,
      paddingRight: 11,
      textAlign: 'right',
    },
    logoutButton: {
      width: 99,
    },

    r2: {
      height: 64,
      textAlign: 'center',
    },
    name: {
      fontSize: 25,
      fontWeight: theme.typography.fontWeightBold,
      paddingLeft: 14,
      paddingRight: 14,
    },

    r3: {
      height: 188,
    },
    r3c1: {
      textAlign: 'center',
      fontSize: 12,
    },
    r3c2: {
      position: 'relative',
    },
    r3c3: {
      textAlign: 'center',
      fontSize: 12,
    },
    strong: {
      fontSize: 23,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },
    rank: {
      fontSize: 16,
    },
    r3c3line: {
      height: 1,
      width: 66,
      backgroundColor: theme.palette.text.primary,
      marginLeft: 'auto',
      marginRight: 'auto',
      marginTop: 6,
      marginBottom: 10,
    },
    faceImage: {
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      position: 'absolute',
      width: 146,
      height: 146,
      borderRadius: '50%',
      margin: 'auto',
      objectFit: 'cover',
    },

    r4: {
      height: 52,
      paddingTop: 15,
      textAlign: 'center',
    },
    goalButton: {
      width: 143,
    },

    r5: {
      height: 79,
      paddingTop: 46,
      textAlign: 'center',
      fontSize: 19,
      fontWeight: theme.typography.fontWeightBold,
    },

    r6: {
      height: 95,
      textAlign: 'center',
    },
    badgeImage: {
      width: 65,
      marginLeft: 2,
      marginRight: 2,
    },
    badgeNum: {
      display: 'inline-block',
      width: 65,
      marginLeft: 2,
      marginRight: 2,
      lineHeight: 0.9,
      fontSize: 20,
      fontWeight: theme.typography.fontWeightBold,
      color: theme.palette.primary.light,
    },

    r7: {
      height: 99,
      paddingTop: 29,
      textAlign: 'center',
    },
    descriptionButton: {
      width: 220,
    },
  }),
);

const defaultImageUrl = imagePath('no_image.png');
export const MyPage: React.FC = () => {
  const classes = useStyles();
  const history = useHistory();
  const dispatch = useDispatch();
  const { myPageResponse } = useSelector(selectMyPage);
  const { homeResponse } = useSelector(selectHome);
  useEffect(() => {
    // TODO キャッシュ？
    dispatch(fetchMyPage({}));

    reportGA(GAEventValue.MYPAGE);
  }, []);

  const logout = async () => {
    await dispatch(setIsAuthenticated(false));
    await dispatch(fetchActorLogout({}));
    window.location.href = window.location.origin + process.env.PUBLIC_URL + '/login';
  };
  const openBadgeTypeDetail = (category: ActorBadgeCategory) => {
    dispatch(setBadgeDialogCategory({ category }));
  };

  const imageUrl = getFaceImagePath(homeResponse.actorFacePhotoId);
  let backgroundImageUrl = '';
  if (myPageResponse.ランクID === 1) {
    backgroundImageUrl = `url(${imagePath('9_mypage/mypage_bg2.png')})`;
  } else if (myPageResponse.ランクID === 2) {
    backgroundImageUrl = `url(${imagePath('9_mypage/mypage_bg3.png')})`;
  } else if (myPageResponse.ランクID === 3) {
    backgroundImageUrl = `url(${imagePath('9_mypage/mypage_bg4.png')})`;
  }

  const badgeImages = orderedBadgeCategories.map((type, index) => {
    if (!myPageResponse.allBadgeList.find((b) => b.category === type)) {
      return <img key={index} className={classes.badgeImage} src={imagePath(getBadgeImageOff(type))} alt="未獲得表彰バッヂ" />;
    } else {
      return (
        <img
          key={index}
          className={classes.badgeImage}
          src={imagePath(getBadgeImageDefault(type))}
          onClick={() => openBadgeTypeDetail(type)}
          alt="表彰バッヂ"
        />
      );
    }
  });
  const badgeNums = orderedBadgeCategories.map((type, index) => {
    return (
      <div key={index} className={classes.badgeNum}>
        {myPageResponse.allBadgeList.filter((b) => b.category === type).length}
      </div>
    );
  });

  const actorName = homeResponse.actorName ? homeResponse.actorName.replace(/[　|\s]/g, '') : '';

  return (
    <div className={classes.root} style={{ backgroundImage: backgroundImageUrl }}>
      <Grid container>
        <Grid item xs={12} className={classes.r1}>
          <img className={classes.logoutButton} src={imagePath('9_mypage/mypage_log-out.png')} onClick={logout} alt="ログアウト" />
        </Grid>

        <Grid item xs={12} className={classNames(classes.r2, classes.verticalCenter)}>
          <div className={classes.name}>
            <SloppyScalableText content={homeResponse.tenpoName + ' ' + actorName} size={27} />
          </div>
        </Grid>

        <Grid item xs={3} className={classNames(classes.r3, classes.r3c1, classes.verticalCenter)}>
          <div>現在</div>
          <div className={classes.strong}>
            <FormattedNumber value={myPageResponse.BP} />
          </div>
          <div>BP</div>
        </Grid>
        <Grid item xs={6} className={classNames(classes.r3, classes.r3c2)}>
          <GradientCircle size={188} value={myPageResponse.次のランクまでの達成率} bgColor={'white'} />
          <img
            className={classes.faceImage}
            src={imageUrl}
            onError={(e) => ((e.target as HTMLImageElement).src = defaultImageUrl)}
            alt="顔写真"
          />
        </Grid>
        <Grid item xs={3} className={classNames(classes.r3, classes.r3c3, classes.verticalCenter)}>
          <div className={classNames(classes.strong, classes.rank)}>
            <span style={{ color: myPageResponse.ランク色 }}>
              <SloppyScalableText content={myPageResponse.ランク名} size={10} />
            </span>
          </div>
          <div>ランク</div>
          <div className={classes.r3c3line} />
          <div>次のランク</div>
          <div>まであと</div>
          <div>
            <span className={classes.strong}>
              <FormattedNumber value={Math.max(myPageResponse.次のランクまでのBP, 0)} />
            </span>{' '}
            BP
          </div>
        </Grid>

        <Grid item xs={12} className={classes.r4}>
          <img
            className={classes.goalButton}
            src={imagePath('9_mypage/mypage_mokuhyouu.png')}
            onClick={() => history.push(Path.config)}
            alt="目標設定"
          />
        </Grid>

        <Grid item xs={12} className={classes.r5}>
          表彰バッジ獲得数
        </Grid>

        <Grid item xs={12} className={classes.r6}>
          <div>{badgeImages}</div>
          <div>{badgeNums}</div>
        </Grid>

        <Grid item xs={12} className={classes.r7}>
          <img
            className={classes.descriptionButton}
            src={imagePath('9_mypage/mypage_BP.png')}
            onClick={() => history.push(Path.help)}
            alt="BP・ランク・バッヂについて"
          />
        </Grid>
      </Grid>
      <BadgeDetailSwiperDialog />
    </div>
  );
};
