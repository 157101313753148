import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { FixedSizeList } from 'react-window';
import { Grid } from '@material-ui/core';
import classNames from 'classnames';
import InfiniteLoader from 'react-window-infinite-loader';
import CloseIcon from '@material-ui/icons/Close';

import {
  ActorRegularRankingPeriodType,
  ActorRegularRankingPeriodTypeValue,
  ActorRegularRankingTypeValue,
  EmptyCustomRankingSummaryResponse,
  EmptyRegularRankingSummaryResponse,
} from '../lib/api';
import { RankingDefine, RankingSummary, selectRanking } from '../app/rankingSlice';
import { getCustomRankingValueByIndex, getRegularRankingValueByIndex, isShowAllow } from '../lib/dataFunctions';

import { RankingListItem } from './RankingListItem';
import { SwitchButtons } from './Common/SwitchButtons';
import { PieChart } from './PieChart';

import { getInnerContentHeight } from 'consts';
import { imagePath } from 'lib/pathes';

const contentHeight = getInnerContentHeight();
const containerHeight = contentHeight + 20; // footer分

const r1Height = 48;
const r2Margin = 20;
const r2Height = 123;
const r3Height = containerHeight - r1Height - r2Margin - r2Height;
const INITIAL_FETCH_NUM = 0;
const FETCH_NUM = 100;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: 345,
      height: containerHeight,
    },
    r1: {
      height: r1Height,
    },
    r1C1: {
      paddingLeft: 26,
      paddingTop: 23,
      // backgroundColor:'red'
    },
    r1C2: {
      textAlign: 'center',
      // backgroundColor:'green',
      paddingTop: 18,
    },
    r1C3: {
      paddingRight: 26,
      paddingTop: 23,
      // backgroundColor:'red'
    },
    r2: {
      marginTop: r2Margin,
      height: r2Height,
    },
    r2C1: {
      paddingLeft: 26,
      // backgroundColor:'red'
    },
    r2C2: {
      textAlign: 'center',
      // backgroundColor:'green'
    },
    r2C3: {
      paddingRight: 26,
      // backgroundColor:'red'
    },
    r4: {
      height: r3Height,
      overflow: 'auto',
    },
    r4C1: {
      display: 'flex',
      flexDirection: 'column',
      textAlign: 'center',
      padding: '0 26px',
      gap: '18px',
      marginBottom: 12,
    },
    r3: {
      height: r3Height,
    },
    listItemContainer: {
      display: 'flex',
      justifyContent: 'center',
    },
    title: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 23,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingBottom: 0,
    },
    periodTitle: {
      fontWeight: theme.typography.fontWeightMedium,
      fontSize: 19,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 12,
      paddingBottom: 0,
    },
    selfRank: {
      fontWeight: theme.typography.fontWeightBold,
      fontSize: 19,
      letterSpacing: 0.6,
      lineHeight: 1,
      paddingTop: 23,
    },
    selfRankValue: {
      color: theme.palette.primary.light,
      fontSize: 26,
      letterSpacing: 0.1,
      paddingLeft: 13,
      paddingRight: 15,
    },
    navArrow: {
      width: 76 / 3,
      height: 68 / 3,
    },
    navArrowLabel: {
      color: theme.palette.text.hint,
      paddingTop: 5,
    },
  }),
);

function useData(define: RankingDefine, periodIndex: number, active: boolean) {
  const { customRankingData, customRankingSummary, regularRankingData, regularRankingSummary } = useSelector(
    selectRanking,
  );
  if (define.isRegular) {
    const entries = active
      ? getRegularRankingValueByIndex(regularRankingData, periodIndex, define.periodType, define.rankingType) || []
      : [];
    // entries = [DefaultRankingEntry]

    const summary = active
      ? getRegularRankingValueByIndex(regularRankingSummary, periodIndex, define.periodType, define.rankingType)
      : EmptyRegularRankingSummaryResponse;
    const nextSummary = active
      ? getRegularRankingValueByIndex(regularRankingSummary, periodIndex + 1, define.periodType, define.rankingType)
      : EmptyRegularRankingSummaryResponse;
    const prevSummary = active
      ? getRegularRankingValueByIndex(regularRankingSummary, periodIndex - 1, define.periodType, define.rankingType)
      : EmptyRegularRankingSummaryResponse;

    return { entries, summary, nextSummary, prevSummary };
  } else {
    const entries = active ? getCustomRankingValueByIndex(customRankingData, define.rankingId, periodIndex) || [] : [];
    // entries = [DefaultRankingEntry]

    const summary = active
      ? getCustomRankingValueByIndex(customRankingSummary, define.rankingId, periodIndex)
      : EmptyCustomRankingSummaryResponse;
    const nextSummary = active
      ? getCustomRankingValueByIndex(customRankingSummary, define.rankingId, periodIndex + 1)
      : EmptyCustomRankingSummaryResponse;
    const prevSummary = active
      ? getCustomRankingValueByIndex(customRankingSummary, define.rankingId, periodIndex - 1)
      : EmptyCustomRankingSummaryResponse;

    return { entries, summary, nextSummary, prevSummary };
  }
}

function getPrevPeriodLabel(define: RankingDefine): string {
  if (define.isRegular) {
    return define.periodType === ActorRegularRankingPeriodTypeValue.毎月 ? '先月' : '先年';
  } else {
    return '前期';
  }
}

function getNextPeriodLabel(define: RankingDefine): string {
  if (define.isRegular) {
    return define.periodType === ActorRegularRankingPeriodTypeValue.毎月 ? '次月' : '次年';
  } else {
    return '次期';
  }
}

function getValueTitlePrefix(define: RankingDefine): string {
  if (define.rankingType === ActorRegularRankingTypeValue.お客様ランキング) {
    const index = periodTypeSwitchList.findIndex((v) => v.periodType === define.periodType);
    return periodTypeSwitchList[index].title;
  } else {
    return 'あなたは';
  }
}

function getValueTitleSuffix(define: RankingDefine): string {
  if (define.rankingType === ActorRegularRankingTypeValue.お客様ランキング) {
    return 'pt';
  } else {
    return '位';
  }
}

function getValueTitleValue(summary: RankingSummary | undefined, define: RankingDefine): string {
  if (define.rankingType === ActorRegularRankingTypeValue.お客様ランキング) {
    return summary?.totalPoint ? `${summary?.totalPoint}` : '-';
  } else {
    return summary?.selfRank ? `${summary?.selfRank}` : '-';
  }
}

const periodTypeSwitchList = [
  { title: '月間', periodType: ActorRegularRankingPeriodTypeValue.毎月 },
  { title: '年間', periodType: ActorRegularRankingPeriodTypeValue.毎年 },
];

const rankAndAnalysisSwitchList: {
  title: '割合' | '分析';
}[] = [{ title: '割合' }, { title: '分析' }];

type Props = {
  define: RankingDefine;
  rankAndAnalysis: string;

  periodIndex: number;
  fetchRanking: (define: RankingDefine, periodIndex: number, limit: number, offset: number) => Promise<void>;

  active: boolean;

  activePeriodIndex: number;

  onPrevClicked: () => void;
  onNextClicked: () => void;
  onRegularRankingPeriodTypeChanged: (v: ActorRegularRankingPeriodType) => void;
  onRankAndAnalysisChanged: (v: '割合' | '分析') => void;
  onClose: () => void;
};

export const RankingDetail = (props: Props) => {
  const classes = useStyles();
  const { entries, summary, nextSummary, prevSummary } = useData(props.define, props.periodIndex, props.active);

  const loadMoreItems = async (startIndex: number, stopIndex: number) => {
    console.log('loadMoreItems', startIndex, stopIndex, props.define.rankingType, props.periodIndex);
    await props.fetchRanking(props.define, props.periodIndex, stopIndex - startIndex + 1, startIndex);
  };

  // 初期状態ではInfiniteLoaderからのloadMoreItemsが走らないため、初期取得
  useEffect(() => {
    if (props.active && !summary) {
      loadMoreItems(0, INITIAL_FETCH_NUM);
    }
  }, [props.define, props.periodIndex, props.active, props.activePeriodIndex]);

  // useEffect(() => {
  //   console.log('init', define.rankingType, props.periodIndex, props.active)
  // }, [])
  // if(!props.active)return (<></>)

  // console.log('?', props, summary, entries, DefaultRankingEntry);
  const onBgClick = () => {
    if (props.onClose) props.onClose();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} container className={classes.r1}>
        <Grid item xs={2} className={classNames(classes.r1C1)}></Grid>
        <Grid item xs={8} className={classNames(classes.r1C2)}>
          {props.define.isRegular && (
            <SwitchButtons
              onSelected={(i) => {
                props.onRegularRankingPeriodTypeChanged(periodTypeSwitchList[i].periodType);
              }}
              width={140}
              titles={periodTypeSwitchList.map((v) => v.title)}
              selectedIndex={periodTypeSwitchList.findIndex((v) => v.periodType === props.define.periodType)}
            />
          )}
        </Grid>
        <Grid item xs={2} className={classNames(classes.r1C3)}>
          <CloseIcon onClick={() => onBgClick()} />
        </Grid>
      </Grid>

      <Grid item xs={12} container className={classNames(classes.r2)}>
        <Grid item xs={2} className={classNames(classes.r2C1)}>
          {/*prevSummary?.countが入っていない かつ　 ここではお客様ランキングで2023年表示中の場合 それ以前 にたどれなくしている */}
          {(!!prevSummary?.count && !(props.define.rankingType === ActorRegularRankingTypeValue.お客様ランキング && prevSummary?.periodTitle.includes("2022"))) && (
            <div onClick={props.onPrevClicked}>
              <img className={classes.navArrow} src={imagePath('icon_ranking_detail_prev.png')} alt="前へ" />
              <div className={classes.navArrowLabel}>{getPrevPeriodLabel(props.define)}</div>
            </div>
          )}
        </Grid>
        <Grid item xs={8} className={classNames(classes.r2C2)}>
          <div onClick={onBgClick}>
            <div className={classes.title}>{props.define.name}</div>
            <div className={classes.periodTitle}>{summary?.periodTitle}</div>
            <div className={classes.selfRank}>
              {props.define.rankingType === ActorRegularRankingTypeValue.お客様ランキング ? (
                <SwitchButtons
                  onSelected={(i) => {
                    props.onRankAndAnalysisChanged(rankAndAnalysisSwitchList[i].title);
                  }}
                  width={140}
                  titles={rankAndAnalysisSwitchList.map((v) => v.title)}
                  selectedIndex={rankAndAnalysisSwitchList.findIndex((v) => v.title === props.rankAndAnalysis)}
                />
              ) : (
                <>
                  {getValueTitlePrefix(props.define)}
                  <span className={classes.selfRankValue}>{getValueTitleValue(summary, props.define)}</span>
                  {getValueTitleSuffix(props.define)}
                </>
              )}
            </div>
          </div>
        </Grid>
        <Grid item xs={2} className={classNames(classes.r2C3)}>
          {(!!nextSummary?.count || (props.define.rankingType === ActorRegularRankingTypeValue.お客様ランキング && props.periodIndex < 0)) && (
            <div onClick={props.onNextClicked}>
              <img className={classes.navArrow} src={imagePath('icon_ranking_detail_next.png')} alt="次へ" />
              <div className={classes.navArrowLabel}>{getNextPeriodLabel(props.define)}</div>
            </div>
          )}
        </Grid>
      </Grid>

      {props.define.rankingType === ActorRegularRankingTypeValue.お客様ランキング &&
        props.rankAndAnalysis === "分析" && (
          <Grid item xs={12} className={classes.r4}>
            <Grid item xs={12} className={classes.r4C1}>
              <PieChart titleText='年代割合' label='AgePercentage' entries={entries} />
              <PieChart titleText='職業割合' label='Occupation' entries={entries} />
              <PieChart titleText='地区割合' label='Region' entries={entries} />
            </Grid>
          </Grid>
        )}

      <Grid item xs={12} className={classes.r3}>
        {props.active && !!summary && (
          <InfiniteLoader
            // isItemLoaded={(index) => index < entries.length - 1}
            isItemLoaded={(index) => !!entries[index]}
            // itemCount={rankingEntries2.length + 1}
            // itemCount={100000}
            itemCount={
              summary?.count || 0

              // props.active
              //   ? summary?.count || 0
              //   : 0
            }
            loadMoreItems={loadMoreItems}
            minimumBatchSize={FETCH_NUM}
          >
            {({ onItemsRendered, ref }) => (
              <FixedSizeList
                height={r3Height}
                width={'100%'}
                itemSize={59}
                itemCount={
                  entries.length
                  // props.active
                  //   ? entries.length
                  //   : 0
                }
                onItemsRendered={onItemsRendered}
                ref={ref}
              >
                {({ index, style }) => (
                  <div style={style} className={classes.listItemContainer}>
                    <RankingListItem
                      entry={entries[index]}
                      isFirst={index === 0}
                      showAllow={isShowAllow(props.define)}
                      totalPoint={summary?.totalPoint}
                    />
                  </div>
                )}
              </FixedSizeList>
            )}
          </InfiniteLoader>
        )}
      </Grid>
    </Grid>
  );
};
