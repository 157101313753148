import React, { Fragment, useEffect } from 'react';
import { useParams } from 'react-router';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useHistory } from 'react-router-dom';

import { Path } from '../routes';
import { helpList } from '../lib/dataFunctions';
import { imagePath } from '../lib/pathes';
import { SloppyScalableText } from '../components';
import { GAEventValue, reportGA } from 'lib/googleAnalytics';

const horizontalPadding = 24;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      width: '100%',
      // height: '100%',
      height: 'calc(100% - 4px)', // - 4pxをつけないとスクロールする messageのflex: 1で誤差がある?
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
      display: 'flex',
      flexDirection: 'column',
    },
    imageArea: {
      textAlign: 'center',
      height: 189,
    },
    space: {
      height: 8,
    },
    image: {
      paddingTop: 30,
      width: 154,
    },
    title: {
      height: 52,
      paddingTop: 22,
      fontSize: 21,
      fontWeight: theme.typography.fontWeightBold,
    },
    message: {
      flex: 1,
      paddingTop: 20,
      paddingBottom: 50,
      fontSize: 15,
      lineHeight: 1.9,
      color: '#5D5D5D',
    },
    buttonArea: {
      height: 79,
      textAlign: 'center',
    },
    buttonImage: {
      width: 142,
      paddingBottom: 43,
    },
  }),
);

export const HelpDetailPage: React.FC = () => {
  const classes = useStyles();
  const { helpIndex } = useParams();
  const history = useHistory();

  const help = helpList[helpIndex];

  const helpGa = [
    GAEventValue.MYPAGE_BPRANK_RANK,
    GAEventValue.MYPAGE_BPRANK_BP,
    GAEventValue.MYPAGE_BPRANK_TENPO,
    GAEventValue.MYPAGE_BPRANK_GROUP,
    GAEventValue.MYPAGE_BPRANK_DOUHAN,
    GAEventValue.MYPAGE_BPRANK_JIKOBEST,
    GAEventValue.MYPAGE_BPRANK_THANKS
  ]

  useEffect(() => {
    if (!helpIndex) return;
    reportGA(helpGa[helpIndex])
  }, []);

  return (
    <div className={classes.container}>
      {help.image ? (
        <div className={classes.imageArea}>
          <img className={classes.image} src={imagePath(help.image)} alt="symbol icon" />
        </div>
      ) : (
        <div className={classes.space}></div>
      )}

      <div className={classes.title}>
        <SloppyScalableText content={help.title} size={30} />
      </div>

      <div className={classes.message}>
        {help.body.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </div>

      <div className={classes.buttonArea}>
        <img
          className={classes.buttonImage}
          src={imagePath('12_icon/my-page.png')}
          onClick={() => history.push(Path.my)}
          alt="マイページへ"
        />
      </div>
    </div>
  );
};
