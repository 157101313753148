import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import { EffectDto, EffectType, EffectTypeValue, TenpoPointUnitTypeValue } from "../lib/api";
import {buildEffectArg} from "../lib/effectLib";
import classNames from 'classnames';
import {StandardButton} from "../components/StandardButton";
import {
  selectEffect,
  setEffectArg, setMaskBlurPx,
  setMaskColorB,
  setMaskColorG,
  setMaskColorR,
  setMaskOpacity
} from "../app/effectSlice";
import {LoadingOverlay} from "../components/LoadingOverlay";
import { TextField, InputAdornment } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      width: '100%',
      textAlign: 'center',
    },
    button: {
      margin: 15,
    },
    cover:{
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
    input:{
      width: 200,
      marginLeft: 'auto',
      marginRight: 'auto',
    }
  }),
);

type Props = {};

function createEffectDto(
  effectType: EffectType,
  typeValue: number,
  params: string[],
): EffectDto {
  return {
    id: '',
    effectType: effectType,
    typeValue: typeValue,
    param1 : params.length > 0 ? params[0] : '',
    param2 : params.length > 1 ? params[1] : '',
    param3 : params.length > 2 ? params[2] : '',
    param4 : params.length > 3 ? params[3] : '',
    tenpoName: '',
    ポイント単位種別: TenpoPointUnitTypeValue.ポイント
  };
}
type SampleDto = {
  name: string,
  postfix?: string,
  value?: number,
  dto?: EffectDto | null,
  params?: string[],
}

function buildSample(v: SampleDto): EffectDto {
  if(v.dto) return v.dto;
  return createEffectDto(v.name as EffectType, v.value ?? 0, v.params ?? [])
  // return null;
}

const samples: SampleDto[] = [
  {name: '達成率', params:['12']},
  {name: '目標達成', params: ['12', '9999999999']},
  {name: '出勤時間', value: 72, postfix: '時間', params:['12']},
  {name: '出勤時間', value: 84, postfix: '時間', params:['12']},
  {name: '同伴件数', value: 5, postfix: '件', params:['12']},
  {name: '同伴件数', value: 8, postfix: '件', params:['12']},
  {name: '同伴件数', value: 12, postfix: '件', params:['12']},
  {name: '同伴件数', value: 20, postfix: '件', params:['12']},
  {name: '指名本数', value: 5, postfix: '本', params:['12']},
  {name: '指名本数', value: 15, postfix: '本', params:['12']},
  {name: '指名本数', value: 25, postfix: '本', params:['12']},
  {name: '指名本数', value: 50, postfix: '本', params:['12']},
  {name: '指名本数', value: 75, postfix: '本', params:['12']},
  {name: '指名本数', value: 100, postfix: '本', params:['12']},
  {name: 'ポイントランキング_店舗_年間', value: 1, postfix: '位', params: ['2022', 'FI']},
  {name: 'ポイントランキング_店舗_年間', value: 4, postfix: '位', params: ['2023', 'LT']},
  {name: 'ポイントランキング_店舗_年間', value: 7, postfix: '位', params: ['2024', 'PJ']},
  {name: 'ポイントランキング_店舗_月間', value: 3, postfix: '位', params: ['12', 'RI']},
  {name: 'ポイントランキング_店舗_月間', value: 6, postfix: '位', params: ['12', 'TR']},
  {name: 'ポイントランキング_店舗_月間', value: 10, postfix: '位', params: ['12', 'TR']},
  {name: 'ポイントランキング_グループ_年間', value: 1, postfix: '位', params:['2025']},
  {name: 'ポイントランキング_グループ_年間', value: 11, postfix: '位', params:['2026']},
  {name: 'ポイントランキング_グループ_年間', value: 21, postfix: '位', params:['2027']},
  {name: 'ポイントランキング_グループ_月間', value: 2, postfix: '位', params:['12']},
  {name: 'ポイントランキング_グループ_月間', value: 12, postfix: '位', params:['12']},
  {name: 'ポイントランキング_グループ_月間', value: 22, postfix: '位', params:['12']},
  {name: '同伴ランキング_グループ_年間', value: 3, postfix: '位', params:['2028']},
  {name: '同伴ランキング_グループ_年間', value: 13, postfix: '位', params:['2029']},
  {name: '同伴ランキング_グループ_年間', value: 23, postfix: '位', params:['2030']},
  {name: '同伴ランキング_グループ_月間', value: 4, postfix: '位', params:['12']},
  {name: '同伴ランキング_グループ_月間', value: 14, postfix: '位', params:['12']},
  {name: '同伴ランキング_グループ_月間', value: 24, postfix: '位', params:['12']},
  {name: '指名ランキング_グループ_年間', value: 5, postfix: '位', params:['2031']},
  {name: '指名ランキング_グループ_年間', value: 15, postfix: '位', params:['2032']},
  {name: '指名ランキング_グループ_年間', value: 25, postfix: '位', params:['2033']},
  {name: '指名ランキング_グループ_月間', value: 6, postfix: '位', params:['12']},
  {name: '指名ランキング_グループ_月間', value: 16, postfix: '位', params:['12']},
  {name: '指名ランキング_グループ_月間', value: 26, postfix: '位', params:['12']},
  {name: '個別レース 第一段階区切り順位', dto: createEffectDto(EffectTypeValue.個別レース, 1, ['あいうえお'])},
  {name: '個別レース 第二段階区切り順位', dto: createEffectDto(EffectTypeValue.個別レース, 2, ['あいうえおあいうえお'])},
  {name: '個別レース 表示最低順位', dto: createEffectDto(EffectTypeValue.個別レース, 3, ['あいうえおあいうえおあいうえおあいうえおあいうえお'])},
  {name: 'ポイントベスト更新_年間', params:['2022', '9999999999']},
  {name: 'ポイントベスト更新_月間', params:['20221224', '9999999999']},
  {name: '同伴数ベスト更新_年間', params:['2022', '99999']},
  {name: '同伴数ベスト更新_月間', params:['20221211', '99999']},
  {name: '指名数ベスト更新_年間', params:['2022', '99999']},
  {name: '指名数ベスト更新_月間', params:['20221211', '99999']},
  {name: 'ポイントレコード_店舗_年間', params:['2022', '9999999999', '9999999999', 'FI']},
  {name: 'ポイントレコード_店舗_月間', params:['12', '9999999999', '9999999999', 'LT']},
  {name: 'ポイントレコード_グループ_年間', params:['2022', '9999999999', '9999999999']},
  {name: 'ポイントレコード_グループ_月間', params:['12', '9999999999', '9999999999']},
  {name: '同伴数レコード_店舗_年間', params:['2022', '999999', '99999', 'PJ']},
  {name: '同伴数レコード_店舗_月間', params:['12', '999999', '99999', 'RI']},
  {name: '同伴数レコード_グループ_年間', params:['2022', '999999', '99999']},
  {name: '同伴数レコード_グループ_月間', params:['12', '999999', '99999']},
  {name: '指名数レコード_店舗_年間', params:['2022', '999999', '99999', 'TR']},
  {name: '指名数レコード_店舗_月間', params:['12', '999999', '99999', 'TR']},
  {name: '指名数レコード_グループ_年間', params:['2022', '999999', '99999']},
  {name: '指名数レコード_グループ_月間', params:['12', '999999', '99999']},
  {name: '在籍日数', value: 3, postfix: 'ヶ月', params:['12', '31']},
  {name: '在籍日数', value: 6, postfix: 'ヶ月', params:['12', '31']},
  {name: '在籍日数', value: 12, postfix: 'ヶ月', params:['12', '31']},
  {name: '在籍日数', value: 24, postfix: 'ヶ月', params:['12', '31']},
  {name: 'お誕生日', params:['あいうえおあいうえおあいうえおあいうえおあいうえお']},
  {name: '指名があった日', params:[]},
  {name: '指名連日獲得', value: 3, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 4, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 5, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 6, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 7, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 8, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 9, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 10, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 11, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 12, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 13, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 14, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 15, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 16, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 17, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 18, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 19, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 20, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 21, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 22, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 23, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 24, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 25, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 26, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 27, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 28, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 29, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 30, postfix: '日', params:[]},
  {name: '指名連日獲得', value: 31, postfix: '日', params:[]},
  {name: 'Instagram初指名', params:[]},
  {name: 'ナイツ初指名', params:[]},
  {name: 'ポケパラ初指名', params:[]},
  {name: '同伴があった日', params:[]},
  {name: '初アルマンド', params:[]},
  {name: '初ソウメイ', params:[]},
  {name: 'ランダム', value: 1, postfix: ' (今日もかわいいよ)', params:[]},
  {name: 'ランダム', value: 2, postfix: ' (いつも応援してますよ)', params:[]},
  {name: 'ランダム', value: 3, postfix: ' (いつもがんばってるね)', params:[]},
  {name: 'ランダム', value: 4, postfix: ' (体調に気をつけてね)', params:[]},
  {name: 'ランダム', value: 5, postfix: ' (がんばるあなたが素敵だよ)', params:[]},
  {name: 'ランダム', value: 6, postfix: ' (今日も笑顔！笑顔！)', params:[]},
];
const sheetsSamples: SampleDto[] = [
  {
    name: EffectTypeValue.達成率,
    value: 0,
    params: ['1'],
  },
  {
    name: EffectTypeValue.達成率,
    value: 0,
    params: ['12'],
  },
  {
    name: EffectTypeValue.目標達成,
    value: 0,
    params: ['12', '1000000000'],
  },
  {
    name: EffectTypeValue.ポイントランキング_店舗_年間,
    value: 1,
    params: ['2022', 'TR'],
  },
  {
    name: EffectTypeValue.ポイントランキング_店舗_月間,
    value: 1,
    params: ['12', 'FI'],
  },
  {
    name: EffectTypeValue.ポイントランキング_グループ_年間,
    value: 1,
    params: ['12'],
  },
  {
    name: EffectTypeValue.個別レース,
    value: 1,
    params: ['あいうえおあいうえおあいうえおあいうえおあいうえお'],
    // params: ['あいうえお'],
  },
  {
    name: EffectTypeValue.指名数ベスト更新_年間,
    value: 0,
    params: ['20221211', '99999'],
  },
  {
    name: EffectTypeValue.ポイントレコード_店舗_年間,
    value: 0,
    params: ['12', '9999999999', '9999999999', 'LT'],
  },
  {
    name: EffectTypeValue.在籍日数,
    value: 99 * 12,
    params: ['12', '31'],
  },
  {
    name: EffectTypeValue.お誕生日,
    value: 0,
    params: ['あいうえおあいうえおあいうえおあいうえおあいうえお'],
    // params: ['あいうえお'],
  },
]
function getNumberString(n: number, min: number, max: number): string {
  if(isNaN(n)) return '0';
  if(min > n) return min.toString();
  if(max < n) return max.toString();
  return n.toString();
}
export function EffectSamplePage(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { showEffect, effectArg, maskColorR, maskColorG, maskColorB, maskOpacity, maskBlurPx } = useSelector(selectEffect);

  // useEffect(()=>{
  //   if(!loaded) return;
  //   dispatch(setEffectArg(buildEffectArg(sheetsSample)));
  // }, [loaded])

  const handlePlay = (effects: EffectDto[]) => {
    if(!!effectArg) return;
    dispatch(setEffectArg(buildEffectArg(effects)));
  }

  return <>
    <div className={classNames(classes.root, classes.verticalCenter)}>
      <TextField
        id="maskColorR"
        label="背景マスク色 R (0 ~ 255)"
        variant="outlined"
        margin='dense'
        className={classes.input}
        value={maskColorR}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => {
          dispatch(setMaskColorR(e.target.value))
        }}
        onBlur={(e) => {
          dispatch(setMaskColorR(getNumberString(parseInt(e.target.value), 0, 255)))
        }}
      />
      <TextField
        id="maskColorG"
        label="背景マスク色 G (0 ~ 255)"
        variant="outlined"
        margin='dense'
        className={classes.input}
        value={maskColorG}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => {
          dispatch(setMaskColorG(e.target.value))
        }}
        onBlur={(e) => {
          dispatch(setMaskColorG(getNumberString(parseInt(e.target.value), 0, 255)))
        }}
      />
      <TextField
        id="maskColorB"
        label="背景マスク色 B (0 ~ 255)"
        variant="outlined"
        margin='dense'
        className={classes.input}
        value={maskColorB}
        inputProps={{ inputMode: 'numeric' }}
        onChange={(e) => {
          dispatch(setMaskColorB(e.target.value))
        }}
        onBlur={(e) => {
          dispatch(setMaskColorB(getNumberString(parseInt(e.target.value), 0, 255)))
        }}
      />
      <TextField
        id="maskOpacity"
        label="背景マスク不透明度 (0 ~ 1)"
        variant="outlined"
        margin='dense'
        className={classes.input}
        value={maskOpacity}
        inputProps={{ inputMode: 'decimal' }}
        onChange={(e) => {
          dispatch(setMaskOpacity(e.target.value))
        }}
        onBlur={(e) => {
          dispatch(setMaskOpacity(getNumberString(parseFloat(e.target.value), 0, 1)))
        }}
      />
      <TextField
        id="maskBlurPx"
        label="背景拡散値 (0 ~ )"
        variant="outlined"
        margin='dense'
        className={classes.input}
        value={maskBlurPx}
        inputProps={{ inputMode: 'decimal' }}
        InputProps={{
          endAdornment: <InputAdornment position="start">px</InputAdornment>,
        }}
        onChange={(e) => {
          dispatch(setMaskBlurPx(e.target.value))
        }}
        onBlur={(e) => {
          dispatch(setMaskBlurPx(getNumberString(parseFloat(e.target.value), 0, 100)))
        }}
      />

      <StandardButton className={classes.button} size='large' onClick={() => handlePlay(sheetsSamples.map((v) => buildSample(v)))}>
        表彰状スワイプ
      </StandardButton>
      { samples.map((v, i) =>
        <StandardButton key={i} className={classes.button} size='large' onClick={() => handlePlay([buildSample(v)])}>
          {`${v.name} ${v.value ?? ''}${v.postfix ?? ''}`}
        </StandardButton>
        )
      }
    </div>
    { (!showEffect && !!effectArg) && <>
      <div className={classes.cover} />
      <LoadingOverlay />
    </> }
  </>;
}
