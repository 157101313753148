import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

const separatorAreaHeight = 1;
const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        container: {
            height: '100%',
            backgroundColor: '#dc3a70',
        },
        separator: {
            height: separatorAreaHeight,
            backgroundColor: 'white',
        },
        urlframe: {
            height: "100%",

        }
    }),
);

export const GachaDetailPage: React.FC = () => {
    
    const classes = useStyles();
    
    const url = 'https://drive.google.com/file/d/1XyetpxKLzoWZ4YLEZf-MDo0d5d4VH6cC/preview?usp=sharing';
    return (
        <>
            <div className={classes.container}>
                <div className={classes.separator}></div>
                <div className={classes.urlframe}>
                    <iframe
                        title="ガチャ概要"
                        src={url}
                        width="100%"
                        height="100%"
                        frameBorder="0"
                        allowFullScreen
                    ></iframe>
                </div>
            </div>
        </>
    );
};