export const consts = {
  // iphone Xのsafe areaを除いた領域
  estimatedContentWidth: 375,
  estimatedContentHeight: 734,

  // アプリケーション固定長
  headerSize: 48,
  footerSize: 56,

  // ios10以前の端末用
  defaultSafeAreaInsetTop: 20,
  defaultSafeAreaInsetBottom: 0,

  // HOME画面バッジ画像表示サイズ
  badgeSize: 68,
};

export const getInnerContentHeight = () => {
  return consts.estimatedContentHeight - consts.headerSize - consts.footerSize;
};
