import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { selectSchedule } from '../app/scheduleSlice';
import { fetchScoreDetailMonthly } from '../lib/api';
import { selectApplication } from '../app/applicationSlice';
import { MonthlyScoreDetail2024Dialog } from '../components/MonthlyScoreDetail2024Dialog';
import { MonthlyScoreDetailGenericDialog } from '../components/MonthlyScoreDetailGenericDialog';

export const MonthlyScoreDetailDialog: React.FC = () => {
  const { monthlyScoreDetailDialogParams } = useSelector(selectApplication);
  const { scoreDetailMonthlyResponse, scoreDetailMonthlyLoaded } = useSelector(selectSchedule);

  const dispatch = useDispatch();
  useEffect(() => {
    if (monthlyScoreDetailDialogParams) {
      dispatch(
        fetchScoreDetailMonthly({
          year: monthlyScoreDetailDialogParams.year,
          month: monthlyScoreDetailDialogParams.month + 1,
        }),
      );
    }
  }, [monthlyScoreDetailDialogParams]);
  
  // ロード未完了の場合は何も表示しない
  if (!scoreDetailMonthlyLoaded) return <div></div>
  return scoreDetailMonthlyResponse.type.endsWith("2024") ? <MonthlyScoreDetail2024Dialog /> : <MonthlyScoreDetailGenericDialog />
};
