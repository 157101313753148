import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useSelector } from 'react-redux';

import { ActorRegularRankingPeriodTypeValue, ActorRegularRankingTypeValue, RankingEntry } from '../lib/api';
import { getFaceImagePath, imagePath } from '../lib/pathes';
import { selectRanking } from '../app/rankingSlice';
import { getBadgeImage, getRegularRankingValueByIndex } from '../lib/dataFunctions';

import { SloppyScalableText, UpDownIcon } from './';


const DEFAULT_HEIGHT = 58;
// const { homeResponse } = useSelector(selectHome);
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    container: {
      height: DEFAULT_HEIGHT,
      width: 299,
      borderTop: '1px #D5D6D6 solid',
      display: 'flex',
    },
    firstRow: {
      height: DEFAULT_HEIGHT + 1,
      borderTop: 'none',
    },
    c1: {
      width: 28,
      fontSize: 15,
      textAlign: 'center',
      color: theme.palette.text.hint,
    },
    c1TopRank: {
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      backgroundSize: '21px 21px',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      paddingTop: 1,
      paddingLeft: 1,
    },
    c2: {
      width: 44,
    },
    c3: {
      height: '100%',
      width: 141,
      paddingTop: 10,
      paddingLeft: 6,
      paddingRight: 2,
    },
    c4: {
      width: 58,
      color: theme.palette.text.secondary,
      textAlign: 'right',
      paddingLeft: '0.3em',
    },
    c4wide: {
      width: 58 + 28,
    },
    c5: {
      width: 28,
    },
    c2FaceImage: {
      width: 36,
      height: 36,
      marginLeft: 'auto',
      marginRight: 'auto',
      objectFit: 'cover',
      borderRadius: '50%',
      position: 'absolute',
      transform: 'translate(10%, 0%)',
    },
    c2FaceGroupTopLankingRainbow: {
      width: 44,
      height: 44,
      display: 'inline-block',
      position: 'absolute',
      backgroundImage: `url('${imagePath('ranking_circle_rainbow.png')}')` ,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    c2FaceGroupTopLankingGold: {
      width: 44,
      height: 44,
      display: 'inline-block',
      position: 'absolute',
      backgroundImage: `url('${imagePath('ranking_circle_gold.png')}')` ,
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center',
    },
    c3Name: {
      fontSize: 17,
      fontWeight: theme.typography.fontWeightMedium,
      width: 141 - 6 - 2 - 36 - 8,
      height: 20,
      whiteSpace: 'nowrap',
    },
    c3GroupTopRank: {
      display: 'inline-block',
      width: 36,
      height: 37 / 3,
      fontSize: 10,
      fontWeight: theme.typography.fontWeightBold,
      color: 'white',
      marginLeft: 4,
      textAlign: 'right',
      verticalAlign: '20%',
    },
    c3GroupTopRankFillia: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_fillia.png')}')`,
    },
    c3GroupTopRankLeicester: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_leicester.png')}')`,
    },
    c3GroupTopRankPj: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_pj.png')}')`,
    },
    c3GroupTopRankRin: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_rin.png')}')`,
    },
    c3GroupTopRankTori: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_touri.png')}')`,
    },
    c3GroupTopRankHisui: {
      backgroundSize: `${107 / 3}px ${37 / 3}px`,
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url('${imagePath('rank_hisui.png')}')`,
    },
    c3GroupTopRankText: {
      display: 'inline-block',
      transformOrigin: '0% 40%',
      transform: 'scale(1.25)',
      marginRight: 4,
      letterSpacing: -2,
    },
    c3GroupTopRankSuffix: {
      display: 'inline-block',
      transformOrigin: '15% 90%',
      transform: 'scale(0.7)',
      letterSpacing: 1,
    },
    badgeListArea: {
      paddingTop: 4,
    },
    badgeImage: {
      width: 11,
      marginLeft: 1,
    },
  }),
);

const getEntryValueText = (entry: RankingEntry, totalPoint: number|undefined): string => {
  if (totalPoint) {
    const result = Math.round((entry.value / totalPoint) * 10) / 10;
    return `${Number(result).toLocaleString()}%`;
  }

  return `${Number(entry.value).toLocaleString()} ${entry.suffix}`;
}

type Props = {
  entry: RankingEntry;
  isFirst?: boolean;
  showAllow?: boolean;
  totalPoint?: number;
};
export function RankingListItem(props: Props) {
  const { regularRankingData } = useSelector(selectRanking);

  const classes = useStyles();
  if (!props.entry) {
    return <></>;
  }

  const defaultImageUrl = imagePath('no_image.png');
  const imageUrl = getFaceImagePath(props.entry.imageUrl);

  // 直近の月間グループランキングアイコン
  const groupRankingEntries =
    getRegularRankingValueByIndex(
      regularRankingData,
      0,
      ActorRegularRankingPeriodTypeValue.毎月,
      ActorRegularRankingTypeValue.グループポイントランキング,
    ) || [];
  const groupRank = groupRankingEntries.find((v) => v.actorId === props.entry.actorId)?.rank || 0;
  
  // 直近の年間グループランキングアイコン
  const yearGroupRankingEntries =
    getRegularRankingValueByIndex(
      regularRankingData,
      0,
      ActorRegularRankingPeriodTypeValue.年間,
      ActorRegularRankingTypeValue.グループポイントランキング,
    ) || [];
  const yearGroupRank = yearGroupRankingEntries.find((v) => v.actorId === props.entry.actorId)?.rank || 0;
  const showGroupRankEdgeRainbow = 0 < yearGroupRank && yearGroupRank <= 5;
  const showGroupRankEdgeGold = 5 < yearGroupRank && yearGroupRank <= 10;

  // 直近の半年ランキングアイコン
  const harfYearGroupRankingEntries =
    getRegularRankingValueByIndex(
      regularRankingData,
      0,
      ActorRegularRankingPeriodTypeValue.半年,
      ActorRegularRankingTypeValue.店内ポイントランキング,
    ) || [];
    const halfStoreRank = harfYearGroupRankingEntries.find((v) => v.actorId === props.entry.actorId)?.rank || 0;
    const showStoreRank = 0 < halfStoreRank && halfStoreRank <= 5;

  const showCustomerPoint = props.totalPoint ? true : false;

  // showGroupRankでは再描画されないため考慮しない
  // const estimateNameLength = showGroupRank ? (props.showAllow ? 12 : 14) : 16;
  const estimateNameLength = props.showAllow ? 12 : 14;
  const actorName = props.entry.name ? props.entry.name.replace(/[　|\s]/g, '') : '';

  const getTenpoImage = () => {
    if (props.entry.tenpoCode === "FI"){
      return classes.c3GroupTopRankFillia
    } else if(props.entry.tenpoCode === "PJ"){
      return classes.c3GroupTopRankPj
    } else if(props.entry.tenpoCode === "TR"){
      return classes.c3GroupTopRankTori
    } else if(props.entry.tenpoCode === "RI"){
      return classes.c3GroupTopRankRin
    } else if(props.entry.tenpoCode === "LT"){
      return classes.c3GroupTopRankLeicester
    } else if(props.entry.tenpoCode === "HS"){
      return classes.c3GroupTopRankHisui
    } else if(props.entry.tenpoCode === "HE"){
      return classes.c3GroupTopRankTori
    } else if (props.entry.tenpoCode === "NT"){
      return classes.c3GroupTopRankTori
    } else {
      return ""
    }
  }

  return (
    <>
      <div className={classNames(classes.container, { [classes.firstRow]: props.isFirst })}>
        <div
          className={classNames(classes.c1, classes.verticalCenter, { [classes.c1TopRank]: props.entry.rank <= 3 })}
          style={{
            backgroundImage: props.entry.rank <= 3 ? `url('${imagePath(`ranking_0${props.entry.rank}.png`)}')` : 'none',
          }}
        >
          <div>{props.entry.rank}</div>
        </div>

        <div className={classNames(classes.c2, classes.verticalCenter)}>
          {showGroupRankEdgeRainbow && (
            <div className={classes.c2FaceGroupTopLankingRainbow}>
            </div>
          )}
          {showGroupRankEdgeGold && (
            <div className={classes.c2FaceGroupTopLankingGold}>
            </div>
          )}
          <img
            className={classes.c2FaceImage}
            src={imageUrl}
            onError={(e) => ((e.target as HTMLImageElement).src = defaultImageUrl)}
            alt="顔写真"
          />
        </div>

        <div className={classNames(classes.c3)}>
          <div className={classes.c3Name}>
            {actorName.includes('様') ? (
              <>
                <SloppyScalableText content={actorName.replace(/様$/, '')} size={estimateNameLength} />
                <span style={{ fontSize: 'small' }}> 様</span>
              </>
            ) : (
              <SloppyScalableText content={actorName} size={estimateNameLength} />
            )}
            {showStoreRank && (
              <div className={classNames(classes.c3GroupTopRank, getTenpoImage())}>
                <span className={classes.c3GroupTopRankText}>{halfStoreRank}</span>
                <span className={classes.c3GroupTopRankSuffix}>th</span>
              </div>
            )}
          </div>
          <div className={classes.badgeListArea}>
            {props.entry.subValue
              ? <SloppyScalableText content={(props.entry.subSuffix == "円" ? (Math.round(props.entry.subValue / 1000) / 10).toLocaleString() : Math.round(props.entry.subValue).toLocaleString()) + (props.entry.subSuffix == "円" ? "万円" : props.entry.subSuffix)} size={7} />
              : <></>
            }
            {props.entry.badges &&
              props.entry.badges.map((e, index) => {
                return (
                  <img
                    key={index}
                    className={classes.badgeImage}
                    src={imagePath(getBadgeImage(e.category, e.badgeType))}
                    alt="所持バッヂ"
                  />
                );
              })}
          </div>
        </div>

        <div className={classNames(classes.c4, classes.verticalCenter, { [classes.c4wide]: !props.showAllow })}>
          <div>
            <SloppyScalableText
              content={getEntryValueText(props.entry, props.totalPoint)}
              size={8}
            />
          </div>
          {showCustomerPoint &&
            <SloppyScalableText content={`(${props.entry.value}${props.entry.suffix})`} size={4} />
          }
        </div>

        {props.showAllow && (
          <div className={classNames(classes.c5, classes.verticalCenter)}>
            <UpDownIcon diff={props.entry.diff}></UpDownIcon>
          </div>
        )}
      </div>
    </>
  );
}
