import { useSelector } from 'react-redux';

import { TenpoPointUnitTypeValue } from '../lib/api';

import { selectHome } from 'app/homeSlice';

export const useTenpoPointUnitType = function <T>(pointValue: T, yenValue: T, defaultValue: T): T {
  const home = useSelector(selectHome);
  if (!home.loaded) return defaultValue;
  if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.ポイント) return pointValue;
  if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.小計) return yenValue;
  return defaultValue;
};

export const getTenpoPointUnitType = function <T>(pointValue: T, yenValue: T, defaultValue: T, home:any): T {
  if (!home.loaded) return defaultValue;
  if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.ポイント) return pointValue;
  if (home.homeResponse.ポイント単位種別 === TenpoPointUnitTypeValue.小計) return yenValue;
  return defaultValue;
};
