import React from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useSelector } from "react-redux";
import moment from "moment";

import { selectApplication } from "../../app/applicationSlice";
import { GraphValue, GraphDataSet } from "../../app/graphSlice";

import {
  ValueFormatter,
  useDateFormat,
  useSelectGraphData,
  useTitle,
  useUnit,
  useValueFormatter,
  場内率ValueFormatter,
} from "./graphDataLib";
import { GraphType, 場内指名件数 } from "./graphDefine";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      position: "relative",
      color: "#FFFFFF",
      paddingLeft: 39,
      paddingRight: 38,
      height: 75,
    },
    r1: {
      paddingTop: 35,
      position: "absolute",
    },
    r2: {
      paddingTop: 25,
      textAlign: "right",
      height: 75 - 25,
    },
    r3: {
      position: "absolute",
      bottom: 0,
      width: 20,
      fontSize: 8,
      textAlign: "right",
    },
    title: {
      fontSize: 16,
      fontWeight: "bold",
    },
    date: {
      fontSize: 13,
      fontWeight: "bold",
      paddingRight: 7,
    },
    valueHeadder: {
      fontSize: 12,
      fontWeight: "bold",
    },
    value: {
      fontSize: 24,
      fontWeight: "bold",
    },
    valueFooter: {
      fontSize: 12,
      fontWeight: "bold",
    },
    subValue: {
      fontSize: 16,
      fontWeight: "bold",
    },
    graph: {},
  })
);

function getLastValidIndex(a: GraphValue[], allowZero: boolean): number {
  const r = a.concat().reverse();
  const lastValidIndex = allowZero
    ? r.findIndex((v) => v !== null)
    : r.findIndex((v) => !!v);
  if (lastValidIndex === -1) {
    return lastValidIndex;
  }
  return a.length - lastValidIndex - 1;
}

function useValueAndDate(
  type: GraphType,
  index: number
): [string, string, string, string, string] {
  // const valueFormatter = useValueFormatter(type);
  const dateFormat = useDateFormat();
  const pastData = useSelectGraphData(type.toString());
  const 場内指名件数Data = useSelectGraphData(場内指名件数);
  const 成績Formatter = useValueFormatter(type);

  // ランキングの場合0は未算出、nullはデータなし
  // それ以外の場合はnullを未定義とする
  const lastValidIndex = getLastValidIndex(
    pastData.values,
    type === GraphType.フリー件数 ||
      type === GraphType.出勤時間 ||
      type === GraphType.同伴数 ||
      type === GraphType.指名本数 ||
      type === GraphType.成績
  );

  const 勤務時間Data = useSelectGraphData("4");
  const firstNonZeroIndex = 勤務時間Data.values.findIndex(
    (element) => element !== 0
  );
  var removeLastIndex =
    firstNonZeroIndex === -1 ? 勤務時間Data.values.length : firstNonZeroIndex;
  const data = {
    labels: pastData.labels.slice(removeLastIndex),
    values: pastData.values.slice(removeLastIndex),
    rankingCounts: [],
  };

  if (
    data.values.length === 0 ||
    data.values.length <= index ||
    lastValidIndex === -1
  ) {
    return ["", "", "", "", ""];
  }
  if (type === GraphType.フリー件数) {
    return 場内指名率GraphHeaderValueMake(
      index,
      dateFormat,
      data,
      場内指名件数Data
    );
  } else {
    return graphHeaderValueMake(index, dateFormat, type, data, 成績Formatter);
  }
}

function graphHeaderValueMake(
  index: number,
  dateFormat: string,
  type: GraphType,
  data: GraphDataSet,
  成績Formatter: ValueFormatter
): [string, string, string, string, string] {
  var formattedDate = moment(data.labels.slice(index)[0]).format(dateFormat);
  const value = data.values.slice(index)[0];
  const valueInt = value !== null ? value : 0;
  const valueStr = addCommas((value !== null ? value : 0).toString());
  let valuesData = data.values.slice(0, data.values.length - 1);
  let totalSumData = valuesData.reduce(
    (sum, element) =>
      (sum !== null ? sum : 0) + (element !== null ? element : 0),
    0
  );
  if (index === -1) {
    formattedDate = "";
  }
  if (type === GraphType.出勤時間) {
    let totalSumDataNullNon = totalSumData !== null ? totalSumData : 0;
    const averageDataStr = addCommas(
      countNonZeroElements(valuesData) <= 1
        ? (Math.round((10 * totalSumDataNullNon) / 60) / 10).toString()
        : (
            Math.round(
              (10 * totalSumDataNullNon) / countNonZeroElements(valuesData) / 60
            ) / 10
          ).toString()
    );
    if (formattedDate === "") {
      return ["平均", averageDataStr, "時間", "", formattedDate];
    } else {
      const selectTimeStr = (Math.round((10 * valueInt) / 60) / 10).toString();
      return ["", selectTimeStr, "時間", "", formattedDate];
    }
  } else if (type === GraphType.給与総支給額) {
    let averageData =
      (totalSumData !== null ? totalSumData : 0) /
      countNonZeroElements(valuesData);
    const averageDataStr = addCommas(
      Math.abs(
        Math.round(
          (averageData !== null
            ? averageData < 10000
              ? averageData * 10000
              : averageData
            : 0) / 10000
        )
      ).toString()
    );
    const footerStr =
      averageData !== null ? (averageData < 10000 ? "円" : "万") : "-";
    if (formattedDate === "") {
      return ["平均", averageDataStr, footerStr, "", formattedDate];
    } else {
      const formattedSelectValue = addCommas(
        Math.round(
          (valueInt !== null
            ? valueInt < 10000
              ? valueInt * 10000
              : valueInt
            : 0) / 10000
        ).toString()
      );
      const footerStr =
        valueInt !== null ? (valueInt < 10000 ? "円" : "万") : "-";
      const selectValueStr = formattedSelectValue === "0" ? "-" : formattedSelectValue;
      return ["", selectValueStr, footerStr, "", formattedDate];
    }
  } else if (
    type === GraphType.グループポイントランキング ||
    type === GraphType.店舗ポイントランキング
  ) {
    const totalSumDataStr = addCommasDecimal(
      Math.abs(
        Math.round(
          (10 * (totalSumData !== null ? totalSumData : 0)) /
            countNonZeroElements(valuesData)
        ) / 10
      ).toString()
    );
    if (formattedDate === "") {
      return ["平均", totalSumDataStr, "位", "", formattedDate];
    } else {
      const valueStr = Math.abs(valueInt).toString();
      return ["", valueStr, "位", "", formattedDate];
    }
  } else if (type === GraphType.成績) {
    let isRinGraph = 成績Formatter(value) === "円";
    let dataCount = countNonZeroElements(valuesData);
    let totalSumDataNullNON = totalSumData !== null ? totalSumData : 0;
    let averageDataInt = Math.abs(Math.round(
        totalSumDataNullNON !== 0 ? totalSumDataNullNON / dataCount : 0
    ));
    const averageDataStr = addCommas(averageDataInt.toString());
    if (isRinGraph) {
      if (formattedDate === "") {
        const footerStr =
          averageDataInt !== null ? (averageDataInt < 10000 ? "円" : "万") : "-";
        const yenAverageDataStr = addCommas(Math.round(
          (averageDataInt !== null
            ? averageDataInt < 10000
              ? averageDataInt * 10000
              : averageDataInt
            : 0) / 10000
        ).toString());
        return ["平均", yenAverageDataStr, footerStr, "", formattedDate];
      } else {
        const footerStr =
          valueInt !== null ? (valueInt < 10000 ? "円" : "万") : "-";
        const yenValueStr = addCommas(Math.round(
          (valueInt !== null
            ? valueInt < 10000
              ? valueInt * 10000
              : valueInt
            : 0) / 10000
        ).toString());
        return ["", yenValueStr, footerStr, "", formattedDate];
      }
    } else {
      if (formattedDate === "") {
        return ["平均", averageDataStr, "pt", "", formattedDate];
      } else {
        return ["", valueStr, "pt", "", formattedDate];
      }
    }
  } else {
    let dataCount = countNonZeroElements(valuesData);
    let totalSumDataNullNON = totalSumData !== null ? totalSumData : 0;
    const averageDataStr = addCommasDecimal(
      totalSumDataNullNON === 0
        ? (0).toString()
        : Math.abs(
            Math.round(
              dataCount !== 0 ? (10 * totalSumDataNullNON) / dataCount : 0
            ) / 10
          ).toString()
    );
    var footerStr = "";
    if (type === GraphType.指名本数) {
      footerStr = "本";
    } else if (type === GraphType.同伴数) {
      footerStr = "件";
    }
    if (formattedDate === "") {
      return ["平均", averageDataStr, footerStr, "", formattedDate];
    } else {
      return ["", valueStr, footerStr, "", formattedDate];
    }
  }
}

function 場内指名率GraphHeaderValueMake(
  index: number,
  dateFormat: string,
  data: GraphDataSet,
  場内指名件数Data: GraphDataSet
): [string, string, string, string, string] {
  const value = data.values.slice(index)[0];
  const valueInt = value !== null ? value : 0;
  const valueStr = addCommas((value !== null ? value : 0).toString());
  var formattedDate = moment(data.labels.slice(index)[0]).format(dateFormat);
  if (index === -1) {
    formattedDate = "";
  }
  let total場内数 = data.values.reduce(
    (sum, element) =>
      (sum !== null ? sum : 0) + (element !== null ? element : 0),
    0
  );
  let total指名場内数 = 場内指名件数Data.values.reduce(
    (sum, element) =>
      (sum !== null ? sum : 0) + (element !== null ? element : 0),
    0
  );
  let rate = 0;
  let subGraphTitle = "(-)";
  if (total場内数 !== null && total指名場内数 !== null && total場内数 !== 0) {
    rate = Math.round((total指名場内数 / total場内数) * 1000) / 10;
    subGraphTitle =
      "(" + total指名場内数.toString() + "/" + total場内数.toString() + ")";
    if (formattedDate === "") {
      return [
        "平均",
        rate >= 100 ? "-" : 場内率ValueFormatter(rate),
        "％",
        subGraphTitle,
        formattedDate,
      ];
    } else {
      const simeiCount = 場内指名件数Data.values.slice(index)[0];
      const simeiCountNotNull = simeiCount !== null ? simeiCount : 0;
      subGraphTitle = "(" + simeiCountNotNull.toString() + "/" + valueStr + ")";
      var selectRate = Math.round((simeiCountNotNull / valueInt) * 1000) / 10;
      if (isNaN(selectRate)) {
        selectRate = 0;
      }
      return [
        "",
        selectRate >= 100 ? "-" : 場内率ValueFormatter(selectRate),
        "％",
        subGraphTitle,
        formattedDate,
      ];
    }
  } else {
    if (formattedDate === "") {
      return ["", "-", "", subGraphTitle, formattedDate];
    } else {
      const simeiCount = 場内指名件数Data.values.slice(index)[0];
      subGraphTitle =
        "(" +
        (simeiCount !== null ? simeiCount : 0).toString() +
        "/" +
        valueStr +
        ")";

      const simeiCountNotNull = simeiCount !== null ? simeiCount : 0;
      var selectRate = Math.round((simeiCountNotNull / valueInt) * 1000) / 10;
      if (isNaN(selectRate)) {
        selectRate = 0;
      }
      return [
        "",
        selectRate >= 100 ? "-" : 場内率ValueFormatter(selectRate),
        "%",
        subGraphTitle,
        formattedDate,
      ];
    }
  }
}

type Props = {
  type: GraphType;
};

function addCommas(numberStr: String) {
  numberStr = numberStr.split("").reverse().join("");
  var formattedStr = numberStr.replace(/(\d{3})(?=\d)/g, "$1,");
  return formattedStr.split("").reverse().join("");
}

function addCommasDecimal(numberStr: String) {
  const parts = numberStr.split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  return parts.join(".");
}

function countNonZeroElements(array: GraphValue[]) {
  let count = 0;
  for (let i = 0; i < array.length; i++) {
    if (array[i] !== 0) {
      count++;
    }
  }
  return count;
}

export function GraphHeader(props: Props) {
  // console.log('GraphHeader render');
  const classes = useStyles();
  const title = useTitle(props.type);
  const unit = useUnit(props.type);
  const applicationState = useSelector(selectApplication);

  const index =
    applicationState.selectedGraphType === props.type
      ? applicationState.selectedGraphEntryIndex
      : -1;
  const [valueHeadder, value, valueFooter, subValue, date] = useValueAndDate(
    props.type,
    index
  );

  return (
    <>
      <div className={classes.container}>
        <div className={classes.r1}>
          <div
            className={classes.title}
            style={{
              fontSize:
                props.type === GraphType.給与総支給額 ||
                props.type === GraphType.出勤時間 ||
                props.type === GraphType.フリー件数
                  ? 14
                  : 16,
            }}
          >
            {title}
          </div>
        </div>
        <div className={classes.r2}>
          <span className={classes.date}>{index === -1 ? "" : date}</span>
          <span className={classes.valueHeadder}>{valueHeadder}</span>
          <span className={classes.value}>{value}</span>
          <span className={classes.valueFooter}>{valueFooter}</span>
          <span className={classes.subValue}>{subValue}</span>
        </div>
        <div className={classes.r3}>{unit}</div>
      </div>
    </>
  );
}
