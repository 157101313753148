import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
import { useDispatch, useSelector } from 'react-redux';

import { hideAlert, selectApplication } from '../app/applicationSlice';
import { consts } from '../consts';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    snackbar: {
      marginBottom: consts.footerSize,
    },
    alert: {
      width: '100%',
    },
  }),
);

export const CommonAlert: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { commonAlertShow, commonAlertParams } = useSelector(selectApplication);

  const handleClose = () => {
    // 画面クリックで閉じないようにする場合
    // if(reason == 'clickaway') return;
    dispatch(hideAlert());
  };

  return (
    <Snackbar
      open={commonAlertShow}
      autoHideDuration={commonAlertParams ? commonAlertParams.autoHideDuration : null}
      onClose={handleClose}
      className={classes.snackbar}
    >
      <Alert
        onClose={handleClose}
        severity={commonAlertParams ? commonAlertParams.severity : 'success'}
        className={classes.alert}
      >
        {commonAlertParams ? commonAlertParams.message : ''}
      </Alert>
    </Snackbar>
  );
};
