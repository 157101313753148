import React, { useEffect, useLayoutEffect, useRef, useState} from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Dialog, Grid } from '@material-ui/core';
import { unwrapResult } from '@reduxjs/toolkit';
import { useDispatch, useSelector } from 'react-redux';

import { StandardButton } from '../components/StandardButton';
import { Path } from '../routes';
import { assetPath, imagePath } from '../lib/pathes';
import { fetchGachaInfo, fetchGachaLotteryNormal } from '../lib/api';
import { buildGachaArg } from '../lib/gachaLib';
import { callNativePlayGachaMovies } from '../lib/nativeBridge';
import { selectGacha } from '../app/gachaSlice';
import { AppDispatch } from '../app/store';
import { SloppyScalableText } from '../components';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

const heightR1 = 155;
const heightR2 = 160;
const heightR3 = heightR2;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      height: '100%',
      display: 'flex',
      flexFlow: 'column',
      justifyContent: 'space-between',
      position: 'relative',
      overflow: 'hidden',
    },
    bgVideo: {
      position: 'absolute',
      // width: '100%',
      // height: '100%',
      zIndex: -1,
      // backgroundSize: 'cover',
      backgroundSize: 'contain',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      backgroundImage: `url(${assetPath('gacha/c005_03_s03_loop.jpg')})`,
      // top: 0,
      // left: 0,
      // objectFit: 'cover',
    },
    r1: {
      height: heightR1,
      textAlign: 'right',
      paddingTop: 34,
      paddingRight: 20,
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'end',
    },
    r1Button: {
      width: 100,
      paddingLeft: 0,
      paddingRight: 0,
      letterSpacing: 1.0
    },
    margin: {
      width: 26,
      height: 10,
    },
    r2: {
      height: heightR2,
      marginLeft: 8,
    },
    gachaButtonDisabled: {
      filter: 'grayscale(100%)',
    },
    gachaButton: {
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      marginTop: 4,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    gachaButtonBG1: {
      width: 972 / 3.05,
      height: 478 / 3.05,
      backgroundImage: `url(${imagePath('gacha/1_button01_kage.png')})`,
    },
    gachaButtonBG2: {
      width: 972 / 3.05,
      height: 449 / 3.05,
      backgroundImage: `url(${imagePath('gacha/1_button02_kage.png')})`,
    },
    gachaText: {
      fontSize: 24,
      fontWeight: 'bold',
      // paddingLeft: 83,
      paddingLeft: 79,
    },
    gachaText1: {
      paddingTop: 98,
      color: theme.palette.primary.main,
    },
    gachaText2: {
      paddingTop: 88,
      color: '#009944',
    },
    gachaTextUnit: {
      fontSize: '0.6em',
      paddingLeft: '0.2em',
    },
    r3: {
      height: heightR3,
      marginLeft: 8,
    },
    exchangeButton: {
      width: 951 / 3,
      height: 293 / 3,
      backgroundImage: `url(${imagePath('gacha/1_button03_kage2.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',

      marginLeft: 'auto',
      marginRight: 'auto',
      marginBottom: 40,
    },
    exchangeText: {
      color: theme.palette.primary.main,
      textAlign: 'center',
      paddingTop: 33,
      paddingLeft: 17,
      letterSpacing: 0.5,
    },
    exchangeTextLine1: {
      fontWeight: 'bold',
      fontSize: 20,
    },
    exchangeTextLine2: {
      marginTop: 1,
      fontSize: 13,
    },

    dialogContainer: {
      width: 975 / 3,
      height: 732 / 3,
      textAlign: 'center',
      backgroundImage: `url(${imagePath('gacha/2_window_kage.png')})`,
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundRepeat: 'no-repeat',
      marginLeft: 8,
      paddingTop: 88,
    },
    dialogText: {
      color: theme.palette.primary.main,
      fontWeight: 'bold',
      fontSize: 20,
      marginTop: 1,
      letterSpacing: 0.3,
    },
    dialogButtonArea: {
      marginTop: 16,
      display: 'flex',
      justifyContent: 'center',
      width: '100%',
    },
    dialogButton: {
      width: 348 / 3,
      height: 102 / 3,
    },
    dialogButtonMargin: {
      width: 14,
    },
  }),
);
const VIDEO_WIDTH = 1080;
const VIDEO_HEIGHT = 1920;
const DEFAULT_VIDEO_STYLE = {
  width: 0,
  height: 0,
  top: 0,
  left: 0,
};
export const GachaPage: React.FC = () => {
  const classes = useStyles();
  const dispatch: AppDispatch = useDispatch();
  const history = useHistory();
  const [open, setOpen] = useState(false);
  const [playing, setPlaying] = useState(false);
  const { info } = useSelector(selectGacha);
  const rootRef = useRef<HTMLDivElement>(null);
  const videoRef = useRef<HTMLVideoElement>(null);
  const [videoLoaded, setVideoLoaded] = useState(false);
  const [videoStyle, setVideoStyle] = useState(DEFAULT_VIDEO_STYLE);

  const ハピネスガチャ実行可能 = 0 < info.ハピネスガチャチケット;

  useEffect(() => {
    if (!videoLoaded) {
      (async function () {
        if (videoRef.current) {
          const videoBuffer = await fetch(assetPath('gacha/c005_03_s03_loop_muted.mp4')).then((e) => e.blob());
          videoRef.current.src = URL.createObjectURL(videoBuffer);
          setVideoLoaded(true);
        }
      })();
    }
  }, [videoLoaded, videoRef.current]);

  useLayoutEffect(() => {
    if (videoRef.current && rootRef.current) {
      const rect = rootRef.current.getBoundingClientRect();
      const style = { ...DEFAULT_VIDEO_STYLE };
      if (rect.height / rect.width < VIDEO_HEIGHT / VIDEO_WIDTH) {
        style.width = rect.width;
        style.height = VIDEO_HEIGHT * (rect.width / VIDEO_WIDTH);
        style.top = (rect.height - style.height) / 2;
      } else {
        style.height = rect.height;
        style.width = VIDEO_WIDTH * (rect.height / VIDEO_HEIGHT);
        style.left = (rect.width - style.width) / 2;
      }
      setVideoStyle(style);
    }
  }, [videoRef.current, rootRef.current]);

  useEffect(() => {
    reloadInfo();

    reportGA(GAEventValue.GACHA);
  }, []);

  const reloadInfo = () => {
    dispatch(fetchGachaInfo({}));
  };

  const handleClickOpen = () => {
    if (!ハピネスガチャ実行可能) return;
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  const handlePlay = async () => {
    // ダイアログの非表示アニメーション中もイベントを受け付けるため、openフラグでの判別も行う
    if (playing || !open) return;
    // stateを即時反映させるためawaitをつける
    await setPlaying(true);
    try{
      const { response } = unwrapResult(await dispatch(fetchGachaLotteryNormal({})));
      if (response.result.toUpperCase() === 'OK') {
        const gachaArg = buildGachaArg(response);
        callNativePlayGachaMovies(gachaArg);
        // console.log(
        //   response.info?.ハピネスガチャチケット,
        //   response.info?.美容ポイント,
        //   JSON.stringify(response.初回抽選結果?.排出),
        //   JSON.stringify(response.復活抽選結果?.排出),
        //   );
        // alert(JSON.stringify(response))

        // シーン切り替わり間際の操作を防ぐため、フラグ更新を遅延させる
        setTimeout(()=>{
          setPlaying(false);
        }, 1000);

        reportGA(GAEventValue.GACHA_BUTTON)
      } else {
        alert('チケット枚数が足りません');
        reloadInfo();
        setPlaying(false);
      }
    } catch {
      setPlaying(false);
    }
    handleClose();
  };

  const gachaURLOpen = () => {
    const externalUrl = 'https://drive.google.com/file/d/1XyetpxKLzoWZ4YLEZf-MDo0d5d4VH6cC/view?usp=sharing';

    return (
      <div>
        <p>This is an internal iframe:</p>
        <iframe
          title="External Site"
          src={externalUrl}
          width="100%"
          height="500"
          frameBorder="0"
          allowFullScreen
        ></iframe>
      </div>
    );
  }

  return (
    <>
      <div className={classNames(classes.root)} ref={rootRef}>
        <video
          playsInline
          autoPlay
          muted
          loop
          // preload={'metadata'}
          preload={'auto'}
          className={classNames(classes.bgVideo)}
          style={videoStyle}
          ref={videoRef}
        >
          {/*<source src={assetPath('gacha/c005_03_s03_loop_muted.mp4')} type="video/mp4" />*/}
        </video>
        <Grid container>
          <Grid item xs={12} className={classNames(classes.r1)}>
            <StandardButton
              className={classes.r1Button}
              appearance={'transparent'}
              size={'medium'}
              onClick={() => history.push(Path.gachaDetailPage)}
            >
              ガチャ概要
            </StandardButton>
            <div className={classNames(classes.margin)}></div>
            <StandardButton
              className={classes.r1Button}
              appearance={'transparent'}
              size={'medium'}
              onClick={() => history.push(Path.gachaLotteryHistory)}
            >
              当選履歴
            </StandardButton>
          </Grid>
          <Grid item xs={12} className={classes.r2}>
            <div className={classNames(classes.gachaButton, classes.gachaButtonBG1, classes.gachaButtonDisabled)}>
              <div className={classNames(classes.gachaText, classes.gachaText1)}>
                0<span className={classes.gachaTextUnit}>枚</span>
              </div>
            </div>
          </Grid>
          <Grid item xs={12} className={classes.r3}>
            <div
              className={classNames(classes.gachaButton, classes.gachaButtonBG2, {
                [classes.gachaButtonDisabled]: !ハピネスガチャ実行可能,
              })}
              onClick={handleClickOpen}
            >
              <div className={classNames(classes.gachaText, classes.gachaText2)}>
                <SloppyScalableText content={' ' + info.ハピネスガチャチケット} size={4} />
                <span className={classes.gachaTextUnit}>枚</span>
              </div>
            </div>
          </Grid>
        </Grid>

        <div className={classNames(classes.exchangeButton)} onClick={() => history.push(Path.gachaExchange)}>
          <div className={classNames(classes.exchangeText)}>
            <div className={classNames(classes.exchangeTextLine1)}>&nbsp;</div>
            <div className={classNames(classes.exchangeTextLine2)}>
              ビューティーコイン: {Number(info.美容ポイント).toLocaleString()}枚
            </div>
          </div>
        </div>
      </div>

      <Dialog
        open={open}
        maxWidth={false}
        PaperProps={{
          style: {
            boxShadow: 'none',
            borderRadius: 0,
            backgroundColor: 'transparent',
          },
        }}
      >
        <div className={classNames(classes.dialogContainer)}>
          <div className={classNames(classes.dialogText)}>チケットを一枚消費して</div>
          <div className={classNames(classes.dialogText)}>ガチャを回しますか？</div>
          <div className={classNames(classes.dialogButtonArea)}>
            <img src={imagePath('gacha/2_yes.png')} className={classNames(classes.dialogButton)} onClick={handlePlay} alt="はい" />
            <div className={classNames(classes.dialogButtonMargin)} />
            <img src={imagePath('gacha/2_no.png')} className={classNames(classes.dialogButton)} onClick={handleClose} alt="いいえ" />
          </div>
        </div>
      </Dialog>
    </>
  );
};
