import axios from 'axios';

export const formClient = axios.create({
  baseURL: (process.env.REACT_APP_API_HOST || '') + (process.env.REACT_APP_API_BASE_PATH || ''),
  headers: {
    'Content-Type': 'application/x-www-form-urlencoded',
  },
  withCredentials: true,
  responseType: 'json',
});
