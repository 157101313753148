import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';

import { selectAuth } from '../app/authSlice';
import { LoginPage } from '../pages';
import {selectHome, selectLoaded, setNow} from '../app/homeSlice';
import {fetchHome, fetchSwitchableActor} from '../lib/api';
import { InitialPage } from '../pages/InitialPage';
import { selectApplication, setNextUrl } from '../app/applicationSlice';

type Props = {
  children?: React.ReactNode;
  loginPath: string;
};
// TODO 責務が曖昧
export const Auth = (props: Props) => {
  const { isAuthenticated } = useSelector(selectAuth);
  const { nextUrl } = useSelector(selectApplication);

  const { loaded, now } = useSelector(selectHome);
  const loadedText = useSelector(selectLoaded);
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();

  useEffect(() => {
    if (!nextUrl) return;
    dispatch(setNextUrl(''));
    history.push(nextUrl);
  }, [nextUrl]);

  useEffect(() => {
    const query = new URLSearchParams(location.search);
    dispatch(setNow(query.get('now')))
  }, [location.search]);

  useEffect(() => {
    console.log('Auth check', loadedText, process.env.PUBLIC_URL, props.loginPath, location.pathname);
    if (loaded) return;
    if (location.pathname === props.loginPath) return;

    dispatch(fetchHome({now: now}));
    dispatch(fetchSwitchableActor({}));
  }, [loaded, props.loginPath, location.pathname, now]);

  if (location.pathname === props.loginPath) {
    return <LoginPage />;
  }
  if (!isAuthenticated) {
    return <InitialPage />;
  }
  return <>{props.children}</>;
};
