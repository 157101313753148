import React, { Fragment } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import moment from 'moment';

import { NotificationMessage } from '../../lib/api';
import { StandardButton } from '../StandardButton';

const horizontalPadding = 24;
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      width: '100%',
      paddingLeft: horizontalPadding,
      paddingRight: horizontalPadding,
    },
    createdAt: {
      paddingTop: 8,
      fontSize: 13,
      color: theme.palette.text.secondary,
    },
    title: {
      paddingTop: 6,
      fontSize: 18,
      fontWeight: theme.typography.fontWeightBold,
    },
    message: {
      paddingTop: 26,
      paddingBottom: 50,
      fontSize: 14,
      lineHeight: 1.7,
      color: '#5D5D5D',
    },
    center: {
      textAlign: 'center',
    },
    button: {
      minWidth: 120,
      marginLeft: 10,
      marginRight: 10,
      marginBottom: 32,
    },
  }),
);

type Props = {
  notification: NotificationMessage;
  handleOnClose: () => void;
};

export function NotificationDetailContents表示型(props: Props) {
  const classes = useStyles();

  const onClose = () => {
    props.handleOnClose();
  };

  return (
    <Grid container className={classes.container}>
      <Grid item xs={12} className={classes.createdAt}>
        {moment(props.notification.createdAt).format('YYYY/MM/DD HH:mm')}
      </Grid>

      <Grid item xs={12} className={classes.title}>
        {props.notification.title}
      </Grid>

      <Grid item xs={12} className={classes.message}>
        {props.notification.message.split('\n').map((item, key) => {
          return (
            <Fragment key={key}>
              {item}
              <br />
            </Fragment>
          );
        })}
      </Grid>

      <Grid item xs={12} className={classes.center}>
        <StandardButton onClick={onClose} className={classes.button}>
          閉じる
        </StandardButton>
      </Grid>
    </Grid>
  );
}
