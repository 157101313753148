import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    verticalCenter: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
    },
    root: {
      width: '100%',
      height: '100%',
      textAlign: 'center',
      color: theme.palette.text.disabled,
      fontSize: 20,
    },
  }),
);
type Prop = {
  title?: string;
  color?: string;
};
export const NoData = (props: Prop) => {
  const classes = useStyles();

  return (
    <div
      className={classNames(classes.root, classes.verticalCenter)}
      style={props.color ? { color: props.color } : undefined}
    >
      {props.title ? props.title : 'データがありません'}
    </div>
  );
};
