export function imagePath(path) {
  return process.env.PUBLIC_URL + '/assets/img/' + path;
}
export function assetPath(path) {
  return process.env.PUBLIC_URL + '/assets/' + path;
}

export function getFaceImagePath(imageUrl) {
  if (!imageUrl) {
    return imagePath('no_image.png');
  }
  if (imageUrl.startsWith('http')) {
    return imageUrl;
  }

  return (process.env.REACT_APP_API_HOST || '') + (process.env.REACT_APP_API_BASE_PATH || '') + '/photo/' + imageUrl;
}

export function getPhotoPath(photoId) {
  return (process.env.REACT_APP_API_HOST || '') + (process.env.REACT_APP_API_BASE_PATH || '') + '/photo/' + photoId;
}
