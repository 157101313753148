import React, { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import { FixedSizeList } from 'react-window';
import { useDispatch, useSelector } from 'react-redux';
import InfiniteLoader from 'react-window-infinite-loader';
import moment from 'moment';

import { consts } from '../consts';
import { selectGacha, setGachaLoading } from '../app/gachaSlice';
import { fetchGachaExchangeHistoryList } from '../lib/api';
import { LoadingOverlay } from '../components/LoadingOverlay';
import { NoData } from '../components/NoData';

import { reportGA, GAEventValue } from "../lib/googleAnalytics";

const separatorAreaHeight = 1;
const titleAreaHeight = 74;
const listHeaderAreaHeight = 37;
const listItemHeight = 44;
const listItemHorizontalMargin = 21;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: '100%',
      backgroundColor: '#dc3a70',
    },
    separator: {
      height: separatorAreaHeight,
      backgroundColor: 'white',
    },
    titleArea: {
      position: 'relative',
      height: titleAreaHeight,
      textAlign: 'center',
      paddingTop: 24,
    },
    titleAreaText: {
      color: 'white',
      fontWeight: 'bold',
      fontSize: 20,
      letterSpacing: 2.6,
    },
    listHeaderArea: {
      color: theme.palette.primary.main,
      height: listHeaderAreaHeight,
      backgroundColor: 'white',
      border: 'solid 1px white',
    },
    row: {
      display: 'table',
      width: consts.estimatedContentWidth - listItemHorizontalMargin * 2,
      boxSizing: 'border-box',
      marginLeft: 'auto',
      marginRight: 'auto',
      fontSize: 10,
    },
    col: {
      display: 'table-cell',
      verticalAlign: 'middle',
      boxSizing: 'border-box',
      paddingLeft: 8,
      paddingRight: 8,
      fontWeight: 'bold',
      wordBreak: 'break-all',
    },
    col1: {
      width: 56,
    },
    col1Body: {
      width: 57,
    },
    col2: {
      width: 120,
    },
    ellipsisBy3Line: {
      display: '-webkit-box',
      overflow: 'hidden',
      '-webkitLineClamp': 3,
      '-webkitBoxOrient': 'vertical',
    },
    col3: {
      width: 38,
      textAlign: 'center',
    },
    col3Body: {
      paddingLeft: 2,
      paddingRight: 2,
    },
    col4: {
      width: 51,
      paddingLeft: 2,
      paddingRight: 2,
      textAlign: 'center',
    },
    col4Body: {
    },
    col5: {
      width: 66,
      paddingLeft: 2,
      paddingRight: 2,
      textAlign: 'center',
    },
    col5Body: {
      width: 67,
    },
    colHeaderBorder: {
      borderLeft: 'solid 1px #dc3a70',
    },
    colBodyBorder: {
      borderLeft: 'solid 1px white',
    },
    listBodyArea: {
      height: `calc(100% - ${listHeaderAreaHeight + titleAreaHeight + separatorAreaHeight}px)`,
    },
    listItemContainer: {
      color: 'white',
      height: '100%',
      borderBottom: 'solid 1px rgba(255, 255, 255, .7)',
    },
    odd: {
      backgroundColor: 'rgba(255, 255, 255, .15)',
    },
  }),
);

const FETCH_NUM = 30;
export const GachaExchangeHistoryPage: React.FC = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const elm = useRef<HTMLDivElement>(null);
  const [listContainerHeight, setListContainerHeight] = useState(0);
  const { exchangeHistoryCount, exchangeHistoryList, loading } = useSelector(selectGacha);
  const count = exchangeHistoryCount;
  const list = exchangeHistoryList;
  const [prevCount, setPrevCount] = useState(0);

  const loadMoreItems = useCallback(async (startIndex: number, stopIndex: number) => {
    dispatch(setGachaLoading(true));
    await dispatch(
      fetchGachaExchangeHistoryList({
        limit: stopIndex - startIndex + 1,
        offset: startIndex,
      }),
    );
    dispatch(setGachaLoading(false));
  }, []);

  useEffect(() => {
    if (count !== prevCount) {
      setPrevCount(count);
      loadMoreItems(0, FETCH_NUM);
    }
  }, [prevCount, count]);

  useLayoutEffect(() => {
    if (!!elm && !!elm.current) {
      const rect = elm.current.getBoundingClientRect();
      setListContainerHeight(rect.height);
    }
  }, [elm, elm.current]);

  useEffect(() => {
    reportGA(GAEventValue.GACHA_KOUKAN_HISTORY);
  }, []);

  let contents = (
    <InfiniteLoader
      isItemLoaded={(index) => !!list[index]}
      itemCount={count}
      loadMoreItems={loadMoreItems}
      minimumBatchSize={FETCH_NUM}
    >
      {({ onItemsRendered, ref }) => (
        <FixedSizeList
          height={listContainerHeight}
          width={'100%'}
          itemSize={listItemHeight}
          itemCount={list.length}
          onItemsRendered={onItemsRendered}
          ref={ref}
        >
          {({ index, style }) => {
            return list[index] ? (
              <div style={style}>
                <div className={classNames(classes.listItemContainer, classes.row, { [classes.odd]: index % 2 === 1 })}>
                  <div
                    className={classNames(classes.col, classes.col1, classes.col1Body)}
                    dangerouslySetInnerHTML={{ __html: moment(list[index].createdAt).format('Y.<br />M.D') }}
                  />
                  <div className={classNames(classes.col, classes.col2, classes.colBodyBorder)}>
                    <div className={classes.ellipsisBy3Line}>{list[index].交換物名}</div>
                    <div className={classes.ellipsisBy3Line}>{list[index].交換物名2行目}</div>
                  </div>
                  <div className={classNames(classes.col, classes.col3, classes.colBodyBorder, classes.col3Body)}>
                    {list[index].個数}
                  </div>
                  <div className={classNames(classes.col, classes.col4, classes.colBodyBorder, classes.col4Body)}>
                    {Number(list[index].消費ポイント).toLocaleString()}
                  </div>
                  <div className={classNames(classes.col, classes.col5, classes.colBodyBorder, classes.col5Body)}>
                    {/* 「ネガティブな表示はしたくない」の要求を採択し、キャンセル済みの場合は「未送付」と表示する */}
                    {list[index].キャンセル済 ? 'キャンセル' : list[index].ステータス}
                  </div>
                </div>
              </div>
            ) : (
              <></>
            );
          }}
        </FixedSizeList>
      )}
    </InfiniteLoader>
  );

  if (count <= 0 || list.length === 0) {
    if (loading) {
      contents = <LoadingOverlay />;
    } else {
      contents = <NoData color={'white'} />;
    }
  }

  return (
    <>
      <div className={classes.container}>
        <div className={classes.separator}></div>
        <div className={classes.titleArea}>
          <span className={classes.titleAreaText}>交換履歴</span>
        </div>
        <div className={classNames(classes.listHeaderArea, classes.row)}>
          <div className={classNames(classes.col, classes.col1)}>交換日</div>
          <div className={classNames(classes.col, classes.col2, classes.colHeaderBorder)}>景品名</div>
          <div className={classNames(classes.col, classes.col3, classes.colHeaderBorder)}>個数</div>
          <div className={classNames(classes.col, classes.col4, classes.colHeaderBorder)}>消費枚数</div>
          <div className={classNames(classes.col, classes.col5, classes.colHeaderBorder)}>ステータス</div>
        </div>

        <div className={classes.listBodyArea} ref={elm}>
          {contents}
        </div>
      </div>
    </>
  );
};
