import { createSlice } from '@reduxjs/toolkit';

import { getCustomRankingValueByIndex, getRegularRankingValueByIndex } from '../lib/dataFunctions';

import { RootState, store } from './store';

import {
  ActorCustomRankingPeriodType,
  ActorRegularRankingPeriodType,
  ActorRegularRankingType,
  CustomRanking,
  RankingEntry,
  fetchCustomRankingEntries,
  fetchCustomRankingEntriesAction,
  fetchCustomRankingSummary,
  fetchCustomRankingSummaryAction,
  fetchRanking,
  fetchRankingAction,
  fetchRegularRankingEntries,
  fetchRegularRankingEntriesAction,
  fetchRegularRankingSummary,
  fetchRegularRankingSummaryAction,
} from 'lib/api';

export type RankingSummary = {
  selfRank: number; // ログインユーザの順位 存在しなければ0
  periodTitle: string; // 集計期間表示
  count: number; // 総件数
  totalPoint?: number; // 総ポイント(お客様ランキングで必要) 
};

export type RankingDefine = {
  isRegular: boolean;
  name: string;
  periodIndex: number;

  // for RegularRanking
  rankingType: ActorRegularRankingType;
  periodType: ActorRegularRankingPeriodType;

  // for CustomRanking
  rankingId: string;
  customRankingPeriodType: ActorCustomRankingPeriodType;
};

// 相対集計番号 : 集計期間種別 : 集計種別
type RegularRankingData = {
  [periodIndex: number]: { [periodType: string]: { [rankingType: string]: RankingEntry[] } };
};
type RegularRankingSummary = {
  [periodIndex: number]: { [periodType: string]: { [rankingType: string]: RankingSummary } };
};

// 集計種別 : 集計番号
type CustomRankingData = { [rankingId: string]: { [periodIndex: number]: RankingEntry[] } };
type CustomRankingSummary = { [rankingId: string]: { [periodIndex: string]: RankingSummary } };

interface RankingState {
  // periodIndex:periodType:rankingType:entries
  regularRankingData: RegularRankingData;
  // periodIndex:periodType:rankingType:RegularRankingSummaryResponse
  regularRankingSummary: RegularRankingSummary;

  customRankings: CustomRanking[];
  // rankingId:periodIndex:entries
  customRankingData: CustomRankingData;
  // rankingId:periodIndex:CustomRankingSummaryResponse
  customRankingSummary: CustomRankingSummary;
}

const initialState: RankingState = {
  regularRankingData: {},
  regularRankingSummary: {},
  customRankings: [],
  customRankingData: {},
  customRankingSummary: {},
};

export const rankingSlice = createSlice({
  name: 'ranking',
  initialState,
  reducers: {
    // setCustomRankings: (state, action: PayloadAction<CustomRanking[]>) => {
    //   state.customRankings = action.payload;
    // },
  },
  extraReducers: {
    [fetchRegularRankingSummary.fulfilled.toString()]: (state, action: fetchRegularRankingSummaryAction) => {
      const [t, p, i, s] = [
        action.payload.request.rankingType,
        action.payload.request.periodType,
        action.payload.request.periodIndex,
        action.payload.response,
      ];
      if (!state.regularRankingSummary[i]) state.regularRankingSummary[i] = {};
      if (!state.regularRankingSummary[i][p]) state.regularRankingSummary[i][p] = {};
      state.regularRankingSummary[i][p][t] = s;
    },
    [fetchRegularRankingEntries.fulfilled.toString()]: (state, action: fetchRegularRankingEntriesAction) => {
      const [t, p, i, e] = [
        action.payload.request.rankingType,
        action.payload.request.periodType,
        action.payload.request.periodIndex,
        action.payload.response.entries,
      ];
      if (!state.regularRankingData[i]) state.regularRankingData[i] = {};
      if (!state.regularRankingData[i][p]) state.regularRankingData[i][p] = {};
      if (!state.regularRankingData[i][p][t]) state.regularRankingData[i][p][t] = [];
      // offset+indexでマージ
      if (e) {
        for (let index = 0; index < e.length; index++) {
          state.regularRankingData[i][p][t][action.payload.request.offset + index] = e[index];
        }
      }
    },
    [fetchRanking.fulfilled.toString()]: (state, action: fetchRankingAction) => {
      // TODO マージ？
      state.customRankings = action.payload.response.latestCustomRankings;
    },
    [fetchCustomRankingSummary.fulfilled.toString()]: (state, action: fetchCustomRankingSummaryAction) => {
      const [rankingId, periodIndex, response] = [
        action.payload.request.rankingId,
        action.payload.request.periodIndex,
        action.payload.response,
      ];
      if (!state.customRankingSummary[rankingId]) state.customRankingSummary[rankingId] = {};
      state.customRankingSummary[rankingId][periodIndex] = response;
    },
    [fetchCustomRankingEntries.fulfilled.toString()]: (state, action: fetchCustomRankingEntriesAction) => {
      const [rankingId, periodIndex, entries] = [
        action.payload.request.rankingId,
        action.payload.request.periodIndex,
        action.payload.response.entries,
      ];
      if (!state.customRankingData[rankingId]) state.customRankingData[rankingId] = {};
      if (!state.customRankingData[rankingId][periodIndex]) state.customRankingData[rankingId][periodIndex] = [];
      // offset+indexでマージ
      if (entries) {
        for (let index = 0; index < entries.length; index++) {
          state.customRankingData[rankingId][periodIndex][action.payload.request.offset + index] = entries[index];
        }
      }
    },
  },
});

// export const { } = rankingSlice.actions;

export const selectRanking = (state: RootState) => state.ranking;

export const fetchRegularRanking = async (
  // useSelectorで取得したstateが更新されないため、コンポーネントからstateを受け取る
  regularRankingSummary: RegularRankingSummary,
  regularRankingData: RegularRankingData,
  rankingType: ActorRegularRankingType,
  periodType: ActorRegularRankingPeriodType,
  periodIndex: number,
  limit: number,
  offset: number,
) => {
  // summaryがstateになければ取得
  if (!getRegularRankingValueByIndex(regularRankingSummary, periodIndex, periodType, rankingType)) {
    console.log('\tregularRanking load Summary', rankingType, periodType, periodIndex);
    await store.dispatch(fetchRegularRankingSummary({ rankingType, periodType, periodIndex }));
  }

  // offset~limitがstateになければ取得, rank順にstate上書き
  const dataCount = getRegularRankingValueByIndex(regularRankingData, periodIndex, periodType, rankingType)?.slice(
    offset,
    offset + limit,
  ).length;
  if (limit !== 0 && (!dataCount || dataCount < offset + limit)) {
    console.log('\tregularRanking load Entries', rankingType, periodType, periodIndex, limit, offset);
    await store.dispatch(fetchRegularRankingEntries({ rankingType, periodType, periodIndex, limit, offset }));
  }
};

export const fetchCustomRanking = (
  customRankingSummary: CustomRankingSummary,
  customRankingData: CustomRankingData,
  rankingId: string,
  periodIndex: number,
  limit: number,
  offset: number,
) => {
  // summaryがstateになければ取得
  if (!getCustomRankingValueByIndex(customRankingSummary, rankingId, periodIndex)) {
    store.dispatch(fetchCustomRankingSummary({ rankingId, periodIndex }));
  }

  // offset~limitがstateになければ取得, rank順にstate上書き
  const dataCount = getCustomRankingValueByIndex(customRankingData, rankingId, periodIndex)?.slice(
    offset,
    offset + limit,
  ).length;
  if (!dataCount || dataCount < offset + limit) {
    store.dispatch(fetchCustomRankingEntries({ rankingId, periodIndex, limit, offset }));
  }
};

export default rankingSlice.reducer;
