import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { IntlProvider } from 'react-intl';
import { PersistGate } from 'redux-persist/integration/react';
import SwiperCore, { Virtual } from 'swiper';

import './index.css';
// import App from './App';
import { persistor, store } from './app/store';
import * as serviceWorker from './serviceWorker';
import { startCheckVersions } from './lib/checkFrontVersion';

import { Layout } from 'layouts';

SwiperCore.use([Virtual]);
ReactDOM.render(
  <IntlProvider locale={navigator.language}>
    <React.StrictMode>
      <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
          {/*<App />*/}
          <Layout />
        </PersistGate>
      </Provider>
    </React.StrictMode>
  </IntlProvider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

// ソース・データ更新の検出ループ開始
startCheckVersions();
