import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';

import {
  CalendarPage,
  GachaDummyPage,
  GachaExchangeHistoryPage,
  GachaExchangePage,
  GachaLotteryHistoryPage,
  GachaMovieCheckPage,
  GachaPage,
  GoalsConfigPage,
  GraphPage,
  HelpDetailPage,
  HelpPage,
  HomePage,
  MyPage,
  NotificationPage,
  PyramidPage,
  RankingPage,
  EffectSamplePage,
  GachaDetailPage
} from 'pages';

export const Path = {
  login: process.env.PUBLIC_URL + '/login',
  home: process.env.PUBLIC_URL + '/',
  pyramid: process.env.PUBLIC_URL + '/pyramid',
  ranking: process.env.PUBLIC_URL + '/ranking',
  graph: process.env.PUBLIC_URL + '/graph',
  calendar: process.env.PUBLIC_URL + '/calendar',
  my: process.env.PUBLIC_URL + '/my',
  notifications: process.env.PUBLIC_URL + '/notifications',
  help: process.env.PUBLIC_URL + '/help',
  config: process.env.PUBLIC_URL + '/config',
  gacha: process.env.PUBLIC_URL + '/gacha',
  gachaDummy: process.env.PUBLIC_URL + '/gacha/dummy',
  gachaMovieCheck: process.env.PUBLIC_URL + '/gacha/movie_check',
  gachaLotteryHistory: process.env.PUBLIC_URL + '/gacha/lottery_history',
  gachaExchangeHistory: process.env.PUBLIC_URL + '/gacha/exchange_history',
  gachaExchange: process.env.PUBLIC_URL + '/gacha/exchange',
  gachaDetailPage: process.env.PUBLIC_URL + '/gacha/detail',
  effectSample: process.env.PUBLIC_URL + '/effectSample'
};

const routes = (
  <Switch>
    <Route exact path={Path.home} component={HomePage} />
    <Route exact path={Path.pyramid} component={PyramidPage} />
    <Route exact path={Path.ranking} component={RankingPage} />
    <Route exact path={Path.graph} component={GraphPage} />
    <Route exact path={Path.calendar} component={CalendarPage} />
    <Route exact path={Path.my} component={MyPage} />
    <Route exact path={Path.notifications} component={NotificationPage} />
    <Route exact path={Path.notifications + '/:notificationId'} component={NotificationPage} />
    <Route exact path={Path.help} component={HelpPage} />
    <Route exact path={Path.help + '/:helpIndex'} component={HelpDetailPage} />
    <Route exact path={Path.config} component={GoalsConfigPage} />
    <Route exact path={Path.effectSample} component={EffectSamplePage} />
    <Route exact path={Path.gacha} component={GachaPage} />
    <Route exact path={Path.gachaDummy} component={GachaDummyPage} />
    <Route exact path={Path.gachaMovieCheck} component={GachaMovieCheckPage} />
    <Route exact path={Path.gachaLotteryHistory} component={GachaLotteryHistoryPage} />
    <Route exact path={Path.gachaExchangeHistory} component={GachaExchangeHistoryPage} />
    <Route exact path={Path.gachaExchange} component={GachaExchangePage} />
    <Route exact path={Path.gachaDetailPage} component={GachaDetailPage} />
    <Redirect to={Path.home} />
  </Switch>
);

export default routes;
