import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    loadingIcon: {
      position: 'absolute',
      left: 0,
      right: 0,
      top: 0,
      bottom: 0,
      margin: 'auto',
    },
  }),
);
export const LoadingOverlay: React.FC = () => {
  const classes = useStyles();
  return <CircularProgress className={classes.loadingIcon} />;
};
