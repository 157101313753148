import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {EffectDetail, effectImageHeight} from "../../lib/effectLib";
import {assetPath} from "../../lib/pathes";
import classNames from 'classnames';

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  shopImage:{
    objectFit: 'contain',
    height: effectImageHeight(74),
    top: effectImageHeight(539),
  },
  calendarImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(386),
    height: effectImageHeight(89),
    textAlign: 'center',
    // backgroundColor: 'blue',
  },
  imageList:{
    objectFit: 'contain',
    height: '100%',
  },
  rankImageListContainer: {
    position: 'absolute',
    width: '100%',
    top: effectImageHeight(759),
    height: effectImageHeight(125),
    textAlign: 'center',
    // backgroundColor: 'blue',
  },
  rankImagePadding1:{
    paddingLeft: 4,
    paddingRight: 3,
  },
  rankImagePadding2:{
    paddingLeft: 3,
    paddingRight: 2,
  },
}));

export function getEffectSheetType3PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}

function getCalendarPathes(monthly: boolean, param: string): string[] {
  if(monthly) return [assetPath(`img/effect/ranking/month/ranking_${param}.png`)]
  let pathes: string[] = param.split('').map((s) => assetPath(`img/effect/ranking/year/ra_year_${s}.png`));
  pathes.push(assetPath(`img/effect/ranking/year/ra_year_nen.png`))
  return pathes;
}

// SheetLayoutType.ランキング
export function EffectSheetType3(props: Props) {
  const classes = useStyles();
  const monthly = Number(props.sheet.dto.param1) <= 12
  const rankPathes = String(props.sheet.dto.typeValue).split('').map((s) => assetPath(`img/effect/ranking/ranking/ra${s}.png`));
  rankPathes.push(assetPath('img/effect/ranking/ranking/ra.png'))

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <div className={classes.calendarImageListContainer}>
      {getCalendarPathes(monthly, props.sheet.dto.param1).map((p, i) =>
        <img key={i} className={classNames(classes.imageList)} src={p} alt="month" />
      )}
    </div>
    {props.sheet.dto.param2 && <img
      className={classNames(classes.image, classes.horizontalCenter, classes.shopImage)}
      src={assetPath(`img/effect/common/shop/ba_pt_${props.sheet.dto.param2}.png`)}
      alt="shop"
    />}
    <div className={classes.rankImageListContainer}>
      {rankPathes.map((p, i) =>
        <img key={i}  className={classNames(
          classes.imageList,
          {[classes.rankImagePadding1]: props.sheet.dto.typeValue < 10},
          {[classes.rankImagePadding2]: props.sheet.dto.typeValue >= 10},
        )} src={p} alt="rank" />
      )}
    </div>
  </div>;
}
