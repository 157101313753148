import ReactGA from "react-ga4";
import moment from "moment";
import {store} from "../app/store";

export const GAEventTypeValue = {
    なし: "なし",
    年月日: "年月日",
    年月: "年月",
    年: "年",
    四半期: "四半期",
    任意期間: "任意期間",
    全期間: "全期間"
}
export type GAEventType = typeof GAEventTypeValue[keyof typeof GAEventTypeValue];

export const GAEventValue = {
    HOME: { path: "/home", type: GAEventTypeValue.なし },
    HOME_BADGE_TPOINT: { path: "/home/badge/tenpo_cast_point_ranking", type: GAEventTypeValue.年月 },
    HOME_BADGE_GPOINT: { path: "/home/badge/group_cast_point_ranking", type: GAEventTypeValue.年月 },
    HOME_BADGE_DOUHAN: { path: "/home/badge/group_douhan_ranking", type: GAEventTypeValue.年月 },
    HOME_BADGE_SAIKOU: { path: "/home/badge/point_saikoukiroku", type: GAEventTypeValue.年月 },
    HOME_BADGE_THANKS: { path: "/home/badge/thanks_coin", type: GAEventTypeValue.年月 },
    HOME_BUTTON_TRANK: { path: "/gragh/", type: GAEventTypeValue.なし },
    HOME_PYRAMID: { path: "/home/group_ranking/pyramid", type: GAEventTypeValue.年月},
    HOME_BUTTON_DRANK: { path: "/gragh/", type: GAEventTypeValue.なし },
    HOME_BUTTON_SRANK: { path: "/gragh/", type: GAEventTypeValue.なし },
    HOME_BUTTON_FRANK: { path: "/gragh/", type: GAEventTypeValue.なし },

    MENU_MYPAGE: { path: "/mypage", type: GAEventTypeValue.なし },
    MENU_NOTIFICATION: { path: "/notification", type: GAEventTypeValue.なし },
    MENU_HOME: { path: "/home", type: GAEventTypeValue.なし },
    MENU_RANKING: { path: "/ranking", type: GAEventTypeValue.なし },
    MENU_GRAPH: { path: "/gragh", type: GAEventTypeValue.なし },
    MENU_CALENDAR: { path: "/calendar", type: GAEventTypeValue.なし },
    MENU_GACHA: { path: "/gacha", type: GAEventTypeValue.なし },

    RANKING: { path: "/ranking", type: GAEventTypeValue.なし },
    RANKING_TENPO_MONTH: { path: "/ranking/upper_block_tenpo_ranking", type: GAEventTypeValue.年月 },
    RANKING_TENPO_YEAR: { path: "/ranking/upper_block_tenpo_ranking", type: GAEventTypeValue.年 },
    RANKING_TENPO_DETAIL_MONTH: { path: "/ranking/tenpo_ranking", type: GAEventTypeValue.年月 },
    RANKING_TENPO_DETAIL_YEAR: { path: "/ranking/tenpo_ranking", type: GAEventTypeValue.年 },
    RANKING_GROUP_DETAIL_MONTH: { path: "/ranking/group_ranking", type: GAEventTypeValue.年月 },
    RANKING_GROUP_DETAIL_YEAR: { path: "/ranking/group_ranking", type: GAEventTypeValue.年 },
    RANKING_DOUHAN_DETAIL_MONTH: { path: "/ranking/douhan_ranking", type: GAEventTypeValue.年月 },
    RANKING_DOUHAN_DETAIL_YEAR: { path: "/ranking/douhan_ranking", type: GAEventTypeValue.年 },
    RANKING_SHIMEI_DETAIL_MONTH: { path: "/ranking/shimei_ranking", type: GAEventTypeValue.年月 },
    RANKING_SHIMEI_DETAIL_YEAR: { path: "/ranking/shimei_ranking", type: GAEventTypeValue.年 },
    RANKING_CUSTOM_ALL: { path: "/ranking/custom_ranking/", type: GAEventTypeValue.全期間 },
    RANKING_CUSTOM_MONTH: { path: "/ranking/custom_ranking/", type: GAEventTypeValue.年月 },
    RANKING_CUSTOM_YEAR: { path: "/ranking/custom_ranking/", type: GAEventTypeValue.年 },
    RANKING_CUSTOM_PERIOD: { path: "/ranking/custom_ranking/", type: GAEventTypeValue.任意期間 },

    GRAPH: { path: "/gragh", type: GAEventTypeValue.なし },
    GRAPH_SOUSHI_MONTH: { path: "/graph/point_soushikyugaku", type: GAEventTypeValue.年月 },
    GRAPH_TENPO_MONTH: { path: "/graph/tenpo_point_group_point", type: GAEventTypeValue.年月 },
    GRAPH_DOUHAN_MONTH: { path: "/graph/douhan_shimei", type: GAEventTypeValue.年月 },
    GRAPH_FREE_MONTH: { path: "/graph/free_kinmujikan", type: GAEventTypeValue.年月 },
    GRAPH_SOUSHI_YEAR: { path: "/graph/point_soushikyugaku", type: GAEventTypeValue.年 },
    GRAPH_TENPO_YEAR: { path: "/graph/tenpo_point_group_point", type: GAEventTypeValue.年 },
    GRAPH_DOUHAN_YEAR: { path: "/graph/douhan_shimei", type: GAEventTypeValue.年 },
    GRAPH_FREE_YEAR: { path: "/graph/free_kinmujikan", type: GAEventTypeValue.年 },
    GRAPH_SOUSHI_ALL: { path: "/graph/point_soushikyugaku", type: GAEventTypeValue.全期間 },
    GRAPH_TENPO_ALL: { path: "/graph/tenpo_point_group_point", type: GAEventTypeValue.全期間 },
    GRAPH_DOUHAN_ALL: { path: "/graph/douhan_shimei", type: GAEventTypeValue.全期間 },
    GRAPH_FREE_ALL: { path: "/graph/free_kinmujikan", type: GAEventTypeValue.全期間 },

    CALENDAR: { path: "/calendar", type: GAEventTypeValue.なし },
    CALENDAR_KYUYO: { path: "/calendar/kyuyo_meisai", type: GAEventTypeValue.年月 },
    CALENDAR_ICON: { path: "/calendar/icon_explanation", type: GAEventTypeValue.なし },
    CALENDAR_MONTHLY: { path: "/calendar/monthly", type: GAEventTypeValue.年月},
    CALENDAR_SEISEKI: { path: "/calendar/seiseki", type: GAEventTypeValue.年月日 },

    GACHA: { path: "/gacha", type: GAEventTypeValue.なし },
    GACHA_BUTTON: { path: "/gacha/gacha_button", type: GAEventTypeValue.なし },
    GACHA_TOUSEN_HISTORY: { path: "/gacha/tousen_history", type: GAEventTypeValue.なし },
    GACHA_KEIHIN: { path: "/gacha/keihin_exchange", type: GAEventTypeValue.なし },
    GACHA_KOUKAN_HISTORY: { path: "/gacha/koukan_history", type: GAEventTypeValue.なし },

    NOTIFICATION: { path: "/notification", type: GAEventTypeValue.なし },

    MYPAGE: { path: "/mypage", type: GAEventTypeValue.なし },
    MYPAGE_TENPO: { path: "/mypage/tenpo_cast_point_ranking", type: GAEventTypeValue.年月 },
    MYPAGE_GROUP: { path: "/mypage/group_cast_point_ranking", type: GAEventTypeValue.年月 },
    MYPAGE_DOUHAN: { path: "/mypage/douhan_ranking", type: GAEventTypeValue.年月 },
    MYPAGE_SAIKOU: { path: "/mypage/point_saikoukiroku", type: GAEventTypeValue.年月 },
    MYPAGE_THANKS: { path: "/mypage/thanks_coin", type: GAEventTypeValue.四半期 },
    MYPAGE_MOKUHYO: { path: "/mypage/mokuhyo_setting", type: GAEventTypeValue.年月 },
    MYPAGE_BPRANK: { path: "/mypage/bp_rank_badge", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_RANK: { path: "/mypage/bp_rank_badge/rank", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_BP: { path: "/mypage/bp_rank_badge/bp", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_TENPO: { path: "/mypage/bp_rank_badge/tenpo_point_ranking_badge", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_GROUP: { path: "/mypage/bp_rank_badge/group_point_ranking_badge", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_DOUHAN: { path: "/mypage/bp_rank_badge/group_douhan_ranking_badge", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_JIKOBEST: { path: "/mypage/bp_rank_badge/jikobest_badge", type: GAEventTypeValue.なし },
    MYPAGE_BPRANK_THANKS: { path: "/mypage/bp_rank_badge/thanks_coin_badge", type: GAEventTypeValue.なし },
} as const;
export type GAEvent = typeof GAEventValue[keyof typeof GAEventValue];

// reportGA(GAEventLabel.RANKING_CUSTOM_YEAR, moment("yyyymmdd", dto.date));
// reportGA(GAEventLabel.RANKING_CUSTOM_YEAR, new Date(dto.date));

const reportGAQueue: string[] = [];

// p1とp2は
export function reportGA(ev: GAEvent, p1?: string | Date, p2?: string | Date): string {
    // console.log("reportGA in", ev.path + "|" + ev.type + "|" + p1 + "|" + p2)
    const page = ev.path + formatDate(ev.type, p1, p2);
    window.document.title = page;
    if (!store.getState().home.loaded) {
        // reportGAがinitialize前に呼ばれた場合はキューに保存
        reportGAQueue.push(page);
        return page;
    }
    if (!!store.getState().home.switchableActorInfos) return page;
    ReactGA.send({ hitType: "pageview", page:  page});
    console.log("reportGA out", page);
    return page;
}

export function reportGACustomRanking(ev: GAEvent, rankingName: string, p1?: string | Date, p2?: string | Date): void {
    const page = ev.path + rankingName + formatDate(ev.type, p1, p2);
    window.document.title = page;
    if (!store.getState().home.loaded) {
        // reportGAがinitialize前に呼ばれた場合はキューに保存
        reportGAQueue.push(page);
        return;
    }
    if (!!store.getState().home.switchableActorInfos) return;
    ReactGA.send({ hitType: "pageview", page: page });
    console.log("reportGACustomRanking", page)
}

// ロードが完了したタイミングでLayoutからコールしてもらう
export function reportGAAsync(): void {
    while (reportGAQueue.length > 0) {
        const q = reportGAQueue.pop();
        if (!q) break;
        reportGASimple(q);
    }
}

function reportGASimple(page: string): void {
    if (!!store.getState().home.switchableActorInfos) {
        console.log("reportGASimple skip staff login");
        return;
    }
    ReactGA.send({ hitType: "pageview", page: page });
    console.log("reportGA async", page);
}

function formatDate(type: GAEventType, p1: Date | string | undefined, p2?: Date | string | undefined) {
    if (type === GAEventTypeValue.年月日) {
        return "/daily/" + (p1 instanceof Date ? moment(p1).format("YYYYMMDD") : p1);
    }
    if (type === GAEventTypeValue.年月) {
        return "/monthly/" + (p1 instanceof Date ? moment(p1).format("YYYYMM") : p1);
    }
    if (type === GAEventTypeValue.年) {
        return "/yearly/" + (p1 instanceof Date ? moment(p1).format("YYYY") : p1);
    }
    if (type === GAEventTypeValue.四半期) {
        return "/quarterly/" +
            (p1 instanceof Date ? moment(p1).format("YYYYMM") : p1)
            +"_"+
            (p2 instanceof Date ? moment(p2).format("YYYYMM") : p2);
    }
    if (type === GAEventTypeValue.任意期間) {
        return "/fixed_period/" +
            (p1 instanceof Date ? moment(p1).format("YYYYMMDD") : p1)
            +"_"+
            (p2 instanceof Date ? moment(p2).format("YYYYMMDD") : p2);

    }
    if (type === GAEventTypeValue.全期間) {
        return "/entire_period";
    }
    return "";
}


