import React, { useEffect, useRef, useState } from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

// import {consts} from "../consts";

function getNodeWidth(node: HTMLElement | null | undefined): number {
  if (!node) return 0;
  const nodeStyles = window.getComputedStyle(node);
  const width = node.offsetWidth;
  // console.log('❓',
  //   node.offsetWidth,
  //   nodeStyles.width,
  //   nodeStyles.maxWidth,
  //   )
  const borderLeftWidth = parseFloat(nodeStyles.borderLeftWidth);
  const borderRightWidth = parseFloat(nodeStyles.borderRightWidth);
  const paddingLeft = parseFloat(nodeStyles.paddingLeft);
  const paddingRight = parseFloat(nodeStyles.paddingRight);
  return width - borderRightWidth - borderLeftWidth - paddingLeft - paddingRight;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'inline-block',
      whiteSpace: 'nowrap',
      transformOrigin: '0 50%',
    },
  }),
);

type Props = {
  content: string;
};

export function ScalableText(props: Props) {
  const classes = useStyles();
  const [scale, setScale] = useState<number>(1);
  const [maxWidth, setMaxWidth] = useState<number | undefined>(undefined);
  const spanRef = useRef<HTMLSpanElement>(null);
  useEffect(() => {
    if (maxWidth || !props.content) return;
    const parentWidth = getNodeWidth(spanRef.current?.parentElement);
    const currentWidth = getNodeWidth(spanRef.current);
    if (!parentWidth || !currentWidth) return;
    setMaxWidth(parentWidth);
    const scale = currentWidth > parentWidth ? parentWidth / currentWidth : 1;
    setScale(scale);
    // console.log('❗', props.content, maxWidth, parentWidth, currentWidth, scale);
  }, [
    props.content,
    spanRef,
    // spanRef.current,
    // spanRef.current?.offsetWidth,
    // maxWidth,
    // currentWidth
  ]);
  //props.content,
  //spanRef,
  // console.log('❗❗', props.content, maxWidth, scale);
  const style = {
    transform: `scale(${scale}, ${scale})`,
    maxWidth: maxWidth,
    // maxWidth: undefined,
  };
  return (
    <span ref={spanRef} className={classes.root} style={style}>
      {props.content}
    </span>
  );
}
