import React, { useCallback } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";
import { useDispatch } from "react-redux";

import { GraphHeader } from "./GraphHeader";
import { GraphBody } from "./GraphBody";
import { useGraphEntries } from "./graphDataLib";
import { GraphType } from "./graphDefine";

import { clearSelectedGraphEntryIndex } from "app/applicationSlice";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      height: 255,
      width: 375,
    },
    graph: {
      width: 375,
      height: 180,
    },
  })
);

type Props = {
  type: GraphType;
};

/**
 *
 * ランキングなど逆のプロット時は値をマイナスにし、表示時に戻している
 * @param props
 * @constructor
 */
export function Graph(props: Props) {
  const classes = useStyles();
  const dispatch = useDispatch();
  const entries = useGraphEntries();

  const reset = useCallback(() => {
    dispatch(clearSelectedGraphEntryIndex());
  }, []);

  return (
    <>
      {entries.length > 0 ? (
        <>
          <div className={classes.container}>
            <GraphHeader type={props.type}></GraphHeader>
            <div className={classes.graph} onMouseLeave={reset}>
              <GraphBody type={props.type}></GraphBody>
            </div>
          </div>
        </>
      ) : null}
    </>
  );
}
