window.AudioContext = window.AudioContext || window.webkitAudioContext

export class AudioPlayer {
  load (url) {
    if(this.isLoadStarted()) return
    this.url = url

    return new Promise((resolve, reject) => {
      fetch(this.url).then(e => e.arrayBuffer()).then(arrayBuffer => {
        (new AudioContext()).decodeAudioData(arrayBuffer, (buffer) => {
          this.buffer = buffer
          this.init()
          resolve()
          console.log('AudioPlayer inited')
        }, (e) => {
          console.error(e)
          reject(e)
        })
      })
    })
  }

  isLoadStarted () {
    return !!this.url
  }
  isReadyToPlay () {
    return this.context != null
  }
  init () {
    const context = new AudioContext()

    this.gainNode = context.createGain()
    this.gainNode.connect(context.destination)
    this.gainNode.gain.value = 1

    this.context = context
  }

  clear () {
    if (this.context) {
      this.context.close()
    }
    this.context = null
    this.source = null
    this.gainNode = null
    this.url = null
    this.buffer = null
  }

  start () {
    if(!this.isReadyToPlay()) return false;
    console.log('AudioPlayer started');
    this.source = this.context.createBufferSource()
    this.source.buffer = this.buffer
    // this.source.connect(context.destination);
    this.source.connect(this.gainNode)
    this.source.start(0)
    return true;
  }
  pause () {
    this.context.suspend()
    this.init()
  }
}
