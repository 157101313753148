import { PayloadAction, createSlice } from "@reduxjs/toolkit";

import {
  GraphDataEntry,
  GraphGoalsDto,
  GraphPeriodType,
  GraphPeriodTypeValue,
  fetchGraphData,
  fetchGraphDataAction,
  fetchGraphGoals,
  fetchGraphGoalsAction,
} from "../lib/api";
import { GraphType, 場内指名件数 } from "../components/Graph/graphDefine";
import { 累積化 } from "../components/Graph/graphDataLib";
import { orEmptyGraphGoalsDto } from "../lib/dataFunctions";

import { RootState } from "./store";

export type GraphValue = number | null;

// 1グラフ表示用のデータセット
export type GraphDataSet = {
  labels: string[];
  values: GraphValue[];
  rankingCounts: number[];
};
// APIレスポンスをグラフ表示用に整形した結果のキャッシュデータ
type GraphData = {
  [periodType: string]: { [graphType: string]: GraphDataSet };
};
type GraphDataResponses = { [periodType: string]: GraphDataEntry[] };

interface GraphState {
  periodType: GraphPeriodType;
  goals: GraphGoalsDto;
  graphData: GraphData;
  graphDataResponses: GraphDataResponses;
}

const initialState: GraphState = {
  // 初期のグラフ表示 期間は年
  periodType: GraphPeriodTypeValue.ONEYEAR,
  goals: orEmptyGraphGoalsDto(),

  graphData: {},
  graphDataResponses: {},
};

export const graphSlice = createSlice({
  name: "graph",
  initialState,
  reducers: {
    setPeriodType: (
      state,
      action: PayloadAction<{ type: GraphPeriodType }>
    ) => {
      state.periodType = action.payload.type;
    },
  },
  extraReducers: {
    [fetchGraphGoals.fulfilled.toString()]: (
      state,
      action: fetchGraphGoalsAction
    ) => {
      console.log("fetchGraphGoals", action.payload.response);
      state.goals = orEmptyGraphGoalsDto(action.payload.response.グラフ目標);
    },
    [fetchGraphData.fulfilled.toString()]: (
      state,
      action: fetchGraphDataAction
    ) => {
      console.log(
        "fetchGraphData",
        action.payload.request.periodType,
        action.payload.response
      );
      var entries = action.payload.response.entries;
      // var periodType = action.payload.request.periodType;
      const periodType = state.periodType;
      state.graphDataResponses[periodType] = entries;

      // 整形
      if (!state.graphData[periodType]) state.graphData[periodType] = {};

      if (state.periodType === GraphPeriodTypeValue.ONEYEAR) {
        const newentries = [];
        const twelveMonthsAgo = new Date();
        twelveMonthsAgo.setMonth(twelveMonthsAgo.getMonth() - 13); // 12ヶ月前の日時を設定
        for (const d of entries) {
          let dDate = [
            d.date.substr(0, 4),
            d.date.substr(4, 2),
            d.date.substr(6, 2),
          ].join("-");
          if (new Date(dDate).getTime() > twelveMonthsAgo.getTime()) {
            newentries.push(d);
          }
        }
        entries = newentries;
      }
      // 日付リスト
      const labels = [];
      for (const d of entries) {
        if (periodType === GraphPeriodTypeValue.MONTH) {
          labels.push(
            [
              d.date.substr(0, 4),
              d.date.substr(4, 2),
              d.date.substr(6, 2),
            ].join("-")
          );
        } else {
          labels.push(
            [d.date.substr(0, 4), d.date.substr(4, 2), "01"].join("-")
          );
        }
      }
      console.log("##################", GraphValueTypes);
      for (const graphType of GraphValueTypes) {
        if (!state.graphData[periodType][graphType]) {
          state.graphData[periodType][graphType] = {
            labels: labels,
            values: [],
            rankingCounts: [],
          };
        }
        state.graphData[periodType][
          graphType
        ].rankingCounts = getRankingCountByEntries(graphType, entries);
        let values = getValueByEntries(graphType, entries);
        // 累積化
        if (periodType === GraphPeriodTypeValue.MONTH) {
          if (
            graphType !== GraphType.グループポイントランキング.toString() &&
            graphType !== GraphType.店舗ポイントランキング.toString() &&
            graphType !== GraphType.給与総支給額.toString()
          ) {
            values = 累積化(values);
          }
        }
        state.graphData[periodType][graphType].values = values;
      }
    },
  },
});

export const { setPeriodType } = graphSlice.actions;
export const selectGraph = (state: RootState) => state.graph;
export default graphSlice.reducer;

export const GraphValueTypes = [
  GraphType.成績.toString(),
  GraphType.給与総支給額.toString(),
  GraphType.同伴数.toString(),
  GraphType.指名本数.toString(),
  GraphType.出勤時間.toString(),
  GraphType.フリー件数.toString(),
  GraphType.店舗ポイントランキング.toString(),
  GraphType.グループポイントランキング.toString(),
  場内指名件数,
];

function getValueByEntries(
  key: string,
  entries: GraphDataEntry[]
): GraphValue[] {
  const values = [];
  for (const entry of entries) {
    if (entry.無効) {
      values.push(null);
      continue;
    }
    switch (key) {
      case GraphType.成績.toString():
        values.push(entry.成績);
        break;
      case GraphType.給与総支給額.toString():
        values.push(entry.支給総額);
        break;
      case GraphType.同伴数.toString():
        values.push(entry.同伴本数);
        break;
      case GraphType.フリー件数.toString():
        values.push(entry.フリー件数);
        break;
      case GraphType.指名本数.toString():
        values.push(entry.指名本数);
        break;
      case GraphType.出勤時間.toString():
        values.push(entry.出勤時間);
        break;
      case GraphType.店舗ポイントランキング.toString():
        values.push(entry.店舗内ランキング * -1);
        break;
      case GraphType.グループポイントランキング.toString():
        values.push(entry.グループ内ランキング * -1);
        break;
      case 場内指名件数:
        values.push(entry.場内指名件数);
        break;
    }
  }
  return values;
}

function getRankingCountByEntries(
  key: string,
  entries: GraphDataEntry[]
): number[] {
  const rankingCounts = [];
  for (const d of entries) {
    switch (key) {
      case GraphType.店舗ポイントランキング.toString():
        rankingCounts.push(d.店内有効キャスト数);
        break;
      case GraphType.グループポイントランキング.toString():
        rankingCounts.push(d.グループ内有効キャスト数);
        break;
    }
  }
  return rankingCounts;
}
