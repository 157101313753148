import React from 'react';
import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
import {EffectDetail, effectImageHeight} from "../../lib/effectLib";
import classNames from 'classnames';
import {assetPath} from "../../lib/pathes";

const useStyles = makeStyles((theme: Theme) => createStyles({
  image:{
    display: 'block',
    position: 'absolute',
  },
  horizontalCenter:{
    position: 'absolute',
    left: '50%',
    transform: 'translate(-50%)',
  },
  title: {
    top: effectImageHeight(980),
    fontSize: 22,
    fontWeight: 'bold',
    color: 'rgb(89, 88, 87)',
    width: '76%',
    textAlign: 'center',
    height: effectImageHeight(197),
    lineHeight: 1.3,
    letterSpacing: 1.5,
    wordBreak: 'break-all',
    display: 'grid',
    placeItems: 'center',
  },
  ellipsisBy2Line: {
    display: '-webkit-box',
    overflow: 'hidden',
    '-webkitLineClamp': 2,
    '-webkitBoxOrient': 'vertical',
  },
}));

export function getEffectSheetType8PreloadImageUrls(sheet: EffectDetail): string[] {
  return [assetPath(sheet.sheetImagePath)]
}

interface Props {
  sheet: EffectDetail;
  className: string;
}

// SheetLayoutType.お誕生日
export function EffectSheetType8(props: Props) {
  const classes = useStyles();

  return <div className={props.className} style={{backgroundImage: `url(${assetPath(props.sheet.sheetImagePath)})`}}>
    <div className={classNames(classes.image, classes.horizontalCenter, classes.title)}>
      <div className={classes.ellipsisBy2Line}>
        {props.sheet.dto.param1}
      </div>
    </div>
  </div>;
}
