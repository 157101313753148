import axios from 'axios';

import { store } from '../app/store';

import { sendDataVersion } from './api';

// TODO enum定義に出力
// const ALL_ORDINAL = 0;
const VOLATILE_ORDINAL = 1;
// const DAILY_ORDINAL = 2;
// const SCHEDULE_ORDINAL = 3;

let dataVersions: string[] = [];
let frontVersion = '';

function validateFrontVersion(v: string) {
  if (!frontVersion) {
    frontVersion = v;
  } else if (frontVersion !== v) {
    window.location.reload();
  }
}

function validateDataVersions(v: string[]) {
  if (0 < dataVersions.length) {
    // TODO ストア永続化実装
    // const prevAllVersion = dataVersions[ALL_ORDINAL];
    const prevVolatileVersion = dataVersions[VOLATILE_ORDINAL];
    // const prevDailyVersion = dataVersions[DAILY_ORDINAL];
    // const prevScheduleVersion = dataVersions[SCHEDULE_ORDINAL];
    // const nextAllVersion = v[ALL_ORDINAL];
    const nextVolatileVersion = v[VOLATILE_ORDINAL];
    // const nextDailyVersion = v[DAILY_ORDINAL];
    // const nextScheduleVersion = v[SCHEDULE_ORDINAL];

    if (prevVolatileVersion !== nextVolatileVersion) {
      console.log(
        `validateDataVersions VOLATILE updated. prev:'${prevVolatileVersion}', next:'${nextVolatileVersion}'`,
      );
      window.location.reload();
    }

    // if (prevAllVersion !== nextAllVersion) {
    //   console.log(
    //     `validateDataVersions ALL updated. prev:'${prevVolatileVersion}', next:'${nextVolatileVersion}'`,
    //   );
    //   // TODO 有効にするとimport周りでエラーが出る
    //   // store.dispatch(clearState());
    // }
  }
  dataVersions = v;
}

export function checkVersions() {
  // React更新チェック
  axios
    .get('/version.txt')
    .then((response) => {
      validateFrontVersion(response.data);
    })
    .catch((error) => {
      // validateFrontVersion('');
    });

  // データ更新チェック
  if (store.getState().auth.isAuthenticated) {
    sendDataVersion(
      {},
      (response) => {
        validateDataVersions(response.v);
      },
      /* eslint @typescript-eslint/no-empty-function: 0 */
      (error) => {},
    );
  }
}

export function startCheckVersions() {
  checkVersions();
  if (!process.env.REACT_APP_VERSION_CHECK_INTERVAL_SECOND) return;
  setInterval(() => {
    checkVersions();
  }, parseInt(process.env.REACT_APP_VERSION_CHECK_INTERVAL_SECOND) * 1000);
}
